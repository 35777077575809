import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAIL,
  MESSAGE_ADDED,
  DELETE_MESSAGE,
  MESSAGE_REMOVED,
  CREATE_MESSAGE,
  CREATE_MESSAGE_FAIL,
  UPDATE_MESSAGE,
  MESSAGE_UPDATED,
} from './actionTypes'
import { IMessage, IPhoneMenu } from 'truly-ts'

export const fetchMessages = () => ({
  type: FETCH_MESSAGES,
  payload: {},
})

export const fetchMessagesSuccess = (messages: IMessage[]) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: {
    messages,
  },
})

export const fetchMessagesFail = () => ({
  type: FETCH_MESSAGES_FAIL,
  payload: {},
})

export const messageAdded = (message: IMessage) => ({
  type: MESSAGE_ADDED,
  payload: {
    message,
  },
})

export const deleteMessage = (
  message: IMessage,
  phoneMenus?: IPhoneMenu[],
) => ({
  type: DELETE_MESSAGE,
  payload: {
    message,
    phoneMenus,
  },
})

export const messageRemoved = (message: IMessage) => ({
  type: MESSAGE_REMOVED,
  payload: {
    message,
  },
})

export const createMessage = (title: string, file: Blob) => ({
  type: CREATE_MESSAGE,
  payload: {
    title,
    file,
  },
})

export const createMessageFail = () => ({
  type: CREATE_MESSAGE_FAIL,
  payload: {},
})

export const updateMessage = (id: number, message: IMessage, file: Blob) => ({
  type: UPDATE_MESSAGE,
  payload: {
    id,
    message,
    file,
  },
})

export const updateMessageFail = () => ({
  type: CREATE_MESSAGE_FAIL,
  payload: {},
})

export const messageUpdated = (message: IMessage) => ({
  type: MESSAGE_UPDATED,
  payload: {
    message,
  },
})
