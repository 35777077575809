import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import {
  FETCH_CONFERENCE_ROOMS,
  CREATE_CONFERENCE_ROOM,
  UPDATE_CONFERENCE_ROOM_SETTINGS,
  DELETE_CONFERENCE_ROOM,
} from './actionTypes'
import {
  fetchConferenceRoomsFail,
  fetchConferenceRoomsSuccess,
  createConferenceRoom,
  conferenceRoomAdded,
  updateConferenceRoomSettings,
  conferenceRoomUpdated,
  deleteConferenceRoom,
  createConferenceRoomFail,
} from './actionCreators'
import * as selectors from './conferenceRoomsSelectors'
import { IConferenceRoom, IConferenceRoomCreate } from 'truly-ts'
import { getRequired } from 'truly-utils/macro'
import { push } from 'connected-react-router'
import { fetchPhoneNumbers } from '../phoneNumbers/actionCreators'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* conferenceRoomsSaga() {
  yield all([
    takeLatest(FETCH_CONFERENCE_ROOMS, fetchConferenceRoomsSaga),
    takeLatest(CREATE_CONFERENCE_ROOM, createConferenceRoomSaga),
    takeLatest(
      UPDATE_CONFERENCE_ROOM_SETTINGS,
      updateConferenceRoomSettingsSaga,
    ),
    takeLatest(DELETE_CONFERENCE_ROOM, deleteConferenceRoomSaga),
  ])
}

export function* fetchConferenceRoomsSaga() {
  try {
    const req = yield call(client.conferencePhones.fetchConferencePhones)
    yield put(fetchConferenceRoomsSuccess(req.data.conference_phones))
  } catch (e) {
    console.error('fetching conference rooms', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get conference rooms. Please refresh the page.',
    )
    yield put(fetchConferenceRoomsFail())
  }
}

function* createConferenceRoomSaga(
  action: ReturnType<typeof createConferenceRoom>,
) {
  try {
    if (!action.payload.isTrulyNumber) {
      // need to purchase first
      yield call(client.lg.purchasePhoneNumber, action.payload.number)
    }

    const req = yield call(client.conferencePhones.createConferencePhone, {
      name: action.payload.name,
      number: action.payload.number,
    } as IConferenceRoomCreate)
    const conferenceRoom = req.data.conference_phone as IConferenceRoom
    yield put(conferenceRoomAdded(conferenceRoom))
    // Because the server will add entity assignment info that we can't determine locally, we just need to refetch numbers
    yield put(fetchPhoneNumbers())
    yield put(push(`/conference-rooms/${req.data.conference_phone.id}`))
  } catch (e) {
    console.log('creating conference room', e)
    toastr.error(
      'An Error Occurred',
      'Unable to add the conference room. Please try again.',
    )
    yield put(createConferenceRoomFail())
  }
}

function* updateConferenceRoomSettingsSaga(
  action: ReturnType<typeof updateConferenceRoomSettings>,
) {
  const rooms = yield select(selectors.conferenceRoomMap)
  const existingRoom = getRequired(rooms[action.payload.id] as IConferenceRoom)
  const newRoom = {
    ...existingRoom,
    settings: action.payload.settings,
  } as IConferenceRoom
  yield put(conferenceRoomUpdated(newRoom)) // optimistic
  try {
    const req = yield call(
      client.conferencePhones.updateConferenceRoomSettings,
      action.payload.id,
      action.payload.settings,
    )
    yield put(conferenceRoomUpdated(req.data.conference_phone)) // post any new updates
  } catch (e) {
    console.log('updating conference room settings', e)
    toastr.error(
      'An Error Occurred',
      'Unable to update conference room settings. Please try again.',
    )
    yield put(conferenceRoomUpdated(existingRoom)) // revert
  }
}

function* deleteConferenceRoomSaga(
  action: ReturnType<typeof deleteConferenceRoom>,
) {
  const { room } = action.payload
  try {
    yield call(client.conferencePhones.deleteConferencePhone, room.id)
  } catch (e) {
    yield put(conferenceRoomAdded(room))
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete conference room. Please try again.',
    )
  }
}
