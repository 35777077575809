import { IState } from '../../store'
import { createSelector } from 'reselect'
import values from 'lodash/values'

export const base = (state: IState) => state.messages
export const messages = createSelector(
  base,
  m => m.messages && values(m.messages),
)
export const messagesLoading = (state: IState) => base(state).loading
export const modifiedMessage = (state: IState) => base(state).modifiedMessage
export const messageById = (id: number) => (state: IState) => {
  const map = base(state).messages
  return map && map[id]
}
