import React, { useCallback } from 'react'
import { IConferenceRoom, IPhoneNumber } from 'truly-ts'
import {
  ModalLayout,
  SidePanelModal,
  colors,
  TabList,
  Tab,
  Regular,
  Record,
} from 'js-components'
import ConferenceRoomDeleteDialog from '../ConferenceRoomDeleteDialog/ConferenceRoomDeleteDialog'
import AddPhoneNumberModal from '../AddPhoneNumberModal/AddPhoneNumberModal'
import EnabledSettingRow from '../EnabledSettingRow/EnabledSettingRow'
import PhoneNumberAssignRow from '../PhoneNumberAssignmentRow/PhoneNumberAssignRow'
import PhoneNumberUnassignRow from '../PhoneNumberAssignmentRow/PhoneNumberUnassignRow'
import { useOpenCloseState, useActionCreator } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  updateConferenceRoomSettings,
  deleteConferenceRoom,
} from '../../reducers/conferenceRooms/actionCreators'
import { assignPhoneNumber } from '../../reducers/phoneNumbers/actionCreators'
import useRouter from '../../utils/custom-hooks/useRouter'
import PasswordRow from '../PasswordRow'
import { ReadonlyText } from '../UserDetail/Styles'

const TabListWrapper: React.SFC = ({ children }) => (
  <ModalLayout.Row>{children}</ModalLayout.Row>
)

const ConferenceRoomEditPanel: React.FC<{
  conferenceRoom: IConferenceRoom | null
}> = props => {
  const { conferenceRoom } = props
  const [deletingRoom, openDeletingRoom, closeDeletingRoom] = useOpenCloseState(
    false,
  )
  const [addingNumber, openAddingNumber, closeAddingNumber] = useOpenCloseState(
    false,
  )
  const onDelete = useActionCreator(deleteConferenceRoom)
  const router = useRouter(true)
  const updateSettings = useActionCreator(updateConferenceRoomSettings)
  const onAssignPhoneNumber = useActionCreator(assignPhoneNumber)

  const show = !!conferenceRoom

  const onClose = () => {
    router.push('/conference-rooms')
  }

  const closeDelete = (deleted?: boolean) => {
    closeDeletingRoom()
    if (deleted) {
      // this is needed to make sure the deletion dialog animates closed too
      setTimeout(onClose)
    }
  }

  const toggleCallRecordingEnabled = useCallback(
    (enabled: boolean) => {
      if (!conferenceRoom) {
        throw new Error('toggleCallRecordingEnabled without conferenceRoom')
      }
      const newSettings = {
        ...conferenceRoom.settings,
        record_calls: enabled,
      } as IConferenceRoom['settings']
      updateSettings(getRequired(conferenceRoom.id), newSettings)
    },
    [conferenceRoom, updateSettings],
  )

  const addPhoneNumber = useCallback(
    (pn: IPhoneNumber) => {
      if (!conferenceRoom) {
        throw new Error("Can't add phone number to null conference room")
      }
      onAssignPhoneNumber('conference_phone', conferenceRoom.id, pn.full_number)
    },
    [conferenceRoom, onAssignPhoneNumber],
  )

  const handleDelete = () => {
    if (!conferenceRoom) {
      throw new Error("Can't handleDelete to null conference room")
    }
    onDelete(conferenceRoom)
  }

  return (
    <SidePanelModal
      width="550px"
      onRequestClose={onClose}
      menuOptions={[
        {
          label: 'Delete Conference Room',
          bold: true,
          color: colors.alertRed,
          onClick: openDeletingRoom,
        },
      ]}
      visible={show}>
      {conferenceRoom && (
        <ConferenceRoomDeleteDialog
          show={deletingRoom}
          selectedRoom={conferenceRoom}
          onClose={closeDelete}
          onDelete={handleDelete}
        />
      )}
      <AddPhoneNumberModal
        show={addingNumber}
        onClose={closeAddingNumber}
        onAdd={addPhoneNumber}
      />
      {conferenceRoom && (
        <ModalLayout.Container>
          <ModalLayout.Header
            title={conferenceRoom.name}
            subtitle="Conference Room"
            addBottomBorder
          />
          <ModalLayout.Content>
            <ModalLayout.Spacer />
            <TabList tabListWrapper={TabListWrapper}>
              <Tab name="Details">
                <ModalLayout.SubHeader title="Basic Information" />
                <ModalLayout.FieldRow
                  label="Conference Room Name"
                  labelWidth="220px">
                  <Regular color={colors.trulyDark}>
                    {conferenceRoom.name}
                  </Regular>
                </ModalLayout.FieldRow>
                <ModalLayout.SubHeader title="SIP Credentials" />
                <ModalLayout.FieldRow
                  shouldPadRight
                  label="Username"
                  labelWidth="160px"
                  data-cy="sip-username">
                  <ReadonlyText>
                    {conferenceRoom.sip_account &&
                      `${conferenceRoom.sip_account.user_name}@${conferenceRoom.sip_account.domain}`}
                  </ReadonlyText>
                </ModalLayout.FieldRow>
                <PasswordRow
                  password={conferenceRoom.sip_account.password}
                  data-cy="sip-password"
                />
                <ModalLayout.SubHeader title="Settings" />
                <ModalLayout.Row>
                  <EnabledSettingRow
                    enabled={conferenceRoom.settings.record_calls}
                    title="Call Recording"
                    enabledDesc="Calls will be recorded for this conference room."
                    disabledDesc="Calls will not be recorded for this conference room."
                    onChange={toggleCallRecordingEnabled}
                    icon={<Record width="22px" height="22px" />}
                  />
                </ModalLayout.Row>
              </Tab>
              <Tab name="Phone Numbers">
                <ModalLayout.SubHeader title="Extension" />
                <ModalLayout.FieldRow
                  label="Extension Number"
                  labelWidth="180px">
                  <Regular color={colors.trulyDark}>Unknown</Regular>
                </ModalLayout.FieldRow>
                <ModalLayout.SubHeader title="Phone Numbers" mb="0px" />
                {conferenceRoom.numbers.map((n, idx) => (
                  <PhoneNumberUnassignRow
                    key={`line-${n}`}
                    number={n}
                    assignedEntityType="conference_phone"
                    assignedEntityId={conferenceRoom.id}
                    assignedEntityDisplay={conferenceRoom.name}
                    includeSeparator={idx !== 0}
                  />
                ))}
                <PhoneNumberAssignRow
                  onClick={openAddingNumber}
                  includeSeparator={conferenceRoom.numbers.length !== 0}
                />
                <ModalLayout.LightHorizontalLine />
              </Tab>
            </TabList>
          </ModalLayout.Content>
          <ModalLayout.Footer
            pinToBottom
            actionText="Done"
            onAction={onClose}
          />
        </ModalLayout.Container>
      )}
    </SidePanelModal>
  )
}

export default ConferenceRoomEditPanel
