import { FINISH_OAUTH, LOGGED_IN } from './actionTypes'
import { IAction } from '../../store'
import { IAccount } from 'truly-ts'
import { loggedIn } from './actionCreators'

export interface AuthState {
  isAuthenticated: boolean
  token: string | null
  loggedInAccount: IAccount | null
}

const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  token: null,
  loggedInAccount: null,
}

const auth = (state: AuthState = INITIAL_STATE, action: IAction): AuthState => {
  switch (action.type) {
    case FINISH_OAUTH:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      }
    case LOGGED_IN:
      return {
        ...state,
        loggedInAccount: (action as ReturnType<typeof loggedIn>).payload
          .account,
      }
    default:
      return state
  }
}

export default auth
