import { connect } from 'react-redux'
import { IState, Dispatch } from '../store'
import { fetchAccounts } from '../reducers/accounts/actionCreators'
import * as accountsSelector from '../reducers/accounts/accountsSelectors'
import NodeRulesRow from '../components/PhoneMenuNodeEditPanel/NodeRulesRow'

const mapStateToProps = (state: IState) => ({
  users: accountsSelector.accounts(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadData: () => {
    dispatch(fetchAccounts())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeRulesRow)
