import React, { useState } from 'react'
import {
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  TableRow,
  TableData,
  ToolTip,
  Trash,
  Small,
  colors,
  FlexColumn,
  DeleteDialog,
  Edit,
  EmptyResults,
} from 'js-components'
import {
  MainContainer,
  ContentContainer,
  TableWrapper,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import IntegrationModal from './IntegrationModal'
import PageTitle from '../PageTitle/PageTitle'
import { IntegrationsTitle } from '../../constants/PageTitles'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { IAPICredential } from 'truly-ts'
import { LoaderWrapper } from './Styles'
import Loader from '../Loader/Loader'
import { useOpenCloseState, usePreloader } from 'truly-utils'
import { useDispatch } from 'react-redux'
import {
  fetchApiCredentials,
  generateAPICredential,
  deleteAPICredential,
  updateAPICredential,
} from '../../reducers/credentials/actionCreators'
import * as selectors from '../../reducers/credentials/credentialsSelectors'

const RowHeight = 50
const NameWidth = '120px'
const APIKeyWidth = '260px'
const APISecretWidth = '265px'

interface CredentialTableRowProps {
  credential: IAPICredential
  rowHeight: number
  onDelete: (credential: IAPICredential) => void
  onEdit: (credential: IAPICredential) => void
}

const IntegrationHeader: React.FC = props => (
  <TableHeaderRow {...props}>
    <TableHeaderData width={NameWidth} text="Name" />
    <TableHeaderData width={APIKeyWidth} text="API Key" />
    <TableHeaderData width={APISecretWidth} text="API Secret" />
    <TableHeaderData text="" />
  </TableHeaderRow>
)

const IntegrationsHeading = ({
  onAdd,
  loading,
}: {
  onAdd?: () => void
  loading?: boolean
}) => (
  <ContentHeading
    title={IntegrationsTitle}
    rightControl={
      !loading && (
        <HeadingAddButton
          accessibilityLabel="Generate API Credentials"
          onClick={onAdd}
        />
      )
    }
  />
)

const Integrations: React.FC = props => {
  const dispatch = useDispatch()
  const credentials = usePreloader(selectors.credentials, fetchApiCredentials)

  const [deletingCreds, openDeleteModal, closeDeleteModal] = useOpenCloseState(
    false,
  )
  const [editingCreds, openEditModal, closeEditModal] = useOpenCloseState(false)

  const [
    selectedCredential,
    setSelectedCredential,
  ] = useState<IAPICredential | null>(null)

  const updateCredential = (name: string | null) => {
    if (selectedCredential) {
      dispatch(
        updateAPICredential({
          ...selectedCredential,
          name,
        }),
      )
    } else {
      dispatch(generateAPICredential(name))
    }
    closeEditModal()
  }

  if (!credentials) {
    return (
      <FlexColumn>
        <PageTitle title={IntegrationsTitle} />
        <IntegrationsHeading loading />
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </FlexColumn>
    )
  }

  const confirmDelete = () => {
    if (!selectedCredential) {
      throw new Error("Can't delete - no credential selected")
    }
    closeDeleteModal()
    dispatch(deleteAPICredential(selectedCredential))
  }

  const handleDelete = (cred: IAPICredential) => {
    setSelectedCredential(cred)
    openDeleteModal()
  }

  const newCredential = () => {
    setSelectedCredential(null)
    openEditModal()
  }

  const handleEdit = (cred: IAPICredential) => {
    setSelectedCredential(cred)
    openEditModal()
  }

  return (
    <MainContainer>
      <PageTitle title={IntegrationsTitle} />
      <IntegrationModal
        credential={selectedCredential}
        show={editingCreds}
        onAdd={updateCredential}
        onClose={closeEditModal}
      />
      <DeleteDialog
        show={deletingCreds}
        title="Delete API Credential"
        deleteActionText="Delete"
        description="This API Credential will be deleted."
        onCancel={closeDeleteModal}
        onDelete={confirmDelete}
        confirmText="DELETE"
      />
      <IntegrationsHeading onAdd={newCredential} />
      <ContentContainer padTop>
        <TableWrapper>
          <ScrollableTable
            header={<IntegrationHeader />}
            emptyView={
              <EmptyResults
                title="Access the Truly API"
                subtitle="Generate an API credentials to retrieve data from Truly"
                actionText="Generate Credential"
                onActionClick={newCredential}
              />
            }
            rowHeight={RowHeight}
            rowCount={credentials.length}
            renderRow={idx => (
              <CredentialRow
                key={idx}
                rowHeight={RowHeight}
                onDelete={handleDelete}
                onEdit={handleEdit}
                credential={credentials[idx]}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    </MainContainer>
  )
}

const CredentialRow: React.FC<CredentialTableRowProps> = props => {
  const { credential, onDelete, onEdit, rowHeight, ...restProps } = props

  const onEditRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onEdit(credential)
  }

  const onDeleteRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onDelete(credential)
  }

  const renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  return (
    <TableRow height={rowHeight} verticalAlign="middle" {...restProps}>
      <TableData width={NameWidth}>
        <Small
          bold
          color={credential.name ? colors.trulyDark : colors.mediumGray}>
          {credential.name ? credential.name : 'No Name'}
        </Small>
      </TableData>
      <TableData width={APIKeyWidth}>
        <Small color={colors.trulyDark}>{credential.app_id}</Small>
      </TableData>
      <TableData width={APISecretWidth}>
        <Small color={colors.trulyDark}>{credential.app_secret}</Small>
      </TableData>
      <TableData showOnHover>
        <RightAlignedTableData>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Delete')}>
            <ActionButton
              cursor="pointer"
              onClick={onDeleteRow}
              role="button"
              data-cy="delete-button">
              <Trash width="24" height="24" />
            </ActionButton>
          </ToolTip>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Edit')}>
            <ActionButton
              cursor="pointer"
              onClick={onEditRow}
              role="button"
              data-cy="edit-button">
              <Edit width="24" height="24" />
            </ActionButton>
          </ToolTip>
        </RightAlignedTableData>
      </TableData>
    </TableRow>
  )
}

export default React.memo(Integrations)
