import {
  CLEAR_UNSAVED_CHANGES,
  SET_UNSAVED_CHANGES,
  SHOW_UNSAVED_CHANGES_DIALOG,
  HIDE_UNSAVED_CHANGES_DIALOG,
} from './actionTypes'

import { setUnsavedChanges, showUnsavedChangesDialog } from './actionCreators'
import { createReducer } from 'truly-utils'
import { LocationActionPayload } from 'connected-react-router'

export interface NavigationState {
  hasUnsavedChanges: boolean
  continueAction?: LocationActionPayload
  unsavedTitle?: string
  unsavedDescription?: string
  allowedNavRegex?: string
}

const INITIAL_STATE: NavigationState = {
  hasUnsavedChanges: false,
}

const navigation = createReducer<NavigationState>(INITIAL_STATE, {
  [SET_UNSAVED_CHANGES]: (
    state,
    action: ReturnType<typeof setUnsavedChanges>,
  ) => ({
    ...state,
    hasUnsavedChanges: true,
    unsavedTitle: action.payload.title,
    unsavedDescription: action.payload.description,
    allowedNavRegex: action.payload.allowedNavRegex,
  }),
  [CLEAR_UNSAVED_CHANGES]: state => ({
    ...state,
    hasUnsavedChanges: false,
    unsavedTitle: undefined,
    unsavedDescription: undefined,
    allowedNavRegex: undefined,
  }),
  [SHOW_UNSAVED_CHANGES_DIALOG]: (
    state,
    action: ReturnType<typeof showUnsavedChangesDialog>,
  ) => ({
    ...state,
    continueAction: action.payload.continueAction,
  }),
  [HIDE_UNSAVED_CHANGES_DIALOG]: state => ({
    ...state,
    continueAction: undefined,
  }),
})

export default navigation
