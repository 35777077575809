import React, { Component, ReactNode } from 'react'
import { BodyContainer, Content } from './Styles'
import HeaderContainer from '../../containers/HeaderContainer'
import LeftNavContainer from '../../containers/LeftNavContainer'

interface MainLayoutProps {
  children: ReactNode
}

export default class MainLayout extends Component<MainLayoutProps> {
  render() {
    const { children } = this.props

    return (
      <div>
        <HeaderContainer />
        <BodyContainer>
          <LeftNavContainer />
          <Content>{children}</Content>
        </BodyContainer>
      </div>
    )
  }
}
