/* eslint global-require: 0 */
function deleteCookie(sKey: string, sPath: string, sDomain?: string) {
  document.cookie = `${encodeURIComponent(
    sKey,
  )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
    sDomain ? `; domain=${sDomain}` : ''
  }${sPath ? `; path=${sPath}` : ''}`
}

function setCookie(name: string, value: any, path: string, days: number) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=${path || '/'}`
}

export const storeToken = (token: string) => {
  localStorage.setItem('token', token)
  setCookie('truly-token', token, '/', 60)
}

export const clearToken = () => {
  localStorage.removeItem('token')
  deleteCookie('truly-token', '/')
}
