import isUndefined from 'lodash/isUndefined'

export function mapifyList<T extends { id?: number }>(lst: T[]) {
  const result: { [k: number]: T } = {}
  for (let i = 0; i < lst.length; i++) {
    const item = lst[i]
    if (!isUndefined(item.id)) {
      result[item.id] = item
    }
  }
  return result
}

export function iterableToArray<T>(iterable: IterableIterator<T>): T[] {
  const result: T[] = []
  let next = iterable.next()
  while (!next.done) {
    if (!isUndefined(next.value)) {
      result.push(next.value)
    }
    next = iterable.next()
  }
  return result
}
