import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import {
  FlexColumn,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  TableRow,
  TableData,
  Trash,
  colors,
  Regular,
  Small,
  ToolTip,
  DeleteDialog,
  FlexRow,
} from 'js-components'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { IBlockedNumber } from 'truly-ts'
import { formatPhoneNumber, numberTypeDisplay } from 'truly-utils'
import Loader from '../Loader/Loader'
import PageTitle from '../PageTitle/PageTitle'
import { BlockedNumbersTitle } from '../../constants/PageTitles'
import {
  addBlockedNumber,
  fetchBlockedNumbers,
  removeBlockedNumber,
} from '../../reducers/blockedNumbers/actionCreators'
import { useSelector, useDispatch } from 'react-redux'
import { IState } from '../../store'
import BlockedNumberCreateModal from './BlockedNumberCreateModal'
import styled from 'styled-components'

const RowHeight = 75
const CellWidth = '160px'

interface BlockedNumbersProps extends RouteComponentProps {
  blockedNumbers?: IBlockedNumber[]
  loadBlockedNumbers: () => void
  navigate: (path: string) => void
}

export default function BlockedNumbers(props: BlockedNumbersProps) {
  const [searchValue, setSearchValue] = React.useState('')

  const [deleting, setDeleting] = React.useState(false)

  const [blocking, setBlocking] = React.useState(false)

  const [
    selectedPhoneNumber,
    setSelectedPhoneNumber,
  ] = React.useState<IBlockedNumber | null>(null)

  const blockedNumbersState = useSelector(
    (state: IState) => state.blockedNumbers,
  )

  const blockedNumbers: IBlockedNumber[] = blockedNumbersState.blockedNumbers

  const filteredBlockedNumbers =
    searchValue.length > 0
      ? blockedNumbers.filter(bn => bn.number.includes(searchValue ?? 'none'))
      : blockedNumbers

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchBlockedNumbers())
  }, [dispatch])

  const onConfirmDelete = () => {
    if (!selectedPhoneNumber) {
      throw new Error("Can't onConfirmDelete without a selected number")
    }

    dispatch(removeBlockedNumber(selectedPhoneNumber))
    setDeleting(false)
    setSelectedPhoneNumber(null)
  }

  const onAddBlockedNumber = () => {
    setBlocking(true)
  }

  const onCloseDelete = () => {
    setDeleting(false)
    setSelectedPhoneNumber(null)
  }

  const clearSearch = () => {
    setSearchValue('')
  }

  const onDeleteBlockedNumber = (bn: IBlockedNumber) => {
    setSelectedPhoneNumber(bn)
    setDeleting(true)
  }

  const onCloseAddBlockedNumber = () => {
    setBlocking(false)
  }

  const confirmAddBlockedNumber = (bn: IBlockedNumber) => {
    dispatch(addBlockedNumber(bn))
  }

  if (blockedNumbersState.loading) {
    return (
      <FlexColumn>
        <PageTitle title={BlockedNumbersTitle} />
        <ContentHeading title={BlockedNumbersTitle} />
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </FlexColumn>
    )
  }

  return (
    <Container>
      <PageTitle title={BlockedNumbersTitle} />
      <BlockedNumberCreateModal
        show={blocking}
        onClose={onCloseAddBlockedNumber}
        addBlockedNumber={confirmAddBlockedNumber}
      />
      <DeleteDialog
        show={deleting}
        title={
          selectedPhoneNumber
            ? `Delete ${formatPhoneNumber(selectedPhoneNumber.number)}`
            : 'No Number Selected'
        }
        deleteActionText="Delete"
        description="This blocked number will be removed from your organization."
        confirmText="DELETE"
        onCancel={onCloseDelete}
        onDelete={onConfirmDelete}
      />
      <ContentHeading
        title={BlockedNumbersTitle}
        rightControl={
          <HeadingAddButton
            accessibilityLabel="Add Phone Number"
            onClick={onAddBlockedNumber}
          />
        }
      />
      <ContentContainer>
        <TableFilterWrapper>
          <TableFilterRow
            searchInput
            searchInputValue={searchValue}
            onSearchInputChanged={setSearchValue}
            selectedFilterOption="all"
            filterOptions={[
              {
                value: 'all',
                label: 'Blocked Numbers',
              },
            ]}
            searchPlaceholder="Filter"
          />
        </TableFilterWrapper>
        <BlockedNumberTable
          clearSearch={clearSearch}
          onDeleteBlockedNumber={onDeleteBlockedNumber}
          blockedNumbers={filteredBlockedNumbers}
        />
      </ContentContainer>
    </Container>
  )
}

const BlockedNumberTable: React.FC<{
  clearSearch: () => void
  blockedNumbers: IBlockedNumber[]
  onDeleteBlockedNumber: (bn: IBlockedNumber) => void
}> = React.memo(props => {
  const { clearSearch, onDeleteBlockedNumber, blockedNumbers } = props

  return (
    <TableWrapper>
      <ScrollableTable
        header={<BlockedNumberTableHeaderRow />}
        rowHeight={RowHeight}
        emptyView={
          <EmptyResults
            title={'No Blocked Numbers Found'}
            actionText={'Clear search'}
            onActionClick={clearSearch}
          />
        }
        rowCount={blockedNumbers.length}
        renderRow={idx => (
          <BlockedNumberTableRow
            key={idx}
            rowHeight={RowHeight}
            onDelete={onDeleteBlockedNumber}
            blockedNumber={blockedNumbers[idx]}
          />
        )}
      />
    </TableWrapper>
  )
})

const BlockedNumberTableHeaderRow = () => {
  return (
    <LeftAlignTableHeaderRow>
      <TableHeaderData width={CellWidth} text="Number" />
    </LeftAlignTableHeaderRow>
  )
}

interface IBlockedNumberTableRow {
  blockedNumber: IBlockedNumber
  onDelete: (bn: IBlockedNumber) => void
  rowHeight: Number
}

function BlockedNumberTableRow({
  blockedNumber,
  onDelete,
}: IBlockedNumberTableRow) {
  const handleDelete = () => {
    onDelete(blockedNumber)
  }

  const renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  return (
    <TableRow height={RowHeight} verticalAlign="top">
      <TableData width={CellWidth}>
        <NumberDataContainer>
          <PhoneNumberTextWrapper>
            <Regular color={colors.trulyDark}>
              {formatPhoneNumber(blockedNumber.number) ?? blockedNumber.number}
            </Regular>
          </PhoneNumberTextWrapper>
          <Small color={colors.accentPurple} bold>
            {numberTypeDisplay(blockedNumber.number)}
          </Small>
        </NumberDataContainer>
      </TableData>
      <TableData width={CellWidth}></TableData>
      <TableData showOnHover>
        <ActionsDataContainer>
          <ActionContainer>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={renderTooltipContent('Delete')}>
              <ActionIconWrapper onClick={handleDelete} data-cy="delete-button">
                <Trash width="24" height="24" />
              </ActionIconWrapper>
            </ToolTip>
          </ActionContainer>
        </ActionsDataContainer>
      </TableData>
    </TableRow>
  )
}

const LeftAlignTableHeaderRow = styled(TableHeaderRow)`
  justify-content: flex-start;
`

const Container = styled(FlexColumn)`
  height: 100%;
  max-height: 100%;
`

const ContentContainer = styled(FlexColumn)`
  padding: 0px 14px 14px 14px;
  max-height: 100%;
  height: 100%;
`

const LoaderWrapper = styled.div`
  margin-top: 200px;
`

const ActionContainer = styled.div`
  cursor: pointer;

  * {
    cursor: pointer;
  }
`

const ActionIconWrapper = styled.div`
  margin-right: 4px;
  margin-left: 4px;
  padding: 6px;
`

const PhoneNumberTextWrapper = styled.div`
  margin-bottom: 2px;
`

const TableFilterWrapper = styled.div`
  flex: 0;
`

const TableWrapper = styled(FlexColumn)`
  height: 100%;
`

const NumberDataContainer = styled(FlexColumn)`
  align-items: flex-start;
`

const ActionsDataContainer = styled(FlexRow)`
  justify-content: flex-end;
`
