import { clearToken, storeToken } from '../session'
import { fetchUser } from '../../reducers/user/actionCreators'

import {
  LOGOUT,
  FINISH_OAUTH,
  LOGGED_IN,
} from '../../reducers/auth/actionTypes'
import { FETCH_USER_SUCCESS } from '../../reducers/user/actionTypes'
import { Store, IAction, Next } from '../../store'
import includes from 'lodash/includes'
import { logout } from '../../reducers/auth/actionCreators'
import { toastr } from 'react-redux-toastr'
import { submitForm, injectQueryParam } from 'truly-utils'

// Can't go in main thread because has not window object
export const handleSession = () => (store: Store) => (next: Next) => (
  action: IAction,
) => {
  switch (action.type) {
    case FINISH_OAUTH: {
      const { token } = action.payload
      if (token) {
        storeToken(action.payload.token)
        // react-router-redux stuff doesn't work here to remove the search params
        const url = window.location.href.split('?')[0]
        window.history.replaceState({}, '', url)
        store.dispatch(fetchUser())
      }
      break
    }
    case LOGGED_IN: {
      // react-router-redux stuff doesn't work here to remove the search params
      const url = window.location.href.split('?')[0]
      window.history.replaceState({}, '', url)
      store.dispatch(fetchUser())
      break
    }
    case FETCH_USER_SUCCESS: {
      const account = action.payload.account
      if (!includes(account.permissions, 'access_admin')) {
        toastr.error('Access Denied', 'You do not have admin access.')
        return store.dispatch(logout())
      }
      break
    }
    case LOGOUT:
      clearToken()
      submitForm('POST', `${window.trulyEnv.truly_auth_url}/auth/logout`, {
        redirect_url: window.location.origin,
        error_redirect_url: injectQueryParam(
          window.location.origin,
          'logout_failed',
          'true',
        ),
      })
      break
    default:
  }

  return next(action)
}
