import { connect } from 'react-redux'
import TeamUsersTable from '../components/TeamUsersTable/TeamUsersTable'
import { push } from 'connected-react-router'
import { fetchAccounts } from '../reducers/accounts/actionCreators'

const mapDispatchToProps = {
  navigate: push,
  fetchAccounts,
}

export default connect(null, mapDispatchToProps)(TeamUsersTable)
