import React, { useState, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  TableRow,
  TableData,
  ToolTip,
  Trash,
  Edit,
  Small,
  colors,
  EmptyResults,
} from 'js-components'
import {
  MainContainer,
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import PageTitle from '../PageTitle/PageTitle'
import { WebhooksTitle } from '../../constants/PageTitles'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { IWebhook } from 'truly-ts'
import useRouter from '../../utils/custom-hooks/useRouter'
import WebhooksDeleteDialog from '../WebhooksDeleteDialog/WebhooksDeleteDialog'
import WebhookModal from '../WebhookModal/WebhookModal'
import * as selectors from '../../reducers/webhooks/webhooksSelectors'
import {
  fetchWebhooks,
  deleteWebhook,
} from '../../reducers/webhooks/actionCreators'
import usePreloader from '../../utils/data-hooks/usePreloader'
import {
  ValidationHandler,
  makeSearchFn,
  useOpenCloseState,
  useActionCreator,
} from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  DISPLAY_CALL_EVENTS,
  DISPLAY_SMS_EVENTS,
} from '../../constants/Webhooks'

const RowHeight = 50
const WebhookNameWidth = '180px'
const WebhookPostsWidth = '340px'

interface WebhooksTableRowProps {
  webhook: IWebhook
  rowHeight: number
  onEdit: (webhook: IWebhook) => void
  onDelete: (webhook: IWebhook) => void
}

const WebhooksHeaderRow: React.FC = props => (
  <TableHeaderRow {...props}>
    <TableHeaderData width={WebhookNameWidth} text="Webhook Name" />
    <TableHeaderData width={WebhookPostsWidth} text="Posts To" />
    <TableHeaderData text="Events" />
    <TableHeaderData text="" />
  </TableHeaderRow>
)

const Webhooks: React.FC<RouteComponentProps<{ id: string }>> = props => {
  const { match } = props
  const [searchValue, setSearchValue] = useState('')
  const [
    deletingWebhook,
    openDeletingWebhook,
    closeDeletingWebhook,
  ] = useOpenCloseState(false)
  const [
    showWebhookModal,
    openShowWebhookModal,
    closeShowWebhookModal,
  ] = useOpenCloseState(false)
  const [selectedWebhook, setSelectedWebhook] = useState<IWebhook>()
  const onDelete = useActionCreator(deleteWebhook)
  const router = useRouter(true)
  const webhooks = usePreloader(selectors.webhooks, fetchWebhooks)

  useEffect(() => {
    if (match.params.id && webhooks) {
      openShowWebhookModal()
      setSelectedWebhook(
        webhooks.find(webhook => webhook.uuid?.toString() === match.params.id),
      )
    } else {
      closeShowWebhookModal()
      setSelectedWebhook(undefined)
    }
  }, [match.params.id, webhooks, closeShowWebhookModal, openShowWebhookModal])

  const clearFilters = () => {
    setSearchValue('')
  }

  const onSearchInputChanged = (newSearchValue: string) => {
    setSearchValue(newSearchValue)
  }

  const onEditWebhook = (webhook: IWebhook) => {
    router.push(`/webhooks/${webhook.uuid}`)
  }

  const onDeleteWebhook = (webhook: IWebhook) => {
    openDeletingWebhook()
    setSelectedWebhook(webhook)
  }

  const handleDelete = () => {
    if (!selectedWebhook) {
      throw new Error("Can't delete null webhook")
    }
    onDelete(selectedWebhook)
    setSelectedWebhook(undefined)
  }

  const onModalClose = () => {
    closeShowWebhookModal()
    router.push(`/webhooks`)
  }

  const urlValidationHandler = new ValidationHandler(
    'a webhook with this URL already exists ',
    url =>
      !webhooks?.find(
        (webhook: IWebhook) =>
          selectedWebhook !== webhook && webhook.url === url,
      ),
  )

  const filteredWebhooks = useMemo(() => {
    if (webhooks) {
      let filteredHooks = getRequired(webhooks)

      if (searchValue && searchValue.trim()) {
        const searchFn = makeSearchFn<IWebhook>(['name'], searchValue)
        filteredHooks = filteredHooks.filter(searchFn)
      }

      return filteredHooks
    }

    return []
  }, [webhooks, searchValue])

  const hasWebhooks = webhooks && webhooks.length && searchValue

  const emptyValues = {
    title: hasWebhooks
      ? 'No Results Found'
      : 'Get Data From Truly In Real Time',
    subtitle: hasWebhooks
      ? null
      : 'Webhooks allow you to integrate Truly with your custom CRM or other integration.',
    actionText: hasWebhooks ? 'Clear Filters' : 'Add Webhook',
    action: hasWebhooks ? clearFilters : openShowWebhookModal,
  }

  return (
    <MainContainer>
      <PageTitle title={WebhooksTitle} />
      <WebhooksDeleteDialog
        show={deletingWebhook}
        onClose={closeDeletingWebhook}
        selectedWebhook={selectedWebhook}
        onDelete={handleDelete}
      />
      <WebhookModal
        show={showWebhookModal}
        onClose={onModalClose}
        webhook={selectedWebhook}
        urlValidationHandler={urlValidationHandler}
      />
      <ContentHeading
        title={WebhooksTitle}
        rightControl={
          <HeadingAddButton
            accessibilityLabel="Add Webhook"
            onClick={openShowWebhookModal}
          />
        }
      />
      <ContentContainer>
        <TableFilterWrapper>
          <TableFilterRow
            searchInput
            searchInputValue={searchValue}
            searchPlaceholder="filter webhooks"
            onSearchInputChanged={onSearchInputChanged}
            selectedFilterOption="all"
            filterOptions={[
              {
                value: 'all',
                label: 'All Webhooks',
              },
            ]}
          />
        </TableFilterWrapper>
        <TableWrapper>
          <ScrollableTable
            header={<WebhooksHeaderRow />}
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={emptyValues.title}
                subtitle={emptyValues.subtitle ?? undefined}
                actionText={emptyValues.actionText}
                onActionClick={emptyValues.action}
              />
            }
            rowCount={filteredWebhooks.length}
            renderRow={idx => (
              <WebhooksTableRow
                key={idx}
                rowHeight={RowHeight}
                onEdit={onEditWebhook}
                onDelete={onDeleteWebhook}
                webhook={filteredWebhooks[idx]}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    </MainContainer>
  )
}

const WebhooksTableRow: React.FC<WebhooksTableRowProps> = props => {
  const { webhook, onEdit, onDelete, rowHeight, ...restProps } = props

  const onEditRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onEdit(webhook)
  }

  const onDeleteRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onDelete(webhook)
  }

  const renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  const getEventsText = () => {
    const callEvents = webhook.call_events
    const smsEvents = webhook.sms_events
    const totalEventAmount = callEvents.length + smsEvents.length

    let eventText = callEvents.length
      ? DISPLAY_CALL_EVENTS[callEvents[0]]
      : DISPLAY_SMS_EVENTS[smsEvents[0]]

    if (totalEventAmount > 1) {
      eventText = `${eventText} (+${totalEventAmount - 1})`
    }

    return (
      <Small color={colors.trulyDark} truncate pr="12px">
        {eventText}
      </Small>
    )
  }

  return (
    <TableRow
      height={rowHeight}
      verticalAlign="middle"
      onClick={onEditRow}
      {...restProps}>
      <TableData width={WebhookNameWidth}>
        <Small bold truncate color={colors.trulyDark} pr="12px">
          {webhook.name}
        </Small>
      </TableData>
      <TableData width={WebhookPostsWidth}>
        <Small color={colors.trulyDark} truncate pr="12px">
          {webhook.url}
        </Small>
      </TableData>
      <TableData hideOverflow>{getEventsText()}</TableData>
      <TableData showOnHover>
        <RightAlignedTableData>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Delete')}>
            <ActionButton
              cursor="pointer"
              onClick={onDeleteRow}
              role="button"
              data-cy="delete-button">
              <Trash width="24" height="24" />
            </ActionButton>
          </ToolTip>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Edit')}>
            <ActionButton cursor="pointer" onClick={onEditRow} role="button">
              <Edit width="24" height="24" />
            </ActionButton>
          </ToolTip>
        </RightAlignedTableData>
      </TableData>
    </TableRow>
  )
}

export default React.memo(Webhooks)
