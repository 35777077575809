import * as React from 'react'
import find from 'lodash/find'
import { IPhoneNumber, EntityType } from 'truly-ts'
import {
  SidePanelModal,
  FlexColumn,
  Large,
  colors,
  NumpadSVG,
  TextInput,
  ModalLayout,
} from 'js-components'
import { formatPhoneNumber, numberTypeDisplay } from 'truly-utils'
import {
  EntityMap,
  EntityOption,
  entitiesToOptions,
  entityMapChanged,
} from '../../utils/Entity'
import EntitySelect from '../EntitySelect/EntitySelect'
import isUndefined from 'lodash/isUndefined'

const InputWidthPX = 350
const LabelWidth = '165px'

interface PhoneNumberEditPanelProps {
  phoneNumber?: IPhoneNumber | null
  show: boolean
  onClose: () => void
  entities: EntityMap
  savePhoneNumber: (
    e164Number: string,
    context: string | null,
    entityType: EntityType | null,
    entityId: number | null,
  ) => void
}

interface PhoneNumberEditPanelState {
  selectedAssignment: EntityOption | null
  context: string | null
  entityOptions: EntityOption[]
  dirty: boolean
  richSelectResetId: number
}

export default class PhoneNumberEditPanel extends React.Component<
  PhoneNumberEditPanelProps,
  PhoneNumberEditPanelState
> {
  private modalRef?: HTMLDivElement

  constructor(props: PhoneNumberEditPanelProps) {
    super(props)

    const entityOptions = entitiesToOptions(props.entities)

    this.state = {
      entityOptions,
      context: props.phoneNumber ? props.phoneNumber.context : '',
      selectedAssignment: this.getEntityOptionFromPhoneNumber(
        entityOptions,
        props.phoneNumber,
      ),
      dirty: false,
      richSelectResetId: 0,
    }
  }

  getEntityOptionFromPhoneNumber(
    options: EntityOption[],
    pn?: IPhoneNumber | null,
  ): EntityOption | null {
    if (!pn || !pn.entity) {
      return null
    }

    const option = find(
      options,
      o => o.entity_type === pn.entity?.type && o.entity_id === pn.entity.id,
    )

    return option || null
  }

  componentDidUpdate(prevProps: PhoneNumberEditPanelProps) {
    let entityOptions = this.state.entityOptions

    if (entityMapChanged(this.props.entities, prevProps.entities)) {
      entityOptions = entitiesToOptions(this.props.entities)
      this.setState({
        entityOptions,
      })
    }

    if (
      this.props.phoneNumber !== prevProps.phoneNumber ||
      this.props.show !== prevProps.show
    ) {
      this.setState({
        selectedAssignment: this.getEntityOptionFromPhoneNumber(
          entityOptions,
          this.props.phoneNumber,
        ),
        context: this.props.phoneNumber ? this.props.phoneNumber.context : '',
        dirty: false,
        richSelectResetId: this.state.richSelectResetId + 1,
      })
    }
  }

  savePhoneNumber = () => {
    if (!this.props.phoneNumber) {
      console.warn('Tried to save a phone number without being selected.')
      return
    }

    this.props.savePhoneNumber(
      this.props.phoneNumber.full_number,
      this.state.context,
      this.state.selectedAssignment
        ? this.state.selectedAssignment.entity_type
        : null,
      this.state.selectedAssignment && this.state.selectedAssignment.entity_id,
    )
    this.props.onClose()
  }

  captureRichSelectModalRef = (e: HTMLDivElement) => {
    if (e) {
      this.modalRef = e
    }
  }

  updateAssignment = (assignment: EntityOption | null) => {
    this.setState({
      selectedAssignment: assignment,
      dirty: true,
    })
  }

  contextChanged = (value?: string) =>
    !isUndefined(value) && this.setState({ context: value, dirty: true })

  getDisplayForOption = (option: EntityOption | null) =>
    option ? option.name : ''

  renderIcon(assignment: EntityOption | null) {
    if (
      assignment &&
      (assignment.entity_type === 'phonemenu' ||
        assignment.entity_type === 'node')
    ) {
      return <NumpadSVG width="100%" fillSeed={`${assignment.entity_id}`} />
    }
  }

  getAvatarText(assignment: EntityOption | null) {
    if (
      assignment &&
      ['conference_phone', 'account'].includes(assignment.entity_type)
    ) {
      return assignment.name
    }
  }

  renderContents(phoneNumber: IPhoneNumber) {
    const {
      entityOptions,
      selectedAssignment,
      context,
      dirty,
      richSelectResetId,
    } = this.state

    return (
      <ModalLayout.Container>
        <ModalLayout.Header
          title={formatPhoneNumber(phoneNumber.full_number) || ''}
          subtitle={numberTypeDisplay(phoneNumber.full_number)}
          addBottomBorder
        />
        <ModalLayout.Content>
          <ModalLayout.Spacer />
          <ModalLayout.FieldRow
            shouldPadRight
            labelWidth={LabelWidth}
            label="Assignment">
            <EntitySelect
              entityOptions={entityOptions}
              selectedAssignment={selectedAssignment}
              onChange={this.updateAssignment}
              optionWidth={InputWidthPX}
              innerRef={this.captureRichSelectModalRef}
              richSelectResetId={richSelectResetId}
              data-cy="phone-number-assignment-select"
            />
          </ModalLayout.FieldRow>
          <ModalLayout.FieldRow
            label="Context"
            shouldPadRight
            labelWidth={LabelWidth}
            helpText="Context will override the caller ID displayed for Truly users within your organization when they recieve a call from this number. Additionally, this will be displayed under each phone number in the phone number selector for your reps.">
            <TextInput
              value={context || ''}
              placeholder="None"
              onChange={this.contextChanged}
              data-cy="phone-number-context"
            />
          </ModalLayout.FieldRow>
        </ModalLayout.Content>
        <ModalLayout.Footer
          actionText="Save"
          pinToBottom
          onCancel={this.props.onClose}
          onAction={this.savePhoneNumber}
          actionDisabled={!dirty}
        />
      </ModalLayout.Container>
    )
  }

  renderEmpty() {
    return (
      <FlexColumn alignItems="center">
        <Large color={colors.trulyDark}>Please select a phone number.</Large>
      </FlexColumn>
    )
  }

  shouldPanelClose = (e: MouseEvent) => {
    return !this.modalRef || !this.modalRef.contains(e.target as any)
  }

  render() {
    const { onClose, show, phoneNumber } = this.props

    return (
      <SidePanelModal
        width="550px"
        onRequestClose={onClose}
        bgClickShouldRequestClose={this.shouldPanelClose}
        visible={show}>
        {phoneNumber ? this.renderContents(phoneNumber) : this.renderEmpty()}
      </SidePanelModal>
    )
  }
}
