import { IPhoneMenu, IPhoneNumber, INode } from 'truly-ts'
import { traverseNode } from './node-utils'

export function getAddedPhoneNumbers(
  oldPhoneMenu: Partial<IPhoneMenu>,
  newPhoneMenu: Partial<IPhoneMenu>,
): IPhoneNumber[] {
  const oldNumbers = getAllPhoneMenuPhoneNumbers(oldPhoneMenu)
  const newNumbers = getAllPhoneMenuPhoneNumbers(newPhoneMenu)
  return newNumbers.filter(
    n => !oldNumbers.some(o => n.full_number === o.full_number),
  )
}

export function getRemovedPhoneNumbers(
  oldPhoneMenu: Partial<IPhoneMenu>,
  newPhoneMenu: Partial<IPhoneMenu>,
): IPhoneNumber[] {
  const oldNumbers = getAllPhoneMenuPhoneNumbers(oldPhoneMenu)
  const newNumbers = getAllPhoneMenuPhoneNumbers(newPhoneMenu)
  return oldNumbers.filter(
    o => !newNumbers.some(n => n.full_number === o.full_number),
  )
}

export function isEditingTreeRoot(
  phoneMenu: IPhoneMenu | null | undefined,
  editingNode: INode,
) {
  if (!phoneMenu) return false
  return phoneMenu.tree.id === editingNode.id
}

function getAllPhoneMenuPhoneNumbers(phoneMenu: Partial<IPhoneMenu>) {
  const result: IPhoneNumber[] = []
  ;(phoneMenu.phonenumbers || []).forEach(pn => result.push(pn))
  if (phoneMenu.tree) {
    traverseNode(phoneMenu.tree, node =>
      (node.phonenumbers || []).forEach(pn => result.push(pn)),
    )
  }
  return result
}
