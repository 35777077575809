import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import {
  FETCH_EXTERNAL_USERS,
  DELETE_EXTERNAL_USER,
  CREATE_EXTERNAL_USER,
  UPDATE_EXTERNAL_USER,
} from './actionTypes'
import { toastr } from 'react-redux-toastr'
import {
  fetchExternalUsersFail,
  fetchExternalUsersSuccess,
  deleteExternalUser,
  externalUserDeleted,
  externalUserAdded,
  createExternalUser,
  externalUserUpdated,
  updateExternalUser,
} from './actionCreators'
import { fetchExtensions } from '../extensions/actionCreators'
import * as selectors from './externalUsersSelectors'
import { IExternalForward } from 'truly-ts'
import { getRequired } from 'truly-utils/macro'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* externalUsersSaga() {
  yield all([
    takeLatest(FETCH_EXTERNAL_USERS, fetchExternalUsersSaga),
    takeLatest(DELETE_EXTERNAL_USER, deleteExternalUserSaga),
    takeLatest(CREATE_EXTERNAL_USER, createExternalUserSaga),
    takeLatest(UPDATE_EXTERNAL_USER, updateExternalUserSaga),
  ])
}

export function* fetchExternalUsersSaga() {
  try {
    const req = yield call(client.externalForwards.fetchExternalForwards)
    yield put(fetchExternalUsersSuccess(req.data.external_forwards))
  } catch (e) {
    console.error('fetching external users', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get external users. Please refresh the page.',
    )
    yield put(fetchExternalUsersFail())
  }
}

function* deleteExternalUserSaga(
  action: ReturnType<typeof deleteExternalUser>,
) {
  const extUser = action.payload.externalUser
  yield put(externalUserDeleted(extUser)) // optimistic update
  try {
    yield call(client.externalForwards.deleteExternalForward, extUser.id)
    yield put(fetchExtensions())
  } catch (e) {
    console.error('deleting external user', e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete external user. Please try again',
    )
    yield put(externalUserAdded(extUser)) // revert
  }
}

function* createExternalUserSaga(
  action: ReturnType<typeof createExternalUser>,
) {
  const extUser = action.payload.externalUser
  try {
    const req = yield call(
      client.externalForwards.createExternalForward,
      extUser,
    )
    yield put(externalUserAdded(req.data.external_forward))
    yield put(fetchExtensions())
  } catch (e) {
    console.error('deleting external user', e)
    toastr.error(
      'An Error Occurred',
      'Unable to create external user. Please try again',
    )
  }
}

function* updateExternalUserSaga(
  action: ReturnType<typeof updateExternalUser>,
) {
  const extUser = action.payload.externalUser
  const existingUser = getExternalUserById(
    yield select(selectors.externalUsers),
    getRequired(extUser.id),
  )
  yield put(externalUserUpdated(extUser)) // optimistic

  try {
    yield call(
      client.externalForwards.updateExternalForward,
      extUser.id,
      extUser,
    )
    yield put(fetchExtensions())
  } catch (e) {
    console.error('deleting external user', e)
    toastr.error(
      'An Error Occurred',
      'Unable to update external user. Please try again',
    )
    if (existingUser) yield put(externalUserUpdated(existingUser)) // revert
  }
}

function getExternalUserById(extUsers: IExternalForward[], id: string) {
  if (extUsers) {
    return extUsers.find((u: IExternalForward) => u.id === id)
  }
}
