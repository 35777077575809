import React from 'react'
import usePreloader from '../../utils/data-hooks/usePreloader'
import * as roleSelectors from '../../reducers/roles/rolesSelectors'
import { colors, EmptyResults, SpinnerMaterial } from 'js-components'
import { push } from 'connected-react-router'
import { fetchRoles } from '../../reducers/roles/actionCreators'
import { devAssert } from 'truly-utils/macro'
import { useActionCreator } from 'truly-utils'

interface TeamPermissionModalProps {
  inputType: string
  tabLocation?: string
  teamId: number | null
}

const TeamPermissionModal = (props: TeamPermissionModalProps) => {
  const navigate = useActionCreator(push)
  const teams = usePreloader(roleSelectors.roles, fetchRoles)

  if (!teams) {
    return (
      <SpinnerMaterial
        color={colors.accentLavender}
        size="22px"
        strokeWidth={3}
      />
    )
  }

  const team = teams.find(tm => tm.id === props.teamId)
  devAssert(assert => assert(team, 'Team not found'))
  if (!team) return <div>Team not found. Contact support.</div>

  return (
    <EmptyResults
      title={`${props.inputType} is assigned at the team level.`}
      actionText={`Go to ${team.display_name} Team`}
      // eslint-disable-next-line react/jsx-no-bind
      onActionClick={() =>
        navigate(`/teams/${team.id}/${props.tabLocation || ''}`)
      }
      hideIcon
    />
  )
}

export default TeamPermissionModal
