import { makeValidatePasswordHandler } from 'truly-utils'
import trulyApiFactory from 'truly-api'
import { authTrulyApi } from './HTTP'

const authClient = trulyApiFactory({
  axios: authTrulyApi,
})

export const validatePassword = makeValidatePasswordHandler(
  authClient.accounts.validatePassword,
)
