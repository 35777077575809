import { IState } from '../../store'
import { createSelector, createStructuredSelector } from 'reselect'
import { inviteMap } from '../invites/invitesSelectors'
import { phoneMenuMap, allNodeMap } from '../phoneMenus/phoneMenusSelectors'
import { conferenceRoomMap } from '../conferenceRooms/conferenceRoomsSelectors'
import { externalUserMap } from '../externalUsers/externalUsersSelectors'
import { accountMap } from '../accounts/accountsSelectors'
import { EntityMap } from '../../utils/Entity'
export const base = (state: IState) => state
export const entitiesLoaded = createSelector(
  base,
  s =>
    !!(
      s.phoneMenus.phoneMenuMap &&
      s.phoneMenus.nodeMap &&
      s.invites.inviteMap &&
      s.conferenceRooms.conferenceRooms &&
      s.externalUsers.externalUsers &&
      s.accounts.accounts
    ),
)

export const entitiesMap = createStructuredSelector<IState, EntityMap>({
  inviteMap,
  phoneMenuMap,
  nodeMap: allNodeMap,
  conferenceRoomMap,
  externalUserMap,
  accountMap,
})
