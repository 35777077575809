export const FETCH_LG_SETTINGS = 'FETCH_LG_SETTINGS'
export const FETCH_LG_SETTINGS_SUCCESS = 'FETCH_LG_SETTINGS_SUCCESS'
export const FETCH_LG_SETTINGS_FAIL = 'FETCH_LG_SETTINGS_FAIL'

export const UPDATE_LG_COMPANY = 'UPDATE_LG_COMPANY'
export const UPDATE_LG_AUTO_INVITE = 'UPDATE_LG_AUTO_INVITE'
export const UPDATE_LG_ROUTING_PROTECTION = 'UPDATE_LG_ROUTING_PROTECTION'
export const UPDATE_LG_ACCESS_RECORDINGS = 'UPDATE_LG_ACCESS_RECORDING'
export const UPDATE_LG_CALL_COMPLIANCE = 'UPDATE_LG_CALL_COMPLIANCE'
export const UPDATE_LG_DATA_RETENTION = 'UPDATE_LG_DATA_RETENTION'
export const UPDATE_LG_ALLOW_PASSWORD_AUTH = 'UPDATE_LG_ALLOW_PASSWORD_AUTH'

export const LG_COMPANY_CHANGED = 'LG_COMPANY_CHANGED'
export const LG_AUTO_INVITE_CHANGED = 'LG_AUTO_INVITE_CHANGED'
export const LG_ROUTING_PROTECTION_CHANGED = 'LG_ROUTING_PROTECTION_CHANGED'
export const LG_ACCESS_RECORDINGS_CHANGED = 'LG_ACCESS_RECORDINGS_CHANGED'
export const LG_CALL_COMPLIANCE_CHANGED = 'LG_CALL_COMPLIANCE_CHANGED'
export const LG_DATA_RETENTION_CHANGED = 'LG_DATA_RETENTION_CHANGED'
export const LG_ALLOW_PASSWORD_AUTH_CHANGED = 'LG_ALLOW_PASSWORD_AUTH_CHANGED'
