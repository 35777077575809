import {
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAIL,
  PATCH_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_UPDATED,
  ACCOUNT_DELETED,
  ACCOUNT_ADDED,
  UPDATE_ACCOUNT_PERMISSION,
  RESET_USER_PASSWORD,
  PATCH_ACCOUNT_ROLE,
  INVITE_USER,
  RESEND_INVITE,
} from './actionTypes'
import { IFeatures, ILicenseGroupAccount } from 'truly-ts'

export const resetUserPassword = (
  id: string,
  password: string,
  requirePasswordReset: boolean,
) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: {
      id,
      password,
      requirePasswordReset,
    },
  }
}

export const fetchAccounts = () => ({
  type: FETCH_ACCOUNTS,
  payload: {},
})

export const fetchAccountsSuccess = (accounts: ILicenseGroupAccount[]) => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  payload: {
    accounts,
  },
})

export const fetchAccountsFail = () => ({
  type: FETCH_ACCOUNTS_FAIL,
  payload: {},
})

export const patchAccount = (
  id: number,
  patchData: Partial<ILicenseGroupAccount>,
) => ({
  type: PATCH_ACCOUNT,
  payload: {
    id,
    patchData,
  },
})

export const updateAccountPermission = (
  id: number,
  permission: string,
  enabled: boolean,
) => ({
  type: UPDATE_ACCOUNT_PERMISSION,
  payload: {
    id,
    permission,
    enabled,
  },
})

export const accountUpdated = (account: ILicenseGroupAccount) => ({
  type: ACCOUNT_UPDATED,
  payload: {
    account,
  },
})

export const deleteAccount = (account: ILicenseGroupAccount) => ({
  type: DELETE_ACCOUNT,
  payload: { account },
})

export const accountDeleted = (account: ILicenseGroupAccount) => ({
  type: ACCOUNT_DELETED,
  payload: { account },
})

export const accountAdded = (account: ILicenseGroupAccount) => ({
  type: ACCOUNT_ADDED,
  payload: { account },
})

export const patchAccountRole = (
  account: ILicenseGroupAccount,
  roleId: number,
) => ({
  type: PATCH_ACCOUNT_ROLE,
  payload: {
    account,
    roleId,
  },
})

export const inviteUser = (
  email: string,
  firstName: string,
  lastName: string,
  isPhoneNumberEntity: boolean,
  features: IFeatures,
  selectedNumber?: string,
) => ({
  type: INVITE_USER,
  payload: {
    email,
    firstName,
    lastName,
    isPhoneNumberEntity,
    features,
    selectedNumber,
  },
})

export const resendInvite = (accountId: number) => ({
  type: RESEND_INVITE,
  payload: {
    accountId,
  },
})
