import React, { useState, useEffect } from 'react'
import { IDataRetentionPolicy, IDataExpiration } from 'truly-ts'
import {
  SidePanelModal,
  ModalLayout,
  colors,
  Regular,
  spaceSizes,
  FlexRow,
  Switch,
  TextInput,
  DeleteDialog,
  Checkbox,
  Select,
} from 'js-components'
import { usePrevious, useOpenCloseState } from 'truly-utils'
import {
  NumberInputWrapper,
  FixedWidthTextWrapper,
  UnitsSelectWrapper,
} from './Style'
import isEqual from 'lodash/isEqual'
import DataRetentionValueDisplay from './DataRetentionValueDisplay'
import {
  createNewDataRetentionPolicy,
  createNewDataExpiration,
  RetentionPeriod,
  parseRetentionPeriod,
  stringifyRetentionPeriod,
  UnitSelectOptions,
  RetentionUnit,
  createDefaultRetentionPeriod,
} from '../../utils/model-utils/data-retention-utils'
import { getRequired } from 'truly-utils/macro'
import { useFlag } from '../../utils/flags'

interface Props {
  show: boolean
  onClose: () => void
  onChange: (policy: IDataRetentionPolicy | null) => void
  companyPolicy?: IDataRetentionPolicy | null
  currentPolicy?: IDataRetentionPolicy | null
  alwaysEnabled?: boolean
}

export default function ChangeDataRetentionPolicyDialog({
  show,
  onClose,
  currentPolicy,
  onChange,
  companyPolicy,
  alwaysEnabled,
}: Props) {
  const [policy, setPolicy] = useState(currentPolicy && { ...currentPolicy })
  const [
    showConfirmation,
    openShowConfirmation,
    closeShowConfirmation,
  ] = useOpenCloseState(false)

  const previousShow = usePrevious(show)

  useEffect(() => {
    if (show && !previousShow) {
      setPolicy(currentPolicy && { ...currentPolicy })
    }
  }, [show, previousShow, currentPolicy])

  const onExpirationChange = (key: keyof IDataRetentionPolicy) => (
    expiration: IDataExpiration | null,
  ) => {
    setPolicy({
      phone_calls: policy?.phone_calls || null,
      recordings: policy?.recordings || null,
      sms: policy?.sms || null,
      [key]: expiration,
    })
  }

  const changeDisabled = isEqual(currentPolicy, policy)

  const confirmChange = () => {
    closeShowConfirmation()
    onChange(policy ?? null)
    onClose()
  }

  const onEnableChange = (enabled: boolean) => {
    setPolicy(enabled ? createNewDataRetentionPolicy() : null)
  }

  // Effective policy will be either the policy, or a blank policy
  const effectivePolicy = policy ?? createNewDataRetentionPolicy()
  const policyEnabled = !!policy

  return (
    <SidePanelModal onRequestClose={onClose} visible={show} width="720px">
      <DeleteDialog
        show={showConfirmation}
        title="Please Read This Carefully!"
        description={
          <>
            <Regular>
              This action <b>cannot</b> be undone. Changes to your data
              retention policy are applied retroactively and can result in
              calls, messages, and recordings being <b>permanently deleted</b>{' '}
              from your organization
            </Regular>
            <Regular bold mt={`${spaceSizes.xs}px`}>
              Only change these settings if you understand their impact
            </Regular>
          </>
        }
        deleteActionText="Apply Change"
        confirmText="CONTINUE"
        onCancel={closeShowConfirmation}
        onDelete={confirmChange}
      />
      <ModalLayout.Container>
        <ModalLayout.Header
          title="Change Data Retention Policy"
          addBottomBorder
        />
        <ModalLayout.Content>
          <ModalLayout.Spacer />
          <ModalLayout.Row>
            <Regular
              color={colors.darkestGray}
              style={{ textAlign: 'center' }}
              m={`${spaceSizes.xs}px`}>
              Changing your data retention policy will change how long data
              around your calls and messages will be available to your
              organization. Data retention policies are applied retroactively.
            </Regular>
          </ModalLayout.Row>
          <ModalLayout.HorizontalLine />
          {!alwaysEnabled && (
            <>
              <ModalLayout.Row shouldPadTop shouldPadBottom>
                <FlexRow alignItems="center" data-cy="custom-rentention-row">
                  <Checkbox
                    checked={policyEnabled}
                    checkChanged={onEnableChange}
                    boldLabel
                    label="Use Custom Data Retention Policy"
                    data-cy="custom-rentention-checkbox"
                  />
                </FlexRow>
              </ModalLayout.Row>
              <ModalLayout.HorizontalLine />
            </>
          )}
          {(policyEnabled || alwaysEnabled) && (
            <>
              <DataExpirationEditRow
                name="Recordings"
                expiration={effectivePolicy.recordings}
                onChange={onExpirationChange('recordings')}
                data-cy="recording-rentention-row"
              />
              <ModalLayout.LightHorizontalLine />
              <DataExpirationEditRow
                name="Call Data"
                expiration={effectivePolicy.phone_calls}
                onChange={onExpirationChange('phone_calls')}
                data-cy="call-rentention-row"
              />
              <ModalLayout.LightHorizontalLine />
              <DataExpirationEditRow
                name="Messages"
                expiration={effectivePolicy.sms}
                onChange={onExpirationChange('sms')}
                data-cy="sms-rentention-row"
              />
              <ModalLayout.LightHorizontalLine />
            </>
          )}
          {!policyEnabled && companyPolicy && policy && (
            <ModalLayout.Row>
              <DataRetentionValueDisplay
                editable={false}
                policy={policy}
                organizationPolicy={companyPolicy}
              />
            </ModalLayout.Row>
          )}
        </ModalLayout.Content>
        <ModalLayout.FooterBigButton
          actionText="Change Policy"
          pinToBottom
          onAction={openShowConfirmation}
          actionDisabled={changeDisabled}
        />
      </ModalLayout.Container>
    </SidePanelModal>
  )
}

interface DataExpirationEditRowProps {
  name: string
  expiration: IDataExpiration | null
  onChange: (expiration: IDataExpiration | null) => void
}

function DataExpirationEditRow({
  name,
  expiration,
  onChange,
  ...restProps
}: DataExpirationEditRowProps) {
  const expirationEnabled = !!expiration

  const [retentionPeriod, setRetentionPeriod] = useState<RetentionPeriod>(() =>
    parseRetentionPeriod(expiration?.retention_period),
  )

  const onToggleExpiration = (checked: boolean) => {
    onChange(checked ? createNewDataExpiration() : null)
    // Reset retention period state too
    setRetentionPeriod(createDefaultRetentionPeriod())
  }

  const retentionPeriodChanged = (newRetentionPeriod: RetentionPeriod) => {
    // Change in the local state and parent component
    setRetentionPeriod(newRetentionPeriod)
    onChange({
      ...getRequired(expiration),
      retention_period: stringifyRetentionPeriod(newRetentionPeriod),
    })
  }

  const onTimeChange = (value: string) => {
    const strippedValue = value.replace(/\D/g, '').trim() // remove non digits
    let numberValue = 0
    if (strippedValue) {
      numberValue = parseInt(strippedValue, 10)
    }
    retentionPeriodChanged({
      ...retentionPeriod,
      time: numberValue,
    })
  }

  const onUnitChange = (unit: string) => {
    const retentionUnit = unit as RetentionUnit
    retentionPeriodChanged({
      ...retentionPeriod,
      unit: retentionUnit,
    })
  }

  const dataRetentionAllowMinutes = useFlag(['dataRetentionAllowMinutes'])

  const filteredUnitSelectOptions = dataRetentionAllowMinutes
    ? UnitSelectOptions
    : UnitSelectOptions.filter(option => option.value !== 'minutes')

  return (
    <ModalLayout.Row>
      <FlexRow alignItems="center" style={{ height: '60px' }} {...restProps}>
        <Switch
          activeColor={colors.accentLavender}
          checked={expirationEnabled}
          onChange={onToggleExpiration}
          data-cy="data-retention-toggle-switch"
        />
        {expirationEnabled ? (
          <>
            <FixedWidthTextWrapper>
              <Regular ml={`${spaceSizes.md}px`} bold color={colors.trulyDark}>
                Delete {name} After
              </Regular>
            </FixedWidthTextWrapper>
            <NumberInputWrapper>
              <TextInput
                value={`${retentionPeriod.time}`}
                onChange={onTimeChange}
                hideClearButton
                placeholder="0"
                textAlign="center"
                data-cy="data-retention-time"
              />
            </NumberInputWrapper>
            <UnitsSelectWrapper>
              <Select
                options={filteredUnitSelectOptions}
                value={retentionPeriod.unit}
                onChange={onUnitChange}
                data-cy="data-retention-time-unit-select"
              />
            </UnitsSelectWrapper>
          </>
        ) : (
          <Regular ml={`${spaceSizes.md}px`} bold color={colors.trulyDark}>
            {name} Will Not Be Deleted
          </Regular>
        )}
      </FlexRow>
    </ModalLayout.Row>
  )
}
