import { connect } from 'react-redux'
import FormEdit from '../components/FormEdit/FormEdit'
import * as formSelectors from '../reducers/forms/formsSelectors'
import * as rolesSelectors from '../reducers/roles/rolesSelectors'
import { IState } from '../store'
import { RouteComponentProps } from 'react-router'
import {
  fetchForms,
  updateForm,
  deleteForm,
  createForm,
} from '../reducers/forms/actionCreators'
import { push } from 'connected-react-router'
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../reducers/navigation/actionCreators'

const mapStateToProps = (
  state: IState,
  ownProps: RouteComponentProps<{ id: string }>,
) => ({
  isNew: ownProps.match.params.id === 'new',
  form:
    ownProps.match.params.id !== 'new'
      ? formSelectors.formById(ownProps.match.params.id)(state)
      : undefined,
  formsLoaded: !!formSelectors.forms(state),
  roles: rolesSelectors.roles(state),
  saveState: formSelectors.formSaveState(state),
})

const mapDispatchToProps = {
  setHasUnsavedChanges: setUnsavedChanges,
  clearHasUnsavedChanges: clearUnsavedChanges,
  loadForms: fetchForms,
  createForm,
  updateForm,
  deleteForm,
  navigate: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEdit)
