import React, { useState, useEffect } from 'react'
import { PopupModal, ModalLayout, TextInput } from 'js-components'
import { nameValidationHandler } from '../../utils/Validation'
import { useValidatedState } from 'truly-utils'

interface TeamCreateModalProps {
  show: boolean
  onClose: () => any
  onCreate: (name: string) => void
}

export default function TeamCreateModal(props: TeamCreateModalProps) {
  const [canValidate, setCanValidate] = useState(false)
  const [name, nameValidationState, setNameAndValidate] = useValidatedState(
    '',
    nameValidationHandler,
  )

  const { show, onClose } = props

  useEffect(() => {
    setNameAndValidate(false, '')
    setCanValidate(false)
  }, [props.show, setNameAndValidate, setCanValidate])

  const onNameChanged = (newName: string) => {
    setNameAndValidate(canValidate, newName)
  }

  const onAdd = () => {
    setCanValidate(true)
    const validationState = setNameAndValidate(true, name)

    if (validationState?.valid) {
      props.onCreate(name.trim())
      props.onClose()
    }
  }

  return (
    <PopupModal
      show={show}
      width="550px"
      onRequestClose={onClose}
      hideOnBgClick>
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header title="New Team" />
          <ModalLayout.HorizontalLine />
          <ModalLayout.FieldRow
            label="Name"
            labelWidth="140px"
            fieldWidth="100%"
            validationState={nameValidationState}
            shouldPadRight>
            <TextInput
              value={name}
              autoFocus
              placeholder="Type a Team Name"
              onChange={onNameChanged}
              validationState={nameValidationState}
              data-cy="team-name-input"
            />
          </ModalLayout.FieldRow>
          <ModalLayout.Footer
            actionText="Add"
            onAction={onAdd}
            onCancel={onClose}
          />
        </ModalLayout.Content>
      </ModalLayout.Container>
    </PopupModal>
  )
}
