import {
  all,
  takeLatest,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'

import {
  FETCH_ACCOUNTS,
  PATCH_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT_PERMISSION,
  PATCH_ACCOUNT_ROLE,
  RESET_USER_PASSWORD,
  INVITE_USER,
  RESEND_INVITE,
} from './actionTypes'

import {
  fetchAccountsSuccess,
  fetchAccountsFail,
  patchAccount,
  deleteAccount,
  accountUpdated,
  accountDeleted,
  accountAdded,
  updateAccountPermission,
  resetUserPassword,
  patchAccountRole,
  inviteUser,
  resendInvite,
} from './actionCreators'

import { roleUpdated } from '../roles/actionCreators'

import * as selector from './accountsSelectors'
import * as roleSelectors from '../roles/rolesSelectors'
import * as authSelectors from '../auth/authSelectors'
import { push } from 'connected-react-router'
import { ILicenseGroupAccount, IRole, IPhoneNumber } from 'truly-ts'
import {
  assignPhoneNumber,
  phoneNumberAdded,
} from '../phoneNumbers/actionCreators'
import { fetchExtensions } from '../extensions/actionCreators'
import defaultTrulyClient from '../../utils/HTTP'
import { logoutUserIfNotAdmin } from '../../utils/saga-utils/user-saga-utils'

export function* accountsSaga() {
  yield all([
    takeLatest(FETCH_ACCOUNTS, fetchAccountsSaga),
    takeLatest(PATCH_ACCOUNT, pathAccountSaga),
    takeLatest(PATCH_ACCOUNT_ROLE, patchAccountRoleSaga),
    takeLatest(DELETE_ACCOUNT, deleteAccountSaga),
    takeLatest(UPDATE_ACCOUNT_PERMISSION, updateAccountPermissionSaga),
    takeLatest(RESET_USER_PASSWORD, resetPassword),
    takeEvery(INVITE_USER, inviteUserSaga),
    takeEvery(RESEND_INVITE, resendInviteSaga),
  ])
}

export function* resetPassword(action: ReturnType<typeof resetUserPassword>) {
  const { id, password, requirePasswordReset } = action.payload
  try {
    yield call(
      defaultTrulyClient.login.updatePassword,
      id,
      password,
      requirePasswordReset,
    )
  } catch (e) {
    toastr.error(
      'An Error Occurred',
      'Unable to reset password. Please try again.',
    )
    console.error('Patch user password', e)
  }
}

export function* fetchAccountsSaga() {
  try {
    const req = yield call(defaultTrulyClient.accounts.fetchAccounts)
    yield put(fetchAccountsSuccess(req.data.accounts))
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to fetch accounts. Please try again.',
    )
    yield put(fetchAccountsFail())
  }
}

export function* pathAccountSaga(action: ReturnType<typeof patchAccount>) {
  const { id, patchData } = action.payload
  const loggedInUser = yield select(authSelectors.account)

  // copying account before patch
  const exisitingAccount = yield select(selector.accountById(id))

  // 'creating' new account data before patch
  const newAccount = { ...exisitingAccount, ...patchData }

  // optimisic patch
  yield put(accountUpdated(newAccount))
  try {
    const { data: account } = yield call(
      defaultTrulyClient.accounts.patchAccount,
      id,
      patchData,
    )
    yield put(accountUpdated(account))
    if (loggedInUser.id === account.id) {
      // If we modified our current user, ensure we're still admin
      yield call(logoutUserIfNotAdmin)
    }
  } catch (e) {
    yield put(accountUpdated(exisitingAccount))
    console.error(e)
    toastr.error('An Error Occurred', 'Unable to edit user account')
  }
}

function* patchAccountRoleSaga(action: ReturnType<typeof patchAccountRole>) {
  const { account, roleId } = action.payload

  const previousTeam: IRole = yield account.role_id
    ? select(roleSelectors.roleById(account.role_id))
    : null
  const newTeam: IRole = yield select(roleSelectors.roleById(roleId))

  try {
    yield put(patchAccount(account.id, { role_id: roleId }))
    if (previousTeam) {
      const accounts = previousTeam.accounts ?? []
      const adjustedPreviousTeam = {
        ...previousTeam,
        accounts: accounts.filter(prevTeamAcc => prevTeamAcc.id !== account.id),
      }
      yield put(roleUpdated(adjustedPreviousTeam))
    }

    // if the new team doesnt have any users in it
    const displayName =
      `${account.first_name} ${account.last_name}`.trim() || account.email
    const adjustedNewTeam = {
      ...newTeam,
      accounts: newTeam.accounts
        ? [...newTeam.accounts, { id: account.id, display_name: displayName }]
        : [{ id: account.id, display_name: displayName }],
    }
    yield put(roleUpdated(adjustedNewTeam))
  } catch (e) {
    if (previousTeam) {
      yield put(roleUpdated(previousTeam)) // revert back old team to include account
    }
    yield put(roleUpdated(newTeam)) // revert back new team to exclude account
    console.error(e)
    toastr.error('An Error Occurred', 'Unable to add user into team')
  }
}

export function* updateAccountPermissionSaga(
  action: ReturnType<typeof updateAccountPermission>,
) {
  const { id, permission, enabled } = action.payload

  // copying account before patch
  const exisitingAccount: ILicenseGroupAccount = yield select(
    selector.accountById(id),
  )

  let newAccount = exisitingAccount
  if (enabled) {
    if (!exisitingAccount.permissions.includes(permission)) {
      newAccount = {
        ...exisitingAccount,
        permissions: [...exisitingAccount.permissions, permission],
      }
    }
  } else {
    newAccount = {
      ...exisitingAccount,
      permissions: exisitingAccount.permissions.filter(
        perm => perm !== permission,
      ),
    }
  }

  // optimisic patch
  yield put(accountUpdated(newAccount))
  try {
    if (enabled) {
      yield call(
        defaultTrulyClient.accounts.addAccountPermission,
        id,
        permission,
      )
    } else {
      yield call(
        defaultTrulyClient.accounts.removeAccountPermission,
        id,
        permission,
      )
    }
  } catch (e) {
    yield put(accountUpdated(exisitingAccount))
    console.error(e)
    toastr.error('An Error Occurred', 'Unable to edit user account')
  }
}

function* deleteAccountSaga(action: ReturnType<typeof deleteAccount>) {
  const { account } = action.payload

  yield put(push('/users'))
  yield put(accountDeleted(account))
  try {
    yield call(defaultTrulyClient.accounts.deleteAccount, account.id)
  } catch (e) {
    yield put(accountAdded(account))
    console.error(e)
    toastr.error('An Error Occurred', 'Unable to delete user account')
  }
}

function* inviteUserSaga(action: ReturnType<typeof inviteUser>) {
  const {
    email,
    firstName,
    lastName,
    isPhoneNumberEntity,
    selectedNumber,
    features,
  } = action.payload
  try {
    const { data: createdAccount }: { data: ILicenseGroupAccount } = yield call(
      defaultTrulyClient.accounts.inviteUser,
      email,
      firstName,
      lastName,
      features,
    )
    // TODO - Do a fetchAccount because of a BE bug where extension info is missing on invite (CORE-1956)
    const { data: account }: { data: ILicenseGroupAccount } = yield call(
      defaultTrulyClient.accounts.fetchAccount,
      createdAccount.id,
    )
    yield put(accountAdded(account))
    yield put(push(`/users/${account.id}`))
    try {
      if (!selectedNumber) return // allowed to choose, so don't assign
      if (isPhoneNumberEntity) {
        yield put(assignPhoneNumber('account', account.id, selectedNumber))
      } else {
        const purchaseResponse = yield call(
          defaultTrulyClient.lg.purchasePhoneNumber,
          selectedNumber,
        )
        const phoneNumber = purchaseResponse.data.phone_number as IPhoneNumber
        yield put(phoneNumberAdded(phoneNumber))
        yield put(
          assignPhoneNumber('account', account.id, phoneNumber.full_number),
        )
      }
    } catch (e) {
      console.error('Failed to assign phone number to new user', email, e)
      toastr.error(
        'An Error Occurred',
        'The user was invited, but we failed to assign the phone number you chose. Please try assigning them another phone number.',
      )
    }
  } catch (e) {
    console.error('Failed to invite user', email, e)
    toastr.error(
      'An Error Occurred',
      `Unable to invite ${email}. Please try again.`,
    )
  } finally {
    yield put(fetchExtensions()) // extensions will have changed because of a new account being created
  }
}

function* resendInviteSaga(action: ReturnType<typeof resendInvite>) {
  const { accountId } = action.payload
  try {
    yield call(defaultTrulyClient.accounts.resendInvite, accountId)
    toastr.success('Resent Invite', 'Invite has been resent!')
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to resend the invite. Please try again.',
    )
  }
}
