import { IFormElement, Unsaved } from 'truly-ts'
import { Form } from '../reducers/forms/types'

export function inputTypeToDisplay(inputType: IFormElement['input_type']) {
  switch (inputType) {
    case 'select':
      return 'Picklist'
    case 'textfield':
      return 'Text Input'
    case 'checkbox':
      return 'Checkbox'
    default:
      throw new Error(`Unkown input type: ${inputType}`)
  }
}

export function newEmptyForm(): Unsaved<Form> {
  return {
    description: '',
    elements: [],
    licensegroup_default: false,
    roles: [],
  }
}
