export const ConferenceRoomsTitle = 'Conference Rooms'
export const ExternalUsersTitle = 'External Users'
export const FormsTitle = 'Forms'
export const IntegrationsTitle = 'API Credentials'
export const OrganizationSettingsTitle = 'Organization Settings'
export const PhoneMenuTitle = 'Phone Menus'
export const PhoneNumbersTitle = 'Phone Numbers'
export const BlockedNumbersTitle = 'Blocked Numbers'
export const ReportsTitle = 'Reports'
export const TeamsTitle = 'Teams'
export const UsersTitle = 'Users'
export const WebhooksTitle = 'Webhooks'
