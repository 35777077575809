import { IState } from '../../store'
export const base = (state: IState) => state.navigation
export const unsavedDialogShowing = (state: IState) =>
  !!base(state).continueAction
export const continueAction = (state: IState) => base(state).continueAction
export const hasUnsavedChanges = (state: IState) =>
  base(state).hasUnsavedChanges
export const unsavedTitle = (state: IState) => base(state).unsavedTitle
export const unsavedDescription = (state: IState) =>
  base(state).unsavedDescription
export const allowedNavRegex = (state: IState) => base(state).allowedNavRegex
