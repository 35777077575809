import * as React from 'react'
import {
  IMessage,
  IPhoneMenu,
  PhoneMenuType,
  NodeType,
  INode,
  MessageType,
} from 'truly-ts'
import {
  FlexColumn,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  Small,
  TableRow,
  TableData,
  AvatarSizes,
  AvatarRow,
  Regular,
  colors,
  ToolTip,
  Trash,
  // Edit, TODO: Reenable when appropriate patch endpoint is created for message updates
  AudioPlayer,
  AudioDisplayMode,
} from 'js-components'
import Loader from '../Loader/Loader'
import {
  ContentContainer,
  TableWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import memoizeOne from 'memoize-one'
import { formattedDuration, makeSearchFn } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { ReactComponent as UsersSVG } from 'js-components/src/components/Icons/Users.svg'
import { ReactComponent as NumpadSVG } from 'js-components/src/components/Icons/Numpad.svg'
import { legacyUrl } from '../../constants/Config'
import { EntityMap } from '../../utils/Entity'

const RowHeight = 65
const AvatarCellWidth = '60px'
const DurationCellWidth = '150px'

interface MenuRecording {
  menuName: string
  menuType: string
  goToPhoneMenuPage: () => void
  icon: JSX.Element
}
interface RecordingsProps {
  loading: boolean
  fetchData: () => void
  onDeleteRecording: (recording: IMessage) => void
  onAddRecording: () => void
  searchValue?: string
  clearFilters: () => void
  onEditRecording: (recording: IMessage) => void
  recordings?: IMessage[]
  phoneMenus?: IPhoneMenu[]
  entities?: EntityMap
  navigate: (path: string) => void
}

interface RecordingsState {
  creating: boolean
  deleting: boolean
  selectedRecording?: IMessage
}

export default class Recordings extends React.Component<
  RecordingsProps,
  RecordingsState
> {
  static filterRecordings = memoizeOne(
    (recordings: IMessage[], searchValue?: string) => {
      let result = recordings
      if (searchValue && searchValue.trim()) {
        const searchFn = makeSearchFn<IMessage>(['title'], searchValue)
        result = result.filter(searchFn)
      }
      return result
    },
  )

  constructor(props: RecordingsProps) {
    super(props)
    this.state = {
      deleting: false,
      creating: false,
    }
  }

  componentDidMount() {
    this.props.fetchData()
  }

  onEditRecordings = (recording: IMessage) => {
    this.props.onEditRecording(recording)
  }

  isFiltered = () => {
    const { searchValue } = this.props
    return (
      this.props.recordings &&
      this.props.recordings.length > 0 &&
      !!(searchValue && searchValue.trim())
    )
  }

  getFilteredRecordings = () => {
    return Recordings.filterRecordings(
      getRequired(this.props.recordings),
      this.props.searchValue,
    )
  }

  getAssignedMenus = (recordingId: number) => {
    const phoneMenus = Object.values(this.props.entities?.phoneMenuMap ?? {})
    let entitiesWithRecording: any = []

    entitiesWithRecording = entitiesWithRecording.concat(
      phoneMenus.filter(
        pm =>
          pm.tree.messages?.find(
            msg =>
              msg.message_id === recordingId &&
              msg.type === MessageType.MenuMessage,
          ) || pm.tree.selected_recording_id === recordingId,
      ),
    )

    entitiesWithRecording = entitiesWithRecording.concat(
      Object.values(this.props.entities?.nodeMap ?? {}).filter(pmn => {
        if (pmn.selected_recording_id === recordingId) return pmn
        return (
          pmn.messages && pmn.messages.find(n => n.message_id === recordingId)
        )
      }),
    )

    return entitiesWithRecording
  }

  getParentMenu = (parentId: number) => {
    return Object.values(this.props.entities?.phoneMenuMap ?? {}).find(
      pm => pm.id === parentId,
    ) as IPhoneMenu
  }

  goToPhoneMenu = (menuId: number) => {
    this.props.navigate(`/phone-menus/${menuId}`)
  }

  render() {
    const {
      loading,
      onDeleteRecording,
      clearFilters,
      onAddRecording,
      recordings,
      entities,
    } = this.props

    if (
      loading ||
      !recordings ||
      !(entities?.phoneMenuMap || entities?.nodeMap)
    ) {
      return (
        <FlexColumn>
          <Loader mt="200px" />
        </FlexColumn>
      )
    }

    const filteredRecordings = this.getFilteredRecordings()
    const isFiltered = this.isFiltered()

    return (
      <ContentContainer>
        <TableWrapper>
          <ScrollableTable
            header={<RecordingsHeaderRow />}
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={
                  isFiltered ? 'No Recordings Found' : 'No Recordings... Yet.'
                }
                actionText={
                  isFiltered ? 'Clear All Filters' : 'Add New Recording'
                }
                onActionClick={isFiltered ? clearFilters : onAddRecording}
              />
            }
            rowCount={filteredRecordings.length}
            renderRow={idx => (
              <RecordingsTableRow
                key={filteredRecordings[idx].id}
                recording={filteredRecordings[idx]}
                rowHeight={RowHeight}
                onDelete={onDeleteRecording}
                onEdit={this.onEditRecordings}
                assignedEntities={this.getAssignedMenus(
                  filteredRecordings[idx].id,
                )}
                getParentMenu={this.getParentMenu}
                goToPhoneMenu={this.goToPhoneMenu}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    )
  }
}

const RecordingsHeaderRow = React.memo(props => (
  <TableHeaderRow {...props}>
    <TableHeaderData width={AvatarCellWidth} text="" />
    <TableHeaderData text="Recording Name" />
    <TableHeaderData width={DurationCellWidth} text="Duration" />
    <TableHeaderData text="Assigned to" />
    <TableHeaderData text="" />
  </TableHeaderRow>
))

class RecordingsTableRow extends React.PureComponent<{
  recording: IMessage
  rowHeight: number
  onEdit: (rec: IMessage) => void
  onDelete: (rec: IMessage) => void
  assignedEntities?: Array<IPhoneMenu | INode>
  getParentMenu?: (parentId: number) => IPhoneMenu
  goToPhoneMenu?: (menuId: number) => void
}> {
  onEdit = (ev: React.MouseEvent) => {
    /*
      TODO: Reenable when appropriate patch endpoint is created for message updates

      ev.stopPropagation();
      this.props.onEdit(this.props.recording);
    */
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.recording)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const {
      recording,
      onEdit,
      onDelete,
      rowHeight,
      assignedEntities,
      getParentMenu,
      goToPhoneMenu,
      ...restProps
    } = this.props

    const menusUsingRecording: MenuRecording[] = []

    if (assignedEntities && assignedEntities.length) {
      assignedEntities.forEach((assignedEntity: IPhoneMenu | INode) => {
        if (
          assignedEntity.type !== PhoneMenuType.Basic &&
          assignedEntity.type !== PhoneMenuType.Advanced
        ) {
          const assignedNode = assignedEntity as INode
          const parentPhoneMenu = assignedNode?.phonemenu_id
            ? getParentMenu?.(assignedNode?.phonemenu_id)
            : null
          const typing = assignedNode.type
          if (!parentPhoneMenu) {
            throw new Error("Can't render message with no parentPhoneMenu")
          }

          const parentMenuName = parentPhoneMenu.name
          const goToPhoneMenuPage = () =>
            goToPhoneMenu &&
            parentPhoneMenu.id &&
            goToPhoneMenu(parentPhoneMenu.id)
          const icon =
            parentPhoneMenu.type === PhoneMenuType.Basic ? (
              <UsersSVG width="100%" />
            ) : (
              <NumpadSVG width="100%" />
            )
          let menuType: string
          let menuName: string

          if (typing !== NodeType.Menu) {
            let addedEntityDescriptor = ''

            if (typing === NodeType.RootNode) {
              const messageType = parentPhoneMenu.tree.messages?.find(
                msg => msg.node_id === assignedEntity.id,
              )?.type

              if (messageType === MessageType.NotInBusinessHoursMessage) {
                addedEntityDescriptor = ' (After Hours)'
              }
            } else {
              addedEntityDescriptor = `: ${assignedNode.pre_select_text_to_speech}`
            }

            menuName = `${parentMenuName}${addedEntityDescriptor}`
            menuType =
              parentPhoneMenu.type === PhoneMenuType.Advanced
                ? 'Advanced Phone Menu'
                : 'Basic Phone Menu'
          } else {
            menuName = assignedNode.pre_select_text_to_speech ?? ''
            menuType = `Submenu of ${parentMenuName}`
          }

          menusUsingRecording.push({
            menuName,
            menuType,
            goToPhoneMenuPage,
            icon,
          })
        }
      })
    }

    return (
      <TableRow
        height={rowHeight}
        verticalAlign="middle"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={AvatarCellWidth}>
          <LeftAlignedTableData>
            <AudioPlayer
              large
              displayMode={AudioDisplayMode.SingleButton}
              src={`${legacyUrl}/message_content?cachebuster=${Math.random()}&messageId=${
                recording.id
              }`}
              lazyLoad
            />
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <LeftAlignedTableData>
            <Regular color={colors.trulyDark} bold>
              {recording.title}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData width={DurationCellWidth}>
          <LeftAlignedTableData>
            <Regular color={colors.trulyDark} bold>
              {formattedDuration(
                Math.floor(recording.recording?.duration ?? 0) || 1,
              )}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <LeftAlignedTableData>
            {menusUsingRecording && menusUsingRecording.length > 0 ? (
              <AvatarRow
                size={AvatarSizes.Small}
                maxVisibleCount={4}
                avatarOptions={menusUsingRecording.map(a => ({
                  label: a.menuName,
                  onClick: a.goToPhoneMenuPage,
                  icon: a.icon,
                  description: a.menuType,
                }))}
                square
              />
            ) : (
              <Regular color={colors.darkGray}>None</Regular>
            )}
          </LeftAlignedTableData>
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Delete')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onDelete}
                role="button"
                data-cy="delete-button">
                <Trash width="24" height="24" />
              </ActionButton>
            </ToolTip>
            {/*
              TODO: Reenable when appropriate patch endpoint is created for message updates

              <ToolTip enabled orderPreference="bottom" toolTipContent={this.renderTooltipContent('Edit')}>
                <ActionButton cursor="pointer" onClick={this.onEdit} role="button">
                  <Edit width="24" height="24" />
                </ActionButton>
              </ToolTip>
            */}
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
