import {
  FETCH_EXTERNAL_USERS,
  FETCH_EXTERNAL_USERS_SUCCESS,
  FETCH_EXTERNAL_USERS_FAIL,
  DELETE_EXTERNAL_USER,
  UPDATE_EXTERNAL_USER,
  CREATE_EXTERNAL_USER,
  EXTERNAL_USER_DELETED,
  EXTERNAL_USER_UPDATED,
  EXTERNAL_USER_ADDED,
} from './actionTypes'

import { IExternalForward, Unsaved } from 'truly-ts'

export const fetchExternalUsers = () => ({
  type: FETCH_EXTERNAL_USERS,
  payload: {},
})

export const fetchExternalUsersSuccess = (
  externalUsers: IExternalForward[],
) => ({
  type: FETCH_EXTERNAL_USERS_SUCCESS,
  payload: {
    externalUsers,
  },
})

export const fetchExternalUsersFail = () => ({
  type: FETCH_EXTERNAL_USERS_FAIL,
  payload: {},
})

export const deleteExternalUser = (externalUser: IExternalForward) => ({
  type: DELETE_EXTERNAL_USER,
  payload: {
    externalUser,
  },
})

export const externalUserDeleted = (externalUser: IExternalForward) => ({
  type: EXTERNAL_USER_DELETED,
  payload: {
    externalUser,
  },
})

export const updateExternalUser = (externalUser: IExternalForward) => ({
  type: UPDATE_EXTERNAL_USER,
  payload: {
    externalUser,
  },
})

export const externalUserUpdated = (externalUser: IExternalForward) => ({
  type: EXTERNAL_USER_UPDATED,
  payload: {
    externalUser,
  },
})

export const createExternalUser = (
  externalUser: Unsaved<IExternalForward>,
) => ({
  type: CREATE_EXTERNAL_USER,
  payload: {
    externalUser,
  },
})

export const externalUserAdded = (externalUser: IExternalForward) => ({
  type: EXTERNAL_USER_ADDED,
  payload: {
    externalUser,
  },
})
