export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAIL = 'FETCH_MESSAGES_FAIL'
export const MESSAGE_ADDED = 'MESSAGE_ADDED'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const MESSAGE_REMOVED = 'MESSAGE_REMOVED'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL'
export const MESSAGE_UPDATED = 'MESSAGE_UPDATED'
