import React from 'react'
import { DeleteDialog } from 'js-components'

interface DeleteDialogProps {
  show: boolean
  onCancel: () => void
  onDelete: () => void
  name?: string
}

export function PhoneMenuDeleteDialog(props: DeleteDialogProps) {
  return (
    <DeleteDialog
      show={props.show}
      title={`Delete ${props.name}`}
      deleteActionText="Delete"
      description="This phone menu will be deleted. Phone numbers assigned to this phone menu will be unassigned. This action cannot be undone."
      confirmText="DELETE"
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  )
}

export function SubmenuDeleteDialog(props: DeleteDialogProps) {
  return (
    <DeleteDialog
      show={props.show}
      title={`Delete Sub-Menu`}
      deleteActionText="Delete"
      description="This sub-menu will be deleted. Phone numbers assigned to this node will be unassigned."
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  )
}
