import React from 'react'
import { InlineTextEdit, ReadOnlyWrapper } from 'js-components'
import TeamPermissionModal from '../TeamPermissionModal/TeamPermissionModal'

interface UserDetailInputProps {
  defaultValue?: string
  inputType: string
  onSave: (value: string) => void
  disabled?: boolean
  placeholder?: string
  teamId: number | null
}

const UserDetailInput: React.FC<UserDetailInputProps> = props => (
  <ReadOnlyWrapper
    disabled={props.disabled}
    readOnlyComponent={
      <TeamPermissionModal inputType={props.inputType} teamId={props.teamId} />
    }>
    <InlineTextEdit
      originalValue={props.defaultValue}
      onSave={props.onSave}
      showButtons
      required
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  </ReadOnlyWrapper>
)

export default UserDetailInput
