import styled from 'styled-components/macro'
import { FlexColumn } from 'js-components'

export const Container = styled(FlexColumn)`
  height: 100%;
`

export const NoReportsWrapper = styled(FlexColumn)`
  height: 70%;
  justify-content: center;
`

export const IFrameContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
`

export const LoaderWrapper = styled.div`
  margin-top: 200px;
`

export const SelectWrapper = styled.div`
  width: 200px;
`
