import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { IConferenceRoom } from 'truly-ts'
import {
  formatPhoneNumber,
  makeSearchFn,
  SearchFunctions,
  useOpenCloseState,
  useActionCreator,
} from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  FlexColumn,
  spaceSizes,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  Small,
  TableRow,
  TableData,
  colors,
  AvatarSizes,
  Avatar,
  ConferencePhoneSVG,
  Regular,
  ToolTip,
  Edit,
  Trash,
} from 'js-components'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import Loader from '../Loader/Loader'
import {
  MainContainer,
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  RightAlignedTableData,
  ActionButton,
  LeftAlignedTableData,
} from '../LayoutHelpers/Styles'
import ConferenceRoomDeleteDialog from '../ConferenceRoomDeleteDialog/ConferenceRoomDeleteDialog'
import ConferenceRoomEditPanel from '../ConferenceRoomEditPanel/ConferenceRoomEditPanel'
import PageTitle from '../PageTitle/PageTitle'
import { ConferenceRoomsTitle } from '../../constants/PageTitles'
import usePreloader from '../../utils/data-hooks/usePreloader'
import useRouter from '../../utils/custom-hooks/useRouter'
import * as selectors from '../../reducers/conferenceRooms/conferenceRoomsSelectors'
import {
  fetchConferenceRooms,
  deleteConferenceRoom,
} from '../../reducers/conferenceRooms/actionCreators'
import ConferenceRoomNewModal from '../ConferenceRoomNewModal/ConferenceRoomNewModal'

const RowHeight = 75
const AvatarCellWidth = '80px'

interface ConferenceRoomsTableRowProps {
  conferenceRoom: IConferenceRoom
  rowHeight: number
  onEdit: (room: IConferenceRoom) => void
  onDelete: (room: IConferenceRoom) => void
}

const ConferenceRooms: React.FC<
  RouteComponentProps<{
    id: string
  }>
> = props => {
  const { match } = props
  const [searchValue, setSearchValue] = useState('')
  const [deletingRoom, openDeletingRoom, closeDeletingRoom] = useOpenCloseState(
    false,
  )
  const [creatingRoom, openCreatingRoom, closeCreatingRoom] = useOpenCloseState(
    false,
  )
  const [editingRoom, openEditingRoom, closeEditingRoom] = useOpenCloseState(
    false,
  )
  const [selectedRoom, setSelectedRoom] = useState<IConferenceRoom | null>(null)
  const router = useRouter(true)
  const conferenceRooms = usePreloader(
    selectors.conferenceRooms,
    fetchConferenceRooms,
  )
  const onDelete = useActionCreator(deleteConferenceRoom)

  useEffect(() => {
    if (match.params.id && conferenceRooms) {
      closeCreatingRoom()
      openEditingRoom()
      const foundRoom = conferenceRooms.find(
        room => room.id.toString() === match.params.id,
      )
      setSelectedRoom(foundRoom ?? null)
    } else {
      closeEditingRoom()
      setSelectedRoom(null)
    }
  }, [
    match.params.id,
    conferenceRooms,
    closeEditingRoom,
    closeCreatingRoom,
    openEditingRoom,
  ])

  const onEditRoom = (room: IConferenceRoom) => {
    router.push(`/conference-rooms/${room.id}`)
  }

  const onDeleteRoom = (room: IConferenceRoom) => {
    openDeletingRoom()
    setSelectedRoom(room)
  }

  const handleDelete = () => {
    if (!selectedRoom) {
      throw new Error("Can't delete without selected conference room")
    }
    onDelete(selectedRoom)
  }

  const onSearchInputChanged = (newSearchValue: string) => {
    setSearchValue(newSearchValue)
  }

  const getFilteredConferenceRooms = () => {
    const rooms: IConferenceRoom[] = getRequired(conferenceRooms) || []

    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<IConferenceRoom>(
        [
          'name',
          { field: 'numbers', fn: SearchFunctions.multiplePhoneNumberSearch },
        ],
        searchValue,
      )
      return rooms.filter(searchFn)
    }

    return rooms
  }

  const isFiltered = () => {
    return (
      conferenceRooms &&
      conferenceRooms.length > 0 &&
      !!(searchValue && searchValue.trim())
    )
  }

  const clearFilters = () => {
    setSearchValue('')
  }

  if (!conferenceRooms) {
    return (
      <FlexColumn>
        <PageTitle title={ConferenceRoomsTitle} />
        <ContentHeading title={ConferenceRoomsTitle} />
        <Loader mt="200px" />
      </FlexColumn>
    )
  }

  const filteredConferenceRooms = getFilteredConferenceRooms()
  const currentlyFiltered = isFiltered()

  return (
    <MainContainer>
      <PageTitle title={ConferenceRoomsTitle} />
      {selectedRoom && (
        <ConferenceRoomDeleteDialog
          show={deletingRoom}
          onClose={closeDeletingRoom}
          selectedRoom={selectedRoom}
          onDelete={handleDelete}
        />
      )}
      <ConferenceRoomNewModal
        show={creatingRoom}
        conferenceRoomCount={filteredConferenceRooms.length}
        onClose={closeCreatingRoom}
      />
      <ConferenceRoomEditPanel
        conferenceRoom={editingRoom ? selectedRoom : null}
      />
      <ContentHeading
        title={ConferenceRoomsTitle}
        rightControl={
          <HeadingAddButton
            accessibilityLabel="Add Conference Room"
            onClick={openCreatingRoom}
          />
        }
      />
      <ContentContainer>
        <TableFilterWrapper>
          <TableFilterRow
            searchInput
            searchInputValue={searchValue}
            searchPlaceholder="Filter"
            onSearchInputChanged={onSearchInputChanged}
            selectedFilterOption="all"
            filterOptions={[
              {
                value: 'all',
                label: 'All Conference Rooms',
              },
            ]}
          />
        </TableFilterWrapper>
        <TableWrapper>
          <ScrollableTable
            header={<ConferenceRoomsHeaderRow />}
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={
                  currentlyFiltered
                    ? 'No Conference Rooms Found'
                    : 'No Conference Rooms... Yet.'
                }
                actionText={
                  currentlyFiltered
                    ? 'Clear All Filters'
                    : 'Add New Conference Room'
                }
                onActionClick={
                  currentlyFiltered ? clearFilters : openCreatingRoom
                }
              />
            }
            rowCount={filteredConferenceRooms.length}
            renderRow={idx => (
              <ConferenceRoomsTableRow
                key={idx}
                rowHeight={RowHeight}
                onEdit={onEditRoom}
                onDelete={onDeleteRoom}
                conferenceRoom={filteredConferenceRooms[idx]}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    </MainContainer>
  )
}

const ConferenceRoomsHeaderRow: React.FC = props => (
  <TableHeaderRow {...props}>
    <TableHeaderData width={AvatarCellWidth} text="" />
    <TableHeaderData text="Name" />
    <TableHeaderData text="Phone Number" />
    <TableHeaderData text="Call Recording" />
    <TableHeaderData text="" />
  </TableHeaderRow>
)

const ConferenceRoomsTableRow: React.FC<ConferenceRoomsTableRowProps> = props => {
  const { conferenceRoom, onEdit, onDelete, rowHeight, ...restProps } = props

  const onEditRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onEdit(conferenceRoom)
  }

  const onDeleteRow = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    onDelete(conferenceRoom)
  }

  const renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  const extraNumberCount = Math.max(conferenceRoom.numbers.length - 1, 0)
  const alignTop = extraNumberCount > 0

  return (
    <TableRow
      height={rowHeight}
      verticalAlign={alignTop ? 'top' : 'middle'}
      onClick={onEditRow}
      {...restProps}>
      <TableData width={AvatarCellWidth}>
        <LeftAlignedTableData>
          <Avatar
            colorSeed={conferenceRoom.name}
            size={AvatarSizes.Big}
            style={alignTop ? { transform: 'translateY(-7px)' } : {}} // Hack to re-center avatar
            square
            icon={<ConferencePhoneSVG width="75%" />}
          />
        </LeftAlignedTableData>
      </TableData>
      <TableData>
        <Regular color={colors.trulyDark}>{conferenceRoom.name}</Regular>
      </TableData>
      <TableData>
        {conferenceRoom.numbers.length > 0 ? (
          <Regular color={colors.trulyDark}>
            {formatPhoneNumber(conferenceRoom.numbers[0])}
          </Regular>
        ) : (
          <Regular color={colors.darkGray}>None</Regular>
        )}
        {extraNumberCount > 0 && (
          <Small color={colors.trulyDark} mt={`${spaceSizes.xs}px`} bold>
            +{extraNumberCount} Other
          </Small>
        )}
      </TableData>
      <TableData>
        <Regular color={colors.trulyDark}>
          {conferenceRoom.settings.record_calls ? 'Enabled' : 'Disabled'}
        </Regular>
      </TableData>
      <TableData showOnHover>
        <RightAlignedTableData>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Delete')}>
            <ActionButton
              cursor="pointer"
              onClick={onDeleteRow}
              role="button"
              data-cy="delete-button">
              <Trash width="24" height="24" />
            </ActionButton>
          </ToolTip>
          <ToolTip
            enabled
            orderPreference="bottom"
            toolTipContent={renderTooltipContent('Edit')}>
            <ActionButton cursor="pointer" onClick={onEditRow} role="button">
              <Edit width="24" height="24" />
            </ActionButton>
          </ToolTip>
        </RightAlignedTableData>
      </TableData>
    </TableRow>
  )
}

export default ConferenceRooms
