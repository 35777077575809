import { Next, IAction, Store, IState } from '../../store/index'
import debounce from 'lodash/debounce'

const cacheDataKey = '_dev_cache_store'
const cacheExpirationKey = '_dev_cache_store_exp'

// This middleware caches the state periodically, to make iterative development faster.

export const devCacheMiddleware = () => {
  if (!(window as any).requestIdleCallback) {
    console.warn(
      'window.requestIdleCallback does not exist, performance may be degraded with caching on.',
    )
  }

  // Update expiration regularly
  setInterval(() => {
    if (localStorage.getItem(cacheExpirationKey)) {
      localStorage.setItem(
        cacheExpirationKey,
        JSON.stringify(new Date().getTime()),
      )
    }
  }, 3000)

  // Callback to store state in localStorage
  const cacheState = debounce(
    (state: IState) => {
      const storeCache = () => {
        const data = JSON.stringify(state)
        if (data.length <= 1024 * 1024 * 5) {
          localStorage.setItem(
            cacheExpirationKey,
            JSON.stringify(new Date().getTime()),
          )
          localStorage.setItem(cacheDataKey, data)
        } else {
          console.log(
            'Warning: Not caching state, larger than 5 MB.',
            data.length,
          )
          localStorage.removeItem(cacheExpirationKey)
          localStorage.removeItem(cacheDataKey)
        }
      }
      if ((window as any).requestIdleCallback) {
        ;(window as any).requestIdleCallback(storeCache)
      } else {
        storeCache()
      }
    },
    1000,
    { trailing: true, leading: false },
  )

  return (store: Store) => (next: Next) => (action: IAction) => {
    cacheState(store.getState())
    return next(action)
  }
}

// Use with createStore to get initial state

export const getCachedState = (
  expirationMS: number = 1000 * 20,
): IState | null => {
  const data = localStorage.getItem(cacheDataKey)
  const expData = localStorage.getItem(cacheExpirationKey)
  if (data && expData) {
    const parsed = JSON.parse(data) as IState
    const exp = JSON.parse(expData) as number
    if (new Date().getTime() - exp <= expirationMS) {
      console.log('Using cached state.')
      return parsed
    }
    console.log('Cache state expired, removing.')
    localStorage.removeItem(cacheExpirationKey)
    localStorage.removeItem(cacheDataKey)
  }
  return null
}
