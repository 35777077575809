import React, { useState, useEffect } from 'react'
import {
  FlexColumn,
  DropShadow,
  Search,
  TextInput,
  Select,
  Avatar,
  Regular,
  colors,
  Small,
  // withAnimation,
  // animations,
  EmptyResults,
  Large,
} from 'js-components'
import Loader from '../Loader/Loader'
import {
  UsersContainer,
  UserListContainer,
  UserListHeader,
  UserListWrapper,
  UserDetailContainer,
  UserDetailWrapper,
  FilterWrapper,
  TeamSelectorWrapper,
  UserRowContainer,
  UserRowTextContainer,
  NotFoundTextContainer,
  RelativeContainer,
} from './Styles'
import { MainContainer } from '../LayoutHelpers/Styles'
import { List, AutoSizer, ListRowProps } from 'react-virtualized'
import { ILicenseGroupAccount, IStatus } from 'truly-ts'
import memoizeOne from 'memoize-one'
import { makeSearchFn, useMountEffect, useOpenCloseState } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import UserDetail from '../UserDetail/UserDetail'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { ReactComponent as EmailSVG } from 'js-components/src/components/Icons/Email.svg'
import { RouteComponentProps } from 'react-router'
import UserInviteModalContainer from '../../containers/UserInviteModalContainer'
import Permissions from '../../constants/Permissions'
import PageTitle from '../PageTitle/PageTitle'
import { UsersTitle } from '../../constants/PageTitles'
import * as accountsSelector from '../../reducers/accounts/accountsSelectors'
import * as rolesSelectors from '../../reducers/roles/rolesSelectors'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRoles } from '../../reducers/roles/actionCreators'
import { fetchAccounts } from '../../reducers/accounts/actionCreators'
import useRouter from '../../utils/custom-hooks/useRouter'
import isEqual from 'lodash/isEqual'

// const AnimatedUserDetailContainer = withAnimation(
//   UserDetailContainer,
//   animations.growAnimations.growIn,
//   '0.5s',
//   'ease'
// ).addAnimation(animations.growAnimations.growOut, '0.5s', 'ease', {
//   animate: 'animateExit',
//   animationComplete: 'animateExitComplete'
// });

const DEFAULT_TEAM_FILTER = 'all_teams'

const filterUsers = memoizeOne(
  (users: ILicenseGroupAccount[], searchValue: string, filterTeam: string) => {
    let result = users

    if (filterTeam && filterTeam !== DEFAULT_TEAM_FILTER) {
      if (filterTeam === Permissions.AccessAdmin.name) {
        result = result.filter(u =>
          u.permissions.find(permission => permission === filterTeam),
        )
      } else if (filterTeam === IStatus.Invited) {
        result = result.filter(u => u.status === IStatus.Invited)
      } else {
        result = result.filter(
          u => !!u.role_id && u.role_id.toString() === filterTeam,
        )
      }
    }

    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<ILicenseGroupAccount>(
        ['first_name', 'last_name', 'department', 'email', 'position'],
        searchValue,
      )
      result = result.filter(searchFn)
    }

    return result
  },
)

function Users(props: { selectedUserId?: string }) {
  const { selectedUserId } = props

  const users = useSelector(accountsSelector.accounts, isEqual)
  const teams = useSelector(rolesSelectors.roles, isEqual)
  const dispatch = useDispatch()
  const { push } = useRouter()

  const [searchValue, setSearchValue] = useState('')
  const [teamFilter, setTeamFilter] = useState(DEFAULT_TEAM_FILTER)
  const [showingInvite, openUserInvite, closeUserInvite] = useOpenCloseState(
    false,
  )

  useMountEffect(() => {
    dispatch(fetchRoles())
    dispatch(fetchAccounts())
  })

  const getSelectedUser = () => {
    let userId = selectedUserId
    if (!users || users.length === 0) return
    // If no param set, assume first one
    // TODO: API should come is as string
    if (!userId) userId = users[0].id.toString()
    return users.find(u => u.id.toString() === userId)
  }

  const getFilteredUsers = () => {
    return filterUsers(getRequired(users), searchValue, teamFilter)
  }

  const getTeamOptions = () => {
    const baseOptions = [
      { value: DEFAULT_TEAM_FILTER, label: 'All Teams' },
      { value: Permissions.AccessAdmin.name, label: 'Administrators' },
      { value: IStatus.Invited, label: 'Invited Users' },
      { value: 'spacer', label: '──────────', disabled: true },
    ]
    if (!teams) return baseOptions
    const options = teams.map(({ id, display_name }) => ({
      value: id,
      label: display_name,
    }))

    return [...baseOptions, ...options]
  }

  const onUserSelected = (selectedUser: ILicenseGroupAccount) => () => {
    push(`/users/${selectedUser.id}`)
  }

  const renderRow = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    // isScrolling, // The List is currently being scrolled
    // isVisible,   // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: ListRowProps) => {
    const filteredUsers = getFilteredUsers()
    const user = filteredUsers[index]
    const selectedUser = getSelectedUser()
    return (
      <div key={key} style={style}>
        <UserRow
          user={user}
          last={index === filteredUsers.length - 1}
          onClick={onUserSelected(user)}
          isSelected={selectedUser === user}
        />
      </div>
    )
  }

  const renderUserDetails = (
    user: ILicenseGroupAccount | undefined,
    animatingEnter: boolean,
    animatingExit: boolean,
  ) => {
    return (
      <UserDetailContainer
        key={`anim-user-${user ? user.id : 'none'}`}
        // animate={animatingEnter}
        // animationComplete={animationEnded}
        // animateExit={animatingExit}
        // animateExitComplete={animationExitEnded}
        // if animating exit, make position absolute so it overlaps
        style={{ position: animatingExit ? 'absolute' : undefined }}
        data-cy="user-detail-container">
        <DropShadow distance={1} blur={5}>
          <UserDetailWrapper>
            {user ? (
              <UserDetail key={user.id} userId={user.id} />
            ) : (
              <NotFoundTextContainer>
                <Large color={colors.trulyDark}>
                  User doesn't exist. Please select a different user.
                </Large>
              </NotFoundTextContainer>
            )}
          </UserDetailWrapper>
        </DropShadow>
      </UserDetailContainer>
    )
  }

  if (!users) {
    return (
      <FlexColumn>
        <PageTitle title={UsersTitle} />
        <ContentHeading title={UsersTitle} />
        <Loader mt="200px" />
      </FlexColumn>
    )
  }

  const selectedUser = getSelectedUser()

  return (
    <MainContainer>
      <PageTitle title={UsersTitle} />
      <UserInviteModalContainer
        show={showingInvite}
        onClose={closeUserInvite}
        users={users}
      />
      <ContentHeading
        title={UsersTitle}
        rightControl={
          <HeadingAddButton
            accessibilityLabel="Invite User"
            onClick={openUserInvite}
          />
        }
      />
      <UsersContainer>
        <UserListContainer>
          <UserListHeader>
            <FilterWrapper>
              <TextInput
                customIcon={<Search width="13px" height="13px" />}
                value={searchValue}
                onChange={setSearchValue}
                placeholder="Filter"
                data-cy="search-input"
              />
            </FilterWrapper>
            <TeamSelectorWrapper data-cy="filter-team-select">
              <Select
                value={teamFilter}
                onChange={setTeamFilter}
                options={getTeamOptions()}
              />
            </TeamSelectorWrapper>
          </UserListHeader>
          <UserListWrapper>
            <AutoSizer>
              {({ height, width }) =>
                getFilteredUsers().length === 0 ? (
                  <div
                    style={{
                      width,
                      height,
                      paddingTop: Math.floor(height / 2 - 130),
                    }}>
                    <EmptyResults title="No Users Found" />
                  </div>
                ) : (
                  <List
                    height={height}
                    width={width}
                    style={{ outline: 0 }}
                    rowHeight={75}
                    rowCount={getFilteredUsers().length}
                    rowRenderer={renderRow}
                    overscanRowCount={3} // Reducing the overscan here doesn't seem to affect it much
                  />
                )
              }
            </AutoSizer>
          </UserListWrapper>
        </UserListContainer>
        <RelativeContainer>
          {/* {animatingOutUser && renderUserDetails(animatingOutUser, false, true)} */}
          {renderUserDetails(selectedUser, false, false)}
        </RelativeContainer>
      </UsersContainer>
    </MainContainer>
  )
}

const MemoUsers = React.memo(Users)

interface UserRowProps {
  user: ILicenseGroupAccount
  last: boolean
  isSelected: boolean
  onClick: () => void
}

const UserRow: React.FC<UserRowProps> = React.memo(props => {
  const { user, last, isSelected, onClick } = props
  const isInvite = user.status === IStatus.Invited
  const fullname = `${user.first_name} ${user.last_name}`
  return (
    <UserRowContainer
      last={last}
      isSelected={isSelected}
      role="button"
      onClick={onClick}
      data-cy="user-row">
      <Avatar
        style={{ marginRight: '20px', flex: '0 0 auto' }}
        size="big"
        colorSeed={!isInvite ? fullname : ''}
        text={!isInvite ? fullname : undefined}
        icon={isInvite && <EmailSVG width="100%" />}
      />
      <UserRowTextContainer>
        <Regular truncate color={colors.trulyDark}>
          {isInvite ? user.email : fullname}
        </Regular>
        <Small truncate color={colors.accentPurple} bold mt="2px">
          {isInvite ? 'Invite Pending' : user.email}
        </Small>
      </UserRowTextContainer>
    </UserRowContainer>
  )
})

// By separating the route logic from the rest of it, we can avoid re-rendering everything if the selected user didn't change
export default function UsersWithDefaultRoute(
  props: RouteComponentProps<{ id: string }>,
) {
  const { location, match } = props
  const { replace } = useRouter()
  const users = useSelector(accountsSelector.accounts)

  useEffect(() => {
    if (!users || users.length === 0) return
    if (location.pathname === '/users') {
      // default first user
      replace(`/users/${users[0].id}`)
    }
  }, [users, replace, location])

  // default first user
  const selectedUserId = match.params.id || users?.[0].id.toString()

  return <MemoUsers selectedUserId={selectedUserId} />
}
