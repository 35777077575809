import React, { useState, useEffect } from 'react'
import {
  PopupModal,
  ModalLayout,
  TextInput,
  FlexRow,
  Regular,
  colors,
  Switch,
} from 'js-components'
import NumberPurchaserContainer from '../../containers/NumberPurchaserContainer'
import { IFeatures, ILicenseGroupAccount } from 'truly-ts'
import memoizeOne from 'memoize-one'
import {
  isValidEmail,
  useValidatedState,
  useOpenCloseState,
  useActionCreator,
} from 'truly-utils'
import { phoneNumberSelectedValidationHandler } from '../../utils/Validation'
import EmailModal from './EmailModal'
import { inviteUser } from '../../reducers/accounts/actionCreators'

interface UserInviteModalProps {
  show: boolean
  onClose: () => void
  users?: ILicenseGroupAccount[]
  company?: string
}

const isEmailValidAndAvailable = memoizeOne(
  (email: string, users?: ILicenseGroupAccount[]) => {
    if (
      users &&
      users.find(
        u => u.email.toLowerCase().trim() === email.toLowerCase().trim(),
      )
    ) {
      return false
    }
    if (isValidEmail(email.trim())) {
      return true
    }
    return false
  },
)

const UserInviteModal: React.FC<UserInviteModalProps> = props => {
  const { show, onClose, users, company } = props
  const onInviteUser = useActionCreator(inviteUser)

  const [email, setEmail] = useState('')
  const [allowNumberChoice, setAllowNumberChoice] = useState(true)

  const [features, setFeatures] = useState<IFeatures>({
    voice_and_text: true,
  } as IFeatures)

  const [enableVoice, setEnableVoice] = useState(true)

  const handleEnableVoice = (newToggleState: boolean) => {
    setFeatures({ ...features, voice_and_text: newToggleState })
    setEnableVoice(newToggleState)
  }

  const [
    selectedNumber,
    phoneNumberValidationState,
    setSelectedNumber,
  ] = useValidatedState<string | null, null>(
    null,
    phoneNumberSelectedValidationHandler,
  )
  const [isPhoneNumberEntity, setIsPhoneNumberEntity] = useState(false)
  const [
    showingEmailModal,
    openEmailModal,
    closeEmailModal,
  ] = useOpenCloseState(false)

  useEffect(() => {
    setEmail('')
    setAllowNumberChoice(true)
    setSelectedNumber(false, null)
    closeEmailModal()
  }, [show, setSelectedNumber, closeEmailModal])

  const onEmailChange = (updatedEmail: string) => setEmail(updatedEmail)
  const onAllowNumberChoiceChange = (updatedAllowNumberChoice: boolean) => {
    setAllowNumberChoice(updatedAllowNumberChoice)
    setSelectedNumber(false, null)
  }
  const onSelectedNumberChanged = (number: string, isEntity: boolean) => {
    setSelectedNumber(true, number)
    setIsPhoneNumberEntity(isEntity)
  }

  const inviteUserClicked = () => {
    onInviteUser(
      email,
      '',
      '',
      isPhoneNumberEntity,
      features,
      !allowNumberChoice ? selectedNumber ?? undefined : undefined,
    )
    onClose()
  }

  return (
    <PopupModal
      show={show}
      width="575px"
      onRequestClose={onClose}
      hideOnBgClick
      showExitButton>
      <ModalLayout.Container>
        <ModalLayout.Header title="Invite User" addBottomBorder />
        <ModalLayout.Content>
          <ModalLayout.Spacer />
          <ModalLayout.Row>
            <EmailModal
              show={showingEmailModal}
              email={email}
              users={users}
              company={company}>
              <TextInput
                data-cy="invite-email"
                value={email}
                onFocus={openEmailModal}
                onBlur={closeEmailModal}
                onKeyDown={openEmailModal}
                onChange={onEmailChange}
                placeholder="Type an Email Address"
                autoFocus
              />
            </EmailModal>
          </ModalLayout.Row>
          <ModalLayout.LightHorizontalLine />
          <ModalLayout.Row>
            <FlexRow
              justifyContent="space-between"
              data-cy="number-setting-row">
              <Regular color={colors.trulyDark}> Enable Voice & SMS </Regular>
              <Switch
                data-cy="setting-switch"
                checked={enableVoice}
                onChange={handleEnableVoice}
                activeColor={colors.accentLavender}
              />
            </FlexRow>
          </ModalLayout.Row>
          <ModalLayout.Row>
            <FlexRow
              justifyContent="space-between"
              data-cy="number-setting-row">
              <Regular color={colors.trulyDark}>
                Allow user to choose own number
              </Regular>
              <Switch
                data-cy="setting-switch"
                checked={allowNumberChoice}
                onChange={onAllowNumberChoiceChange}
                disabled={!enableVoice}
                activeColor={colors.accentLavender}
              />
            </FlexRow>
          </ModalLayout.Row>
          {!allowNumberChoice && <ModalLayout.LightHorizontalLine />}
          {!allowNumberChoice && enableVoice ? (
            <NumberPurchaserContainer
              labelWidth="165px"
              selectedNumber={selectedNumber ?? undefined}
              onSelectedNumberChanged={onSelectedNumberChanged}
              validation={phoneNumberValidationState}
              enableSelectUnassigned
            />
          ) : null}
        </ModalLayout.Content>
        <ModalLayout.FooterBigButton
          actionText="Invite"
          onAction={inviteUserClicked}
          actionDisabled={
            !isEmailValidAndAvailable(email, users) ||
            (!allowNumberChoice && !selectedNumber)
          }
        />
      </ModalLayout.Container>
    </PopupModal>
  )
}

export default UserInviteModal
