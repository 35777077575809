import {
  FETCH_PHONE_MENUS,
  FETCH_PHONE_MENUS_SUCCESS,
  FETCH_PHONE_MENUS_FAIL,
  CREATE_PHONE_MENU,
  CREATE_PHONE_MENU_FAIL,
  PHONE_MENU_ADDED,
  PHONE_MENU_REMOVED,
  DELETE_PHONE_MENU,
  UPDATE_PHONE_MENU,
  PHONE_MENU_UPDATED,
  UPDATE_NODE,
  UPDATE_NODE_ASSIGNMENTS_FOR_TEAM,
  UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION,
} from './actionTypes'

import { IPhoneMenu, IPhoneMenuCreate, INode } from 'truly-ts'

export const fetchPhoneMenus = () => ({
  type: FETCH_PHONE_MENUS,
  payload: {},
})

export const fetchPhoneMenusSuccess = (phoneMenus: IPhoneMenu[]) => ({
  type: FETCH_PHONE_MENUS_SUCCESS,
  payload: {
    phoneMenus,
  },
})

export const fetchPhoneMenusFail = () => ({
  type: FETCH_PHONE_MENUS_FAIL,
  payload: {},
})

export const createPhoneMenu = (
  phoneMenu: IPhoneMenuCreate,
  options: { phoneNumber: string; isPhoneNumberEntityId: boolean },
) => ({
  type: CREATE_PHONE_MENU,
  payload: {
    phoneMenu,
    options,
  },
})

export const createPhoneMenuFail = () => ({
  type: CREATE_PHONE_MENU_FAIL,
  payload: {},
})

export const deletePhoneMenu = (phoneMenu: IPhoneMenu) => ({
  type: DELETE_PHONE_MENU,
  payload: {
    phoneMenu,
  },
})

export const updatePhoneMenu = (phoneMenu: IPhoneMenu) => ({
  type: UPDATE_PHONE_MENU,
  payload: {
    phoneMenu,
  },
})

export const phoneMenuAdded = (phoneMenu: IPhoneMenu) => ({
  type: PHONE_MENU_ADDED,
  payload: {
    phoneMenu,
  },
})

export const phoneMenuRemoved = (phoneMenu: IPhoneMenu) => ({
  type: PHONE_MENU_REMOVED,
  payload: {
    phoneMenu,
  },
})

export const phoneMenuUpdated = (phoneMenu: IPhoneMenu) => ({
  type: PHONE_MENU_UPDATED,
  payload: {
    phoneMenu,
  },
})

export const updateNode = (phoneMenuId: number, node: INode) => ({
  type: UPDATE_NODE,
  payload: {
    node,
    phoneMenuId,
  },
})

export const updateNodeAssignmentsForTeam = (
  teamId: number,
  nodeIds: number[],
) => ({
  type: UPDATE_NODE_ASSIGNMENTS_FOR_TEAM,
  payload: {
    teamId,
    nodeIds,
  },
})

export const updateNodeAssignmentsForExtension = (
  extensionId: number,
  nodeIds: number[],
) => ({
  type: UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION,
  payload: {
    extensionId,
    nodeIds,
  },
})
