import * as React from 'react'
import { Container, SectionHeader, NavOption } from './Styles'
import { Regular } from 'js-components'
import memoize from 'lodash/memoize'

interface LeftNavProps {
  navigate: (path: string) => void
  currentPath: string
}

export default class LeftNav extends React.Component<LeftNavProps> {
  navigate = memoize((path: string) => () => {
    this.props.navigate(path)
  })

  render() {
    const { currentPath } = this.props
    return (
      <Container className="nav-container">
        <SectionHeader>Users</SectionHeader>
        <NavItem
          navigate={this.navigate}
          path="/users"
          activePath={currentPath}>
          Users
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/teams"
          activePath={currentPath}>
          Teams
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/external-users"
          activePath={currentPath}>
          External Users
        </NavItem>
        <SectionHeader>Phone Numbers</SectionHeader>
        <NavItem
          navigate={this.navigate}
          path="/phone-numbers"
          activePath={currentPath}>
          Phone Numbers
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/phone-menus"
          activePath={currentPath}>
          Phone Menus
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/conference-rooms"
          activePath={currentPath}>
          Conference Rooms
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/blocked-numbers"
          activePath={currentPath}>
          Blocked Numbers
        </NavItem>
        <SectionHeader>Other</SectionHeader>
        <NavItem
          navigate={this.navigate}
          path="/webhooks"
          activePath={currentPath}>
          Webhooks
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/reports"
          activePath={currentPath}>
          Reports
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/integrations"
          activePath={currentPath}>
          API Credentials
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/forms"
          activePath={currentPath}>
          Forms
        </NavItem>
        <NavItem
          navigate={this.navigate}
          path="/org-settings"
          activePath={currentPath}>
          Organization Settings
        </NavItem>
      </Container>
    )
  }
}

interface NavItemProps {
  navigate: (path: string) => () => void
  path: string
  activePath: string
  children: string
}

class NavItem extends React.PureComponent<NavItemProps> {
  isActive = (path: string) => {
    return this.props.activePath.startsWith(path)
  }

  render() {
    const { navigate, path, children } = this.props
    return (
      <NavOption onClick={navigate(path)} data-active={this.isActive(path)}>
        <Regular>{children}</Regular>
      </NavOption>
    )
  }
}
