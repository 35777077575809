import { connect } from 'react-redux'
import Recordings from '../components/Recordings/Recordings'
import { IState, Dispatch } from '../store'
import * as selectors from '../reducers/messages/messagesSelectors'
import * as menusSelectors from '../reducers/phoneMenus/phoneMenusSelectors'
import { fetchExtensions } from '../reducers/extensions/actionCreators'
import { fetchMessages } from '../reducers/messages/actionCreators'
import { fetchPhoneMenus } from '../reducers/phoneMenus/actionCreators'
import { IMessage } from 'truly-ts'
import { push } from 'connected-react-router'

interface RecordingsContainerProps {
  onDeleteRecording: (phoneMenu: IMessage) => void
  searchValue: string
  clearFilters: () => void
}

const mapStateToProps = (state: IState) => ({
  loading: selectors.messagesLoading(state),
  recordings: selectors.messages(state),
  entities: {
    phoneMenuMap: menusSelectors.phoneMenuMap(state),
    nodeMap: menusSelectors.allNodeMap(state),
  },
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: RecordingsContainerProps,
) => ({
  fetchData: () => {
    dispatch(fetchExtensions())
    dispatch(fetchMessages())
    dispatch(fetchPhoneMenus())
  },
  deleteRecording: ownProps.onDeleteRecording,
  searchValue: ownProps.searchValue,
  clearFilters: ownProps.clearFilters,
  navigate: (path: string) => {
    dispatch(push(path))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Recordings)
