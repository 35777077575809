import * as React from 'react'
import ReactDOM from 'react-dom'
import {
  IExtension,
  IRole,
  IForwardingNode,
  INode,
  NodeType,
  PhoneMenuType,
} from 'truly-ts'
import {
  SidePanelModal,
  ModalLayout,
  colors,
  Select,
  TextInput,
} from 'js-components'
import ForwardCallEdit from './ForwardCallEdit/ForwardCallEdit'
import { ValidationHandler, ValidationErrorClassName } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { changeNode } from '../../utils/model-utils/node-utils'
import PlayRecordingEdit from './PlayRecordingEdit/PlayRecordingEdit'
import SendToVoicemailEdit from './SendToVoicemailEdit/SendToVoicemailEdit'
import {
  NodeValidationState,
  NodeValidationContext,
  AdvancedPhoneMenuNodeValidationState,
  isNodeValid,
} from '../../utils/model-utils/node-validation'
import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'

export const labelValidationHandler = new ValidationHandler<string | null>(
  'Please enter a label',
  v => !!(v || '').trim(),
)

const NodeTypeOptions: Array<{ label: string; value: NodeType }> = [
  {
    value: NodeType.ForwardCall,
    label: 'forward',
  },
  {
    value: NodeType.PlayRecording,
    label: 'play recording',
  },
  {
    value: NodeType.SendMessage,
    label: 'send to voicemail',
  },
  {
    value: NodeType.Menu,
    label: 'submenu',
  },
]

interface PhoneMenuNodeEditPanelProps {
  phoneMenuId: number
  type: PhoneMenuType
  availableOptionNumbers?: number[] // only for advanced menus
  node?: INode | null
  show: boolean
  updateNode: (node: INode) => void
  removeNode: (node: INode) => void
  onClose: (wasSaved?: boolean) => void
  extensions: IExtension[]
  teams: IRole[]
  isNewNode: boolean
}

interface PhoneMenuNodeEditPanelState {
  node?: INode | null
  originalOptionNumber?: number
  isExistingSubmenu?: boolean
}

const getDefaultValidationState = (
  node: INode | undefined | null,
  phoneMenuType: PhoneMenuType,
) => {
  return {
    showErrors: false,
    validationType: node ? node.type : undefined,
    isAdvanced: phoneMenuType === PhoneMenuType.Advanced,
  }
}

export default class PhoneMenuNodeEditPanel extends React.Component<
  PhoneMenuNodeEditPanelProps,
  PhoneMenuNodeEditPanelState
> {
  private containerRef = React.createRef<any>()
  private nodeValidationState: NodeValidationState

  constructor(props: PhoneMenuNodeEditPanelProps) {
    super(props)

    this.state = this.initialState()
    this.nodeValidationState = getDefaultValidationState(props.node, props.type)
  }

  componentDidMount() {
    if (this.props.node) {
      this.validateNode(this.props.node)
    }
  }

  componentDidUpdate(prevProps: PhoneMenuNodeEditPanelProps) {
    if (prevProps.show && !this.props.show) {
      // do this to reset node state, otherwise we'll get a race condition on validation
      this.setState({
        node: null,
      })
    }

    if (!prevProps.show && this.props.show) {
      this.setState(this.initialState())
      this.nodeValidationState = getDefaultValidationState(
        this.props.node,
        this.props.type,
      )
      if (this.props.node) this.validateNode(this.props.node)
    }
  }

  initialState(): PhoneMenuNodeEditPanelState {
    const { node, show, isNewNode } = this.props
    return {
      // ensure falsey if not showing, so that validation race condition doesn't happen
      node: node && show ? { ...node } : null,
      originalOptionNumber: node?.option_number ?? undefined,
      isExistingSubmenu:
        node && !isNewNode && node.type === NodeType.Menu ? true : false,
    }
  }

  onRemoveNode = () => {
    if (this.props.node) {
      this.props.removeNode(this.props.node)
    }
  }

  debouncedUpdate = debounce((cb?: () => void) => this.forceUpdate(cb), 50, {
    leading: false,
    trailing: true,
  })

  onSaveValidationComplete = () => {
    if (this.state.node) {
      this.props.updateNode(this.state.node)
    }
    this.props.onClose(true)
  }

  onClose = () => this.props.onClose(false)

  onSave = () => {
    this.nodeValidationState = {
      ...this.nodeValidationState,
      showErrors: true,
    }
    const isValid = isNodeValid(this.nodeValidationState)
    if (isValid) {
      this.forceUpdate(this.onSaveValidationComplete)
    } else {
      this.debouncedUpdate(this.scrollToValidationError)
    }
  }

  scrollToValidationError = () => {
    if (!this.containerRef.current) return
    const domNode = ReactDOM.findDOMNode(this.containerRef.current) as Element
    const firstError = domNode.querySelector(`.${ValidationErrorClassName}`)
    // go up enough to be able to find the input
    if (
      firstError &&
      firstError.parentElement &&
      firstError.parentElement.parentElement
    ) {
      const parent = firstError.parentElement.parentElement
      // scroll that into view
      parent.scrollIntoView(true)
      const input = parent.querySelector('input')
      if (input) {
        // focus if exists
        input.focus()
      }
    }
  }

  onNodeChange = (node: INode) => {
    this.setState({
      node,
    })
  }

  onOptionNumberChanged = (value: string) => {
    if (!this.state.node) {
      throw new Error('onOptionNumberChanged called without node')
    }

    const parsedNumber = parseInt(value, 10)

    this.setState({
      node: {
        ...this.state.node,
        option_number: parsedNumber,
      },
    })
  }

  onNodeTypeChanged = (value: string) => {
    if (!this.state.node) {
      throw new Error('onNodeTypeChanged called without node')
    }

    const node = changeNode(this.state.node, value as NodeType)
    this.setState({
      node,
    })
    this.nodeValidationState = getDefaultValidationState(node, this.props.type)
    this.validateNode(node)
  }

  onLabelChanged = (value: string) => {
    if (!this.state.node) {
      throw new Error('onLabelChanged called without node')
    }

    const node = {
      ...this.state.node,
      pre_select_text_to_speech: value,
    }
    this.setState({
      node,
    })
    this.validateNode(node)
  }

  validateNode(node: INode) {
    if (this.props.type === PhoneMenuType.Advanced) {
      this.onNodeValidationChanged({
        label: labelValidationHandler.validate(node.pre_select_text_to_speech),
      } as AdvancedPhoneMenuNodeValidationState)
    }
  }

  onNodeValidationChanged = (validationState: Partial<NodeValidationState>) => {
    this.nodeValidationState = {
      ...this.nodeValidationState,
      ...validationState,
    }
    this.debouncedUpdate()
  }

  getAvailableOptionNumbers() {
    const availableOptionNumbers = getRequired(
      this.props.availableOptionNumbers,
    )
    return uniq([...availableOptionNumbers, this.state.originalOptionNumber])
      .sort()
      .map(num => ({
        label: `${num}`,
        value: `${num}`,
      }))
  }

  renderNodeTypeEditor() {
    const nodeValidationState = this
      .nodeValidationState as AdvancedPhoneMenuNodeValidationState
    return (
      <>
        <ModalLayout.FieldRow
          label="Dialpad Option"
          labelWidth="150px"
          labelColor={colors.darkGray}
          shouldPadRight
          fieldWidth="100%">
          <Select
            options={this.getAvailableOptionNumbers()}
            value={`${this.state.node?.option_number ?? ''}`}
            onChange={this.onOptionNumberChanged}
            data-cy="dialpad-options"
          />
        </ModalLayout.FieldRow>
        {!this.state.isExistingSubmenu && ( // don't allow changing a submenu type
          <ModalLayout.FieldRow
            label="Node Type"
            labelWidth="150px"
            labelColor={colors.darkGray}
            shouldPadRight
            fieldWidth="100%">
            <Select
              options={NodeTypeOptions}
              value={this.state.node?.type}
              onChange={this.onNodeTypeChanged}
              data-cy="node-type-options"
            />
          </ModalLayout.FieldRow>
        )}
        <ModalLayout.FieldRow
          label="Label"
          labelWidth="150px"
          labelColor={colors.darkGray}
          shouldPadRight
          fieldWidth="100%"
          validationState={
            nodeValidationState.showErrors ? nodeValidationState.label : null
          }>
          <TextInput
            autoFocus
            value={this.state.node?.pre_select_text_to_speech}
            onChange={this.onLabelChanged}
            validationState={
              nodeValidationState.showErrors
                ? nodeValidationState.label
                : undefined
            }
            data-cy="label-input"
          />
        </ModalLayout.FieldRow>
        <ModalLayout.HorizontalLine />
      </>
    )
  }

  render() {
    const { show, extensions, teams, type } = this.props
    const { node } = this.state

    return (
      <SidePanelModal
        width="700px"
        onRequestClose={this.onClose}
        visible={show}
        menuOptions={
          type === PhoneMenuType.Advanced
            ? [
                {
                  bold: true,
                  color: colors.alertRed,
                  label: 'Delete Option',
                  onClick: this.onRemoveNode,
                },
              ]
            : undefined
        }>
        {node && (
          <NodeValidationContext.Provider
            value={{
              validationState: this.nodeValidationState,
              validationChange: this.onNodeValidationChanged,
            }}>
            <ModalLayout.Container ref={this.containerRef}>
              <ModalLayout.Header
                title={
                  type === PhoneMenuType.Basic ? 'Behavior' : 'Menu Option'
                }
                addBottomBorder
              />
              <ModalLayout.Content>
                <ModalLayout.Spacer />
                {type === PhoneMenuType.Advanced && this.renderNodeTypeEditor()}
                {(node.type === NodeType.ForwardCall ||
                  node.type === NodeType.RootNode) && (
                  <ForwardCallEdit
                    node={node as IForwardingNode}
                    onChange={this.onNodeChange}
                    extensions={extensions}
                    teams={teams}
                  />
                )}
                {node.type === NodeType.PlayRecording && (
                  <PlayRecordingEdit node={node} onChange={this.onNodeChange} />
                )}
                {node.type === NodeType.SendMessage && (
                  <SendToVoicemailEdit
                    node={node as IForwardingNode}
                    onChange={this.onNodeChange}
                    extensions={extensions}
                    teams={teams}
                  />
                )}
              </ModalLayout.Content>
              <ModalLayout.Footer
                pinToBottom
                actionText="Save"
                onAction={this.onSave}
              />
            </ModalLayout.Container>
          </NodeValidationContext.Provider>
        )}
      </SidePanelModal>
    )
  }
}
