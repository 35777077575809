import React from 'react'
import { DetailedLabel, ValueWrapper, IconWrapper } from './Styles'
import { FlexRow, Switch, colors, ReadOnlyWrapper } from 'js-components'
import { getDataProps } from 'truly-utils'

interface EnabledSettingRowProps {
  enabled: boolean
  title: string
  onChange: (enabled: boolean) => void
  enabledDesc: string
  disabledDesc: string
  icon?: React.ReactNode
  readonly?: boolean
  readOnlyComponent?: React.ReactNode
}

export default function EnabledSettingRow(props: EnabledSettingRowProps) {
  const {
    onChange,
    enabled,
    title,
    enabledDesc,
    disabledDesc,
    icon,
    readonly,
    readOnlyComponent,
  } = props

  const fillColor = enabled && !readonly ? colors.accentPurple : colors.darkGray
  const dataProps = getDataProps(props)

  const handleChange = (checked: boolean) => {
    if (!readonly) {
      onChange(checked)
    }
  }

  return (
    <FlexRow justifyContent="space-between" alignItems="center" {...dataProps}>
      <FlexRow justifyContent="flex-start" alignItems="center">
        {icon && (
          <IconWrapper>
            {React.cloneElement(icon as any, {
              fill: fillColor,
              color: fillColor,
            })}
          </IconWrapper>
        )}
        <DetailedLabel
          description={enabled ? enabledDesc : disabledDesc}
          enabled={enabled}
          readonly={readonly}>
          {title} <EnabledText enabled={enabled} />
        </DetailedLabel>
      </FlexRow>
      <ValueWrapper>
        <ReadOnlyWrapper
          disabled={readonly}
          readOnlyComponent={readOnlyComponent}>
          <Switch
            data-cy="setting-switch"
            disabled={readonly}
            onChange={handleChange}
            checked={enabled}
            activeColor={colors.accentLavender}
          />
        </ReadOnlyWrapper>
      </ValueWrapper>
    </FlexRow>
  )
}

function EnabledText(props: { enabled?: boolean | null }) {
  return <strong>{props.enabled ? 'Enabled' : 'Disabled'}</strong>
}
