import { IState } from '../../store'
import values from 'lodash/values'

export const base = (state: IState) => state.accounts
export const accounts = (state: IState) =>
  base(state).accounts &&
  values(base(state).accounts).sort((a, b) => {
    const aName = a.last_name ? a.last_name : a.first_name
    const bName = b.last_name ? b.last_name : b.first_name
    if (aName > bName || !aName) {
      return 1
    }

    if (aName < bName || !bName) {
      return -1
    }

    return 0
  })
export const accountMap = (state: IState) => base(state).accounts
export const accountById = (id: number) => (state: IState) =>
  accounts(state)?.find(a => a.id === id)
export const accountByExtensionId = (id: number) => (state: IState) =>
  accounts(state)?.find(a => a.extension_id === id)
