import React from 'react'
import { PhoneNumberRowContainer, IconWrapper } from './Styles'
import {
  CircleRemove,
  FlexColumn,
  Regular,
  colors,
  Small,
  ModalLayout,
  FlexRow,
  Edit,
  DeleteDialog,
} from 'js-components'
import {
  formatPhoneNumber,
  numberTypeDisplay,
  usePreloader,
  useOpenCloseState,
} from 'truly-utils'
import PhoneNumberEditPanelContainer from '../../containers/PhoneNumberEditPanelContainer'
import { fetchEntities } from '../../reducers/entities/actionCreators'
import { entitiesMap } from '../../reducers/entities/entitiesSelectors'
import * as phoneNumberSelectors from '../../reducers/phoneNumbers/phoneNumbersSelectors'
import {
  fetchPhoneNumbers,
  unassignPhoneNumber,
} from '../../reducers/phoneNumbers/actionCreators'
import { EntityType } from 'truly-ts'
import { useDispatch } from 'react-redux'

interface PhoneNumberUnassignRowProps {
  number: string
  assignedEntityType: EntityType
  assignedEntityId: number
  assignedEntityDisplay: string
  includeSeparator?: boolean
}

const PhoneNumberUnassignRow: React.FC<PhoneNumberUnassignRowProps> = props => {
  const { number, includeSeparator } = props
  const dispatch = useDispatch()
  const entities = usePreloader(entitiesMap, fetchEntities)
  const phoneNumbers = usePreloader(
    phoneNumberSelectors.phoneNumbers,
    fetchPhoneNumbers,
  )
  const selectedPhoneNumber =
    phoneNumbers && phoneNumbers.find(phone => phone.full_number === number)
  const [editingNumber, openEditNumber, closeEditNumber] = useOpenCloseState(
    false,
  )
  const [unassigning, openUnassigning, closeUnassigning] = useOpenCloseState(
    false,
  )

  const formattedNumber = formatPhoneNumber(props.number)
  const onUnassign = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    openUnassigning()
  }
  const onConfirmUnassign = () => {
    dispatch(
      unassignPhoneNumber(
        props.assignedEntityType,
        props.assignedEntityId,
        props.number,
      ),
    )
    closeUnassigning()
  }

  const formattedUnassigningNumber =
    selectedPhoneNumber && formatPhoneNumber(selectedPhoneNumber.full_number)

  return (
    <>
      {entities && selectedPhoneNumber && (
        <PhoneNumberEditPanelContainer
          key="phone-number-edit"
          entities={entities}
          onClose={closeEditNumber}
          show={editingNumber}
          phoneNumber={selectedPhoneNumber}
        />
      )}
      <DeleteDialog
        show={unassigning}
        deleteActionText="Unassign"
        title={`Unassign ${formattedUnassigningNumber}?`}
        description={`The phone number ${formattedUnassigningNumber} will be unassigned from ${props.assignedEntityDisplay}.`}
        onCancel={closeUnassigning}
        onDelete={onConfirmUnassign}
      />
      <ModalLayout.HoverableRow onClick={openEditNumber} key="phone-row">
        {includeSeparator ? (
          <ModalLayout.LightHorizontalLine />
        ) : (
          <ModalLayout.Spacer />
        )}
        <ModalLayout.Row>
          <PhoneNumberRowContainer>
            <FlexColumn justifyContent="center" alignItems="flex-start">
              <Regular color={colors.trulyDark} mb="2px">
                {formattedNumber}
              </Regular>
              <Small color={colors.trulyDark} bold>
                {numberTypeDisplay(props.number)}
              </Small>
            </FlexColumn>
            <FlexRow data-hover-row-show>
              <IconWrapper onClick={onUnassign}>
                <CircleRemove width="22px" height="22px" />
              </IconWrapper>
              <IconWrapper>
                <Edit width="22px" height="22px" />
              </IconWrapper>
            </FlexRow>
          </PhoneNumberRowContainer>
        </ModalLayout.Row>
      </ModalLayout.HoverableRow>
    </>
  )
}

export default PhoneNumberUnassignRow
