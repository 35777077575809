import { connect } from 'react-redux'
import RecordingModal from '../components/RecordingModal/RecordingModal'
import {
  updateMessage,
  deleteMessage,
} from '../reducers/messages/actionCreators'
import { updatePhoneMenu } from '../reducers/phoneMenus/actionCreators'
import * as selectors from '../reducers/messages/messagesSelectors'
import {
  phoneMenuMap,
  allNodeMap,
  phoneMenus,
} from '../reducers/phoneMenus/phoneMenusSelectors'
import { IState } from '../store'

const mapStateToProps = (state: IState) => ({
  modifiedMessage: selectors.modifiedMessage(state),
  entities: {
    phoneMenuMap: phoneMenuMap(state),
    nodeMap: allNodeMap(state),
  },
  phoneMenus: phoneMenus(state) ?? [],
})

const mapDispatchToProps = {
  updateMessage,
  deleteMessage,
  updatePhoneMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordingModal)
