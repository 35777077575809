import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import LogRocket from 'logrocket'
import getRootReducer from '../reducers'
import rootSaga from './rootSaga'
import { routerMiddleware } from 'connected-react-router'
import { Enhancer, Store } from '.'
import { handleSession } from '../middleware/session-middleware'
import { handleApp } from '../middleware/app-middleware'
import { INITIALIZE } from '../reducers/actionTypes'
import { handleNavigationHooks } from '../middleware/navigation-middleware'
import { History } from 'history'
import { analyticsMiddleware } from '../middleware/analytics-middleware'
import { AnalyticsClient } from 'truly-utils'
import {
  devCacheMiddleware,
  getCachedState,
} from '../middleware/dev-cache-middleware'
import { stateCacheEnabled } from '../constants/Config'
import { handleFlags } from '../middleware/flag-middleware'

const debug = process.env.NODE_ENV === 'development'

declare let module: any

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

function getStore(initialState: any, enhancer: Enhancer, history: History) {
  const rootReducer = getRootReducer(history) as any
  const store = createStore(rootReducer, initialState, enhancer)
  if (process.env.NODE_ENV === 'development' && (module.hot as any)) {
    module.hot.accept('../reducers', async () => {
      console.warn('reloading reducers')
      const newReducers = (await import('../reducers')).default(history)
      store.replaceReducer(newReducers as any)
    })
  }
  sagaMiddleware.run(rootSaga)
  store.dispatch({ type: INITIALIZE, payload: {} })

  // TODO fix this cast
  return (store as unknown) as Store
}

export function configureStore(history: History) {
  const analyticsToken = window.trulyEnv.analytics_app_token
  if (!analyticsToken && !window.trulyEnv.disable_analytics) {
    throw new Error('analytics_app_token is not set, check your .env file')
  }

  const middlewares: any = [
    handleSession(),
    sagaMiddleware,
    handleApp(),
    window.trulyEnv.disable_analytics
      ? false
      : analyticsMiddleware(new AnalyticsClient(debug, analyticsToken)),
    handleNavigationHooks(), // this must come before router
    routerMiddleware(history),
    handleFlags(),
    window.trulyEnv.disable_analytics ? false : LogRocket.reduxMiddleware(),
    stateCacheEnabled ? devCacheMiddleware() : false,
  ].filter(m => !!m)

  if (window.trulyEnv.enable_redux_logger) {
    const logger = createLogger({ level: 'info', collapsed: true })
    middlewares.push(logger)
  }

  const enhancer: Enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
  ) as Enhancer

  const store = getStore(
    (stateCacheEnabled && getCachedState()) || {},
    enhancer,
    history,
  )
  return store
}
