import React, { useState, useMemo } from 'react'
import { IRole } from 'truly-ts'
import {
  FlexColumn,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  TableRow,
  TableData,
  Avatar,
  AvatarSizes,
  Small,
  Regular,
  colors,
  AvatarRow,
  ToolTip,
  Trash,
  Edit,
  spaceSizes,
} from 'js-components'
import Loader from '../Loader/Loader'
import { makeSearchFn, useOpenCloseState, useMountEffect } from 'truly-utils'
import {
  MainContainer,
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import TeamDeleteDialogContainer from '../../containers/TeamDeleteDialogContainer'
import TeamCreateModalContainer from '../../containers/TeamCreateModalContainer'
import PageTitle from '../PageTitle/PageTitle'
import { TeamsTitle } from '../../constants/PageTitles'
import * as selectors from '../../reducers/roles/rolesSelectors'
import { useSelector, useDispatch } from 'react-redux'
import useRouter from '../../utils/custom-hooks/useRouter'
import { fetchRoles } from '../../reducers/roles/actionCreators'
import useClearableState from '../../utils/custom-hooks/useClearableState'
import isEqual from 'lodash/isEqual'

const RowHeight = 75
const AvatarCellWidth = '80px'

export default function Teams() {
  const teams = useSelector(selectors.roles, isEqual)
  const { push } = useRouter()
  const dispatch = useDispatch()

  const [searchValue, setSearchValue, clearSearchValue] = useClearableState('')
  const [deleting, openDeleting, closeDeleting] = useOpenCloseState(false)
  const [creating, openCreating, closeCreating] = useOpenCloseState(false)
  const [selectedTeam, setSelectedTeam] = useState<IRole>()

  useMountEffect(() => {
    dispatch(fetchRoles())
  })

  const onEditTeam = (team: IRole) => {
    push(`/teams/${team.id}`)
  }

  const onDeleteTeam = (deletingTeam: IRole) => {
    openDeleting()
    setSelectedTeam(deletingTeam)
  }

  const onCloseDelete = () => {
    closeDeleting()
    setSelectedTeam(undefined)
  }

  const filteredTeams = useMemo(() => {
    if (!teams) return []
    let resultTeams = teams

    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<IRole>(
        ['display_name', 'department'],
        searchValue,
      )
      resultTeams = resultTeams.filter(searchFn)
    }

    return resultTeams
  }, [teams, searchValue])

  const isFiltered =
    teams && teams.length > 0 && !!(searchValue && searchValue.trim())

  if (!teams) {
    return (
      <FlexColumn>
        <PageTitle title={TeamsTitle} />
        <ContentHeading title={TeamsTitle} />
        <Loader mt="200px" />
      </FlexColumn>
    )
  }

  return (
    <MainContainer>
      <PageTitle title={TeamsTitle} />
      <TeamDeleteDialogContainer
        show={deleting}
        selectedTeam={selectedTeam}
        onClose={onCloseDelete}
      />
      <TeamCreateModalContainer show={creating} onClose={closeCreating} />
      <ContentHeading
        title={TeamsTitle}
        rightControl={
          <HeadingAddButton
            accessibilityLabel="Add Team"
            onClick={openCreating}
          />
        }
      />
      <ContentContainer>
        <TableFilterWrapper>
          <TableFilterRow
            searchInput
            searchInputValue={searchValue}
            selectedFilterOption="all"
            onSearchInputChanged={setSearchValue}
            searchPlaceholder="Filter"
            filterOptions={[
              {
                value: 'all',
                label: 'All Teams',
              },
            ]}
          />
        </TableFilterWrapper>
        <TableWrapper>
          <ScrollableTable
            header={<TeamTableHeaderRow />}
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={isFiltered ? 'No Teams Found' : 'No Teams... Yet.'}
                actionText={isFiltered ? 'Clear All Filters' : 'Add New Team'}
                onActionClick={isFiltered ? clearSearchValue : openCreating}
              />
            }
            rowCount={filteredTeams.length}
            renderRow={idx => (
              <TeamTableRow
                key={idx}
                rowHeight={RowHeight}
                onEdit={onEditTeam}
                onDelete={onDeleteTeam}
                team={filteredTeams[idx]}
                navigate={push}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    </MainContainer>
  )
}

class TeamTableHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData width={AvatarCellWidth} text="" />
        <TableHeaderData text="Team" />
        <TableHeaderData text="Users" />
        <TableHeaderData text="" />
      </TableHeaderRow>
    )
  }
}

interface TeamTableRowProps {
  team: IRole
  rowHeight: number
  onEdit: (team: IRole) => void
  onDelete: (team: IRole) => void
  navigate: (path: string) => void
}

class TeamTableRow extends React.PureComponent<TeamTableRowProps> {
  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.team)
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.team)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const {
      team,
      onEdit,
      onDelete,
      rowHeight,
      navigate,
      ...restProps
    } = this.props

    return (
      <TableRow
        height={rowHeight}
        verticalAlign="middle"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={AvatarCellWidth}>
          <LeftAlignedTableData>
            <Avatar
              colorSeed={team.display_name}
              size={AvatarSizes.Big}
              square
              text={(team.display_name || '').split(' ')[0]}
            />
          </LeftAlignedTableData>
        </TableData>
        <TableData hideOverflow>
          <Regular truncate mr="20px" color={colors.trulyDark}>
            {team.display_name}
          </Regular>
          <Small
            truncate
            mr="20px"
            color={colors.accentPurple}
            mt={`${spaceSizes.xs}px`}
            bold>
            {team.department}
          </Small>
        </TableData>
        <TableData>
          {team.accounts && team.accounts.length > 0 ? (
            <AvatarRow
              size={AvatarSizes.Small}
              maxVisibleCount={4}
              avatarOptions={team.accounts.map(a => ({
                label: a.display_name ?? '',
                onClick: () => navigate(`/users/${a.id}`),
              }))}
            />
          ) : (
            <Regular color={colors.darkGray}>None</Regular>
          )}
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Delete')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onDelete}
                role="button"
                data-cy="delete-button">
                <Trash width="24" height="24" />
              </ActionButton>
            </ToolTip>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Edit')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onEdit}
                role="button"
                data-cy="edit-button">
                <Edit width="24" height="24" />
              </ActionButton>
            </ToolTip>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
