import styled from 'styled-components'
import { FlexRow } from 'js-components'

export const ContainerBG = styled.div`
  background: linear-gradient(#91d7e8 0%, #41a3cc 100%);
  height: 100%;
  width: 100%;
  position: fixed;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-size: cover;
  background-repeat: repeat;
  background-image: url(${`${process.env.PUBLIC_URL}/img/shapes.svg`});
  color: white;
  text-align: center;
  -webkit-app-region: drag;
  overflow: auto;

  @media (max-height: 600px) {
    justify-content: initial;
  }
`

export const ButtonHeader = styled(FlexRow)`
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-top: 30px;

  .admin {
    margin-left: 10px;
    margin-bottom: 8px;
  }
`
