import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useActionCreator } from 'truly-utils'

type AnyFunction = (...args: any[]) => any

export default function usePreloader<T extends AnyFunction>(
  selector: T,
  fetchAction: () => any,
  forceReload?: boolean,
): ReturnType<T> {
  const loaded = useRef(false)
  const entities = useSelector(selector)
  const fetchEntities = useActionCreator(fetchAction)
  useEffect(() => {
    if ((!entities && !loaded.current) || forceReload) {
      fetchEntities()
      loaded.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, fetchEntities, forceReload])

  return entities
}
