import React, { useCallback } from 'react'
import {
  IPhoneMenu,
  INode,
  IMessage,
  IForwardingNode,
  IExtension,
  PhoneMenuType,
  MessageType,
} from 'truly-ts'
import {
  HoursContainer,
  PaddedColumn,
  TimezoneRow,
  LightHorizontalLine,
  AvailabilityPickerWrapper,
} from '../Styles'
import {
  Label,
  MediumSectionHeader,
  MediumSectionHeaderDescription,
} from '../../LayoutHelpers/Styles'
import {
  TimezoneSelect,
  InlineMultiSelect,
  colors,
  AvailabilityPicker,
  AvailabilityUtils,
} from 'js-components'
import RecordingEditor from '../../RecordingEditor/RecordingEditor'
import {
  getMessageFromNode,
  messageToNodeMessage,
  markNodeDirty,
} from '../../../utils/model-utils/node-utils'
import { isEditingTreeRoot } from '../../../utils/model-utils/phone-menus-utils'
import { devAssert, getRequired } from 'truly-utils/macro'
import EntityRow from '../../EntityRow/EntityRow'

interface PhoneMenuEditHoursTabProps {
  phoneMenu: IPhoneMenu
  editingNode: INode
  extensions?: IExtension[]
  updateEditingNode: (node: INode) => void
  phoneMenuChanged: (phoneMenu: IPhoneMenu) => void
  goToUsers: () => void
}

const PhoneMenuEditHoursTab: React.FC<PhoneMenuEditHoursTabProps> = props => {
  const {
    phoneMenu,
    editingNode,
    extensions = [],
    updateEditingNode,
    phoneMenuChanged,
    goToUsers,
  } = props

  const afterHoursMessage = getMessageFromNode(
    editingNode,
    MessageType.NotInBusinessHoursMessage,
  )

  const onTimezoneChanged = useCallback(
    (tz: string) => {
      phoneMenuChanged({
        ...phoneMenu,
        timezone: tz,
      })
    },
    [phoneMenuChanged, phoneMenu],
  )

  const onAfterHoursRecordingChanged = useCallback(
    (message: IMessage) => {
      // edit and save node
      const nodeMessages = editingNode.messages
      const rootNode = markNodeDirty({
        ...editingNode,
        messages: [
          // remove old message
          ...(nodeMessages || []).filter(
            m => m.type !== MessageType.NotInBusinessHoursMessage,
          ),
          {
            ...messageToNodeMessage(message),
            type: MessageType.NotInBusinessHoursMessage,
          },
        ],
      })
      updateEditingNode(rootNode)
    },
    [editingNode, updateEditingNode],
  )

  const onExtensionsChanged = useCallback(
    (exts: IExtension[]) => {
      devAssert(assert =>
        assert(
          isEditingTreeRoot(phoneMenu, editingNode),
          'Should not be able to edit non-root node',
        ),
      )
      const node = getRequired(phoneMenu).tree
      const tree: IForwardingNode = {
        ...node,
        extensions: exts.map(e => ({
          ...e,
          hunt_group: 0,
        })),
        roles: [],
        hunt_groups: (node as IForwardingNode).hunt_groups || [],
      }
      updateEditingNode(markNodeDirty(tree))
    },
    [phoneMenu, editingNode, updateEditingNode],
  )

  const navigateToUsers = useCallback(() => {
    goToUsers()
  }, [goToUsers])

  const updateBusinessHours = useCallback(
    (business_hours: IPhoneMenu['business_hours']) => {
      phoneMenuChanged({
        ...phoneMenu,
        business_hours,
      })
    },
    [phoneMenu, phoneMenuChanged],
  )

  const renderExtensionOption = useCallback((extension: IExtension) => {
    return (
      <EntityRow
        title={extension.name}
        subtitle="Extension"
        avatarText={extension.name.split(' ')[0]}
        avatarSeed={extension.name}
        width="300px"
      />
    )
  }, [])

  return (
    <HoursContainer>
      <PaddedColumn>
        <TimezoneRow>
          <Label>Timezone</Label>
          <TimezoneSelect
            timezone={phoneMenu.timezone}
            saveTimezone={onTimezoneChanged}
            width="240px"
            inline
          />
        </TimezoneRow>
      </PaddedColumn>
      <LightHorizontalLine />
      <PaddedColumn style={{ paddingBottom: '24px' }}>
        <MediumSectionHeader>After Hours Recording</MediumSectionHeader>
        <MediumSectionHeaderDescription>
          This voicemail will be played for users that call your phone number.
        </MediumSectionHeaderDescription>
        <RecordingEditor
          message={afterHoursMessage ?? undefined}
          onChange={onAfterHoursRecordingChanged}
          newRecordingDefaultName={
            afterHoursMessage
              ? afterHoursMessage.title
              : `${phoneMenu.name} (After Hours)`
          }
          largePlay
        />
        {phoneMenu.type === PhoneMenuType.Advanced && (
          <>
            <MediumSectionHeader>
              Send after hours voicemail to the following users
            </MediumSectionHeader>
            <InlineMultiSelect
              defaultSelectedOptions={
                (editingNode as IForwardingNode).extensions
              }
              emptyView={{ entityName: 'Users', onClick: navigateToUsers }}
              options={extensions}
              placeholder="Type a User's Name"
              saveOnChange
              onSave={onExtensionsChanged}
              valueDisplayMap={extensionNameMapper}
              renderOption={renderExtensionOption}
            />
          </>
        )}
      </PaddedColumn>
      <LightHorizontalLine />
      <PaddedColumn>
        <MediumSectionHeader color={colors.trulyDark}>
          Hours
        </MediumSectionHeader>
        <AvailabilityPickerWrapper>
          <AvailabilityPicker
            availability={getCalendarAvailability(phoneMenu)}
            rawAvailability={phoneMenu.business_hours || null}
            saveAvailability={updateBusinessHours}
          />
        </AvailabilityPickerWrapper>
      </PaddedColumn>
    </HoursContainer>
  )
}

const extensionNameMapper = (extension: IExtension) => extension.name

const getCalendarAvailability = (phoneMenu: IPhoneMenu) => {
  const availability = phoneMenu.business_hours
  return AvailabilityUtils.formatCalendar(availability) || []
}

export default PhoneMenuEditHoursTab
