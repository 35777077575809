import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import {
  FETCH_WEBHOOKS,
  CREATE_WEBHOOK,
  UPDATE_WEBHOOK,
  DELETE_WEBHOOK,
} from './actionTypes'
import {
  fetchWebhooksFail,
  fetchWebhooksSuccess,
  createWebhook,
  webhookAdded,
  updateWebhook,
  webhookUpdated,
  deleteWebhook,
} from './actionCreators'
import * as selectors from './webhooksSelectors'
import { IWebhook } from 'truly-ts'
import { getRequired } from 'truly-utils/macro'
import { push } from 'connected-react-router'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* webhooksSaga() {
  yield all([
    takeLatest(FETCH_WEBHOOKS, fetchWebhooksSaga),
    takeLatest(CREATE_WEBHOOK, createWebhookSaga),
    takeLatest(UPDATE_WEBHOOK, updateWebhookSaga),
    takeLatest(DELETE_WEBHOOK, deleteWebhookSaga),
  ])
}

export function* fetchWebhooksSaga() {
  try {
    const req = yield call(client.webhooks.fetchWebhooks)
    yield put(fetchWebhooksSuccess(req.data.webhooks))
  } catch (e) {
    console.error('fetching webhooks', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get webhooks. Please refresh the page.',
    )
    yield put(fetchWebhooksFail())
  }
}

function* createWebhookSaga(action: ReturnType<typeof createWebhook>) {
  try {
    const req = yield call(
      client.webhooks.createWebhook,
      action.payload.webhook,
    )
    yield put(webhookAdded(req.data.webhook))
  } catch (e) {
    console.log('creating webhook', e)
    toastr.error(
      'An Error Occurred',
      'Unable to add the webhook. Please try again.',
    )
  }
}

function* updateWebhookSaga(action: ReturnType<typeof updateWebhook>) {
  const webhooks = yield select(selectors.webhooks)
  const existingHook = getRequired(
    webhooks.find((webhook: IWebhook) => webhook.uuid === action.payload.id),
  )
  const newHook = {
    ...existingHook,
    ...action.payload.webhook,
  } as IWebhook
  yield put(webhookUpdated(newHook)) // optimistic
  try {
    const req = yield call(
      client.webhooks.updateWebhook,
      action.payload.id,
      action.payload.webhook,
    )
    yield put(webhookUpdated(req.data.webhook)) // post any new updates
    yield put(push('/webhooks'))
  } catch (e) {
    console.log('updating webhook', e)
    toastr.error(
      'An Error Occurred',
      'Unable to update the webhook. Please try again.',
    )
    yield put(webhookUpdated(existingHook)) // revert
  }
}

function* deleteWebhookSaga(action: ReturnType<typeof deleteWebhook>) {
  const { webhook } = action.payload
  try {
    if (!webhook.uuid) {
      throw new Error('Trying to deleteWebhookSaga without uuid')
    }

    yield call(client.webhooks.deleteWebhook, webhook.uuid)
  } catch (e) {
    yield put(webhookAdded(webhook))
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete webhook. Please try again.',
    )
  }
}
