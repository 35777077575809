import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../reducers/navigation/actionCreators'

export default function useDirty(
  title: string,
  description: string,
  allowedNavRegex?: string,
): [boolean, () => void, () => void] {
  const dispatch = useDispatch()
  const [dirty, setDirty] = useState(false)
  const reset = useCallback(() => {
    setDirty(false)
    dispatch(clearUnsavedChanges())
  }, [dispatch])
  const makeDirty = useCallback(() => {
    setDirty(true)
    dispatch(setUnsavedChanges(title, description, allowedNavRegex))
  }, [title, description, allowedNavRegex, dispatch])
  return [dirty, makeDirty, reset]
}
