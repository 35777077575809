import { IState } from '../../store'
import values from 'lodash/values'
import { createSelector } from 'reselect'
export const base = (state: IState) => state.conferenceRooms
export const loadingConferenceRoom = (state: IState) =>
  base(state).loadingConferenceRoom
export const conferenceRoomMap = createSelector(
  base,
  c => c.conferenceRooms ?? undefined,
)
export const conferenceRooms = createSelector(
  conferenceRoomMap,
  rooms => rooms && values(rooms),
)
export const conferenceRoomById = (id: number) => (state: IState) => {
  const rooms = conferenceRoomMap(state)
  return rooms && rooms[id]
}
