import * as React from 'react'
import { makeSearchFn } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  FlexColumn,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  Small,
  TableRow,
  TableData,
  Avatar,
  AvatarSizes,
  colors,
  Regular,
  Link,
  AvatarRow,
  spaceSizes,
  ToolTip,
  Edit,
  Trash,
  DeleteDialog,
} from 'js-components'
import { ReactComponent as BulletList } from '../../common/icons/bulletlist.svg'
import Loader from '../Loader/Loader'
import {
  MainContainer,
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import { Form } from '../../reducers/forms/types'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import PageTitle from '../PageTitle/PageTitle'
import { FormsTitle } from '../../constants/PageTitles'

const RowHeight = 75
const AvatarCellWidth = '80px'

interface FormsProps {
  forms?: Form[]
  loadForms: () => void
  deleteForm: (form: Form) => void
  updateForm: (id: string, form: Partial<Form>) => void
  navigate: (path: string) => void
}

interface FormsState {
  selectedFilter: string
  searchValue: string

  deleting: boolean
  selectedForm?: Form | null
}

export default class Forms extends React.Component<FormsProps, FormsState> {
  constructor(props: FormsProps) {
    super(props)
    this.state = {
      selectedFilter: 'all',
      searchValue: '',
      deleting: false,
    }
  }

  componentDidMount() {
    this.props.loadForms()
  }

  onSearchInputChanged = (searchValue: string) => {
    this.setState({
      searchValue,
    })
  }

  onFilterOptionChanged = (selectedFilter: string) => {
    this.setState({
      selectedFilter,
    })
  }

  clearFilters = () => {
    this.setState({
      selectedFilter: 'all',
      searchValue: '',
    })
  }

  getFilteredForms = () => {
    let forms = getRequired(this.props.forms)
    const { searchValue, selectedFilter } = this.state

    if (selectedFilter === 'assigned') {
      forms = forms.filter(f => f.roles.length > 0)
    }

    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<Form>(['description'], searchValue)
      forms = forms.filter(searchFn)
    }

    return forms
  }

  isFiltered = () => {
    const { searchValue, selectedFilter } = this.state

    return (
      this.props.forms &&
      this.props.forms.length > 0 &&
      (selectedFilter !== 'all' || !!(searchValue && searchValue.trim()))
    )
  }

  onAddForm = () => {
    this.props.navigate('/forms/new')
  }

  onMakeFormDefault = (form: Form) => {
    if (!form.id) {
      throw new Error('Unable to set default form wtih no ID')
    }
    this.props.updateForm(form.id, { licensegroup_default: true })
  }

  onEditForm = (form: Form) => {
    this.props.navigate(`/forms/${form.id}`)
  }

  onDeleteForm = (form: Form) => {
    this.setState({
      deleting: true,
      selectedForm: form,
    })
  }

  onCancelDelete = () => this.setState({ deleting: false })

  onConfirmDelete = () => {
    if (!this.state.selectedForm) {
      throw new Error("Can't delete without selected form")
    }
    this.setState({
      deleting: false,
    })
    this.props.deleteForm(this.state.selectedForm)
  }

  render() {
    const { navigate } = this.props
    const { selectedFilter, searchValue, deleting, selectedForm } = this.state

    if (!this.props.forms) {
      return (
        <FlexColumn>
          <PageTitle title={FormsTitle} />
          <ContentHeading title={FormsTitle} />
          <Loader mt="200px" />
        </FlexColumn>
      )
    }

    const forms = this.getFilteredForms()
    const isFiltered = this.isFiltered()

    return (
      <MainContainer>
        <PageTitle title={FormsTitle} />
        <DeleteDialog
          show={deleting}
          title={
            selectedForm
              ? `Delete ${selectedForm.description}`
              : 'No Form Selected'
          }
          deleteActionText="Delete"
          description="This form will be deleted from you organization."
          confirmText="DELETE"
          onCancel={this.onCancelDelete}
          onDelete={this.onConfirmDelete}
        />
        <ContentHeading
          title={FormsTitle}
          rightControl={
            <HeadingAddButton
              accessibilityLabel="Add Form"
              onClick={this.onAddForm}
            />
          }
        />
        <ContentContainer>
          <TableFilterWrapper>
            <TableFilterRow
              searchInput
              searchInputValue={searchValue}
              selectedFilterOption={selectedFilter}
              onFilterOptionChanged={this.onFilterOptionChanged}
              onSearchInputChanged={this.onSearchInputChanged}
              searchPlaceholder="Filter"
              filterOptions={[
                {
                  value: 'all',
                  label: 'All Teams',
                },
                {
                  value: 'assigned',
                  label: 'Assigned to Team',
                },
              ]}
            />
          </TableFilterWrapper>
          <TableWrapper>
            <ScrollableTable
              header={<FormTableHeaderRow />}
              rowHeight={RowHeight}
              emptyView={
                <EmptyResults
                  title={isFiltered ? 'No Forms Found' : 'No Forms... Yet.'}
                  actionText={isFiltered ? 'Clear All Filters' : 'Add New Form'}
                  onActionClick={
                    isFiltered ? this.clearFilters : this.onAddForm
                  }
                />
              }
              rowCount={forms.length}
              renderRow={idx => (
                <FormTableRow
                  key={idx}
                  rowHeight={RowHeight}
                  onEdit={this.onEditForm}
                  onDelete={this.onDeleteForm}
                  onMakeDefault={this.onMakeFormDefault}
                  form={forms[idx]}
                  navigate={navigate}
                />
              )}
            />
          </TableWrapper>
        </ContentContainer>
      </MainContainer>
    )
  }
}

class FormTableHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData width={AvatarCellWidth} text="" />
        <TableHeaderData text="Form" />
        <TableHeaderData text="Assigned To Teams" />
        <TableHeaderData text="" />
      </TableHeaderRow>
    )
  }
}

interface FormTableRowProps {
  form: Form
  rowHeight: number
  onEdit: (form: Form) => void
  onDelete: (form: Form) => void
  onMakeDefault: (form: Form) => void
  navigate: (path: string) => void
}

class FormTableRow extends React.PureComponent<FormTableRowProps> {
  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.form)
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.form)
  }

  onMakeDefault = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onMakeDefault(this.props.form)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const {
      form,
      onEdit,
      onDelete,
      rowHeight,
      navigate,
      onMakeDefault,
      ...restProps
    } = this.props

    return (
      <TableRow
        height={rowHeight}
        verticalAlign="middle"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={AvatarCellWidth}>
          <LeftAlignedTableData>
            <Avatar
              colorSeed={form.description}
              size={AvatarSizes.Big}
              square
              icon={<BulletList width="75%" />}
            />
          </LeftAlignedTableData>
        </TableData>
        <TableData hideOverflow>
          <Regular mr="20px" truncate color={colors.trulyDark}>
            {form.description}
          </Regular>
          {form.licensegroup_default ? (
            <Small color={colors.trulyDark} mt={`${spaceSizes.xs}px`} bold>
              Default Form
            </Small>
          ) : (
            <Link color={colors.trulyDark} onClick={this.onMakeDefault}>
              <Small mt={`${spaceSizes.xs}px`} bold>
                Make Default
              </Small>
            </Link>
          )}
        </TableData>
        <TableData>
          {form.roles.length > 0 ? (
            <AvatarRow
              size={AvatarSizes.Small}
              maxVisibleCount={4}
              square
              avatarOptions={form.roles.map(r => ({
                label: r.display_name,
                onClick: () => navigate(`/teams/${r.id}`),
              }))}
            />
          ) : (
            <Regular color={colors.darkGray}>None</Regular>
          )}
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            {!form.licensegroup_default && (
              <ToolTip
                enabled
                orderPreference="bottom"
                toolTipContent={this.renderTooltipContent('Delete')}>
                <ActionButton
                  cursor="pointer"
                  onClick={this.onDelete}
                  role="button"
                  data-cy="delete-button">
                  <Trash width="24" height="24" />
                </ActionButton>
              </ToolTip>
            )}
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Edit')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onEdit}
                role="button"
                data-cy="edit-button">
                <Edit width="24" height="24" />
              </ActionButton>
            </ToolTip>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
