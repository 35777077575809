import {
  FETCH_PHONE_MENUS,
  FETCH_PHONE_MENUS_SUCCESS,
  FETCH_PHONE_MENUS_FAIL,
  PHONE_MENU_ADDED,
  PHONE_MENU_REMOVED,
  PHONE_MENU_UPDATED,
  CREATE_PHONE_MENU,
  CREATE_PHONE_MENU_FAIL,
} from './actionTypes'

import {
  fetchPhoneMenusSuccess,
  phoneMenuAdded,
  phoneMenuRemoved,
  phoneMenuUpdated,
} from './actionCreators'

import { IPhoneMenu, INode } from 'truly-ts'
import { createReducer } from 'truly-utils'
import unionBy from 'lodash/unionBy'
import { mapifyList, iterableToArray } from '../../utils/Redux'
import { FETCH_ENTITIES } from '../entities/actionTypes'
import values from 'lodash/values'
import {
  PHONE_NUMBER_UNASSIGNED,
  PHONE_NUMBER_ASSIGNED,
} from '../phoneNumbers/actionTypes'
import {
  phoneNumberUnassigned,
  phoneNumberAssigned,
} from '../phoneNumbers/actionCreators'
import { replaceNode } from '../../utils/model-utils/node-utils'

export interface PhoneMenusState {
  loading: boolean
  phoneMenuMap?: { [k: number]: IPhoneMenu }
  nodeMap?: { [k: number]: INode }
  phoneMenuLoading: boolean
}

const INITIAL_STATE: PhoneMenusState = {
  loading: false,
  phoneMenuLoading: false,
}

const phoneMenus = createReducer<PhoneMenusState>(INITIAL_STATE, {
  [FETCH_ENTITIES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_PHONE_MENUS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_PHONE_MENUS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchPhoneMenusSuccess>,
  ) => {
    const nodes = selectAllNodes(action.payload.phoneMenus)
    return {
      ...state,
      nodeMap: mapifyList(nodes),
      phoneMenuMap: mapifyList(action.payload.phoneMenus),
      loading: false,
    }
  },
  [FETCH_PHONE_MENUS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [PHONE_MENU_ADDED]: (state, action: ReturnType<typeof phoneMenuAdded>) => {
    if (!action.payload.phoneMenu.id) {
      throw new Error('PHONE_MENU_ADDED with no id')
    }

    const newPhoneMenus = {
      ...state.phoneMenuMap,
      [action.payload.phoneMenu.id]: action.payload.phoneMenu,
    }
    const nodes = selectAllNodes(values(newPhoneMenus))
    return {
      ...state,
      phoneMenuMap: newPhoneMenus,
      nodeMap: mapifyList(nodes),
      phoneMenuLoading: false,
    }
  },
  [PHONE_MENU_REMOVED]: (
    state,
    action: ReturnType<typeof phoneMenuRemoved>,
  ) => {
    const idToRemove = action.payload.phoneMenu.id
    if (!idToRemove) {
      throw new Error('PHONE_MENU_REMOVED with no id')
    }
    const newPhoneMenus = {
      ...state.phoneMenuMap,
    }
    delete newPhoneMenus[idToRemove]
    const nodes = selectAllNodes(values(newPhoneMenus))
    return {
      ...state,
      phoneMenuMap: newPhoneMenus,
      nodeMap: mapifyList(nodes),
    }
  },
  [PHONE_MENU_UPDATED]: (
    state,
    action: ReturnType<typeof phoneMenuUpdated>,
  ) => {
    if (!action.payload.phoneMenu.id) {
      throw new Error('PHONE_MENU_UPDATED with no id')
    }
    const newPhoneMenus = {
      ...state.phoneMenuMap,
      [action.payload.phoneMenu.id]: action.payload.phoneMenu,
    }
    const nodes = selectAllNodes(values(newPhoneMenus))
    return {
      ...state,
      phoneMenuMap: newPhoneMenus,
      nodeMap: mapifyList(nodes),
    }
  },
  [CREATE_PHONE_MENU]: state => {
    return {
      ...state,
      phoneMenuLoading: true,
    }
  },
  [CREATE_PHONE_MENU_FAIL]: state => {
    return {
      ...state,
      phoneMenuLoading: false,
    }
  },
  [PHONE_NUMBER_UNASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberUnassigned>,
  ) => {
    const { entityType, entityId, e164Number } = action.payload
    if (entityType === 'phonemenu' && state.phoneMenuMap) {
      const phoneMenu = state.phoneMenuMap[entityId]
      return {
        ...state,
        phoneMenuMap: {
          ...state.phoneMenuMap,
          [entityId]: {
            ...phoneMenu,
            phonenumbers: (phoneMenu.phonenumbers || []).filter(
              number => number.full_number !== e164Number,
            ),
          },
        },
      }
    }
    if (entityType === 'node' && state.nodeMap && state.phoneMenuMap) {
      const node = state.nodeMap[entityId]
      const newNode: INode = {
        ...node,
        phonenumbers: (node.phonenumbers || []).filter(
          number => number.full_number !== e164Number,
        ),
      }

      if (!node.phonemenu_id) {
        throw new Error(
          'trying to reduce PHONE_NUMBER_UNASSIGNED on node without phonemenu_id',
        )
      }

      const phoneMenu = state.phoneMenuMap[node.phonemenu_id]
      return {
        ...state,
        nodeMap: {
          ...state.nodeMap,
          [entityId]: newNode,
        },
        phoneMenuMap: {
          ...state.phoneMenuMap,
          [node.phonemenu_id]: {
            ...phoneMenu,
            tree: replaceNode(phoneMenu.tree, newNode),
          },
        },
      }
    }
    return state
  },
  [PHONE_NUMBER_ASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberAssigned>,
  ) => {
    const { entityType, entityId, phoneNumber } = action.payload
    if (entityType === 'phonemenu' && state.phoneMenuMap) {
      const phoneMenu = state.phoneMenuMap[entityId]
      return {
        ...state,
        phoneMenuMap: {
          ...state.phoneMenuMap,
          [entityId]: {
            ...phoneMenu,
            phonenumbers: [...(phoneMenu.phonenumbers || []), phoneNumber],
          },
        },
      }
    }
    if (entityType === 'node' && state.nodeMap && state.phoneMenuMap) {
      const node = state.nodeMap[entityId]
      const newNode: INode = {
        ...node,
        phonenumbers: [...(node.phonenumbers || []), phoneNumber],
      }

      if (!node.phonemenu_id) {
        throw new Error(
          'trying to reduce PHONE_NUMBER_ASSIGNED on node without phonemenu_id',
        )
      }

      const phoneMenu = state.phoneMenuMap[node.phonemenu_id]
      return {
        ...state,
        nodeMap: {
          ...state.nodeMap,
          [entityId]: newNode,
        },
        phoneMenuMap: {
          ...state.phoneMenuMap,
          [node.phonemenu_id]: {
            ...phoneMenu,
            tree: replaceNode(phoneMenu.tree, newNode),
          },
        },
      }
    }
    return state
  },
})

function selectAllNodes(pms: IPhoneMenu[]): INode[] {
  const partitionedNodes = pms.map(n => selectAllNodesFromPhoneMenu(n))
  // workaround: https://github.com/Microsoft/TypeScript/issues/4130
  return (unionBy as any).call(unionBy, ...partitionedNodes, (n: INode) => n.id)
}

function selectAllNodesFromPhoneMenu(pm: IPhoneMenu): INode[] {
  return iterableToArray(getNodesRecursive(pm.tree))
}

function* getNodesRecursive(node: INode): IterableIterator<INode> {
  yield node
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      yield* getNodesRecursive(node.children[i])
    }
  }
}

export default phoneMenus
