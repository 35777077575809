import {
  FETCH_EXTENSIONS,
  FETCH_EXTENSIONS_SUCCESS,
  FETCH_EXTENSIONS_FAIL,
} from './actionTypes'

import { fetchExtensionsSuccess } from './actionCreators'

import { IExtension } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { mapifyList } from '../../utils/Redux'
import { FETCH_ENTITIES } from '../entities/actionTypes'

export interface ExtensionsState {
  loading: boolean
  extensions?: IExtension[]
  extensionMap?: { [k: number]: IExtension }
}

const INITIAL_STATE: ExtensionsState = {
  loading: false,
}

const extensions = createReducer<ExtensionsState>(INITIAL_STATE, {
  [FETCH_ENTITIES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_EXTENSIONS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_EXTENSIONS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchExtensionsSuccess>,
  ) => ({
    ...state,
    extensions: action.payload.extensions,
    extensionMap: mapifyList(action.payload.extensions),
    loading: false,
  }),
  [FETCH_EXTENSIONS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
})

export default extensions
