import React, { useState, useEffect } from 'react'
import {
  SidePanelModal,
  ModalLayout,
  TextInput,
  Regular,
  Small,
  Checkbox,
  FlexColumn,
  FlexContainer,
  spaceSizes,
  colors,
} from 'js-components'
import { nameValidationHandler } from '../../utils/Validation'
import { IWebhook } from 'truly-ts'
import {
  CALL_EVENTS,
  SMS_EVENTS,
  DISPLAY_SMS_EVENTS,
} from '../../constants/Webhooks'
import {
  createWebhook,
  updateWebhook,
} from '../../reducers/webhooks/actionCreators'
import {
  useValidatedState,
  ValidationHandler,
  useActionCreator,
} from 'truly-utils'

interface WebhookModalProps {
  show: boolean
  onClose: () => void
  webhook?: IWebhook
  urlValidationHandler: ValidationHandler
}

const inputLabelWidth = '125px'
const nameMaxLength = 64

export const WebhookModal: React.FC<WebhookModalProps> = props => {
  const { show, onClose, webhook, urlValidationHandler } = props

  const [webhookHeader, setWebhookHeader] = useState('New Webhook')
  const [name, nameValidationState, setNameAndValidate] = useValidatedState(
    '',
    nameValidationHandler,
  )
  const [url, urlValidationState, setUrlAndValidate] = useValidatedState(
    '',
    urlValidationHandler,
  )
  const [canValidate, setCanValidate] = useState(false)
  const [callEvents, setCallEvents] = useState<string[]>([])
  const [smsEvents, setSmsEvents] = useState<string[]>([])

  const onCreate = useActionCreator(createWebhook)
  const onUpdate = useActionCreator(updateWebhook)

  useEffect(() => {
    if (webhook && webhook.uuid) {
      setCanValidate(true)
      setNameAndValidate(true, webhook.name)
      setUrlAndValidate(true, webhook.url)
      setCallEvents(webhook.call_events)
      setSmsEvents(webhook.sms_events)
      setWebhookHeader('Edit Webhook')
    } else {
      setCanValidate(false)
      setNameAndValidate(false, '')
      setUrlAndValidate(false, '')
      setCallEvents([])
      setSmsEvents([])
      setWebhookHeader('New Webhook')
    }
  }, [webhook, setNameAndValidate, setUrlAndValidate])

  const onNameChanged = (updatedName: string) =>
    setNameAndValidate(canValidate, updatedName)
  const onUrlChanged = (updatedUrl: string) =>
    setUrlAndValidate(canValidate, updatedUrl)

  const updateCallEvents = (callEvent: string) => {
    const updatedCallEvents = [...callEvents]
    const callEventIndex = updatedCallEvents.indexOf(callEvent)

    if (callEventIndex >= 0) {
      updatedCallEvents.splice(callEventIndex, 1)
    } else {
      updatedCallEvents.push(callEvent)
    }

    setCallEvents(updatedCallEvents)
  }

  const updateSmsEvents = (smsEvent: string) => {
    const updatedSmsEvents = [...smsEvents]
    const smsEventIndex = updatedSmsEvents.indexOf(smsEvent)

    if (smsEventIndex >= 0) {
      updatedSmsEvents.splice(smsEventIndex, 1)
    } else {
      updatedSmsEvents.push(smsEvent)
    }

    setSmsEvents(updatedSmsEvents)
  }

  const setCallStarts = () => updateCallEvents(CALL_EVENTS.CALL_START)

  const setCallIsAnswered = () => updateCallEvents(CALL_EVENTS.CALL_ANSWERED)

  const setCallEnds = () => updateCallEvents(CALL_EVENTS.CALL_ENDED)

  const setRecordingIsReady = () =>
    updateCallEvents(CALL_EVENTS.RECORDING_READY)

  const setVoicemailIsLeft = () => updateCallEvents(CALL_EVENTS.VOICEMAIL)

  const setSmsPending = () => updateSmsEvents(SMS_EVENTS.PENDING)

  const setSmsDelivered = () => updateSmsEvents(SMS_EVENTS.DELIVERED)

  const setSmsFailed = () => updateSmsEvents(SMS_EVENTS.FAILED)

  const setSmsReceived = () => updateSmsEvents(SMS_EVENTS.RECEIVED)

  const createOrUpdateWebhook = () => {
    const nameValid = setNameAndValidate(true, name)
    const urlValid = setUrlAndValidate(true, url)
    setCanValidate(true)

    if (nameValid?.valid && urlValid?.valid) {
      const updatedWebhook: IWebhook = {
        name,
        url,
        call_events: callEvents,
        sms_events: smsEvents,
      }

      if (webhook && webhook.uuid) {
        onUpdate(webhook.uuid, updatedWebhook)
      } else {
        setCanValidate(false)
        setNameAndValidate(false, '')
        setUrlAndValidate(false, '')
        setCallEvents([])
        setSmsEvents([])
        onCreate(updatedWebhook)
      }

      setCanValidate(false)
    }
  }

  return (
    <SidePanelModal visible={show} width="550px" onRequestClose={onClose}>
      <ModalLayout.Container>
        <ModalLayout.Header title={webhookHeader} addBottomBorder />
        <ModalLayout.Content fullHeight>
          <ModalLayout.Spacer />
          <ModalLayout.FieldRow
            label="Name"
            labelWidth={inputLabelWidth}
            shouldPadRight
            validationState={nameValidationState}>
            <TextInput
              data-cy="webhook-name"
              value={name}
              autoFocus
              placeholder="Add a Name"
              onChange={onNameChanged}
              validationState={nameValidationState}
              maxLength={nameMaxLength}
            />
          </ModalLayout.FieldRow>
          <ModalLayout.FieldRow
            helpText="Your URL registered to receive events generated by phone calls on the Truly platform."
            label="URL"
            labelWidth={inputLabelWidth}
            shouldPadRight
            validationState={urlValidationState}>
            <TextInput
              data-cy="webhook-url"
              value={url}
              placeholder="Add a URL"
              onChange={onUrlChanged}
              validationState={urlValidationState}
            />
          </ModalLayout.FieldRow>
          <ModalLayout.HorizontalLine />

          <FlexColumn
            mb={`${spaceSizes.lg}px`}
            marginLeft={`${spaceSizes.lg}px`}
            marginRight="210px">
            <Regular
              color={colors.accentPurple}
              marginTop={`${spaceSizes.sm}px`}
              marginBottom={`${spaceSizes.sm}px`}
              bold>
              Events
            </Regular>
            <Regular
              marginBottom={`${spaceSizes.lg}px`}
              color={colors.ultraDarkGray}>
              The following events will post to the URL provided above when they
              occur in Truly.
            </Regular>
            <Small
              marginBottom={`${spaceSizes.md}px`}
              color={colors.darkGray}
              bold>
              Call Events
            </Small>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label="Call Starts"
                checked={callEvents.includes(CALL_EVENTS.CALL_START)}
                checkChanged={setCallStarts}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label="Call Is Answered"
                checked={callEvents.includes(CALL_EVENTS.CALL_ANSWERED)}
                checkChanged={setCallIsAnswered}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label="Call Ends"
                checked={callEvents.includes(CALL_EVENTS.CALL_ENDED)}
                checkChanged={setCallEnds}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label="Recording Is Ready"
                checked={callEvents.includes(CALL_EVENTS.RECORDING_READY)}
                checkChanged={setRecordingIsReady}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.lg}px`}>
              <Checkbox
                label="Voicemail Is Left"
                checked={callEvents.includes(CALL_EVENTS.VOICEMAIL)}
                checkChanged={setVoicemailIsLeft}
              />
            </FlexContainer>
            <Small
              marginBottom={`${spaceSizes.md}px`}
              color={colors.darkGray}
              bold>
              SMS Events
            </Small>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label={DISPLAY_SMS_EVENTS[SMS_EVENTS.PENDING]}
                checked={smsEvents.includes(SMS_EVENTS.PENDING)}
                checkChanged={setSmsPending}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label={DISPLAY_SMS_EVENTS[SMS_EVENTS.DELIVERED]}
                checked={smsEvents.includes(SMS_EVENTS.DELIVERED)}
                checkChanged={setSmsDelivered}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label={DISPLAY_SMS_EVENTS[SMS_EVENTS.FAILED]}
                checked={smsEvents.includes(SMS_EVENTS.FAILED)}
                checkChanged={setSmsFailed}
              />
            </FlexContainer>
            <FlexContainer marginBottom={`${spaceSizes.md}px`}>
              <Checkbox
                label={DISPLAY_SMS_EVENTS[SMS_EVENTS.RECEIVED]}
                checked={smsEvents.includes(SMS_EVENTS.RECEIVED)}
                checkChanged={setSmsReceived}
              />
            </FlexContainer>
          </FlexColumn>
        </ModalLayout.Content>
        <ModalLayout.Footer
          actionText="Save"
          onCancel={onClose}
          onAction={createOrUpdateWebhook}
          actionDisabled={
            !name || !url || (!callEvents.length && !smsEvents.length)
          }
        />
      </ModalLayout.Container>
    </SidePanelModal>
  )
}

export default React.memo(WebhookModal)
