import {
  INode,
  IMessage,
  IHuntGroup,
  IForwardingNode,
  NodeType,
  MessageType,
} from 'truly-ts'
import { getRandomInt } from '../Math'
import omit from 'lodash/omit'
import { createRuleWithParam } from './node-rules-utils'
// NOTICE - do not include node-validation.tsx or we will have circular reference

const DefaultForwardingCallProps: Partial<INode> = {
  queue_announce_hold_time: false,
  queue_weight: 0,
  wrapup_time: 11,
  phonenumbers: [],
}

export function getMessageFromNode(
  node: INode,
  type: MessageType,
): IMessage | null {
  if (!node.messages) return null
  return node.messages.find(m => m.type === type) || null
}

export const DefaultQueueRingCycles = 1
export const DefaultQueueTimeout = 25
export const DefaultQueueRetry = 5
export const DefaultQueueMaxTime = 1

export function createDefaultHuntGroup(ringOrder: number): IHuntGroup {
  return {
    queue_join_empty: false,
    queue_leave_empty: false,
    queue_max_time: null,
    queue_retry: DefaultQueueRetry,
    queue_ring_cycles: DefaultQueueRingCycles,
    queue_strategy: 'ring_all',
    queue_timeout: DefaultQueueTimeout,
    ring_order: ringOrder,
  }
}

export function messageToNodeMessage(
  message: IMessage,
): IMessage & { message_id?: number } {
  const result: any = { ...message }
  result.message_id = message.id
  delete result.id
  delete result.recording
  delete result.recording_id
  return result
}

export function changeNode(node: INode, type: NodeType): INode {
  //TODO something is wrong with the types below requiring the unknown
  switch (type) {
    case NodeType.ForwardCall:
      return {
        ...node,
        ...DefaultForwardingCallProps,
        type,
        extensions: [],
        roles: [],
        hunt_groups: [createDefaultHuntGroup(0)],
      } as IForwardingNode
    case NodeType.PlayRecording:
    case NodeType.SendMessage:
      return ({
        ...node,
        type,
        extensions: [],
        roles: [],
        hunt_groups: [],
        queue_announce_hold_time: null,
        queue_weight: null,
        wrapup_time: null,
      } as unknown) as IForwardingNode
    case NodeType.Menu:
      return (omit(
        {
          ...node,
          children: node.children || [],
          type,
          queue_announce_hold_time: null,
          queue_weight: null,
          wrapup_time: null,
        },
        ['hunt_groups'],
      ) as unknown) as INode
    default:
      return (omit(
        {
          ...node,
          type,
          queue_announce_hold_time: null,
          queue_weight: null,
          wrapup_time: null,
        },
        ['hunt_groups'],
      ) as unknown) as INode
  }
}

export function createDefaultAdvancedNode(
  optionNumber: number,
  parentNodeId: number,
  phoneMenuId: number,
): INode {
  return ({
    option_number: optionNumber,
    parent_id: parentNodeId,
    phonemenu_id: phoneMenuId,
    type: NodeType.ForwardCall,
    messages: [],
    queue_strategy: 'ring_all',
    queue_ring_cycles: 1,
    extensions: [],
    roles: [],
    hunt_groups: [createDefaultHuntGroup(0)],
    pre_select_text_to_speech: '',
    notification_rules: [],
    sync_rules: [
      createRuleWithParam(
        'salesforce_sync_enabled',
        'node_answered',
        undefined,
        'sync_enabled',
        true,
      ),
      createRuleWithParam(
        'zendesk_sync_enabled',
        'node_answered',
        undefined,
        'sync_enabled',
        true,
      ),
    ],
    sip_push_context: '',
    queue_announce_frequency: null,
    ...DefaultForwardingCallProps,
  } as IForwardingNode) as INode
}

export function getRandomNegativeId(): number {
  return -getRandomInt(999999999)
}

export function markNodeDirty(node: INode) {
  return {
    ...node,
    dirty: true,
  } as INode
}

export function findDirtyNodes(node: INode): INode[] {
  const result: INode[] = []
  findDirtyNodesRecursive(result, node)
  return result
}

function findDirtyNodesRecursive(
  result: INode[],
  node: INode & { dirty?: boolean },
) {
  if (node.dirty) {
    result.push(node)
  }
  if (node.children) {
    node.children.forEach(n => findDirtyNodesRecursive(result, n))
  }
}

export function replaceNode(
  currentNode: INode,
  newNode: INode,
  oldNodeId?: number,
): INode {
  if (oldNodeId && currentNode.id === oldNodeId) return newNode
  if (currentNode.id === newNode.id) return newNode
  return {
    ...currentNode,
    children:
      currentNode.children &&
      currentNode.children.map(node => replaceNode(node, newNode, oldNodeId)),
  }
}

export function replacedNodes(root: INode, newNodes: INode[]): INode {
  let result = root
  for (const node of newNodes) {
    result = replaceNode(result, node)
  }
  return result
}

export function removeNode(
  currentNode: INode,
  removingNode: INode,
): INode | null {
  if (currentNode.id === removingNode.id) return null

  const children = currentNode.children
    ? (currentNode.children
        .map(node => removeNode(node, removingNode))
        .filter(n => n) as INode[]) // ensure truthy
    : undefined

  return {
    ...currentNode,
    children,
  }
}

export function findRemovedNodes(
  oldRootNode: INode,
  newRootNode: INode,
): INode[] {
  const result: INode[] = []
  findRemovedNodesRecursive(result, oldRootNode, newRootNode)
  return result
}

function findRemovedNodesRecursive(
  removedNodes: INode[],
  oldRootNode: INode,
  newRootNode: INode,
) {
  const oldNodes = oldRootNode.children || []
  const newNodes = newRootNode.children || []
  oldNodes.forEach(n => {
    const foundNode = newNodes.find(nn => nn.id === n.id)
    if (foundNode) {
      findRemovedNodesRecursive(removedNodes, n, foundNode)
    } else {
      removedNodes.push(n)
    }
  })
}

// Same as traceNode, but trims off the "from" and "to" node
export function traceNodeExclusive(from: INode, to: INode): INode[] {
  return traceNode(from, to).slice(1).reverse().slice(1).reverse()
}

// Get the trace from the "from" node to the "to" node. Returns [from, ..., to]
export function traceNode(from: INode, to: INode): INode[] {
  const traceRoute: INode[] = []
  if (!traceNodeRecursive(from, to, traceRoute)) {
    throw new Error(`No trace found from node ${from.id} to node ${to.id}`)
  }
  return traceRoute.reverse() // array created backwards, now reverse forwards
}

function traceNodeRecursive(
  current: INode,
  to: INode,
  traced: INode[],
): boolean {
  if (current.id === to.id) {
    traced.push(to)
    return true
  }
  if (current.children) {
    const hasOne = current.children.some(n => traceNodeRecursive(n, to, traced))
    if (hasOne) traced.push(current)
    return hasOne
  }
  return false
}

export function findNodeById(node: INode, nodeId: number): INode | undefined {
  if (node.id === nodeId) return node
  return (
    node.children &&
    node.children.map(n => findNodeById(n, nodeId)).find(n => !!n)
  )
}

export function traverseNode(node: INode, callback: (node: INode) => void) {
  if (!node) return
  callback(node)
  if (node.children)
    node.children.forEach(child => traverseNode(child, callback))
}

export function traverseNodesZipped(
  nodeA: INode,
  nodeB: INode,
  callback: (nodes: { nodeA?: INode; nodeB?: INode }) => void,
  sortFn: (a: INode, b: INode) => number,
) {
  if (nodeA || nodeB) {
    callback({ nodeA, nodeB })
  }
  const childrenA = [...((nodeA && nodeA.children) || [])]
  const childrenB = [...((nodeB && nodeB.children) || [])]
  childrenA.sort(sortFn)
  childrenB.sort(sortFn)
  for (let i = 0; i < Math.max(childrenA.length, childrenB.length); i++) {
    const childA = childrenA && childrenA[i]
    const childB = childrenB && childrenB[i]
    traverseNodesZipped(childA, childB, callback, sortFn)
  }
}

export function zipNodes(
  nodeA: INode,
  nodeB: INode,
): Array<{ nodeA?: INode; nodeB?: INode }> {
  const result: Array<{ nodeA?: INode; nodeB?: INode }> = []
  traverseNodesZipped(
    nodeA,
    nodeB,
    nodes => result.push(nodes),
    (a, b) => (a.option_number ?? 0) - (b.option_number ?? 0),
  )
  return result
}
