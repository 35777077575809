import styled from 'styled-components'
import { FlexRow, colors, SectionItem } from 'js-components'

const CIRCLE_SIZE = '32px'

export const PurpleCircle = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${colors.accentLavender};
  flex: 0 0 auto;
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
`

export const ButtonsRow = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`

export const DialOptionSectionItem = styled(SectionItem)`
  ${ButtonsRow} {
    visibility: hidden;
  }
  &:hover {
    ${ButtonsRow} {
      visibility: visible;
    }
  }
`
