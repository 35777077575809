import React from 'react'
import {
  FlexColumn,
  Regular,
  InlineBold,
  colors,
  spaceSizes,
  FlexRow,
  Edit,
  Small,
  Link,
} from 'js-components'
import { IDataRetentionPolicy } from 'truly-ts'
import styled from 'styled-components/macro'
import { EditButton } from './Style'
import useRouter from '../../utils/custom-hooks/useRouter'
import { dataExpirationToDisplayLongFormat } from './utils'

const StyledBold = styled(InlineBold)`
  margin-right: ${spaceSizes.xs}px;
`

interface DataRetentionValueDisplayProps {
  policy?: IDataRetentionPolicy
  editable: boolean
  organizationPolicy?: IDataRetentionPolicy | null
  onEdit?: () => void
}

export default function DataRetentionValueDisplay({
  onEdit,
  policy,
  organizationPolicy,
  editable,
}: DataRetentionValueDisplayProps) {
  const showHeader = !!organizationPolicy
  const displayOrgSettings = !!organizationPolicy && !policy

  const router = useRouter()
  const goToOrgSettings = () => {
    router.push('/org-settings')
  }

  const renderPolicyDisplay = (activePolicy?: IDataRetentionPolicy) => (
    <>
      <Regular
        color={colors.trulyDark}
        mb={`${spaceSizes.sm}px`}
        mt={`${spaceSizes.sm}px`}>
        <StyledBold>Recordings</StyledBold>{' '}
        {dataExpirationToDisplayLongFormat(activePolicy?.recordings ?? null)}{' '}
      </Regular>
      <Regular color={colors.trulyDark} mb={`${spaceSizes.sm}px`}>
        <StyledBold>Call Data</StyledBold>{' '}
        {dataExpirationToDisplayLongFormat(activePolicy?.phone_calls ?? null)}{' '}
      </Regular>
      <Regular color={colors.trulyDark} mb={`${spaceSizes.sm}px`}>
        <StyledBold>Messages</StyledBold>{' '}
        {dataExpirationToDisplayLongFormat(activePolicy?.sms ?? null)}{' '}
      </Regular>
    </>
  )

  return (
    <FlexRow justifyContent="space-between" data-cy="data-retention-display">
      <FlexColumn
        justifyContent="flex-start"
        alignItems="flex-start"
        ml={`${spaceSizes.sm}px`}>
        {showHeader && (
          <Small
            color={colors.accentLavender}
            bold
            mt={`${spaceSizes.sm}px`}
            mb={`${spaceSizes.xs}px`}>
            {displayOrgSettings
              ? 'Company Default Retention Policy'
              : 'Custom Data Retention Policy'}
          </Small>
        )}
        {displayOrgSettings
          ? renderPolicyDisplay(organizationPolicy ?? undefined)
          : renderPolicyDisplay(policy)}
        {displayOrgSettings && (
          <Regular mt={`${spaceSizes.xs}px`} mb={`${spaceSizes.sm}px`}>
            <Link onClick={goToOrgSettings}>Go To Organization Settings</Link>
          </Regular>
        )}
      </FlexColumn>
      {editable && (
        <EditButton onClick={onEdit} data-cy="edit-retention-button">
          <Edit width="24px" height="24px" />
        </EditButton>
      )}
    </FlexRow>
  )
}
