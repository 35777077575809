import {
  FETCH_EXTENSIONS,
  FETCH_EXTENSIONS_SUCCESS,
  FETCH_EXTENSIONS_FAIL,
} from './actionTypes'

import { IExtension } from 'truly-ts'

export const fetchExtensions = () => ({
  type: FETCH_EXTENSIONS,
  payload: {},
})

export const fetchExtensionsSuccess = (extensions: IExtension[]) => ({
  type: FETCH_EXTENSIONS_SUCCESS,
  payload: {
    extensions,
  },
})

export const fetchExtensionsFail = () => ({
  type: FETCH_EXTENSIONS_FAIL,
  payload: {},
})
