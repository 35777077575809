import * as React from 'react'
import {
  INode,
  IExtension,
  IRole,
  IForwardingNode,
  MessageType,
} from 'truly-ts'
import { ModalLayout } from 'js-components'
import { CheckItem } from '../Styles'
import MessageEditRow from '../MessageEditRow'
import UsersTeamsEditRowContainer from '../../../containers/UsersTeamsEditRowContainer'
import {
  NodeValidationContext,
  NodeValidationContextValue,
  SendToVoicemailValidationState,
} from '../../../utils/model-utils/node-validation'
import { ValidationHandler } from 'truly-utils'

const usersTeamsValidator = new ValidationHandler<any[]>(
  'Please add a user or role',
  values => values.length > 0,
)

interface SendToVoicemailEditProps {
  node: IForwardingNode
  onChange: (node: INode) => void
  extensions: IExtension[]
  teams: IRole[]
}

export default class SendToVoicemailEdit extends React.Component<SendToVoicemailEditProps> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<SendToVoicemailValidationState> // docs say to do this

  componentDidMount() {
    this.validateNode(this.props.node)
  }

  validateNode(node: IForwardingNode) {
    this.context.validationChange({
      userTeams: usersTeamsValidator.validate([
        ...node.extensions,
        ...node.roles,
      ]),
    })
  }

  onChange = (node: IForwardingNode) => {
    this.props.onChange(node)
    this.validateNode(node)
  }

  render() {
    const { node, extensions, teams } = this.props
    const { validationState } = this.context
    const defaultRecordingName =
      node && node.pre_select_text_to_speech
        ? `${node.pre_select_text_to_speech} `
        : ''

    return (
      <>
        <UsersTeamsEditRowContainer
          node={node}
          huntGroup={0}
          onNodeChange={this.onChange}
          extensions={extensions}
          teams={teams}
          validationState={
            validationState.showErrors ? validationState.userTeams : undefined
          }
        />
        <ModalLayout.HorizontalLine />
        <ModalLayout.Row>
          <CheckItem>
            <MessageEditRow
              label="customize voicemail message"
              onNodeChange={this.props.onChange}
              node={node}
              type={MessageType.NodeMessage}
              validationKey="customizeVoicemail"
              validationMessage="Please choose a voicemail"
              newRecordingDefaultName={`${defaultRecordingName}Voicemail`}
            />
          </CheckItem>
        </ModalLayout.Row>
      </>
    )
  }
}
