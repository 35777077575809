import styled from 'styled-components'
import { colors, FlexColumn } from 'js-components'

export const HeaderHeight = '46px'

export const Container = styled.div`
  width: 100%;
  height: ${HeaderHeight};

  background-color: ${colors.trulyDark};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const UserInfoContainer = styled(FlexColumn)`
  flex-wrap: nowrap;
  align-items: flex-end;
  cursor: pointer;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
`

export const LogoWrapper = styled.div`
  margin-left: 16px;
  margin-right: 4px;
  margin-top: 3px;
`

export const CaretWrapper = styled.div`
  margin-right: 12px;
  margin-left: 6px;
`
