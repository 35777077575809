import * as React from 'react'
import { IFormElement, ValidationState } from 'truly-ts'
import {
  Regular,
  colors,
  Small,
  TableRow,
  TableData,
  ToolTip,
  Trash,
  Edit,
  TableDragHandle,
  TableHeaderRow,
  TableHeaderData,
  Table,
  CircleAdd,
} from 'js-components'
import uniq from 'lodash/uniq'
import { ReactComponent as Draggable } from '../../common/icons/draggable.svg'
import {
  LeftAlignedTableData,
  ActionButton,
  RightAlignedTableData,
} from '../LayoutHelpers/Styles'
import { inputTypeToDisplay } from '../../utils/Forms'
import { AddFieldButtonWrapper } from './Styles'

interface FormFieldTableProps {
  elementOrderChanged: (oldIndex: number, newIndex: number) => void
  onEditFormField: (el: IFormElement) => void
  onDeleteFormField: (el: IFormElement) => void
  onAddFormField: () => void
  validationState: ValidationState | null
  formElements?: IFormElement[]
}

export default class FormFieldTable extends React.Component<FormFieldTableProps> {
  render() {
    const {
      elementOrderChanged,
      formElements,
      onEditFormField,
      onDeleteFormField,
      onAddFormField,
      validationState,
    } = this.props

    return (
      <Table
        validationState={validationState}
        header={<FormFieldTableHeaderRow />}
        sortable
        orderChanged={elementOrderChanged}>
        {formElements &&
          formElements.map((el, idx) => (
            <FormFieldTableRow
              index={el.position}
              element={el}
              key={idx}
              onEdit={onEditFormField}
              onDeleteElement={onDeleteFormField}
            />
          ))}
        <TableRow verticalAlign="middle" height={75} style={{ padding: 0 }}>
          <TableData>
            <AddFieldButtonWrapper role="button" onClick={onAddFormField}>
              <CircleAdd width="18px" height="18px" />
              <Regular ml="12px" color={colors.alertGreen}>
                Add New Form Field
              </Regular>
            </AddFieldButtonWrapper>
          </TableData>
        </TableRow>
      </Table>
    )
  }
}

class FormFieldTableHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData text="Name" />
        <TableHeaderData text="API Key" />
        <TableHeaderData text="Type" />
        <TableHeaderData text="" />
      </TableHeaderRow>
    )
  }
}

interface FormFieldTableRowProps {
  element: IFormElement
  index: number
  onDeleteElement: (element: IFormElement) => void
  onEdit: (element: IFormElement) => void
}

class FormFieldTableRow extends React.PureComponent<FormFieldTableRowProps> {
  getElementSyncId() {
    const extFields = this.props.element.external_fields

    if (extFields && extFields.length > 0) {
      const display = uniq(extFields.map(f => f.field_name)).join(', ')
      return (
        <Regular truncate mr="20px" color={colors.trulyDark}>
          {display}
        </Regular>
      )
    }

    return <Regular color={colors.darkGray}>None</Regular>
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  onDelete = () => {
    this.props.onDeleteElement(this.props.element)
  }

  onEdit = () => {
    this.props.onEdit(this.props.element)
  }

  render() {
    const { element, index, onEdit, onDeleteElement, ...restProps } = this.props
    return (
      <TableRow
        height={75}
        sortIndex={index}
        verticalAlign="middle"
        {...restProps}>
        <TableData hideOverflow>
          <Regular truncate mr="20px" color={colors.trulyDark} bold>
            {element.label}
            {element.required && '*'}
          </Regular>
        </TableData>
        <TableData hideOverflow>{this.getElementSyncId()}</TableData>
        <TableData>
          <LeftAlignedTableData>
            <Regular color={colors.trulyDark}>
              {inputTypeToDisplay(element.input_type)}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Delete')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onDelete}
                role="button"
                data-cy="delete-button">
                <Trash width="24" height="24" />
              </ActionButton>
            </ToolTip>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Edit')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onEdit}
                role="button"
                data-cy="edit-button">
                <Edit width="24" height="24" />
              </ActionButton>
            </ToolTip>
            <TableDragHandle>
              <ActionButton cursor="move">
                <Draggable width="24" height="24" />
              </ActionButton>
            </TableDragHandle>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
