import * as React from 'react'
import { ModalLayout, InlineMultiSelect, colors } from 'js-components'
import { IForwardingNode, IExtension, IRole, ValidationState } from 'truly-ts'
import EntityRow from '../EntityRow/EntityRow'
import sortBy from 'lodash/sortBy'
import isUndefined from 'lodash/isUndefined'
import { mapIRoleToINodeRole } from 'truly-utils'

type UserRoleOption = {
  id: number
  name: string
  type: 'user' | 'role'
}

interface UsersTeamsEditRowProps {
  node: IForwardingNode
  huntGroup: number
  onNodeChange: (node: IForwardingNode) => void
  extensions: IExtension[]
  teams: IRole[]
  validationState?: ValidationState
  navigate: (path: string) => void
}

interface UsersTeamsEditRowState {
  selectionOptions: UserRoleOption[]
}

export default class UsersTeamsEditRow extends React.Component<
  UsersTeamsEditRowProps,
  UsersTeamsEditRowState
> {
  constructor(props: UsersTeamsEditRowProps) {
    super(props)

    this.state = this.initialState()
  }

  componentDidUpdate(prevProps: UsersTeamsEditRowProps) {
    if (
      prevProps.extensions !== this.props.extensions ||
      prevProps.teams !== this.props.teams
    ) {
      this.setState(this.initialState())
    }
  }

  initialState(): UsersTeamsEditRowState {
    return {
      selectionOptions: sortBy(
        [
          ...this.props.extensions.map(
            extension =>
              ({
                id: extension.id,
                name: extension.name,
                type: 'user',
              } as UserRoleOption),
          ),
          ...this.props.teams.map(
            team =>
              ({
                id: team.id,
                name: team.display_name,
                type: 'role',
              } as UserRoleOption),
          ),
        ],
        (o: UserRoleOption) => o.name,
      ),
    }
  }

  getNodeSelectedOptions = (
    huntGroup: number,
    node: IForwardingNode,
    options: UserRoleOption[],
  ) => {
    return options.filter(o => {
      if (o.type === 'user') {
        return node.extensions.some(
          e => e.hunt_group === huntGroup && e.id === o.id,
        )
      }
      return node.roles.some(r => r.hunt_group === huntGroup && r.id === o.id)
    })
  }

  userRoleOptionNameMapper = (option: UserRoleOption) => option.name

  renderUserRoleOption = (option: UserRoleOption) => {
    return (
      <EntityRow
        title={option.name}
        subtitle={option.type === 'user' ? 'Extension' : 'Team'}
        avatarText={option.name.split(' ')[0]}
        avatarSeed={`${option.id}-${option.name}`}
        width="300px"
      />
    )
  }

  getSelectedEntitiesForHuntGroup<T extends { id?: number }>(
    entities: T[],
    huntGroup: number,
    options: UserRoleOption[],
    type: 'user' | 'role',
  ) {
    return entities
      .filter(e => options.some(o => o.type === type && o.id === e.id))
      .map(e => ({
        ...e,
        hunt_group: huntGroup,
      }))
  }

  getEntitiesExcept<T extends { hunt_group: number }>(
    entities: T[],
    huntGroup: number,
  ): T[] {
    return entities.filter(e => e.hunt_group !== huntGroup)
  }

  onUserRoleChanged = (huntGroup: number) => (options: UserRoleOption[]) => {
    const { node, extensions, teams } = this.props
    this.props.onNodeChange({
      ...node,
      extensions: [
        ...this.getSelectedEntitiesForHuntGroup(
          extensions,
          huntGroup,
          options,
          'user',
        ),
        ...this.getEntitiesExcept(node.extensions, huntGroup),
      ],
      roles: [
        ...this.getSelectedEntitiesForHuntGroup(
          teams,
          huntGroup,
          options,
          'role',
        ),
        ...this.getEntitiesExcept(node.roles, huntGroup),
      ].map(r =>
        mapIRoleToINodeRole(
          r as IRole,
          isUndefined(r.hunt_group) ? huntGroup : r.hunt_group,
        ),
      ),
    })
  }

  goToUsers = () => {
    this.props.navigate('/users')
  }

  render() {
    const { node, huntGroup, validationState } = this.props
    const { selectionOptions } = this.state

    return (
      <ModalLayout.FieldRow
        label="Users / Teams:"
        labelWidth="150px"
        labelColor={colors.darkGray}
        shouldPadRight
        fieldWidth="100%"
        validationState={validationState}>
        <InlineMultiSelect
          defaultSelectedOptions={this.getNodeSelectedOptions(
            huntGroup,
            node,
            selectionOptions,
          )}
          emptyView={{ entityName: 'Users', onClick: this.goToUsers }}
          options={selectionOptions}
          placeholder="Type a User / Team Name"
          saveOnChange
          onSave={this.onUserRoleChanged(huntGroup)}
          valueDisplayMap={this.userRoleOptionNameMapper}
          renderOption={this.renderUserRoleOption}
          validationState={validationState}
          data-cy={`user-teams-select-${huntGroup}`}
          data-options-cy={`user-teams-select-options-${huntGroup}`}
          alwaysShowBorder
        />
      </ModalLayout.FieldRow>
    )
  }
}
