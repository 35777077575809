export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL'

export const PATCH_ACCOUNT = 'PATCH_ACCOUNT'
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const ACCOUNT_ADDED = 'ACCOUNT_ADDED'

export const UPDATE_ACCOUNT_PERMISSION = 'UPDATE_ACCOUNT_PERMISSION'

export const PATCH_ACCOUNT_ROLE = 'PATCH_ACCOUNT_ROLE'

export const INVITE_USER = 'INVITE_USER'
export const RESEND_INVITE = 'RESEND_INVITE'
