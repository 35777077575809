import {
  FETCH_PHONE_NUMBERS,
  FETCH_PHONE_NUMBERS_SUCCESS,
  FETCH_PHONE_NUMBERS_FAIL,
  PHONE_NUMBER_CHANGED,
  SEARCH_PHONE_NUMBERS,
  SEARCH_PHONE_NUMBERS_SUCCESS,
  SEARCH_PHONE_NUMBERS_FAIL,
  PHONE_NUMBER_REMOVED,
  PHONE_NUMBER_ADDED,
} from './actionTypes'

import {
  fetchPhoneNumbersSuccess,
  fetchPhoneNumbersFail,
  phoneNumberChanged,
  searchPhoneNumbersSuccess,
  phoneNumberRemoved,
  phoneNumberAdded,
} from './actionCreators'

import { IPhoneNumber } from 'truly-ts'
import { createReducer } from 'truly-utils'
import keyBy from 'lodash/keyBy'

export interface PhoneNumbersState {
  loading: boolean
  error: string | null
  phoneNumbers?: { [id: string]: IPhoneNumber }
  search: {
    searching: boolean
    numbers?: string[]
    failed: boolean
  }
}

const INITIAL_STATE: PhoneNumbersState = {
  error: null,
  loading: false,
  search: {
    searching: false,
    failed: false,
  },
}

const phoneNumbers = createReducer<PhoneNumbersState>(INITIAL_STATE, {
  [FETCH_PHONE_NUMBERS]: state => ({
    ...state,
    error: null,
    loading: true,
  }),
  [FETCH_PHONE_NUMBERS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchPhoneNumbersSuccess>,
  ) => ({
    ...state,
    phoneNumbers: keyBy(action.payload.phoneNumbers, 'full_number'),
    error: null,
    loading: false,
  }),
  [FETCH_PHONE_NUMBERS_FAIL]: (
    state,
    action: ReturnType<typeof fetchPhoneNumbersFail>,
  ) => ({
    ...state,
    error: action.payload.error.message,
    loading: false,
  }),
  [PHONE_NUMBER_CHANGED]: (
    state,
    action: ReturnType<typeof phoneNumberChanged>,
  ) => ({
    ...state,
    phoneNumbers: {
      ...state.phoneNumbers,
      [action.payload.phoneNumber.full_number]: action.payload.phoneNumber,
    },
  }),
  [PHONE_NUMBER_REMOVED]: (
    state,
    action: ReturnType<typeof phoneNumberRemoved>,
  ) => {
    const numbers = { ...state.phoneNumbers }
    delete numbers[action.payload.phoneNumber.full_number]
    return {
      ...state,
      phoneNumbers: numbers,
    }
  },
  [PHONE_NUMBER_ADDED]: (
    state,
    action: ReturnType<typeof phoneNumberAdded>,
  ) => ({
    ...state,
    phoneNumbers: {
      ...state.phoneNumbers,
      [action.payload.phoneNumber.full_number]: action.payload.phoneNumber,
    },
  }),
  [SEARCH_PHONE_NUMBERS]: state => ({
    ...state,
    search: {
      ...state.search,
      searching: true,
      failed: false,
    },
  }),
  [SEARCH_PHONE_NUMBERS_SUCCESS]: (
    state,
    action: ReturnType<typeof searchPhoneNumbersSuccess>,
  ) => ({
    ...state,
    search: {
      ...state.search,
      numbers: action.payload.numbers,
      searching: false,
      failed: false,
    },
  }),
  [SEARCH_PHONE_NUMBERS_FAIL]: state => ({
    ...state,
    search: {
      ...state.search,
      searching: false,
      failed: true,
    },
  }),
})

export default phoneNumbers
