import {
  FETCH_DASHBOARDS,
  FETCH_DASHBOARDS_FAIL,
  FETCH_DASHBOARDS_SUCCESS,
} from './actionTypes'

import { fetchDashboardsSuccess } from './actionCreators'

import { IDashboard } from 'truly-ts'
import { createReducer } from 'truly-utils'

export interface ReportsState {
  loading: boolean
  dashboards?: IDashboard[]
}

const INITIAL_STATE: ReportsState = {
  loading: false,
}

const reports = createReducer<ReportsState>(INITIAL_STATE, {
  [FETCH_DASHBOARDS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_DASHBOARDS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchDashboardsSuccess>,
  ) => ({
    ...state,
    loading: false,
    dashboards: action.payload.dashboards,
  }),
  [FETCH_DASHBOARDS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
})

export default reports
