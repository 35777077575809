import styled from 'styled-components'
import { FlexColumn, FlexRow } from 'js-components'

export const IconWrapper = styled(FlexColumn)`
  margin-right: 22px;
  justify-content: center;
`

export const PhoneNumberRowContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
