import {
  findNodeById,
  traceNodeExclusive,
} from '../../utils/model-utils/node-utils'
import { IPhoneMenu, INode } from 'truly-ts'
import { ContentHeadingBacklink } from '../ContentHeading/ContentHeading'

export function getNode(
  menu: IPhoneMenu | undefined,
  nodeId: number | undefined,
) {
  if (menu && nodeId) {
    return findNodeById(menu.tree, nodeId)
  }
  return null
}

export function getBacklinks(
  openSubmenu: (node: INode) => void,
  phoneMenu?: IPhoneMenu,
  editingNode?: INode | null,
) {
  const phoneMenusLink: ContentHeadingBacklink = {
    label: 'Phone Menus',
    url: '/phone-menus',
  }
  if (!phoneMenu || !editingNode) {
    return [phoneMenusLink]
  }
  const root = phoneMenu.tree

  if (editingNode.id === root.id) {
    return [phoneMenusLink]
  }

  const backlinks: ContentHeadingBacklink[] = [
    phoneMenusLink,
    {
      // nav back to phone menu
      label: phoneMenu.name,
      onClick: () => openSubmenu(phoneMenu.tree),
    },
    // add any in-between submenus
    ...traceNodeExclusive(root, editingNode).map(
      n =>
        ({
          label: n.pre_select_text_to_speech,
          onClick: () => openSubmenu(n),
        } as ContentHeadingBacklink),
    ),
  ]
  return backlinks
}
