if (process.env.NODE_ENV === 'development') {
  console.log('CONFIG: ', window.trulyEnv)
}

const shortHash = (window.trulyEnv.commit_hash || '').substring(0, 8)
console.log(`Admin v${window.trulyEnv.version} - #${shortHash}`)

export const apiUrl = window.trulyEnv.api_url
export const legacyUrl = window.trulyEnv.legacy_url
export const authUrl = window.trulyEnv.truly_auth_url

export const redirectUrl = `https://${window.location.host}`

export const stateCacheEnabled = process.env.REACT_APP_CACHE_STATE === 'true'
