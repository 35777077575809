import styled from 'styled-components'
import { FlexRow, FlexColumn, colors, Large } from 'js-components'

const ItemHeight = 75

export const ExistingAudioList = styled(FlexColumn)`
  width: 100%;
  max-height: ${ItemHeight * 3}px;
  overflow-y: auto;
  border-top: 1px solid ${colors.lightGray};
`

export const ExistingAudioWrapper = styled(FlexRow)<{ last: boolean }>`
  align-items: center;
  flex: 0 0 auto;
  height: ${ItemHeight}px;
  padding-left: 16px;
  padding-right: 16px;
  ${props => !props.last && `border-bottom: 1px solid ${colors.lightGray};`}

  [data-hover-show="true"] {
    display: none;
  }

  &:hover {
    background-color: ${colors.ultraLightGray};
    cursor: pointer;
    [data-hover-show='true'] {
      display: flex;
    }
  }
`

export const AudioTitle = styled(Large)`
  margin-left: 12px;
  color: ${colors.trulyDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RecordingRow = styled(FlexRow)`
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  padding-right: 32px;
  padding-left: 12px;
`
export const AudioPlayerRow = styled(FlexRow)`
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  padding-right: 20px;
  padding-left: 12px;
`

export const UploadingContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  padding: 32px;
`
