import { connect } from 'react-redux'
import PhoneNumberEditPanel from '../components/PhoneNumberEditPanel/PhoneNumberEditPanel'
import { IState, Dispatch } from '../store'
import { bindActionCreators } from 'redux'
import { updatePhoneNumber } from '../reducers/phoneNumbers/actionCreators'

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  savePhoneNumber: bindActionCreators(updatePhoneNumber, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneNumberEditPanel)
