import * as React from 'react'
import {
  SidePanelModal,
  ModalLayout,
  TextInput,
  SpinnerMaterial,
  colors,
  ConfirmDialog,
} from 'js-components'
import {
  EntityOption,
  EntityMap,
  entitiesToOptions,
  entityMapChanged,
} from '../../utils/Entity'
import EntitySelect from '../EntitySelect/EntitySelect'
import NumberPurchaserContainer from '../../containers/NumberPurchaserContainer'
import { phoneNumberSelectedValidationHandler } from '../../utils/Validation'
import { IPhoneNumber, ValidationState, EntityType } from 'truly-ts'
import { SaveStatus } from '../../utils/Saving'
import defaultTrulyClient from '../../utils/HTTP'

const InputWidthPX = 350
const LabelWidth = '165px'

interface PhoneNumberPurchasePanelProps {
  show: boolean
  onClose: () => void
  onUpdatePhoneNumber: (
    e164Number: string,
    context: string,
    entityType: EntityType | null,
    entityId: number | null,
  ) => void
  onPhoneNumberAdded: (phoneNumber: IPhoneNumber) => void
  entities: EntityMap
}

interface PhoneNumberPurchasePanelState {
  selectedAssignment: EntityOption | null
  context: string | null
  entityOptions: EntityOption[]
  selectedNumber?: string

  canValidate: boolean
  phoneNumberValidationState: ValidationState | null
  purchaseStatus: SaveStatus
}

export default class PhoneNumberPurchasePanel extends React.Component<
  PhoneNumberPurchasePanelProps,
  PhoneNumberPurchasePanelState
> {
  constructor(props: PhoneNumberPurchasePanelProps) {
    super(props)

    const entityOptions = entitiesToOptions(props.entities)

    this.state = this.initialState(entityOptions)
  }

  initialState(entityOptions: EntityOption[]): PhoneNumberPurchasePanelState {
    return {
      entityOptions,
      selectedAssignment: null,
      context: '',
      canValidate: false,
      phoneNumberValidationState: null,
      purchaseStatus: SaveStatus.Unsaved,
    }
  }

  componentDidUpdate(prevProps: PhoneNumberPurchasePanelProps) {
    let entityOptions = this.state.entityOptions

    if (entityMapChanged(this.props.entities, prevProps.entities)) {
      entityOptions = entitiesToOptions(this.props.entities)
      this.setState({
        entityOptions,
      })
    }

    if (this.props.show && !prevProps.show) {
      const newState = this.initialState(entityOptions)
      this.setState(newState)
    }
  }

  purchaseNumber = () => {
    if (!this.state.selectedNumber) {
      throw new Error('Unable to purchase null number')
    }

    const phoneNumberValidationState = phoneNumberSelectedValidationHandler.validate(
      this.state.selectedNumber,
    )

    this.setState({
      canValidate: true,
      phoneNumberValidationState,
    })

    if (phoneNumberValidationState.valid) {
      this.setState({ purchaseStatus: SaveStatus.Saving })
      defaultTrulyClient.lg
        .purchasePhoneNumber(this.state.selectedNumber)
        .then(resp => resp.data.phone_number)
        .then(phoneNumber => {
          this.setState({ purchaseStatus: SaveStatus.Unsaved })
          this.props.onPhoneNumberAdded(phoneNumber)

          const context = (this.state.context || '').trim()

          // Set the assignment
          if (this.state.selectedAssignment || context) {
            this.props.onUpdatePhoneNumber(
              phoneNumber.full_number,
              context,
              this.state.selectedAssignment
                ? this.state.selectedAssignment.entity_type
                : null,
              this.state.selectedAssignment &&
                this.state.selectedAssignment.entity_id,
            )
          }

          this.props.onClose()
        })
        .catch(err => {
          this.setState({ purchaseStatus: SaveStatus.Failed })
          console.error('Failed to purchase phone number', err)
        })
    }
  }

  updateAssignment = (assignment: EntityOption | null) => {
    this.setState({
      selectedAssignment: assignment,
    })
  }

  onSelectedNumberChanged = (selectedNumber: string) => {
    this.setState({
      selectedNumber,
      phoneNumberValidationState: this.state.canValidate
        ? phoneNumberSelectedValidationHandler.validate(selectedNumber)
        : null,
    })
  }

  contextChanged = (value: string) => this.setState({ context: value })

  resetPurchaseStatus = () =>
    this.setState({ purchaseStatus: SaveStatus.Unsaved })

  render() {
    const { onClose, show } = this.props
    const {
      context,
      selectedAssignment,
      selectedNumber,
      entityOptions,
      phoneNumberValidationState,
      purchaseStatus,
    } = this.state

    return (
      <SidePanelModal width="550px" onRequestClose={onClose} visible={show}>
        <ConfirmDialog
          show={purchaseStatus === SaveStatus.Failed}
          confirmText="Okay"
          title="Request Failed"
          description="Please try again or select a different number."
          color={colors.alertRed}
          onConfirm={this.resetPurchaseStatus}
          onCancel={this.resetPurchaseStatus}
        />
        <ModalLayout.Container>
          <ModalLayout.Header title="New Phone Number" addBottomBorder />
          <ModalLayout.Content>
            <ModalLayout.SubHeader title="Number" />
            <NumberPurchaserContainer
              selectedNumber={selectedNumber}
              onSelectedNumberChanged={this.onSelectedNumberChanged}
              validation={phoneNumberValidationState}
              labelWidth={LabelWidth}
            />
            <ModalLayout.SubHeader title="Details" />
            <ModalLayout.FieldRow
              label="Assignment"
              shouldPadRight
              labelWidth={LabelWidth}>
              <EntitySelect
                entityOptions={entityOptions}
                selectedAssignment={selectedAssignment}
                onChange={this.updateAssignment}
                optionWidth={InputWidthPX}
                richSelectResetId={1}
                data-cy="phone-number-assignment-select"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.FieldRow
              label="Context"
              shouldPadRight
              labelWidth={LabelWidth}
              helpText="Context will override the caller ID displayed for Truly users within your organization when they recieve a call from this number. Additionally, this will be displayed under each phone number in the phone number selector for your reps.">
              <TextInput
                value={context || ''}
                placeholder="None"
                onChange={this.contextChanged}
                data-cy="phone-number-context"
              />
            </ModalLayout.FieldRow>
          </ModalLayout.Content>
          <ModalLayout.Footer
            actionText={
              purchaseStatus === SaveStatus.Saving ? (
                <SpinnerMaterial
                  color={colors.accentPurple}
                  strokeWidth={9}
                  size="20px"
                />
              ) : (
                'Add'
              )
            }
            actionDisabled={purchaseStatus === SaveStatus.Saving}
            actionButtonWidth={140}
            pinToBottom
            onCancel={onClose}
            onAction={this.purchaseNumber}
          />
        </ModalLayout.Container>
      </SidePanelModal>
    )
  }
}
