import * as React from 'react'
import {
  INode,
  IForwardingNode,
  IExtension,
  IRole,
  PhoneMenuType,
} from 'truly-ts'
import { QueueStrategyDisplay } from '../../constants/phone-menu'
import { MediumSectionHeader } from '../LayoutHelpers/Styles'
import {
  SectionGroup,
  SectionItem,
  FlexRow,
  MultiAvatar,
  colors,
  Regular,
  AvatarSizes,
} from 'js-components'
import PhoneMenuNodeEditPanel from '../PhoneMenuNodeEditPanel/PhoneMenuNodeEditPanel'
import { replaceNode, markNodeDirty } from '../../utils/model-utils/node-utils'

interface NodeBasicOptionProps {
  node: IForwardingNode
  phoneMenuId: number
  extensions?: IExtension[]
  teams?: IRole[]
  onNodeChange: (node: INode) => void
}

interface NodeBasicOptionState {
  editing: boolean
}

export default class NodeBasicOption extends React.Component<
  NodeBasicOptionProps,
  NodeBasicOptionState
> {
  constructor(props: NodeBasicOptionProps) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  onEditNode = () => {
    this.setState({
      editing: true,
    })
  }

  onCloseNodeEditor = () => {
    this.setState({
      editing: false,
    })
  }

  onNodeUpdated = (node: INode) => {
    this.props.onNodeChange(replaceNode(this.props.node, markNodeDirty(node)))
  }

  onNodeRemoved = () => {
    throw new Error('Basic node cannot be removed')
  }

  getBasicQueueStrategyName(node: IForwardingNode) {
    const firstStrategy =
      QueueStrategyDisplay[node.hunt_groups[0].queue_strategy]
    if (node.hunt_groups.length > 1) {
      return `${firstStrategy} (+${node.hunt_groups.length - 1})`
    }
    return firstStrategy
  }

  render() {
    const { node } = this.props
    const { editing } = this.state

    return (
      <>
        <PhoneMenuNodeEditPanel
          phoneMenuId={this.props.phoneMenuId}
          node={node}
          extensions={this.props.extensions ?? []}
          teams={this.props.teams ?? []}
          onClose={this.onCloseNodeEditor}
          show={editing}
          type={PhoneMenuType.Basic}
          updateNode={this.onNodeUpdated}
          removeNode={this.onNodeRemoved}
          isNewNode={false}
        />
        <MediumSectionHeader>Call Forwarding</MediumSectionHeader>
        <SectionGroup>
          <SectionItem
            role="button"
            data-cy="edit-forwarding-button"
            onClick={this.onEditNode}>
            <FlexRow justifyContent="flex-start" alignItems="center">
              <MultiAvatar
                bgColor={colors.white}
                size={AvatarSizes.Big}
                avatars={[
                  ...node.extensions.map(extension => ({
                    text: extension.name,
                    colorSeed: extension.name,
                  })),
                  ...node.roles.map(role => ({
                    text: role.name,
                    colorSeed: role.name,
                  })),
                ]}
              />
              <Regular ml="16px" color={colors.trulyDark}>
                {this.getBasicQueueStrategyName(node)}
              </Regular>
            </FlexRow>
          </SectionItem>
        </SectionGroup>
      </>
    )
  }
}
