import { connect } from 'react-redux'
import UnsavedChangesDialog from '../components/UnsavedChangesDialog/UnsavedChangesDialog'
import * as selectors from '../reducers/navigation/navigationSelectors'
import { IState } from '../store'
import {
  hideUnsavedChangesDialog,
  continueNavigation,
} from '../reducers/navigation/actionCreators'

const mapStateToProps = (state: IState) => ({
  show: selectors.unsavedDialogShowing(state),
  title: selectors.unsavedTitle(state) || '',
  description: selectors.unsavedDescription(state) || '',
})

const mapDispatchToProps = {
  back: hideUnsavedChangesDialog,
  goOn: continueNavigation,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsavedChangesDialog)
