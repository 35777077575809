import styled from 'styled-components'
import { FlexRow } from 'js-components'

export const AddFieldButtonWrapper = styled(FlexRow)`
  width: 200px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
`
