import { call, put, all } from 'redux-saga/effects'
import { finishOauth } from './actionCreators'

export function* authSaga(): any {
  yield all([call(autoLogin)])
}
export function* autoLogin() {
  // you cant call getItem as a function reference because dumb
  const token = yield call(key => localStorage.getItem(key), 'token')
  if (token) {
    yield put(finishOauth(token))
  }
}
