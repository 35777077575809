import { connect } from 'react-redux'
import ExternalUserEditModal from '../components/ExternalUserEditModal/ExternalUserEditModal'
import { IState } from '../store'
import {
  createExternalUser,
  updateExternalUser,
} from '../reducers/externalUsers/actionCreators'

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = {
  onAdd: createExternalUser,
  onUpdate: updateExternalUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalUserEditModal)
