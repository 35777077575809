import * as React from 'react'
import { SidePanelModal, ModalLayout, TextInput } from 'js-components'
import { nameValidationHandler } from '../../utils/Validation'
import RecordingEditor from '../RecordingEditor/RecordingEditor'
import {
  ValidationState,
  IMessage,
  INode,
  IPhoneMenu,
  MessageType,
} from 'truly-ts'
import { EntityOption, EntityMap, entitiesToOptions } from '../../utils/Entity'
import EntitySelect from '../EntitySelect/EntitySelect'
import {
  replaceNode,
  findNodeById,
  markNodeDirty,
  messageToNodeMessage,
} from '../../utils/model-utils/node-utils'

interface RecordingModalProps {
  show: boolean
  onClose: () => void
  updateMessage: (id: number, message: IMessage, file: Blob) => void
  deleteMessage: (message: IMessage) => void
  updatePhoneMenu: (phoneMenu: IPhoneMenu) => void
  entities: EntityMap
  phoneMenus: IPhoneMenu[]
  currentMessage?: IMessage
  isEditing?: boolean
}

interface RecordingModalState {
  name: string
  message: IMessage | null
  file: Blob | null
  nameValidationState: ValidationState | null
  canValidate: boolean
  selectedAssignment: EntityOption | null
  entityOptions: EntityOption[]
  recordingHeader: string
  storedRecording?: IMessage
}

const InputWidthPX = 350

export default class RecordingModal extends React.Component<
  RecordingModalProps,
  RecordingModalState
> {
  constructor(props: RecordingModalProps) {
    super(props)
    this.state = this.initialState(entitiesToOptions(props.entities))
  }

  componentDidUpdate(prevProps: RecordingModalProps) {
    if (!prevProps.show && this.props.show) {
      this.setState(this.initialState(entitiesToOptions(this.props.entities)))
    }
  }

  initialState(entityOptions: EntityOption[]): RecordingModalState {
    const { currentMessage } = this.props
    return {
      entityOptions,
      name: currentMessage ? currentMessage.title : '',
      nameValidationState: null,
      canValidate: false,
      message: currentMessage || null,
      file: null,
      selectedAssignment: null,
      recordingHeader: currentMessage ? currentMessage.title : 'New Recording',
      storedRecording: currentMessage,
    }
  }

  onNameChanged = (name: string) => {
    this.setState({
      name,
      nameValidationState: this.state.canValidate
        ? nameValidationHandler.validate(name)
        : null,
    })
  }

  onSetFile = (message: IMessage, file: Blob) => {
    const { currentMessage, isEditing } = this.props

    const updatedMessage =
      isEditing && currentMessage
        ? {
            ...currentMessage,
            recording: message.recording,
            recording_id: message.recording_id,
            id: message.id,
          }
        : message

    this.setState({
      message: updatedMessage,
      file,
    })
  }

  createNewRecording = () => {
    const { name, message, file, selectedAssignment } = this.state
    const {
      // currentMessage,  TODO: Reenable when appropriate patch endpoint is created for message updates
      // deleteMessage,
      // isEditing,
      updateMessage,
      onClose,
      updatePhoneMenu,
      phoneMenus,
    } = this.props

    const updatedMessage = {
      ...message,
      title: name,
    } as IMessage

    // if there is an assignment then update the phone menu tree
    if (selectedAssignment) {
      let newNode: INode
      let updatedMenu: IPhoneMenu | undefined

      if (selectedAssignment.entity_type === 'phonemenu') {
        updatedMenu = phoneMenus.find(
          pm => pm.id === selectedAssignment.entity_id,
        ) as IPhoneMenu

        // edit and save node
        const editingNode = { ...updatedMenu.tree }
        const nodeMessages = editingNode.messages
        const rootNode = markNodeDirty({
          ...editingNode,
          messages: [
            // remove old message
            ...(nodeMessages || []).filter(
              m => m.type !== MessageType.MenuMessage,
            ),
            {
              ...messageToNodeMessage(updatedMessage),
              type: MessageType.MenuMessage,
            },
          ],
        })

        newNode = {
          ...rootNode,
          selected_recording_id: message?.id,
        }
      } else {
        updatedMenu = phoneMenus.find(pm =>
          findNodeById(pm.tree, selectedAssignment.entity_id),
        )

        if (!updatedMenu) {
          throw new Error('updatedMenu not found')
        }

        const childToUpdate = findNodeById(
          updatedMenu.tree,
          selectedAssignment.entity_id,
        ) as INode

        newNode = {
          ...childToUpdate,
          messages: [
            {
              ...messageToNodeMessage(updatedMessage),
              type: MessageType.NodeMessage,
            },
          ],
        }
      }

      const updatedPhoneMenu = {
        ...updatedMenu,
        tree: replaceNode(updatedMenu.tree, newNode),
      }

      updatePhoneMenu(updatedPhoneMenu)
    }

    // TODO: Reenable when appropriate patch endpoint is created for message updates
    // if(isEditing) {
    //   updateMessage(currentMessage.id, updatedMessage);
    //   deleteMessage(message);
    // } else {
    if (!message) {
      throw new Error("can't create recording withot message")
    }

    if (!file) {
      throw new Error("can't create recording withot file")
    }
    updateMessage(message.id, updatedMessage, file)
    // }

    onClose()
  }

  closeMessageCreate = () => {
    const { message } = this.state
    const { deleteMessage, onClose, isEditing, currentMessage } = this.props

    if (
      !isEditing &&
      message &&
      (!currentMessage || message.id !== currentMessage.id)
    ) {
      deleteMessage(message)
    }
    onClose()
  }

  updateAssignment = (assignment: EntityOption | null) => {
    this.setState({
      selectedAssignment: assignment,
    })
  }

  setRecordingHeader = () => {
    this.setState({ recordingHeader: this.state.name || 'New Recording' })
  }

  render() {
    const { show } = this.props
    const {
      name,
      nameValidationState,
      message,
      selectedAssignment,
      entityOptions,
      recordingHeader,
    } = this.state

    return (
      <SidePanelModal
        visible={show}
        width="575px"
        onRequestClose={this.closeMessageCreate}>
        <ModalLayout.Container>
          <ModalLayout.Header title={recordingHeader} addBottomBorder />
          <ModalLayout.Content>
            <ModalLayout.SubHeader title="Basic Information" />
            <ModalLayout.FieldRow
              label="Recording Name"
              labelWidth="202px"
              shouldPadRight
              validationState={nameValidationState}
              data-cy="recording-name-row">
              <TextInput
                value={name}
                autoFocus
                placeholder="Add a Name"
                onChange={this.onNameChanged}
                onBlur={this.setRecordingHeader}
                validationState={nameValidationState}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.SubHeader title="Recording" mb="0" />
            <RecordingEditor
              onAddFileCallback={this.onSetFile}
              newRecordingDefaultName="New Recording"
              hideExistingRecordings
              hideRecordingName
              message={message ?? undefined}
              borderless
              largePlay
              minimal
            />
            <ModalLayout.SubHeader title="Assignment" />
            <ModalLayout.FieldRow
              label="Assignment"
              labelWidth="202px"
              shouldPadRight>
              <EntitySelect
                entityOptions={entityOptions}
                selectedAssignment={selectedAssignment}
                onChange={this.updateAssignment}
                optionWidth={InputWidthPX}
                richSelectResetId={1}
              />
            </ModalLayout.FieldRow>
          </ModalLayout.Content>
          <ModalLayout.Footer
            actionText="Done"
            onAction={this.createNewRecording}
            actionDisabled={!name || !message}
          />
        </ModalLayout.Container>
      </SidePanelModal>
    )
  }
}
