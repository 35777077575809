import {
  FETCH_INVITES,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_FAIL,
} from './actionTypes'

import { fetchInvitesSuccess } from './actionCreators'

import { IInvite } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { mapifyList } from '../../utils/Redux'
import { FETCH_ENTITIES } from '../entities/actionTypes'

export interface InvitesState {
  loading: boolean
  invites?: IInvite[]
  inviteMap?: { [k: number]: IInvite }
}

const INITIAL_STATE: InvitesState = {
  loading: false,
}

const invites = createReducer<InvitesState>(INITIAL_STATE, {
  [FETCH_ENTITIES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_INVITES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_INVITES_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchInvitesSuccess>,
  ) => ({
    ...state,
    invites: action.payload.invites,
    inviteMap: mapifyList(action.payload.invites),
    loading: false,
  }),
  [FETCH_INVITES_FAIL]: state => ({
    ...state,
    loading: false,
  }),
})

export default invites
