import {
  ROLES_FETCHED,
  FETCH_ROLES,
  FETCH_ROLES_FAILED,
  DELETE_ROLE,
  ROLE_REMOVED,
  ROLE_ADDED,
  ROLE_UPDATED,
  UPDATE_ROLE,
  CREATE_ROLE,
} from './actionTypes'
import { IRole } from 'truly-ts'

export const fetchRoles = () => ({
  type: FETCH_ROLES,
  payload: {},
})

export const fetchRolesFailed = () => ({
  type: FETCH_ROLES_FAILED,
  payload: {},
})

export const rolesFetched = (roles: IRole[]) => ({
  type: ROLES_FETCHED,
  payload: {
    roles,
  },
})

export const deleteRole = (id: number, navToTeams: boolean) => ({
  type: DELETE_ROLE,
  payload: {
    id,
    navToTeams,
  },
})

export const updateRole = (role: IRole) => ({
  type: UPDATE_ROLE,
  payload: {
    role,
  },
})

export const createRole = (name: string) => ({
  type: CREATE_ROLE,
  payload: {
    name,
  },
})

export const roleRemoved = (id: number) => ({
  type: ROLE_REMOVED,
  payload: {
    id,
  },
})

export const roleAdded = (role: IRole) => ({
  type: ROLE_ADDED,
  payload: {
    role,
  },
})

export const roleUpdated = (role: IRole) => ({
  type: ROLE_UPDATED,
  payload: {
    role,
  },
})
