import { IUserAccount } from 'truly-ts'
import defaultTrulyClient from '../HTTP'
import { call, put } from 'redux-saga/effects'
import { logout } from '../../reducers/auth/actionCreators'

export function* logoutUserIfNotAdmin() {
  const userReq = yield call(defaultTrulyClient.me.fetchMe)
  const account = userReq.data.account as IUserAccount
  if (!account.permissions?.includes('access_admin')) {
    yield put(logout())
  }
}
