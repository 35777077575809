import { IState } from '../../store'
import values from 'lodash/values'
import { createSelector } from 'reselect'
export const base = (state: IState) => state.externalUsers
export const externalUsers = createSelector(
  base,
  e => e.externalUsers && values(e.externalUsers),
)

export const externalUserMap = createSelector(base, e => e.externalUsers)
