import {
  FETCH_BLOCKED_NUMBERS,
  FETCH_BLOCKED_NUMBERS_SUCCESS,
  FETCH_BLOCKED_NUMBERS_FAIL,
  BLOCKED_NUMBER_REMOVED,
  BLOCKED_NUMBER_ADDED,
  BLOCKED_NUMBER_ADDED_SUCCESS,
  BLOCKED_NUMBER_REMOVED_FAIL,
  BLOCKED_NUMBER_REMOVED_SUCCESS,
} from './actionTypes'

import {
  fetchBlockedNumbersSuccess,
  fetchBlockedNumbersFail,
  addBlockedNumber,
  removeBlockedNumber,
  addBlockedNumberSuccess,
  removeBlockedNumberSuccess,
} from './actionCreators'

import { IBlockedNumber } from 'truly-ts'
import { createReducer } from 'truly-utils'

export interface BlockedNumbersState {
  loading: boolean
  error: string | null
  blockedNumbers: IBlockedNumber[]
  search: {
    searching: boolean
    numbers?: string[]
    failed: boolean
  }
}

const INITIAL_STATE: BlockedNumbersState = {
  error: null,
  loading: true,
  blockedNumbers: [],
  search: {
    searching: false,
    failed: false,
  },
}

const blockedNumbers = createReducer<BlockedNumbersState>(INITIAL_STATE, {
  [FETCH_BLOCKED_NUMBERS]: state => ({
    ...state,
    error: null,
    loading: true,
  }),
  [FETCH_BLOCKED_NUMBERS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchBlockedNumbersSuccess>,
  ) => ({
    ...state,
    blockedNumbers: action.payload.blockedNumbers,
    error: null,
    loading: false,
  }),
  [FETCH_BLOCKED_NUMBERS_FAIL]: (
    state,
    action: ReturnType<typeof fetchBlockedNumbersFail>,
  ) => ({
    ...state,
    error: action.payload.error.message,
    loading: false,
  }),
  [BLOCKED_NUMBER_REMOVED]: (
    state,
    _: ReturnType<typeof removeBlockedNumber>,
  ) => ({
    ...state,
    error: null,
    loading: true,
  }),
  [BLOCKED_NUMBER_REMOVED_SUCCESS]: (
    state,
    action: ReturnType<typeof removeBlockedNumberSuccess>,
  ) => ({
    ...state,
    blockedNumbers: state.blockedNumbers.filter(
      (num: IBlockedNumber) =>
        num.number !== action.payload.blockedNumber.number,
    ),
    error: null,
    loading: false,
  }),
  [BLOCKED_NUMBER_REMOVED_FAIL]: (
    state,
    _: ReturnType<typeof fetchBlockedNumbersFail>,
  ) => ({
    ...state,
    error: null,
    loading: false,
  }),
  [BLOCKED_NUMBER_ADDED]: (state, _: ReturnType<typeof addBlockedNumber>) => ({
    ...state,
    error: null,
    loading: false,
  }),
  [BLOCKED_NUMBER_ADDED_SUCCESS]: (
    state,
    action: ReturnType<typeof addBlockedNumberSuccess>,
  ) => ({
    ...state,
    blockedNumbers: [action.payload.blockedNumber, ...state.blockedNumbers],
  }),
})

export default blockedNumbers
