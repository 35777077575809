import * as React from 'react'
import { colors, FlexColumn, Regular, Large } from 'js-components'
import { IconWrapper, DetailedLabelWrapper, Container } from './Styles'

interface DescriptiveActionProps {
  title: string
  desc: string
  icon: React.ReactNode
  iconSize?: string
  onClick?: () => void
}

export default class DescriptiveAction extends React.PureComponent<DescriptiveActionProps> {
  static defaultProps = {
    iconSize: '100%',
  }

  render() {
    const { title, desc, icon, onClick, iconSize } = this.props

    return (
      <Container role="button" onClick={onClick}>
        {icon && (
          <IconWrapper>
            {React.cloneElement(icon as any, {
              fill: colors.accentPurple,
              color: colors.accentPurple,
              width: iconSize,
              height: iconSize,
            })}
          </IconWrapper>
        )}
        <DetailedLabelWrapper>
          <FlexColumn alignItems="space-between">
            <Large color={colors.trulyDark}>{title}</Large>
            <Regular color={colors.accentPurple}>{desc}</Regular>
          </FlexColumn>
        </DetailedLabelWrapper>
      </Container>
    )
  }
}
