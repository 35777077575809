import styled from 'styled-components/macro'
import { spaceSizes } from 'js-components'

export const NumberInputWrapper = styled.div`
  width: 60px;
  margin-left: 12px;
`

export const UnitsSelectWrapper = styled.div`
  width: 90px;
  margin-left: 12px;
`

export const FixedWidthTextWrapper = styled.div`
  width: 190px;
`

export const EditButton = styled.div.attrs({ role: 'button' })`
  align-self: center;
  padding: ${spaceSizes.sm}px;
  cursor: pointer;
`
