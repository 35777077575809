import * as React from 'react'
import { EntityOption } from '../../utils/Entity'
import {
  RichSelect,
  NumpadSVG,
  RichSelectOption,
  Regular,
  colors,
} from 'js-components'
import EntityRow from '../EntityRow/EntityRow'
import { getDataProps } from 'truly-utils'

interface EntitySelectProps {
  selectedAssignment: EntityOption | null
  optionWidth: number
  onChange: (value: EntityOption | null) => void
  entityOptions: EntityOption[]
  richSelectResetId: number
  innerRef?: (r: HTMLDivElement) => void
  disabled?: boolean
}

export default class EntitySelect extends React.Component<EntitySelectProps> {
  getDisplayForOption = (option: EntityOption | null) =>
    option ? option.name : ''

  renderIcon(assignment: EntityOption | null) {
    if (
      assignment &&
      (assignment.entity_type === 'phonemenu' ||
        assignment.entity_type === 'node')
    ) {
      return <NumpadSVG width="100%" fillSeed={`${assignment.entity_id}`} />
    }
  }

  getAvatarText(assignment: EntityOption | null) {
    if (
      assignment &&
      !assignment.isInvite &&
      ['conference_phone', 'account'].includes(assignment.entity_type)
    ) {
      return assignment.name
    }
  }

  render() {
    const {
      selectedAssignment,
      onChange,
      entityOptions,
      optionWidth,
      richSelectResetId,
      disabled,
    } = this.props

    return (
      <RichSelect<EntityOption>
        selectedValue={selectedAssignment || null}
        onChange={onChange}
        getDisplayForValue={this.getDisplayForOption}
        modalRef={this.props.innerRef}
        optionMaxHeight={200}
        optionWidth={optionWidth}
        placeholder="None"
        limitResults={10}
        resetId={richSelectResetId}
        avatarOptions={{
          icon: this.renderIcon(selectedAssignment),
          text: this.getAvatarText(selectedAssignment),
          colorSeed:
            selectedAssignment && !selectedAssignment.isInvite
              ? `${selectedAssignment.entity_id}`
              : undefined,
        }}
        disabled={disabled}
        {...getDataProps(this.props, 'data-cy')}>
        {entityOptions.map(e => {
          return (
            <RichSelectOption<EntityOption>
              key={`${e.entity_type};${e.entity_id}`}
              value={e}
              data-cy="entity-select-row">
              <EntityRow
                title={
                  <Regular
                    color={e.isInvite ? colors.darkGray : colors.trulyDark}>
                    {e.name} {e.isInvite ? '(Invite Pending)' : ''}
                  </Regular>
                }
                subtitle={e.description}
                avatarIcon={this.renderIcon(e)}
                avatarText={this.getAvatarText(e) ?? ''}
                avatarSeed={e.isInvite ? undefined : `${e.entity_id}`}
                width={`${optionWidth}px`}
              />
            </RichSelectOption>
          )
        })}
      </RichSelect>
    )
  }
}
