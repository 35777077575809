import {
  FETCH_API_CREDENTIALS,
  FETCH_API_CREDENTIALS_SUCCESS,
  FETCH_API_CREDENTIALS_FAIL,
  GENERATE_API_CREDENTIAL,
  GENERATE_API_CREDENTIAL_FAIL,
  DELETE_API_CREDENTIAL_FAIL,
  DELETE_API_CREDENTIAL_SUCCESS,
  GENERATE_API_CREDENTIAL_SUCCESS,
  DELETE_API_CREDENTIAL,
  UPDATE_API_CREDENTIAL,
} from './actionTypes'

import { IAPICredential } from 'truly-ts'

export const fetchApiCredentials = () => ({
  type: FETCH_API_CREDENTIALS,
  payload: {},
})

export const fetchApiCredentialsSuccess = (credentials: IAPICredential[]) => ({
  type: FETCH_API_CREDENTIALS_SUCCESS,
  payload: {
    credentials,
  },
})

export const fetchApiCredentialsFail = () => ({
  type: FETCH_API_CREDENTIALS_FAIL,
  payload: {},
})

export const generateAPICredential = (name: string | null) => ({
  type: GENERATE_API_CREDENTIAL,
  payload: {
    name,
  },
})

export const updateAPICredential = (credential: IAPICredential) => ({
  type: UPDATE_API_CREDENTIAL,
  payload: {
    credential,
  },
})

export const generateAPICredentialSuccess = () => ({
  type: GENERATE_API_CREDENTIAL_SUCCESS,
  payload: {},
})

export const generateAPICredentialFail = () => ({
  type: GENERATE_API_CREDENTIAL_FAIL,
  payload: {},
})

export const deleteAPICredential = (credential: IAPICredential) => ({
  type: DELETE_API_CREDENTIAL,
  payload: {
    credential,
  },
})

export const deleteAPICredentialSuccess = () => ({
  type: DELETE_API_CREDENTIAL_SUCCESS,
  payload: {},
})

export const deleteAPICredentialFail = () => ({
  type: DELETE_API_CREDENTIAL_FAIL,
  payload: {},
})
