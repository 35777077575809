import * as React from 'react'
import {
  ModalLayout,
  PopupModal,
  TextInput,
  PhoneNumberInput,
} from 'js-components'
import { IExternalForward, ValidationState, Unsaved } from 'truly-ts'
import { formatPhoneNumber, e164Number } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  nameValidationHandler,
  phoneNumberValidationHandler,
} from '../../utils/Validation'
import isUndefined from 'lodash/isUndefined'

const LabelWidth = '155px'

interface ExternalUserEditModalProps {
  show: boolean
  onClose: () => any
  onAdd: (extUser: Unsaved<IExternalForward>) => void
  onUpdate: (extUser: IExternalForward) => void
  externalUser?: IExternalForward
}

interface ExternalUserEditModalState {
  id?: string
  name: string
  phoneNumber: string

  canValidate: boolean
  nameValidationState: ValidationState | null
  phoneNumberValidationState: ValidationState | null
}

export default class ExternalUserEditModal extends React.Component<
  ExternalUserEditModalProps,
  ExternalUserEditModalState
> {
  constructor(props: ExternalUserEditModalProps) {
    super(props)
    this.state = this.getBlankState()
  }

  getBlankState(): ExternalUserEditModalState {
    return {
      id: '',
      name: '',
      phoneNumber: '',
      canValidate: false,
      nameValidationState: null,
      phoneNumberValidationState: null,
    }
  }

  componentDidUpdate(prevProps: ExternalUserEditModalProps) {
    if (this.props.show && !prevProps.show) {
      if (this.props.externalUser) {
        this.setState({
          id: this.props.externalUser.id,
          name: this.props.externalUser.name,
          phoneNumber: PhoneNumberInput.ParseNumber(
            formatPhoneNumber(this.props.externalUser.forwarding_number) ?? '',
          ),
          canValidate: false,
          nameValidationState: null,
          phoneNumberValidationState: null,
        })
      } else {
        this.setState(this.getBlankState())
      }
    }
  }

  saveExternalUser = () => {
    const extUser = getRequired(this.props.externalUser)
    this.props.onUpdate({
      id: extUser.id,
      name: this.state.name,
      forwarding_number: e164Number(this.state.phoneNumber) || '',
    })
    this.props.onClose()
  }

  addExternalUser = () => {
    this.props.onAdd({
      name: this.state.name,
      forwarding_number: e164Number(this.state.phoneNumber) || '',
    })
    this.props.onClose()
  }

  onActionClicked = () => {
    const nameValidationState = nameValidationHandler.validate(this.state.name)
    const phoneNumberValidationState = phoneNumberValidationHandler.validate(
      this.state.phoneNumber,
    )

    this.setState({
      canValidate: true,
      nameValidationState,
      phoneNumberValidationState,
    })

    if (nameValidationState.valid && phoneNumberValidationState.valid) {
      const isNew = !this.state.id
      if (isNew) this.addExternalUser()
      else this.saveExternalUser()
    }
  }

  onNameChanged = (name?: string) =>
    !isUndefined(name) &&
    this.setState({
      name,
      nameValidationState: this.state.canValidate
        ? nameValidationHandler.validate(name)
        : null,
    })

  onPhoneNumberChanged = (phoneNumber: string) =>
    this.setState({
      phoneNumber,
      phoneNumberValidationState: this.state.canValidate
        ? phoneNumberValidationHandler.validate(phoneNumber)
        : null,
    })

  render() {
    const { show, onClose } = this.props
    const {
      name,
      phoneNumber,
      id,
      nameValidationState,
      phoneNumberValidationState,
    } = this.state

    const isNew = !id

    return (
      <PopupModal
        show={show}
        width="550px"
        onRequestClose={onClose}
        hideOnBgClick>
        <ModalLayout.Container>
          <ModalLayout.Content>
            <ModalLayout.Header
              title={isNew ? 'New External User' : 'Edit External User'}
            />
            <ModalLayout.HorizontalLine />
            <ModalLayout.FieldRow
              label="Name"
              fieldWidth="100%"
              labelWidth={LabelWidth}
              validationState={nameValidationState}
              shouldPadRight>
              <TextInput
                value={name}
                autoFocus
                placeholder="Type a Name"
                onChange={this.onNameChanged}
                validationState={nameValidationState}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.FieldRow
              label="Phone Number"
              fieldWidth="100%"
              labelWidth={LabelWidth}
              validationState={nameValidationState}
              shouldPadRight>
              <PhoneNumberInput
                value={phoneNumber}
                placeholder="Type a Phone Number"
                validationState={phoneNumberValidationState}
                phoneNumberChanged={this.onPhoneNumberChanged}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.Footer
              actionText={isNew ? 'Add' : 'Save'}
              onAction={this.onActionClicked}
              onCancel={onClose}
            />
          </ModalLayout.Content>
        </ModalLayout.Container>
      </PopupModal>
    )
  }
}
