import { connect } from 'react-redux'
import ExternalUsers from '../components/ExternalUsers/ExternalUsers'
import { IState } from '../store'
import * as selectors from '../reducers/externalUsers/externalUsersSelectors'
import {
  fetchExternalUsers,
  deleteExternalUser,
} from '../reducers/externalUsers/actionCreators'

const mapStateToProps = (state: IState) => ({
  externalUsers: selectors.externalUsers(state),
})

const mapDispatchToProps = {
  loadExternalUsers: fetchExternalUsers,
  onDelete: deleteExternalUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUsers)
