export const FETCH_PHONE_MENUS = 'FETCH_PHONE_MENUS'
export const FETCH_PHONE_MENUS_SUCCESS = 'FETCH_PHONE_MENUS_SUCCESS'
export const FETCH_PHONE_MENUS_FAIL = 'FETCH_PHONE_MENUS_FAIL'
export const CREATE_PHONE_MENU = 'CREATE_PHONE_MENU'
export const CREATE_PHONE_MENU_FAIL = 'CREATE_PHONE_MENU_FAIL'
export const DELETE_PHONE_MENU = 'DELETE_PHONE_MENU'
export const UPDATE_PHONE_MENU = 'UPDATE_PHONE_MENU'
export const PHONE_MENU_ADDED = 'PHONE_MENU_ADDED'
export const PHONE_MENU_REMOVED = 'PHONE_MENU_REMOVED'
export const PHONE_MENU_UPDATED = 'PHONE_MENU_UPDATED'

export const UPDATE_NODE = 'UPDATE_NODE'
export const UPDATE_NODE_ASSIGNMENTS_FOR_TEAM =
  'UPDATE_NODE_ASSIGNMENTS_FOR_TEAM'
export const UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION =
  'UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION'
export const UPDATE_NODE_ASSIGNMENTS_FOR_MESSAGE =
  'UPDATE_NODE_ASSIGNMENTS_FOR_MESSAGE'
