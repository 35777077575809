import {
  FETCH_WEBHOOKS,
  FETCH_WEBHOOKS_FAIL,
  FETCH_WEBHOOKS_SUCCESS,
  WEBHOOK_ADDED,
  WEBHOOK_UPDATED,
  DELETE_WEBHOOK,
} from './actionTypes'

import {
  fetchWebhooksSuccess,
  webhookAdded,
  webhookUpdated,
  deleteWebhook,
} from './actionCreators'

import keyBy from 'lodash/keyBy'
import { IWebhook } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'

export interface WebhooksState {
  loading: boolean
  webhooks?: { [key: string]: IWebhook }
}

const INITIAL_STATE: WebhooksState = {
  loading: false,
}

const webhooks = createReducer<WebhooksState>(INITIAL_STATE, {
  [FETCH_WEBHOOKS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_WEBHOOKS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchWebhooksSuccess>,
  ) => ({
    ...state,
    loading: false,
    webhooks: keyBy(action.payload.webhooks, 'uuid'),
  }),
  [FETCH_WEBHOOKS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [WEBHOOK_ADDED]: (state, action: ReturnType<typeof webhookAdded>) => ({
    ...state,
    webhooks: {
      ...state.webhooks,
      [getRequired(action.payload.webhook.uuid)]: action.payload.webhook,
    },
  }),
  [DELETE_WEBHOOK]: (state, action: ReturnType<typeof deleteWebhook>) => {
    const extUsers: WebhooksState['webhooks'] = { ...state.webhooks }
    delete extUsers[getRequired(action.payload.webhook.uuid)]
    return {
      ...state,
      webhooks: extUsers,
    }
  },
  [WEBHOOK_UPDATED]: (state, action: ReturnType<typeof webhookUpdated>) => ({
    ...state,
    webhooks: {
      ...state.webhooks,
      [getRequired(action.payload.webhook.uuid)]: action.payload.webhook,
    },
  }),
})

export default webhooks
