import { Permission } from '../../constants/Permissions'
import { IRole } from 'truly-ts'

export default class TeamUtils {
  static removePermission(team: IRole, permission: Permission): IRole {
    const permissions = (team.permissions || []).filter(
      p => p.id !== permission.id,
    )
    return {
      ...team,
      permissions,
    }
  }

  static addPermission(team: IRole, permission: Permission): IRole {
    const permissions = [...(team.permissions || [])]
    if (!permissions.find(p => p.id === permission.id)) {
      permissions.push(permission)
    }

    return {
      ...team,
      permissions,
    }
  }

  static isPermissionEnabled(team: IRole, permission: Permission): boolean {
    if (!team.permissions || team.permissions.length === 0) {
      return false
    }

    return !!team.permissions.find(p => p.id === permission.id)
  }

  static unassignAccount(
    team: IRole,
    account: { id: number; display_name?: string },
  ): IRole {
    const newAccounts = (team.accounts || []).filter(a => a.id !== account.id)
    return {
      ...team,
      accounts: newAccounts,
    }
  }
}
