import styled from 'styled-components'
import { HeaderHeight } from '../Header/Styles'
import { LeftNavWidth } from '../LeftNav/Styles'

export const BodyContainer = styled.div`
  height: calc(100vh - ${HeaderHeight});
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const Content = styled.div`
  width: calc(100vw - ${LeftNavWidth});
  min-width: 600px;
`
