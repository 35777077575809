import {
  FETCH_INVITES,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_FAIL,
} from './actionTypes'

import { IInvite } from 'truly-ts'

export const fetchInvites = () => ({
  type: FETCH_INVITES,
  payload: {},
})

export const fetchInvitesSuccess = (invites: IInvite[]) => ({
  type: FETCH_INVITES_SUCCESS,
  payload: {
    invites,
  },
})

export const fetchInvitesFail = () => ({
  type: FETCH_INVITES_FAIL,
  payload: {},
})
