import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Form } from '../../reducers/forms/types'
import {
  MainContainer,
  ContentContainer,
  SmallSectionHeader,
  Label,
  FullWidthWrapper,
} from '../LayoutHelpers/Styles'
import {
  FlexColumn,
  colors,
  SectionGroup,
  SectionItem,
  FlexRow,
  InlineTextEdit,
  InlineMultiSelect,
  DeleteDialog,
  ValidationMessage,
  ConfirmDialog,
} from 'js-components'
import Loader from '../Loader/Loader'
import { ValidationHandler } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import ContentHeading, {
  HeadingDropdownMenu,
} from '../ContentHeading/ContentHeading'
import { IFormElement, IRole, ValidationState, Unsaved } from 'truly-ts'
import sortBy from 'lodash/sortBy'
import { newEmptyForm } from '../../utils/Forms'
import EntityRow from '../EntityRow/EntityRow'
import ContentFooter from '../ContentFooter/ContentFooter'
import FormFieldEdit from '../FormFieldEdit/FormFieldEdit'
import { titleValidationHandler } from '../../utils/Validation'
import FormFieldTable from '../FormFieldTable/FormFieldTable'
import { SaveState, SaveStatus } from '../../utils/Saving'
import PageTitle from '../PageTitle/PageTitle'
import { FormsTitle } from '../../constants/PageTitles'
import memoizeOne from 'memoize-one'

const formFieldsValidationHandler = new ValidationHandler<
  IFormElement[] | undefined
>('Please add at least one form field.', v => (v?.length ?? 0) > 0)

interface FormEditProps extends RouteComponentProps<{ id: string }> {
  isNew: boolean
  form?: Form
  roles?: IRole[]
  formsLoaded: boolean
  saveState: SaveState
  loadForms: () => void
  updateForm: (id: string, form: Partial<Form>) => void
  createForm: (form: Unsaved<Form>) => void
  deleteForm: (form: Form) => void
  navigate: (path: string) => void
  setHasUnsavedChanges: (title: string, description: string) => void
  clearHasUnsavedChanges: () => void
}

interface FormEditState {
  form?: Form | Unsaved<Form>
  deleting: boolean
  dirty: boolean
  editingFormField: boolean
  editingElement?: IFormElement | null

  canValidate: boolean
  titleValidationState: ValidationState | null

  saveStatus: SaveStatus
  showFailDialog: boolean
  formFieldValidationState: ValidationState | null
}

export default class FormEdit extends React.Component<
  FormEditProps,
  FormEditState
> {
  constructor(props: FormEditProps) {
    super(props)
    this.state = {
      deleting: false,
      titleValidationState: null,
      editingFormField: false,
      form: props.isNew
        ? newEmptyForm()
        : props.form
        ? { ...props.form }
        : undefined,
      dirty: false,
      canValidate: false,
      saveStatus: SaveStatus.Unsaved,
      showFailDialog: false,
      formFieldValidationState: null,
    }
  }

  componentDidUpdate(prevProps: FormEditProps) {
    if (this.props.form !== prevProps.form) {
      this.setState({
        form: this.props.isNew
          ? newEmptyForm()
          : this.props.form && { ...this.props.form },
      })
      this.setClean()
    }
    if (prevProps.saveState !== this.props.saveState) {
      if (
        this.state.form &&
        (this.state.form as Form).id === this.props.saveState.id
      ) {
        if (this.props.saveState.status === SaveStatus.Failed) {
          // Failed to save, make dirty and show dialog
          this.setState({
            showFailDialog: true,
          })
          this.setDirty()
        } else {
          this.setState({
            saveStatus: this.props.saveState.status,
          })
        }
      } else {
        // Different form was saved than this one
        this.setState({
          saveStatus: SaveStatus.Unsaved,
        })
      }
    }
  }

  componentDidMount() {
    if (!this.props.formsLoaded) {
      this.props.loadForms()
    }
  }

  componentWillUnmount() {
    this.props.clearHasUnsavedChanges()
  }

  setDirty = () => {
    this.setState({
      dirty: true,
      saveStatus: SaveStatus.Unsaved,
    })
    this.props.setHasUnsavedChanges(
      'Unpublished Changes',
      'You have made changes to this form that have not been published. Leaving this page without publishing causes all changes to be lost.',
    )
  }

  setClean = () => {
    this.setState({
      dirty: false,
      canValidate: false,
      titleValidationState: null,
    })
    this.props.clearHasUnsavedChanges()
  }

  updateDescription = (value: string) => {
    if (!this.state.form) {
      throw new Error("Can't update null form")
    }
    this.setState({
      form: {
        ...this.state.form,
        description: value,
      },
      titleValidationState: this.state.canValidate
        ? titleValidationHandler.validate(value)
        : null,
    })
    this.setDirty()
  }

  onMakeDefault = () => {
    if (!this.props.form?.id) {
      throw new Error("Can't make form default without id")
    }

    this.props.updateForm(this.props.form.id, { licensegroup_default: true })
  }

  onPublishChanges = () => {
    const titleValidationState = titleValidationHandler.validate(
      this.state.form?.description,
    )
    const formFieldValidationState = formFieldsValidationHandler.validate(
      this.state.form?.elements,
    )

    this.setState({
      canValidate: true,
      titleValidationState,
      formFieldValidationState,
    })

    if (!titleValidationState.valid) return
    if (!formFieldValidationState.valid) return

    this.setClean()
    if (this.props.isNew && this.state.form) {
      this.props.createForm(this.state.form)
    } else if (this.props.form?.id && this.state.form) {
      this.props.updateForm(this.props.form.id, this.state.form)
    }
  }

  onClose = () => {
    this.props.navigate('/forms')
  }

  goToTeams = () => {
    this.props.navigate('/teams')
  }

  goToTeam = (role: IRole) => {
    this.props.navigate(`/teams/${role.id}`)
  }

  hideFailedDialog = () => this.setState({ showFailDialog: false })

  roleNameMapper = (role: IRole) => role.display_name

  onUpdateAssignedRoles = (roles: IRole[]) => {
    if (!this.state.form) {
      throw new Error("Can't update roles without form")
    }

    this.setState({
      form: {
        ...this.state.form,
        roles,
      },
    })
    this.setDirty()
  }

  elementOrderChanged = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex || !this.state.form) return
    const formElements = this.state.form.elements ?? []
    const movedElement = formElements.find(el => el.position === oldIndex)
    if (newIndex > oldIndex) {
      const newElements = formElements.map(el => ({
        ...el,
        position:
          el === movedElement
            ? newIndex
            : el.position > oldIndex && el.position <= newIndex
            ? el.position - 1
            : el.position,
      }))
      this.setState({
        form: {
          ...this.state.form,
          elements: newElements,
        },
      })
    } else {
      const newElements = formElements.map(el => ({
        ...el,
        position:
          el === movedElement
            ? newIndex
            : el.position < oldIndex && el.position >= newIndex
            ? el.position + 1
            : el.position,
      }))
      this.setState({
        form: {
          ...this.state.form,
          elements: newElements,
        },
      })
    }

    this.setDirty()
  }

  shiftElementPositionsDown = (
    elements: IFormElement[],
    fromPosition: number,
  ) => {
    return elements.map(el => ({
      ...el,
      position: el.position > fromPosition ? el.position - 1 : el.position,
    }))
  }

  onDeleteElement = (element: IFormElement) => {
    if (!this.state.form?.elements) {
      throw new Error("Can't delete element from form with no elements")
    }

    const newElements = [...this.state.form.elements]
    newElements.splice(newElements.indexOf(element), 1)
    const shiftedElements = this.shiftElementPositionsDown(
      newElements,
      element.position,
    )
    this.setState({
      form: {
        ...this.state.form,
        elements: shiftedElements,
      },
    })
    if (this.state.canValidate) {
      this.setState({
        formFieldValidationState: formFieldsValidationHandler.validate(
          shiftedElements,
        ),
      })
    }
    this.setDirty()
  }

  onDelete = () => this.setState({ deleting: true })
  onCancelDelete = () => this.setState({ deleting: false })

  onConfirmDelete = () => {
    if (!this.props.form) {
      throw new Error("Can't delete null form")
    }

    this.setClean() // safely navigate away
    this.setState({
      deleting: false,
    })
    this.props.deleteForm(this.props.form)
  }

  onAddFormField = () => {
    this.setState({
      editingFormField: true,
      editingElement: null,
    })
  }

  onCloseEditFormField = () => this.setState({ editingFormField: false })

  onEditFormField = (editingElement: IFormElement) => {
    this.setState({
      editingFormField: true,
      editingElement,
    })
  }

  onFormFieldChanged = (
    newElement: IFormElement,
    oldElement?: IFormElement,
  ) => {
    if (!this.state.form?.elements) {
      throw new Error("Can't onFormFieldChanged without fields")
    }

    //erase type because indexOf types don't like posibly defined values
    const existingIndex = (this.state.form.elements as any[]).indexOf(
      oldElement,
    )
    const elements = [...this.state.form.elements]
    if (existingIndex >= 0) {
      elements.splice(existingIndex, 1, newElement)
    } else {
      // set position to last
      newElement.position = this.state.form.elements.length + 1
      elements.push(newElement)
    }

    this.setState({
      form: {
        ...this.state.form,
        elements,
      },
    })
    if (this.state.canValidate) {
      this.setState({
        formFieldValidationState: formFieldsValidationHandler.validate(
          elements,
        ),
      })
    }
    this.setDirty()
  }

  renderRoleOption = (role: IRole) => {
    return (
      <EntityRow
        title={role.display_name}
        subtitle={role.department ?? ''}
        avatarText={role.display_name.split(' ')[0]}
        avatarSeed={role.display_name}
        width="300px"
      />
    )
  }

  render() {
    const {
      deleting,
      dirty,
      editingElement,
      editingFormField,
      titleValidationState,
      saveStatus,
      showFailDialog,
      formFieldValidationState,
    } = this.state

    if (
      !this.props.formsLoaded ||
      !this.state.form ||
      (!this.props.isNew && !this.props.form)
    ) {
      return (
        <FlexColumn>
          <PageTitle title={FormsTitle} />
          <ContentHeading title="Form" />
          <Loader mt="200px" />
        </FlexColumn>
      )
    }

    const form = getRequired(this.state.form)
    const roles = getRequired(this.props.roles)

    const formElements = sortBy(form.elements, 'position')

    return (
      <MainContainer>
        <PageTitle title={FormsTitle} />
        <DeleteDialog
          show={deleting}
          title={`Delete "${form.description}"`}
          deleteActionText="Delete"
          description="This form will be deleted. This action cannot be undone."
          confirmText="DELETE"
          onCancel={this.onCancelDelete}
          onDelete={this.onConfirmDelete}
        />
        <ConfirmDialog
          show={showFailDialog}
          confirmText="Okay"
          title="Publish Failed"
          description="Your form failed to publish. Try again later."
          color={colors.alertRed}
          onConfirm={this.hideFailedDialog}
          onCancel={this.hideFailedDialog}
        />
        <FormFieldEdit
          show={editingFormField}
          onElementChanged={this.onFormFieldChanged}
          onClose={this.onCloseEditFormField}
          element={editingElement}
          validateSyncIdHandler={getFormFieldSyncIdValidationHandler(
            form,
            editingElement,
          )}
        />
        <ContentHeading
          title={
            this.props.isNew ? 'New Form' : this.props.form?.description ?? ''
          }
          backlinks={[{ label: 'Forms', url: '/forms' }]}
          rightControl={
            !this.props.isNew &&
            !form.licensegroup_default && (
              <HeadingDropdownMenu
                options={[
                  {
                    color: colors.trulyDark,
                    label: 'Make Default',
                    onClick: this.onMakeDefault,
                  },
                  {
                    color: colors.alertRed,
                    bold: true,
                    label: 'Delete Form',
                    onClick: this.onDelete,
                  },
                ]}
              />
            )
          }
        />
        <ContentContainer padTop>
          <SmallSectionHeader first>Details</SmallSectionHeader>
          <SectionGroup>
            <SectionItem>
              <FlexRow alignItems="center">
                <Label width="100px">Title</Label>
                <FullWidthWrapper>
                  <InlineTextEdit
                    originalValue={form.description}
                    onSave={this.updateDescription}
                    saveOnType
                    validationState={titleValidationState}
                    placeholder="Type a Form Title"
                    data-cy="form-title"
                  />
                </FullWidthWrapper>
              </FlexRow>
              <FlexRow justifyContent="flex-end">
                <ValidationMessage validation={titleValidationState} />
              </FlexRow>
            </SectionItem>
            <SectionItem>
              <FlexRow alignItems="center">
                <Label width="100px">Assigned to</Label>
                <FullWidthWrapper>
                  <InlineMultiSelect<IRole>
                    defaultSelectedOptions={form.roles}
                    emptyView={{ entityName: 'Teams', onClick: this.goToTeams }}
                    options={roles}
                    placeholder="Type a Team Name"
                    saveOnChange
                    onTokenClick={this.goToTeam}
                    onSave={this.onUpdateAssignedRoles}
                    valueDisplayMap={this.roleNameMapper}
                    renderOption={this.renderRoleOption}
                    data-cy="form-team-assignment"
                  />
                </FullWidthWrapper>
              </FlexRow>
            </SectionItem>
          </SectionGroup>
          <SmallSectionHeader>Form Fields</SmallSectionHeader>
          <FormFieldTable
            validationState={formFieldValidationState}
            elementOrderChanged={this.elementOrderChanged}
            onEditFormField={this.onEditFormField}
            onDeleteFormField={this.onDeleteElement}
            onAddFormField={this.onAddFormField}
            formElements={formElements}
          />
          <FlexRow justifyContent="flex-end">
            <ValidationMessage validation={formFieldValidationState} />
          </FlexRow>
        </ContentContainer>
        <ContentFooter
          actionText="Publish Form"
          savedText="Published"
          closeText="Close"
          saveStatus={saveStatus}
          actionDisabled={!dirty}
          onActionClicked={this.onPublishChanges}
          onCloseClicked={this.onClose}
        />
      </MainContainer>
    )
  }
}

const getFormFieldSyncIdValidationHandler = memoizeOne(
  (
    form: Unsaved<Form>,
    editingElement?: IFormElement | null,
  ): ValidationHandler<string[]> => {
    return new ValidationHandler<string[]>(
      'This key is already used in another field',
      ids => {
        if (!ids || ids.length === 0) {
          return true
        }
        return !ids.some(id =>
          (form.elements || []).some(
            element =>
              element !== editingElement &&
              (element.external_fields || [])
                .map(external => external.field_name)
                .indexOf(id.trim()) >= 0,
          ),
        )
      },
    )
  },
)
