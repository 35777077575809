export const FETCH_FORMS = 'FETCH_FORMS'
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS'
export const FETCH_FORMS_FAIL = 'FETCH_FORMS_FAIL'

export const DELETE_FORM = 'DELETE_FORM'
export const UPDATE_FORM = 'UPDATE_FORM'
export const CREATE_FORM = 'CREATE_FORM'

export const FORM_DELETED = 'FORM_DELETED'
export const FORM_ADDED = 'FORM_ADDED'
export const FORM_UPDATED = 'FORM_UPDATED'

export const SET_FORM_SAVING = 'SET_FORM_SAVING'
