import { connect } from 'react-redux'
import PhoneNumbers from '../components/PhoneNumbers/PhoneNumbers'
import { IState, Dispatch } from '../store'
import { phoneNumbers } from '../reducers/phoneNumbers/phoneNumbersSelectors'
import {
  fetchPhoneNumbers,
  releasePhoneNumber,
} from '../reducers/phoneNumbers/actionCreators'
import { fetchEntities } from '../reducers/entities/actionCreators'
import {
  entitiesLoaded,
  entitiesMap,
} from '../reducers/entities/entitiesSelectors'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'

const mapStateToProps = (state: IState) => ({
  entitiesLoaded: entitiesLoaded(state),
  phoneNumbers: phoneNumbers(state),
  entities: entitiesMap(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadPhoneNumbers: () => {
    dispatch(fetchPhoneNumbers())
    dispatch(fetchEntities())
  },
  navigate: bindActionCreators(push, dispatch),
  releasePhoneNumber: bindActionCreators(releasePhoneNumber, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumbers)
