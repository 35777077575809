import React, { useState, useEffect } from 'react'
import {
  FlexRow,
  colors,
  FlexColumn,
  InlineSelectEdit,
  Regular,
  HelpIcon,
} from 'js-components'
import keyBy from 'lodash/keyBy'
import { getDataProps } from 'truly-utils'

interface OptionType {
  label: string
  description: string
  value: string
}
interface SelectSettingRowProps {
  title: string
  options: OptionType[]
  originalValue?: string
  onSave: (value: string) => void
  width?: string
  helpText?: React.ReactNode
}

const SelectSettingRow: React.FC<SelectSettingRowProps> = props => {
  const { helpText } = props
  const [mappedOptions, setMappedOptions] = useState<{
    [key: string]: OptionType
  }>()
  const [currentSelectValue, setCurrentSelectValue] = useState(
    props.originalValue ?? '',
  )

  useEffect(() => {
    const mapOption = keyBy(props.options, 'value')
    setMappedOptions(mapOption)
  }, [props.options])

  const onSelectChange = (selectVal: string) => {
    setCurrentSelectValue(selectVal)
    props.onSave(selectVal)
  }

  const { title, originalValue, options, width } = props
  const dataProps = getDataProps(props)

  return (
    <FlexColumn justifyContent="center" {...dataProps}>
      <FlexRow alignItems="center">
        {helpText && <HelpIcon>{helpText}</HelpIcon>}
        <Regular
          bold
          color={colors.trulyDark}
          mr="4px"
          ml={helpText ? '5px' : '0px'}>
          {title}
        </Regular>
        <InlineSelectEdit
          onSave={onSelectChange}
          originalValue={`${originalValue}`}
          options={options}
          width={width}
          data-cy="admin-setting-select"
        />
      </FlexRow>
      {!!mappedOptions && (
        <Regular
          data-cy="admin-setting-description"
          color={colors.accentPurple}>
          {mappedOptions[currentSelectValue].description}
        </Regular>
      )}
    </FlexColumn>
  )
}

export default SelectSettingRow
