import * as React from 'react'
import {
  IRule,
  ValidationState,
  ILicenseGroupAccount,
  IForwardingNode,
} from 'truly-ts'
import {
  Checkbox,
  FlexColumn,
  FlexRow,
  Select,
  TextInput,
  ValidationMessage,
  spaceSizes,
  colors,
  SpinnerMaterial,
} from 'js-components'
import {
  NodeRulesState,
  createDefaultRuleState,
} from '../../utils/model-utils/node-rules-utils'
import { EntityOption } from '../../utils/Entity'
import EntitySelect from '../EntitySelect/EntitySelect'
import memoize from 'lodash/memoize'
import {
  SupportedNotificationActionType,
  SupportedCallNotificationOptions,
  SupportedSalesforceAssignmentsOptions,
} from '../../constants/node-rules'
import { ValidationRow } from './Styles'

interface NodeRulesRowProps {
  label: string
  rules: NodeRulesState
  showNotificationOptions?: boolean
  showSyncOptions?: boolean
  onChange: (ruleState: NodeRulesState) => void
  emailValidationState?: ValidationState
  salesforceOptions?: EntityOption[]
  assignableSalesforce?: boolean
  loadData: () => void
  users?: ILicenseGroupAccount[]
  node?: IForwardingNode
}

interface NodeRulesRowState {
  enabled: boolean
  loadedUsers: boolean
  enabledSalesforceAssignment: boolean
  selectedSalesforceAssignment: EntityOption | null
}

export default class NodeRulesRow extends React.PureComponent<
  NodeRulesRowProps,
  NodeRulesRowState
> {
  constructor(props: NodeRulesRowProps) {
    super(props)

    this.state = {
      enabled: this.anyRuleEnabled(),
      enabledSalesforceAssignment: !!props.rules.createSalesforceTicket
        .account_id,
      selectedSalesforceAssignment: null,
      loadedUsers: false,
    }
  }

  componentDidMount() {
    this.props.loadData()
  }

  componentWillReceiveProps(nextProps: NodeRulesRowProps) {
    const {
      loadedUsers,
      selectedSalesforceAssignment,
      enabledSalesforceAssignment,
    } = this.state
    const { rules, users } = nextProps

    if (users && !loadedUsers && !selectedSalesforceAssignment) {
      const salesforceAccount = rules.createSalesforceTicket.account_id
      const filteredOptions = this.getFilteredSalesforceOptions()
      let salesforceEntity: EntityOption | undefined

      if (salesforceAccount && filteredOptions) {
        const userAccount = users.find(user => user.id === salesforceAccount)

        if (userAccount) {
          salesforceEntity = filteredOptions.find(filteredOption => {
            if (filteredOption.entity_type === 'extension') {
              return filteredOption.entity_id === userAccount.extension_id
            }

            return filteredOption.entity_id === userAccount.id
          })
        }
      }

      this.setState({
        enabledSalesforceAssignment:
          !!salesforceEntity || enabledSalesforceAssignment,
        selectedSalesforceAssignment: salesforceEntity || null,
        loadedUsers: true,
      })
    }
  }

  // this is convention based, may need to change to util
  isNotifRule = (rule: IRule) => rule.action.startsWith('notify')

  anyRuleEnabled() {
    const { rules } = this.props
    return (
      rules.notify.enabled ||
      rules.sendEmail.enabled ||
      rules.createZendeskTicket.enabled ||
      rules.createSalesforceTicket.enabled
    )
  }

  ruleEnabledChanged = memoize(
    (key: keyof NodeRulesState) => (checked: boolean) => {
      this.props.onChange({
        ...this.props.rules,
        [key]: {
          ...this.props.rules[key],
          enabled: checked,
        },
      })
    },
  )

  notifyActionChanged = (action: string) => {
    this.props.onChange({
      ...this.props.rules,
      notify: {
        ...this.props.rules.notify,
        value: action as SupportedNotificationActionType,
      },
    })
  }

  sendEmailChanged = (email: string) => {
    this.props.onChange({
      ...this.props.rules,
      sendEmail: {
        ...this.props.rules.sendEmail,
        email,
      },
    })
  }

  isEnabled() {
    return this.anyRuleEnabled() || this.state.enabled
  }

  enabledChanged = (enabled: boolean) => {
    this.setState({
      enabled,
    })

    if (!enabled) {
      this.props.onChange(createDefaultRuleState()) // reset
    }
  }

  getFilteredSalesforceOptions = () => {
    const { salesforceOptions, node } = this.props

    if (salesforceOptions && node) {
      return salesforceOptions.filter(salesforceOption => {
        if (salesforceOption.entity_type === 'extension') {
          return (
            node.extensions.find(
              extension => extension.id === salesforceOption.entity_id,
            ) ?? []
          )
        }
        return (
          node.roles.find(role => role.id === salesforceOption.entity_id) ?? []
        )
      })
    }

    return []
  }

  salesforceActionChanged = (action: string) => {
    const { selectedSalesforceAssignment } = this.state
    const { users } = this.props
    const updatedAssignment = action === 'salesforce_custom_user'
    let userAccount: ILicenseGroupAccount | undefined

    if (updatedAssignment && selectedSalesforceAssignment && users) {
      userAccount = users.find(
        user => user.extension_id === selectedSalesforceAssignment.entity_id,
      )
    }

    this.props.onChange({
      ...this.props.rules,
      createSalesforceTicket: {
        ...this.props.rules.createSalesforceTicket,
        account_id: userAccount ? userAccount.id : null,
      },
    })

    this.setState({
      enabledSalesforceAssignment: updatedAssignment,
    })
  }

  updateAssignment = (assignment: EntityOption | null) => {
    this.setState(
      {
        selectedSalesforceAssignment: assignment,
      },
      () => {
        this.salesforceActionChanged('salesforce_custom_user')
      },
    )
  }

  getSalesforceAssignText = () => {
    if (this.props.assignableSalesforce) {
      return 'Create an activity in Salesforce and assign to'
    }

    return 'Create a Salesforce activity'
  }

  salesforceUserAssignmentRow = () => {
    const { selectedSalesforceAssignment, loadedUsers } = this.state

    if (loadedUsers) {
      return (
        <EntitySelect
          entityOptions={this.getFilteredSalesforceOptions()}
          selectedAssignment={selectedSalesforceAssignment}
          onChange={this.updateAssignment}
          optionWidth={300}
          richSelectResetId={1}
        />
      )
    }

    return (
      <SpinnerMaterial
        color={colors.accentPurple}
        strokeWidth={9}
        size="20px"
      />
    )
  }

  render() {
    const { enabledSalesforceAssignment } = this.state
    const {
      label,
      rules,
      showNotificationOptions,
      showSyncOptions,
      emailValidationState,
      assignableSalesforce,
    } = this.props

    const rowHeight = '38px'

    return (
      <FlexColumn style={{ marginTop: '6px', marginBottom: '8px' }}>
        <Checkbox
          data-cy="rule-enabled-check"
          checked={this.isEnabled()}
          checkChanged={this.enabledChanged}
          label={label}
          boldLabel
        />
        {this.isEnabled() && (
          <FlexColumn
            style={{
              marginLeft: '32px',
              marginTop: '8px',
              marginBottom: '8px',
            }}>
            {showNotificationOptions && (
              <>
                <FlexRow alignItems="center" width="100%" height={rowHeight}>
                  <Checkbox
                    checked={rules.notify.enabled}
                    label="Missed call notification sent to"
                    checkChanged={this.ruleEnabledChanged('notify')}
                  />
                  <div style={{ width: '260px', marginLeft: '8px' }}>
                    <Select
                      value={rules.notify.value}
                      onChange={this.notifyActionChanged}
                      options={SupportedCallNotificationOptions}
                      disabled={!rules.notify.enabled}
                    />
                  </div>
                </FlexRow>
                <FlexRow alignItems="center" width="100%" height={rowHeight}>
                  <Checkbox
                    checked={rules.sendEmail.enabled}
                    label="Send email to"
                    checkChanged={this.ruleEnabledChanged('sendEmail')}
                  />
                  <div style={{ width: '200px', marginLeft: '8px' }}>
                    <TextInput
                      placeholder="support@example.com"
                      value={rules.sendEmail.email}
                      onChange={this.sendEmailChanged}
                      validationState={emailValidationState}
                    />
                  </div>
                </FlexRow>
                <ValidationRow style={{ paddingRight: '280px' }}>
                  <ValidationMessage
                    validation={emailValidationState ?? null}
                  />
                </ValidationRow>
              </>
            )}
            {showSyncOptions && (
              <>
                <FlexRow alignItems="center" width="100%" height={rowHeight}>
                  <Checkbox
                    checked={rules.createZendeskTicket.enabled}
                    label="Create a ticket in Zendesk"
                    checkChanged={this.ruleEnabledChanged(
                      'createZendeskTicket',
                    )}
                  />
                </FlexRow>
                <FlexRow alignItems="center" width="100%" height={rowHeight}>
                  <Checkbox
                    checked={rules.createSalesforceTicket.enabled}
                    label={this.getSalesforceAssignText()}
                    checkChanged={this.ruleEnabledChanged(
                      'createSalesforceTicket',
                    )}
                  />
                  {assignableSalesforce && (
                    <div style={{ width: '249px', marginLeft: '8px' }}>
                      <Select
                        value={
                          enabledSalesforceAssignment
                            ? 'salesforce_custom_user'
                            : 'salesforce_user_random'
                        }
                        onChange={this.salesforceActionChanged}
                        options={SupportedSalesforceAssignmentsOptions}
                      />
                    </div>
                  )}
                </FlexRow>
                {assignableSalesforce && enabledSalesforceAssignment && (
                  <FlexRow ml="26px" mt={`${spaceSizes.sm}px`}>
                    {this.salesforceUserAssignmentRow()}
                  </FlexRow>
                )}
              </>
            )}
          </FlexColumn>
        )}
      </FlexColumn>
    )
  }
}
