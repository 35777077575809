import * as React from 'react'
import { IMessage, INode, MessageType, ValidationState } from 'truly-ts'
import { FlexColumn, Checkbox, ValidationMessage } from 'js-components'
import { SubItem } from './Styles'
import {
  getMessageFromNode,
  messageToNodeMessage,
} from '../../utils/model-utils/node-utils'
import {
  NodeCFOptionsValidationSubState,
  NodeValidationContextValue,
  NodeValidationContext,
  makeNodeMessageValidator,
  NodeValidationState,
} from '../../utils/model-utils/node-validation'
import RecordingEditor from '../RecordingEditor/RecordingEditor'
import { ValidationHandler } from 'truly-utils'

interface MessageEditRowProps {
  label: string
  node: INode
  type: MessageType
  onNodeChange: (node: INode) => void
  validationKey: keyof NodeCFOptionsValidationSubState
  validationMessage: string
  newRecordingDefaultName: string
}

interface MessageEditRowState {
  enabled: boolean
}

export default class MessageEditRow extends React.PureComponent<
  MessageEditRowProps,
  MessageEditRowState
> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<
    NodeCFOptionsValidationSubState & NodeValidationState
  > // docs say to do this
  private validationHandler: ValidationHandler<INode>

  constructor(props: MessageEditRowProps) {
    super(props)

    this.state = {
      enabled: !!getMessageFromNode(props.node, props.type),
    }

    this.validationHandler = makeNodeMessageValidator(
      props.type,
      props.validationMessage,
    )
  }

  componentDidMount() {
    this.validateNode(this.props.node, this.state.enabled)
  }

  componentDidUpdate(prevProps: MessageEditRowProps) {
    if (prevProps.node.id !== this.props.node.id) {
      this.setState({
        enabled: !!getMessageFromNode(this.props.node, this.props.type),
      })
      this.validateNode(this.props.node, this.state.enabled)
    }
  }

  validateNode(node: INode, enabled: boolean) {
    this.context.validationChange({
      // if disabled, it's valid
      [this.props.validationKey]: enabled
        ? this.validationHandler.validate(node)
        : ValidationHandler.createValidState(),
    })
  }

  onMessageChange = (message: IMessage | null) => {
    let messages: IMessage[] = [...(this.props.node.messages || [])]
    messages = messages.filter(m => m.type !== this.props.type) // remove message
    if (message) {
      // add new message
      messages.push({
        ...messageToNodeMessage(message),
        type: this.props.type,
      })
    }

    const newNode = {
      ...this.props.node,
      messages,
    }
    this.props.onNodeChange(newNode)
    this.validateNode(newNode, message ? true : false)
  }

  onEnableChange = (enabled: boolean) => {
    this.setState({
      enabled,
    })
    if (!enabled) {
      this.onMessageChange(null)
    } else {
      this.validateNode(this.props.node, enabled)
    }
  }

  render() {
    const message = getMessageFromNode(this.props.node, this.props.type)
    const { validationState } = this.context

    return (
      <FlexColumn width="100%">
        <Checkbox
          label={this.props.label}
          checked={this.state.enabled}
          checkChanged={this.onEnableChange}
        />
        {this.state.enabled && (
          <SubItem>
            <RecordingEditor
              newRecordingDefaultName={
                message ? message.title : this.props.newRecordingDefaultName
              }
              message={message ?? undefined}
              onChange={this.onMessageChange}
              height={52}
            />
            {validationState.showErrors && (
              <ValidationMessage
                validation={
                  validationState[this.props.validationKey] as ValidationState
                }
              />
            )}
          </SubItem>
        )}
      </FlexColumn>
    )
  }
}
