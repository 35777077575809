import { IState } from '../../store'
import { createSelector } from 'reselect'
import { createNewDataRetentionPolicy } from '../../utils/model-utils/data-retention-utils'
export const base = (state: IState) => state.lgSettings
export const lgInfo = (state: IState) => base(state).lg
export const companyName = createSelector(
  base,
  s => (s.lg && s.lg.company) || '',
)
export const callCompliance = createSelector(
  base,
  s => s.lg && s.lg.settings && s.lg.settings.call_compliance,
)
export const autoInvite = createSelector(
  base,
  s => (s.lg && s.lg.settings && s.lg.settings.auto_invite) || false,
)
export const routingProtection = createSelector(
  base,
  s =>
    (s.lg && s.lg.settings && s.lg.settings.routing_protection) || 'phone_menu',
)
export const accessRecordings = createSelector(
  base,
  s => (s.lg && s.lg.settings && s.lg.settings.access_recording) || false,
)
export const dataRetention = createSelector(
  base,
  // Force license group to have one
  s => s.lg?.settings?.data_retention ?? createNewDataRetentionPolicy(),
)
export const allowPasswordAuth = createSelector(
  base,
  s => s.lg?.settings?.allow_password_auth ?? true,
)
