import React, { useCallback } from 'react'
import {
  IPhoneMenu,
  INode,
  IForwardingNode,
  IExtension,
  IRole,
  IMessage,
  PhoneMenuType,
  MessageType,
} from 'truly-ts'
import {
  ContentContainer,
  MediumSectionHeader,
  MediumSectionHeaderDescription,
} from '../../LayoutHelpers/Styles'
import NodeBasicOption from '../../NodeBasicOption/NodeBasicOption'
import NodeDialOptions from '../../NodeDialOptions/NodeDialOptions'
import RecordingEditor from '../../RecordingEditor/RecordingEditor'
import {
  getMessageFromNode,
  markNodeDirty,
  messageToNodeMessage,
} from '../../../utils/model-utils/node-utils'
import { SectionGroup, SectionItem } from 'js-components'
import EnabledSettingRow from '../../EnabledSettingRow/EnabledSettingRow'
import { isEditingTreeRoot } from '../../../utils/model-utils/phone-menus-utils'

interface PhoneMenuEditDetailsTabProps {
  phoneMenu: IPhoneMenu
  editingNode: INode
  extensions?: IExtension[]
  teams?: IRole[]
  updateEditingNode: (node: INode) => void
  phoneMenuChanged: (phoneMenu: IPhoneMenu) => void
  openSubMenu: (node: INode) => void
}

const PhoneMenuEditDetailsTab: React.FC<PhoneMenuEditDetailsTabProps> = props => {
  const {
    phoneMenu,
    editingNode,
    extensions,
    teams,
    updateEditingNode,
    openSubMenu,
    phoneMenuChanged,
  } = props

  const greetingMessage = getMessageFromNode(
    editingNode,
    MessageType.MenuMessage,
  )
  const isEditingRoot = isEditingTreeRoot(phoneMenu, editingNode)

  const onGreetingChanged = useCallback(
    (message: IMessage) => {
      // edit and save node
      const nodeMessages = editingNode.messages
      const rootNode = markNodeDirty({
        ...editingNode,
        messages: [
          // remove old message
          ...(nodeMessages || []).filter(
            m => m.type !== MessageType.MenuMessage,
          ),
          {
            ...messageToNodeMessage(message),
            type: MessageType.MenuMessage,
          },
        ],
      })
      updateEditingNode(rootNode)
    },
    [editingNode, updateEditingNode],
  )

  const onDialByExtensionChanged = useCallback(
    (value: boolean) => {
      phoneMenuChanged({
        ...phoneMenu,
        is_prompt_extensions: value,
      })
    },
    [phoneMenu, phoneMenuChanged],
  )

  return (
    <ContentContainer>
      {phoneMenu.type === PhoneMenuType.Basic ? (
        <NodeBasicOption
          extensions={extensions}
          teams={teams}
          node={editingNode as IForwardingNode}
          onNodeChange={updateEditingNode}
          phoneMenuId={phoneMenu.id}
        />
      ) : (
        <NodeDialOptions
          extensions={extensions}
          teams={teams}
          parentNode={editingNode}
          onParentNodeChange={updateEditingNode}
          onOpenSubmenuNode={openSubMenu}
          phoneMenuId={phoneMenu.id}
        />
      )}
      <MediumSectionHeader>Phone Menu Greeting</MediumSectionHeader>
      <MediumSectionHeaderDescription>
        This greeting will be played when users connect to this phone menu.
      </MediumSectionHeaderDescription>
      <RecordingEditor
        newRecordingDefaultName={
          greetingMessage
            ? greetingMessage.title
            : `${phoneMenu.name} (Greeting)`
        }
        message={greetingMessage ?? undefined}
        onChange={onGreetingChanged}
        largePlay
      />
      {isEditingRoot && (
        <>
          <MediumSectionHeader>Settings</MediumSectionHeader>
          <SectionGroup>
            <SectionItem>
              <EnabledSettingRow
                enabled={phoneMenu.is_prompt_extensions}
                title="Dial By Extension"
                enabledDesc="Allow callers to dial users by three digit extension"
                disabledDesc="Don't allow callers to dial users by three digit extension"
                onChange={onDialByExtensionChanged}
              />
            </SectionItem>
          </SectionGroup>
        </>
      )}
    </ContentContainer>
  )
}

export default PhoneMenuEditDetailsTab
