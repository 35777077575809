export interface Permission {
  id: number
  name: string
}

const Permissions = {
  CallMonitor: {
    id: 1,
    name: 'call_monitor',
  },
  AccessAdmin: {
    id: 3,
    name: 'access_admin',
  },
}

export default Permissions
