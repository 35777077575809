import * as React from 'react'
import styled from 'styled-components'
import { colors, Regular, FlexColumn } from 'js-components'

export const ValueWrapper = styled.div`
  margin: 8px;
`

export const IconWrapper = styled.div`
  margin-right: 20px;
`

const DetailedLabelWrapper = styled.div`
  margin-left: 6px;
  line-height: 1.7;
`

export const DetailedLabel: React.SFC<{
  children: React.ReactNode
  description: string
  enabled: boolean
  readonly?: boolean
}> = ({ children, description, enabled, readonly }) => (
  <DetailedLabelWrapper>
    <FlexColumn alignItems="space-between">
      <Regular
        color={enabled && !readonly ? colors.trulyDark : colors.darkGray}>
        {children}
      </Regular>
      <Regular
        color={enabled && !readonly ? colors.accentPurple : colors.darkGray}>
        {description}
      </Regular>
    </FlexColumn>
  </DetailedLabelWrapper>
)
