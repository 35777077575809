import { connect } from 'react-redux'
import UserInviteModal from '../components/UserInviteModal/UserInviteModal'
import { IState } from '../store'
import { company } from '../reducers/user/userSelectors'

const mapStateToProps = (state: IState) => ({
  company: company(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserInviteModal)
