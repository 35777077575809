import * as React from 'react'
import {
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import { IRole } from 'truly-ts'
import {
  TableFilterRow,
  ScrollableTable,
  EmptyResults,
  TableRow,
  TableData,
  Avatar,
  AvatarSizes,
  Regular,
  colors,
  Small,
  ToolTip,
  CircleRemove,
  Edit,
  DeleteDialog,
} from 'js-components'
import noop from 'js-components/dist/src/utils/noop'

import AddUserModal from './AddUserModal'

type Account = { id: number; display_name?: string }

const RowHeight = 75
const AvatarCellWidth = '80px'

interface TeamUsersTableProps {
  team: IRole
  onUnassignAccount: (account: Account) => void
  navigate: (path: string) => void
  fetchAccounts: () => void
}

interface TeamUsersTableState {
  searchValue: string
  unassigning: boolean
  selectedAccount?: Account
  addUser: boolean
}

export default class TeamUsersTable extends React.Component<
  TeamUsersTableProps,
  TeamUsersTableState
> {
  constructor(props: TeamUsersTableProps) {
    super(props)

    this.state = {
      searchValue: '',
      unassigning: false,
      addUser: false,
    }
  }

  componentDidMount() {
    this.props.fetchAccounts()
  }

  onSearchChanged = (searchValue: string) => this.setState({ searchValue })
  clearAllFilters = () => this.setState({ searchValue: '' })

  onEditUser = (acct: Account) => {
    this.props.navigate(`/users/${acct.id}`)
  }

  onConfirmUnassign = () => {
    if (!this.state.selectedAccount) {
      throw new Error("Can't unassign from team without selected account")
    }

    this.props.onUnassignAccount(this.state.selectedAccount)

    this.setState({
      unassigning: false,
      selectedAccount: undefined,
    })
  }

  onUnassignUser = (selectedAccount: Account) => {
    this.setState({
      unassigning: true,
      selectedAccount,
    })
  }

  onCancelUnassign = () =>
    this.setState({ unassigning: false, selectedAccount: undefined })

  onOpenAddUserModal = () => {
    this.setState(() => ({ addUser: true }))
  }

  onCloseAddUserModal = () => {
    this.setState(() => ({ addUser: false }))
  }

  onAddUser = () => {
    alert('not implemented')
  }

  isFiltered() {
    const { searchValue } = this.state

    return (
      this.props.team.accounts &&
      this.props.team.accounts.length > 0 &&
      !!(searchValue && searchValue.trim())
    )
  }

  getFilteredAccounts() {
    let accounts = this.props.team.accounts
    if (!accounts) {
      return []
    }

    const { searchValue } = this.state

    if (searchValue && searchValue.trim()) {
      const search = searchValue.trim().toLowerCase()
      accounts = accounts.filter(
        a => (a.display_name || '').toLowerCase().indexOf(search) >= 0,
      )
    }

    return accounts
  }

  render() {
    const { team } = this.props
    const { searchValue, unassigning, selectedAccount, addUser } = this.state

    const accounts = this.getFilteredAccounts()

    const isFiltered = this.isFiltered()

    return (
      <ContentContainer>
        <DeleteDialog
          show={unassigning}
          deleteActionText="Unassign"
          title={
            selectedAccount ? `Unassign ${selectedAccount.display_name}?` : ''
          }
          description={
            selectedAccount &&
            `${selectedAccount.display_name} will be removed from the ${team.display_name} team.`
          }
          onCancel={this.onCancelUnassign}
          onDelete={this.onConfirmUnassign}
        />
        <TableFilterWrapper>
          <TableFilterRow
            searchInput
            searchInputValue={searchValue}
            onSearchInputChanged={this.onSearchChanged}
            selectedFilterOption="all"
            filterOptions={[
              {
                value: 'all',
                label: 'Users',
              },
            ]}
            searchPlaceholder="filter users"
            addButtonText="Add User"
            onAddButtonClicked={this.onOpenAddUserModal}
            renderButton={btn => (
              <AddUserModal
                show={addUser}
                onClose={this.onCloseAddUserModal}
                team={team}>
                {btn}
              </AddUserModal>
            )}
          />
        </TableFilterWrapper>
        <TableWrapper>
          <ScrollableTable
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={isFiltered ? 'No Users Found' : 'No Users Assigned'}
                actionText={isFiltered ? 'Clear All Filters' : ''}
                onActionClick={isFiltered ? this.clearAllFilters : noop}
              />
            }
            rowCount={accounts.length}
            renderRow={idx => (
              <UserTableRow
                key={accounts[idx].id}
                account={accounts[idx]}
                rowHeight={RowHeight}
                onEdit={this.onEditUser}
                onUnassign={this.onUnassignUser}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    )
  }
}

interface UserTableRowProps {
  account: Account
  rowHeight: number
  onUnassign: (acct: Account) => void
  onEdit: (acct: Account) => void
}

class UserTableRow extends React.PureComponent<UserTableRowProps> {
  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  onUnassign = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onUnassign(this.props.account)
  }

  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.account)
  }

  render() {
    const { rowHeight, account, onEdit, onUnassign, ...restProps } = this.props

    return (
      <TableRow height={rowHeight} verticalAlign="middle" {...restProps}>
        <TableData width={AvatarCellWidth}>
          <LeftAlignedTableData>
            <Avatar
              colorSeed={account.display_name}
              size={AvatarSizes.Big}
              text={account.display_name}
            />
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <Regular color={colors.trulyDark}>{account.display_name}</Regular>
          {/* <Small color={colors.accentPurple} mt={`${spaceSizes.xs}px`} bold>
            TODO@TODO.com
          </Small> */}
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Unassign')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onUnassign}
                role="button"
                data-cy="unassign-user">
                <CircleRemove width="24px" height="24px" />
              </ActionButton>
            </ToolTip>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Edit')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onEdit}
                role="button"
                data-cy="edit-user">
                <Edit width="24px" height="24px" />
              </ActionButton>
            </ToolTip>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
