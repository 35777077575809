import { Store, Next, IAction } from '../../store'
import { INITIALIZE } from '../../reducers/actionTypes'
import { setFlagsAction } from '../../utils/flags'

export const handleFlags = () => (store: Store) => (next: Next) => (
  action: IAction,
) => {
  switch (action.type) {
    case INITIALIZE: {
      // Example for feature flags from local storage:
      // if (localStorage.getItem('some-feature-enabled') === 'true') {
      //   store.dispatch(
      //     setFlagsAction({
      //       myFeature: true
      //     })
      //   );
      // }
      if (localStorage.getItem('data_retention_allow_minutes') === 'true') {
        store.dispatch(
          setFlagsAction({
            dataRetentionAllowMinutes: true,
          }),
        )
      }
      break
    }
    default:
      break
  }
  return next(action)
}
