import { connect } from 'react-redux'
import { NumberPurchaser, NumberPurchaserProps } from 'js-components'
import { IState } from '../store'
import * as selectors from '../reducers/phoneNumbers/phoneNumbersSelectors'
import {
  searchPhoneNumbers,
  fetchPhoneNumbers,
} from '../reducers/phoneNumbers/actionCreators'

const mapStateToProps = (
  state: IState,
  ownProps: Pick<NumberPurchaserProps, 'phoneNumbers'>,
) => ({
  searching: selectors.searchingPhoneNumbers(state),
  failedSearch: selectors.failedSearchingPhoneNumbers(state),
  foundNumbers: selectors.foundPhoneNumbers(state) ?? null,
  phoneNumbers: ownProps.phoneNumbers || selectors.phoneNumbers(state),
})

const mapDispatchToProps = {
  searchPhoneNumbers,
  fetchPhoneNumbers,
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberPurchaser)
