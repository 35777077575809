import styled from 'styled-components'

export const Container = styled.div<{ marginTop?: string }>`
  margin-left: auto;
  margin-right: auto;
  ${props => (props.marginTop ? `margin-top: ${props.marginTop};` : '')}
`

export const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
`

export const OverlayWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`
