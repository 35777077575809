import * as React from 'react'
import { IPhoneMenu, PhoneMenuType } from 'truly-ts'
import {
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  Small,
  TableRow,
  TableData,
  AvatarSizes,
  Avatar,
  Regular,
  colors,
  ToolTip,
  Trash,
  Edit,
  spaceSizes,
} from 'js-components'
import Loader from '../Loader/Loader'
import {
  ContentContainer,
  TableWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
} from '../LayoutHelpers/Styles'
import memoizeOne from 'memoize-one'
import { formatPhoneNumber, makeSearchFn } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { ReactComponent as UsersSVG } from 'js-components/src/components/Icons/Users.svg'
import { ReactComponent as NumpadSVG } from 'js-components/src/components/Icons/Numpad.svg'

const RowHeight = 65
const AvatarCellWidth = '60px'

interface PhoneMenuProps {
  phoneMenus?: IPhoneMenu[]
  fetchData: () => void
  navigate: (path: string) => void
  onDeletePhoneMenu: (phoneMenu: IPhoneMenu) => void
  searchValue?: string
  clearFilters: () => void
  onNewPhoneMenu: () => void
}

interface PhoneMenuState {
  deleting: boolean
  creating: boolean
  selectedPhoneMenu?: IPhoneMenu
}

export default class PhoneMenus extends React.Component<
  PhoneMenuProps,
  PhoneMenuState
> {
  static filterPhoneMenus = memoizeOne(
    (phoneMenus: IPhoneMenu[], searchValue?: string) => {
      let result = phoneMenus
      if (searchValue && searchValue.trim()) {
        const searchFn = makeSearchFn<IPhoneMenu>(
          ['name', 'status'], // TODO add phone number search
          searchValue,
        )
        result = result.filter(searchFn)
      }
      return result
    },
  )

  constructor(props: PhoneMenuProps) {
    super(props)
    this.state = {
      deleting: false,
      creating: false,
    }
  }

  componentDidMount() {
    this.props.fetchData()
  }

  onEditPhoneMenu = (phoneMenu: IPhoneMenu) => {
    this.props.navigate(`/phone-menus/${phoneMenu.id}`)
  }

  goToUsers = () => {
    this.props.navigate('/users')
  }

  isFiltered = () => {
    const { searchValue } = this.props
    return (
      this.props.phoneMenus &&
      this.props.phoneMenus.length > 0 &&
      !!(searchValue && searchValue.trim())
    )
  }

  getFilteredPhoneMenus = () => {
    return PhoneMenus.filterPhoneMenus(
      getRequired(this.props.phoneMenus),
      this.props.searchValue,
    )
  }

  render() {
    const { onDeletePhoneMenu, clearFilters, onNewPhoneMenu } = this.props

    if (!this.props.phoneMenus) {
      return <Loader mt="200px" />
    }

    const phoneMenus = this.getFilteredPhoneMenus()
    const isFiltered = this.isFiltered()

    return (
      <ContentContainer>
        <TableWrapper>
          <ScrollableTable
            header={<PhoneMenusHeaderRow />}
            rowHeight={RowHeight}
            emptyView={
              <EmptyResults
                title={
                  isFiltered ? 'No Phone Menus Found' : 'No Phone Menus... Yet.'
                }
                actionText={
                  isFiltered ? 'Clear All Filters' : 'Add New Phone Menu'
                }
                onActionClick={isFiltered ? clearFilters : onNewPhoneMenu}
              />
            }
            rowCount={phoneMenus.length}
            renderRow={idx => (
              <PhoneMenusTableRow
                key={phoneMenus[idx].id}
                phoneMenu={phoneMenus[idx]}
                rowHeight={RowHeight}
                onDelete={onDeletePhoneMenu}
                onEdit={this.onEditPhoneMenu}
              />
            )}
          />
        </TableWrapper>
      </ContentContainer>
    )
  }
}

class PhoneMenusHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData width={AvatarCellWidth} text="" />
        <TableHeaderData text="Name" />
        <TableHeaderData text="Phone Numbers" />
        <TableHeaderData text="" />
      </TableHeaderRow>
    )
  }
}

class PhoneMenusTableRow extends React.PureComponent<{
  phoneMenu: IPhoneMenu
  rowHeight: number
  onEdit: (pm: IPhoneMenu) => void
  onDelete: (pm: IPhoneMenu) => void
}> {
  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.phoneMenu)
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.phoneMenu)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const { phoneMenu, onEdit, onDelete, rowHeight, ...restProps } = this.props

    const extraNumberCount = Math.max(
      phoneMenu.phonenumbers ? phoneMenu.phonenumbers.length - 1 : 0,
      0,
    )

    return (
      <TableRow
        height={rowHeight}
        verticalAlign="middle"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={AvatarCellWidth}>
          <LeftAlignedTableData>
            <Avatar
              colorSeed={`${phoneMenu.id}`}
              size={AvatarSizes.Medium}
              square
              icon={
                phoneMenu.type === PhoneMenuType.Basic ? (
                  <UsersSVG width="100%" />
                ) : (
                  <NumpadSVG width="100%" />
                )
              }
            />
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <LeftAlignedTableData>
            <Regular color={colors.trulyDark} bold>
              {phoneMenu.name}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <LeftAlignedTableData>
            {phoneMenu.phonenumbers && phoneMenu.phonenumbers.length > 0 ? (
              <Regular color={colors.trulyDark}>
                {formatPhoneNumber(phoneMenu.phonenumbers[0].full_number)}
              </Regular>
            ) : (
              <Regular color={colors.darkGray}>None</Regular>
            )}
            {extraNumberCount > 0 && (
              <Small color={colors.trulyDark} mt={`${spaceSizes.xs}px`} bold>
                +{extraNumberCount} Other
              </Small>
            )}
          </LeftAlignedTableData>
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Delete')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onDelete}
                role="button"
                data-cy="delete-button">
                <Trash width="24" height="24" />
              </ActionButton>
            </ToolTip>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={this.renderTooltipContent('Edit')}>
              <ActionButton
                cursor="pointer"
                onClick={this.onEdit}
                role="button">
                <Edit width="24" height="24" />
              </ActionButton>
            </ToolTip>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  }
}
