import { AnalyticsClient } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { Next, IAction } from '../../store'
import once from 'lodash/once'
import { INITIALIZE } from '../../reducers/actionTypes'
import { FETCH_USER_SUCCESS } from '../../reducers/user/actionTypes'
import { fetchUserSuccess } from '../../reducers/user/actionCreators'

const appStarted = once((client: AnalyticsClient) =>
  client.track('App Started'),
)

export const analyticsMiddleware = (client: AnalyticsClient) => () => (
  next: Next,
) => (action: IAction) => {
  switch (action.type) {
    case INITIALIZE:
      appStarted(client)
      break
    case '@@router/INIT_PATH':
    case '@@router/UPDATE_PATH':
    case '@@router/LOCATION_CHANGE':
      if (action.payload.action === 'PUSH') {
        client.page(action.payload.pathname)
      }
      break
    case FETCH_USER_SUCCESS:
      const account = (action as ReturnType<typeof fetchUserSuccess>).payload
        .account
      const licenseGroup = (action as ReturnType<typeof fetchUserSuccess>)
        .payload.licenseGroup
      client.identify(
        {
          email: getRequired(account.email),
          first_name: getRequired(account.first_name),
          last_name: getRequired(account.last_name),
        },
        licenseGroup,
        {
          webVersion: window.trulyEnv.version,
        },
      )

      break
  }
  return next(action)
}
