import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { FETCH_EXTENSIONS } from './actionTypes'
import { fetchExtensionsSuccess, fetchExtensionsFail } from './actionCreators'
import { toastr } from 'react-redux-toastr'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* extensionsSaga() {
  yield all([takeLatest(FETCH_EXTENSIONS, fetchExtensionsSaga)])
}

export function* fetchExtensionsSaga() {
  try {
    const req = yield call(client.lg.fetchExtensions)
    yield put(fetchExtensionsSuccess(req.data.extensions))
  } catch (e) {
    console.error('fetching extensions', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get extensions. Please refresh the page.',
    )
    yield put(fetchExtensionsFail())
  }
}
