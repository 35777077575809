import * as React from 'react'
import {
  PopupModal,
  ModalLayout,
  TextInput,
  SpinnerMaterial,
  colors,
} from 'js-components'
import DescriptiveAction from '../DescriptiveAction/DescriptiveAction'
import { ReactComponent as UsersSVG } from 'js-components/src/components/Icons/Users.svg'
import { ReactComponent as NumpadSVG } from 'js-components/src/components/Icons/Numpad.svg'
import NumberPurchaserContainer from '../../containers/NumberPurchaserContainer'
import {
  nameValidationHandler,
  phoneNumberSelectedValidationHandler,
} from '../../utils/Validation'
import { IPhoneMenuCreate, PhoneMenuType, ValidationState } from 'truly-ts'

interface PhoneMenuCreateModalProps {
  show: boolean
  onClose: () => void
  createPhoneMenu: (
    phoneMenu: IPhoneMenuCreate,
    options: { phoneNumber: string; isPhoneNumberEntityId: boolean },
  ) => void
  phoneMenuLoading?: boolean
}

interface PhoneMenuCreateModalState {
  selectedType: PhoneMenuType | null
  name: string
  selectedNumber: string | null
  numberIsEntity: boolean
  nameValidationState: ValidationState | null
  phoneNumberValidationState: ValidationState | null
  canValidate: boolean
}

export default class PhoneMenuCreateModal extends React.Component<
  PhoneMenuCreateModalProps,
  PhoneMenuCreateModalState
> {
  static selectedTypeToDisplay(selectedType: PhoneMenuType) {
    return selectedType === PhoneMenuType.Basic ? 'Basic' : 'Advanced'
  }

  constructor(props: PhoneMenuCreateModalProps) {
    super(props)
    this.state = this.initialState()
  }

  componentDidUpdate(prevProps: PhoneMenuCreateModalProps) {
    if (!prevProps.show && this.props.show) {
      this.setState(this.initialState())
    }
  }

  initialState(): PhoneMenuCreateModalState {
    return {
      name: '',
      selectedType: null,
      selectedNumber: null,
      numberIsEntity: false,
      phoneNumberValidationState: null,
      nameValidationState: null,
      canValidate: false,
    }
  }

  selectBasic = () => this.setState({ selectedType: PhoneMenuType.Basic })
  selectAdvanced = () => this.setState({ selectedType: PhoneMenuType.Advanced })

  onNameChanged = (name: string) => {
    this.setState({
      name,
      nameValidationState: this.state.canValidate
        ? nameValidationHandler.validate(name)
        : null,
    })
  }

  onSelectedNumberChanged = (
    selectedNumber: string,
    isPhoneNumberEntity: boolean,
  ) => {
    this.setState({
      selectedNumber,
      numberIsEntity: isPhoneNumberEntity,
      phoneNumberValidationState: this.state.canValidate
        ? phoneNumberSelectedValidationHandler.validate(selectedNumber)
        : null,
    })
  }

  createPhoneMenu = () => {
    const nameValidationState = nameValidationHandler.validate(this.state.name)
    const phoneNumberValidationState = phoneNumberSelectedValidationHandler.validate(
      this.state.selectedNumber ?? null,
    )

    this.setState({
      canValidate: true,
      nameValidationState,
      phoneNumberValidationState,
    })

    if (
      nameValidationState.valid &&
      phoneNumberValidationState.valid &&
      this.state.selectedType &&
      this.state.selectedNumber
    ) {
      this.props.createPhoneMenu(
        {
          name: this.state.name,
          type: this.state.selectedType,
        },
        {
          phoneNumber: this.state.selectedNumber,
          isPhoneNumberEntityId: this.state.numberIsEntity,
        },
      )
    }
  }

  renderTypeSelection() {
    return (
      <>
        <ModalLayout.Row>
          <DescriptiveAction
            title="Advanced Phone Menu"
            desc="Callers can select an option using the dialpad"
            onClick={this.selectAdvanced}
            icon={<NumpadSVG />}
          />
        </ModalLayout.Row>
        <ModalLayout.LightHorizontalLine />
        <ModalLayout.Row>
          <DescriptiveAction
            title="Basic Phone Menu"
            desc="Callers are forwarded to specific users"
            onClick={this.selectBasic}
            icon={<UsersSVG />}
          />
        </ModalLayout.Row>
      </>
    )
  }

  renderDetails() {
    const {
      name,
      selectedNumber,
      phoneNumberValidationState,
      nameValidationState,
    } = this.state
    const { phoneMenuLoading } = this.props

    return (
      <>
        <ModalLayout.FieldRow
          label="Name"
          labelWidth="150px"
          shouldPadRight
          validationState={nameValidationState}>
          <TextInput
            value={name}
            autoFocus
            placeholder="Enter a Phone Menu Name"
            onChange={this.onNameChanged}
            validationState={nameValidationState}
            data-cy="phone-menu-name-input"
          />
        </ModalLayout.FieldRow>
        <ModalLayout.LightHorizontalLine />
        <NumberPurchaserContainer
          labelWidth="150px"
          selectedNumber={selectedNumber ?? undefined}
          validation={phoneNumberValidationState}
          onSelectedNumberChanged={this.onSelectedNumberChanged}
          enableSelectUnassigned
        />
        <ModalLayout.FooterBigButton
          actionText={
            phoneMenuLoading ? (
              <SpinnerMaterial
                color={colors.lightGray}
                strokeWidth={5}
                size="25px"
              />
            ) : (
              'Create Phone Menu'
            )
          }
          onAction={this.createPhoneMenu}
          actionDisabled={phoneMenuLoading}
        />
      </>
    )
  }

  render() {
    const { show, onClose, phoneMenuLoading } = this.props
    const { selectedType } = this.state
    return (
      <PopupModal
        show={show}
        width="575px"
        onRequestClose={phoneMenuLoading ? () => false : onClose}
        hideOnBgClick
        showExitButton>
        <ModalLayout.Container>
          <ModalLayout.Content>
            <ModalLayout.Header
              title={
                selectedType
                  ? `New ${PhoneMenuCreateModal.selectedTypeToDisplay(
                      selectedType,
                    )} Phone Menu`
                  : `New Phone Menu`
              }
            />
            <ModalLayout.HorizontalLine />
            {selectedType ? this.renderDetails() : this.renderTypeSelection()}
          </ModalLayout.Content>
        </ModalLayout.Container>
      </PopupModal>
    )
  }
}
