import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReduxToastr from 'react-redux-toastr'
import { Provider } from 'react-redux'
import { createHashHistory, History } from 'history'
import 'url-search-params-polyfill'
import App from './App'
import './index.css'
import { configureStore } from './store/configureStore'
import { Store } from 'redux'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { setApiStore } from './utils/HTTP'
import { GlobalStyle, ThemeProvider } from 'js-components'
import UnsavedChangesDialogContainer from './containers/UnsavedChangesDialogContainer'
import { ConnectedRouter } from 'connected-react-router'
import { withErrorBoundary } from './utils/bugsnag'
import LogRocket from 'logrocket'
import { ConnectedFlagsProvider } from './utils/flags'

if (!window.trulyEnv.disable_analytics) {
  LogRocket.init('ieosrx/truly-web-app')
}

const history = createHashHistory()
const store = configureStore(history)
setApiStore(store)

interface RootProps {
  store: Store
  history: History
}

declare let module: any

class Root extends React.Component<RootProps> {
  App = App

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      if (module.hot) {
        module.hot.accept('./App', async () => {
          console.warn('react hot update')
          this.App = (await import('./App')).default
          this.forceUpdate()
        })
      }
    }
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <div>
          <GlobalStyle />
          <ConnectedRouter history={this.props.history}>
            <ConnectedFlagsProvider>
              <ThemeProvider theme={{}}>
                <this.App />
              </ThemeProvider>
            </ConnectedFlagsProvider>
          </ConnectedRouter>
          <ReduxToastr
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            preventDuplicates
          />
          <UnsavedChangesDialogContainer />
        </div>
      </Provider>
    )
  }
}

ReactDOM.render(
  withErrorBoundary(<Root store={store} history={history} />),
  document.getElementById('root') as HTMLElement,
)
