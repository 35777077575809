import { IState } from '../../store'
import { createSelector } from 'reselect'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'

export const base = (state: IState) => state.phoneMenus
export const phoneMenuMap = (state: IState) => base(state).phoneMenuMap
export const phoneMenus = createSelector(
  phoneMenuMap,
  menus => menus && sortBy(values(menus), pm => pm.name.toLowerCase()),
)
export const allNodeMap = (state: IState) => base(state).nodeMap
export const allNodes = createSelector(
  allNodeMap,
  nodes => nodes && values(nodes),
)
export const phoneMenuById = (id: number | null) => (state: IState) => {
  const map = base(state).phoneMenuMap
  return id && map && map[id]
}
export const nodeById = (id: number) => (state: IState) => {
  const map = base(state).nodeMap
  return map && map[id]
}
export const phoneMenuLoading = (state: IState) => base(state).phoneMenuLoading
