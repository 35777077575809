import {
  IPhoneMenu,
  INode,
  NodeType,
  IForwardingNode,
  INonForwardingNode,
  PhoneMenuType,
} from 'truly-ts'
import { iterableToArray } from '../Redux'

export interface AssignableNodeOption {
  title: string
  subtitle: string
  phoneMenuId: number
  phoneMenuType: PhoneMenuType
  node: IForwardingNode | INonForwardingNode
}

export function assignableNodeOptionsFromPhoneMenus(
  phoneMenus: IPhoneMenu[],
): AssignableNodeOption[] {
  return iterableToArray(iterableOptionsFromPhoneMenus(phoneMenus))
}

function* iterableOptionsFromPhoneMenus(
  phoneMenus: IPhoneMenu[],
): IterableIterator<AssignableNodeOption> {
  for (const pm of phoneMenus) {
    if (pm.type === 'basic') {
      yield {
        title: pm.name,
        subtitle: 'Basic Phone Menu',
        node: pm.tree,
        phoneMenuId: pm.id,
        phoneMenuType: pm.type,
      }
    } else {
      // can't yield root node because it can't be assigned
      yield* iterableOptionsFromNode(pm.id, pm.tree, pm.name)
    }
  }
}

function* iterableOptionsFromNode(
  phoneMenuId: number,
  node: INode,
  phoneMenuName: string,
  parentNode?: INode,
): IterableIterator<AssignableNodeOption> {
  if (
    parentNode &&
    (node.type === NodeType.ForwardCall || node.type === NodeType.SendMessage)
  ) {
    if (parentNode.type === NodeType.RootNode) {
      yield {
        title: `${phoneMenuName}: ${node.pre_select_text_to_speech}`,
        subtitle: 'Advanced Phone Menu',
        node,
        phoneMenuId,
        phoneMenuType: PhoneMenuType.Advanced,
      }
    } else {
      yield {
        title: `${parentNode.pre_select_text_to_speech} (Submenu): ${node.pre_select_text_to_speech}`,
        subtitle: phoneMenuName,
        node,
        phoneMenuId,
        phoneMenuType: PhoneMenuType.Advanced,
      }
    }
  }
  if (node.children) {
    for (const child of node.children) {
      yield* iterableOptionsFromNode(phoneMenuId, child, phoneMenuName, node)
    }
  }
}

/*
ROOT
title => <Phone Menu Name>: <Node Label>
subtitle => Advanced Phone Menu

SUBMENU
title => <Submenu Label> (Submenu): <Node Label>
subtitle => <Phone Menu Name>
*/
