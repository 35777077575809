import * as React from 'react'
import find from 'lodash/find'
import { RouteComponentProps } from 'react-router'
import {
  LoaderWrapper,
  IFrameContainer,
  SelectWrapper,
  Container,
  NoReportsWrapper,
} from './Styles'
import { IDashboard } from 'truly-ts'
import {
  FlexColumn,
  Select,
  EmptyResults,
  Button,
  FlexRow,
  colors,
  spaceSizes,
  RefreshSVG,
} from 'js-components'
import ContentHeading, {
  RightHeadingControl,
} from '../ContentHeading/ContentHeading'
import Loader from '../Loader/Loader'
import PageTitle from '../PageTitle/PageTitle'
import { ReportsTitle } from '../../constants/PageTitles'

interface ReportsProps extends RouteComponentProps {
  loadDashboards: () => void
  openChatSupport: () => void
  dashboards?: IDashboard[]
}

interface ReportsState {
  selectedDashboard: IDashboard | null
  refresherIsLoading: boolean
  refreshStartTime: number
  refreshProgress: number
  reportTitle: string
}

export default class Reports extends React.Component<
  ReportsProps,
  ReportsState
> {
  private refresher: any
  private refreshButtonProgress: any
  private iframeRef = React.createRef<HTMLIFrameElement>()

  constructor(props: ReportsProps) {
    super(props)

    this.state = {
      selectedDashboard: null,
      refreshProgress: 1,
      refresherIsLoading: false,
      refreshStartTime: 0,
      reportTitle: ReportsTitle,
    }
  }

  componentDidMount() {
    this.props.loadDashboards()
  }

  componentDidUpdate(prevProps: ReportsProps) {
    if (
      this.props.dashboards !== prevProps.dashboards &&
      this.props.dashboards &&
      this.props.dashboards.length > 0 &&
      !this.state.selectedDashboard
    ) {
      this.setState({
        selectedDashboard: this.props.dashboards[0],
      })

      this.checkForRefresh(this.props.dashboards[0])
    }
  }

  componentWillUnmount() {
    this.stopRefresher()
    this.stopRefreshButtonProgress()
  }

  stopRefresher = () => {
    if (this.refresher) {
      clearInterval(this.refresher)
      this.refresher = null
    }
  }

  stopRefreshButtonProgress = () => {
    if (this.refreshButtonProgress) {
      clearInterval(this.refreshButtonProgress)
      this.refreshButtonProgress = null
    }
  }

  runRefresher = () => {
    if (!this.refresher) {
      this.refresher = setInterval(this.refreshDashboard, 10 * 1000) // 10 seconds
    }
  }

  checkForRefresh = (selectedDashboard?: IDashboard | null) => {
    if (
      selectedDashboard &&
      selectedDashboard.name.toLowerCase().indexOf('wallboard') >= 0
    ) {
      this.runRefresher()
    } else {
      this.stopRefresher()
    }
  }

  refreshDashboard = () => {
    if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
      this.iframeRef.current.contentWindow.postMessage(
        { event_type: 'refresh_charts' },
        '*',
      )
      console.log('attempting to refresh dashboard')
    }
  }

  onIframeLoad = () => {
    this.refreshDashboard()
    this.checkForRefresh(this.state.selectedDashboard)
  }

  onSelectChanged = (value: string) => {
    const dashboard = find(this.props.dashboards, { id: parseInt(value, 10) })
    this.setState({
      selectedDashboard: dashboard || null,
      reportTitle: dashboard
        ? `${dashboard.name} | ${ReportsTitle}`
        : ReportsTitle,
    })
    this.checkForRefresh(dashboard)
  }

  onRefreshManually = () => {
    this.refreshDashboard()

    this.setState(
      {
        refreshProgress: 0,
        refresherIsLoading: true,
        refreshStartTime: new Date().getTime(),
      },
      () => {
        this.refreshButtonProgress = setInterval(() => {
          const currentTime = new Date().getTime()
          const timeDiff = (currentTime - this.state.refreshStartTime) / 1000

          if (timeDiff >= 60) {
            this.stopRefreshButtonProgress()
            this.setState({
              refreshProgress: 1,
              refresherIsLoading: false,
            })
          } else {
            this.setState({ refreshProgress: timeDiff / 60 })
          }
        }, 1000)
      },
    )
  }

  render() {
    const { dashboards } = this.props
    const {
      selectedDashboard,
      refreshProgress,
      refresherIsLoading,
      reportTitle,
    } = this.state

    if (!dashboards) {
      return (
        <FlexColumn>
          <PageTitle title={reportTitle} />
          <ContentHeading title={ReportsTitle} />
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </FlexColumn>
      )
    }

    if (dashboards.length === 0) {
      return (
        <Container>
          <PageTitle title={reportTitle} />
          <ContentHeading title={ReportsTitle} />
          <NoReportsWrapper>
            <EmptyResults
              title="No Reports... Yet."
              actionText="Contact Support"
              onActionClick={this.props.openChatSupport}
            />
          </NoReportsWrapper>
        </Container>
      )
    }

    return (
      <Container>
        <PageTitle title={reportTitle} />
        <ContentHeading
          title={ReportsTitle}
          rightControl={
            <RightHeadingControl>
              <FlexRow>
                <FlexColumn mr={`${spaceSizes.sm}px`}>
                  <Button
                    value="Refresh"
                    size="medium"
                    color={colors.accentLavender}
                    progress={refresherIsLoading ? refreshProgress : undefined}
                    progressColor={colors.trulyDark}
                    disabled={refresherIsLoading}
                    onClick={this.onRefreshManually}
                    icon={<RefreshSVG />}
                  />
                </FlexColumn>
                <SelectWrapper>
                  <Select
                    keyField="id"
                    labelField="name"
                    options={dashboards}
                    value={
                      selectedDashboard ? `${selectedDashboard.id}` : undefined
                    }
                    onChange={this.onSelectChanged}
                  />
                </SelectWrapper>
              </FlexRow>
            </RightHeadingControl>
          }
        />
        {selectedDashboard && (
          <IFrameContainer>
            <iframe
              onLoad={this.onIframeLoad}
              src={selectedDashboard.url}
              width="100%"
              height="100%"
              frameBorder="0"
              seamless
              title="ReportFrame"
              ref={this.iframeRef}
            />
          </IFrameContainer>
        )}
      </Container>
    )
  }
}
