import { combineReducers } from 'redux'
import { IAction, IState } from '../store'
import auth from './auth/auth'
import { reducer as toastrReducer } from 'react-redux-toastr'
import user from './user/userReducer'
import lgSettings from './lgSettings/lgSettingsReducer'
import phoneNumbers from './phoneNumbers/phoneNumbersReducer'
import phoneMenus from './phoneMenus/phoneMenusReducer'
import invites from './invites/invitesReducer'
import extensions from './extensions/extensionsReducer'
import externalUsers from './externalUsers/externalUsersReducer'
import reports from './reports/reportsReducer'
import forms from './forms/formsReducer'
import roles from './roles/rolesReducer'
import navigation from './navigation/navigationReducer'
import credentials from './credentials/credentialsReducer'
import conferenceRooms from './conferenceRooms/conferenceRoomsReducer'
import messages from './messages/messagesReducer'
import accounts from './accounts/accountsReducer'
import webhooks from './webhooks/webhooksReducer'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { createFlagsReducer, DefaultFlags } from '../utils/flags'
import blockedNumbers from './blockedNumbers/blockedNumbersReducer'

const appReducer = (history: History) => {
  const reducers = {
    auth,
    conferenceRooms,
    credentials,
    extensions,
    externalUsers,
    forms,
    invites,
    lgSettings,
    messages,
    navigation,
    phoneMenus,
    phoneNumbers,
    reports,
    roles,
    user,
    accounts,
    webhooks,
    blockedNumbers,
    router: connectRouter(history),
    toastr: toastrReducer,
    flags: createFlagsReducer(DefaultFlags),
  }

  return combineReducers({ ...reducers })
}

export default function getRootReducer(history: History) {
  return (state: IState, action: IAction) => {
    return appReducer(history)(state, action)
  }
}
