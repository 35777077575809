import { ILicenseGroupAccount } from 'truly-ts'

import {
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAIL,
  ACCOUNT_UPDATED,
  ACCOUNT_DELETED,
  ACCOUNT_ADDED,
} from './actionTypes'

import {
  fetchAccountsSuccess,
  accountUpdated,
  accountDeleted,
  accountAdded,
} from './actionCreators'
import { createReducer } from 'truly-utils'
import keyBy from 'lodash/keyBy'
import {
  PHONE_NUMBER_ASSIGNED,
  PHONE_NUMBER_UNASSIGNED,
} from '../phoneNumbers/actionTypes'
import {
  phoneNumberAssigned,
  phoneNumberUnassigned,
} from '../phoneNumbers/actionCreators'

export interface AccountsState {
  loading: boolean
  accounts?: {
    [id: string]: ILicenseGroupAccount
  }
}

const INITIAL_STATE: AccountsState = {
  loading: false,
}

const accounts = createReducer<AccountsState>(INITIAL_STATE, {
  [FETCH_ACCOUNTS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_ACCOUNTS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchAccountsSuccess>,
  ) => {
    const mappedAccounts = keyBy(action.payload.accounts, 'id')
    return {
      ...state,
      loading: false,
      accounts: mappedAccounts,
    }
  },
  [FETCH_ACCOUNTS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [ACCOUNT_UPDATED]: (state, action: ReturnType<typeof accountUpdated>) => ({
    ...state,
    accounts: {
      ...state.accounts,
      [action.payload.account.id]: action.payload.account,
    },
  }),
  [ACCOUNT_DELETED]: (state, action: ReturnType<typeof accountDeleted>) => {
    const accountsState = { ...state.accounts }
    delete accountsState[action.payload.account.id]

    return {
      ...state,
      accounts: accountsState,
    }
  },
  [ACCOUNT_ADDED]: (state, action: ReturnType<typeof accountAdded>) => {
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [action.payload.account.id]: action.payload.account,
      },
    }
  },
  [PHONE_NUMBER_UNASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberUnassigned>,
  ) => {
    const { entityType, entityId, e164Number } = action.payload
    if (entityType !== 'account' || !state.accounts) return state
    const account = state.accounts[entityId]
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [entityId]: {
          ...account,
          phone_numbers: (account.phone_numbers || []).filter(
            number => number.number !== e164Number,
          ),
        },
      },
    }
  },
  [PHONE_NUMBER_ASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberAssigned>,
  ) => {
    const { entityType, entityId, phoneNumber } = action.payload
    if (entityType !== 'account' || !state.accounts) return state
    const account = state.accounts[entityId]
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [entityId]: {
          ...account,
          phone_numbers: [
            ...(account.phone_numbers || []),
            {
              ...phoneNumber,
              number: phoneNumber.full_number,
              supports_sms: phoneNumber.sms_capable,
              entity_description: 'Direct Line',
            },
          ],
        },
      },
    }
  },
})

export default accounts
