import styled from 'styled-components'
import { spaceSizes, FlexColumn, colors, Regular } from 'js-components'
import React from 'react'

export const LoaderWrapper = styled.div`
  margin-top: 200px;
`

export const TitleWrapper = styled(FlexColumn)`
  padding-left: ${spaceSizes.sm}px;
  padding-bottom: ${spaceSizes.md}px;
  flex: 0 0 auto;
`

const LabelWrapper = styled.div<{ width?: string }>`
  margin-right: 14px;
  margin-left: 6px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
  ${props => (props.width ? `width: ${props.width};` : '')}
`

export const CredentialLabel: React.SFC<{
  children: React.ReactNode
  width?: string
}> = ({ children, width }) => (
  <LabelWrapper width="90px">
    <Regular bold color={colors.trulyDark}>
      {children}
    </Regular>
  </LabelWrapper>
)
