import { connect } from 'react-redux'
import PhoneMenuCreateModal from '../components/PhoneMenuCreateModal/PhoneMenuCreateModal'
import { createPhoneMenu } from '../reducers/phoneMenus/actionCreators'
import { IState } from '../store'
import * as phoneMenusSelectors from '../reducers/phoneMenus/phoneMenusSelectors'

const mapStateToProps = (state: IState) => ({
  phoneMenuLoading: phoneMenusSelectors.phoneMenuLoading(state),
})

const mapDispatchToProps = {
  createPhoneMenu,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneMenuCreateModal)
