import {
  FETCH_API_CREDENTIALS,
  FETCH_API_CREDENTIALS_SUCCESS,
  FETCH_API_CREDENTIALS_FAIL,
  DELETE_API_CREDENTIAL,
  UPDATE_API_CREDENTIAL,
} from './actionTypes'

import {
  fetchApiCredentialsSuccess,
  deleteAPICredential,
  updateAPICredential,
} from './actionCreators'

import { IAPICredential } from 'truly-ts'
import { createReducer } from 'truly-utils'

export interface CredentialState {
  credentials?: IAPICredential[]
  loading: boolean
}

const INITIAL_STATE: CredentialState = {
  loading: false,
}

const credentials = createReducer<CredentialState>(INITIAL_STATE, {
  [FETCH_API_CREDENTIALS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_API_CREDENTIALS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchApiCredentialsSuccess>,
  ) => ({
    ...state,
    credentials: action.payload.credentials,
    loading: false,
  }),
  [FETCH_API_CREDENTIALS_FAIL]: state => ({
    credentials: undefined,
    loading: false,
  }),
  [DELETE_API_CREDENTIAL]: (
    state,
    action: ReturnType<typeof deleteAPICredential>,
  ) => ({
    ...state,
    credentials: state.credentials?.filter(
      c => c.app_id !== action.payload.credential.app_id,
    ),
  }),
  [UPDATE_API_CREDENTIAL]: (
    state,
    action: ReturnType<typeof updateAPICredential>,
  ) => ({
    ...state,
    credentials: state.credentials?.map(c => {
      if (c.app_id === action.payload.credential.app_id) {
        return action.payload.credential
      }
      return c
    }),
  }),
})

export default credentials
