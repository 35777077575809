import React, { useMemo, useCallback } from 'react'
import memoize from 'lodash/memoize'
import { Container, RightControlContainer, OptionsWrapper } from './Styles'
import {
  colors,
  FlexRow,
  Fab,
  Add,
  Large,
  DropdownMenuOption,
  DropdownMenu,
  Link,
  OptionsDots,
  Caret,
  InlineTextEdit,
  Title3,
} from 'js-components'
import { devAssert } from 'truly-utils/macro'
import { push } from 'connected-react-router'
import { useOpenCloseState, useActionCreator } from 'truly-utils'

const textMargin = '8px'

type HeadingSize = 'large' | 'small'

function textComponentFromSize(size?: HeadingSize) {
  return size === 'small' ? Large : Title3
}

export interface ContentHeadingBacklink {
  label: string
  url?: string
  onClick?: () => void
}

interface ContentHeadingProps {
  title: string
  textSize?: HeadingSize
  rightControl?: React.ReactNode
  backlinks?: ContentHeadingBacklink[]
  collapse?: boolean
  editableTitle?: boolean
  onTitleChanged?: (title: string) => void
}

export const ContentHeading: React.FC<ContentHeadingProps> = props => {
  const {
    textSize = 'large',
    backlinks,
    rightControl,
    title,
    collapse,
    editableTitle,
    onTitleChanged,
  } = props
  const TextComponent = textComponentFromSize(textSize)
  const caretSize = textSize === 'small' ? '16px' : '26px'

  devAssert(assert =>
    assert(
      !editableTitle || (editableTitle && textSize === 'small'),
      'If the title is editable, it must be small size',
    ),
  )

  const navigate = useActionCreator(push)
  const linkClicked = useCallback(
    (backlink: ContentHeadingBacklink) => {
      if (backlink.onClick) backlink.onClick()
      if (backlink.url) navigate(backlink.url)
    },
    [navigate],
  )

  const onSaveTitleEdit = useCallback(
    (newTitle: string) => {
      if (onTitleChanged) onTitleChanged(newTitle)
    },
    [onTitleChanged],
  )

  const collapsableBacklinks = useMemo(() => {
    if (backlinks && backlinks.length > 2 && collapse) {
      return [
        backlinks[0],
        backlinks.slice(1, backlinks.length - 1),
        backlinks[backlinks.length - 1],
      ]
    }
    return backlinks
  }, [backlinks, collapse])

  return (
    <Container data-cy="content-heading">
      <FlexRow flex={1} justifyContent="space-between" alignItems="center">
        <FlexRow justifyContent="flex-start" alignItems="center">
          {collapsableBacklinks &&
            collapsableBacklinks.map((backlink, idx) => [
              Array.isArray(backlink) ? (
                <CollapsedBacklinkComponent
                  key={backlink[0].url || idx}
                  textSize={textSize}
                  backlinks={backlink}
                  onClick={linkClicked}
                />
              ) : (
                <BacklinkComponent
                  key={backlink.url || idx}
                  textSize={textSize}
                  backlink={backlink}
                  onClick={linkClicked}
                />
              ),
              <div
                key={`caret-${
                  (Array.isArray(backlink) ? backlink[0].url : backlink.url) ||
                  idx
                }`}
                style={{ flex: '0 0 auto' }}>
                <Caret
                  color={colors.trulyDark}
                  size={caretSize}
                  orientation="right"
                />
              </div>,
            ])}
          {editableTitle ? (
            <InlineTextEdit
              originalValue={title}
              required
              large
              style={{ flex: '1 1 auto' }}
              onSave={onSaveTitleEdit}
            />
          ) : (
            <TextComponent
              color={colors.trulyDark}
              ml={textMargin}
              style={{ flex: '0 0 auto' }}>
              {title}
            </TextComponent>
          )}
        </FlexRow>
        {rightControl}
      </FlexRow>
    </Container>
  )
}

const BacklinkComponent: React.FC<{
  backlink: ContentHeadingBacklink
  textSize?: HeadingSize
  onClick: (b: ContentHeadingBacklink) => void
}> = props => {
  const { backlink, textSize, onClick } = props
  const TextComponent = textComponentFromSize(textSize)

  const onClickLink = useCallback(() => onClick(backlink), [onClick, backlink])

  return (
    <Link
      color={colors.trulyDark}
      onClick={onClickLink}
      style={{ flex: '0 0 auto' }}>
      <TextComponent mr={textMargin} ml={textMargin}>
        {backlink.label}
      </TextComponent>
    </Link>
  )
}

const CollapsedBacklinkComponent: React.FC<{
  backlinks: ContentHeadingBacklink[]
  textSize?: HeadingSize
  onClick: (b: ContentHeadingBacklink) => void
}> = props => {
  const { backlinks, textSize, onClick } = props
  const TextComponent = textComponentFromSize(textSize)

  const anchor = React.useRef()
  const [showDropdown, openDropdown, closeDropdown] = useOpenCloseState(false)
  const options = useMemo(
    () =>
      backlinks.map(
        (b): DropdownMenuOption => ({
          color: colors.trulyDark,
          label: b.label,
          onClick: () => onClick(b),
        }),
      ),
    [backlinks, onClick],
  )

  return (
    <Link
      color={colors.trulyDark}
      onClick={openDropdown}
      style={{ flex: '0 0 auto' }}>
      <DropdownMenu
        show={showDropdown}
        placement="bottom"
        onClose={closeDropdown}
        options={options}
        minWidth="200px">
        <TextComponent ref={anchor} mr={textMargin} ml={textMargin}>
          ...
        </TextComponent>
      </DropdownMenu>
    </Link>
  )
}

interface HeadingAddButtonProps {
  onClick?: () => void
  accessibilityLabel?: string
  options?: DropdownMenuOption[]
  minWidth?: string
}

interface HeadingAddButtonState {
  showOptions: boolean
}

export class HeadingAddButton extends React.PureComponent<
  HeadingAddButtonProps,
  HeadingAddButtonState
> {
  renderAddIcon = memoize((props: any) => (
    <Add color={props.fill} width="100%" />
  ))

  constructor(props: HeadingDropdownMenuProps) {
    super(props)

    this.state = {
      showOptions: false,
    }
  }

  showOptions = () => this.setState({ showOptions: true })
  hideOptions = () => this.setState({ showOptions: false })

  render() {
    const { options } = this.props

    return (
      <>
        <RightControlContainer>
          <OptionsWrapper onClick={this.showOptions} role="button">
            <Fab
              data-cy="add-new-button"
              size="large"
              iconColor={colors.white}
              iconFactory={this.renderAddIcon}
              accessibilityLabel={this.props.accessibilityLabel}
              onClick={this.props.onClick}
              gradient={{
                from: colors.jordyBlue,
                to: colors.accentLavender,
              }}
            />
            {!!options && (
              <DropdownMenuOptions
                show={this.state.showOptions}
                onClose={this.hideOptions}
                options={options}
                minWidth={this.props.minWidth || '300px'}>
                <div />
              </DropdownMenuOptions>
            )}
          </OptionsWrapper>
        </RightControlContainer>
      </>
    )
  }
}

interface HeadingDropdownMenuProps {
  options: DropdownMenuOption[]
  minWidth?: string
}

interface HeadingDropdownMenuState {
  showOptions: boolean
}

export class HeadingDropdownMenu extends React.PureComponent<
  HeadingDropdownMenuProps,
  HeadingDropdownMenuState
> {
  constructor(props: HeadingDropdownMenuProps) {
    super(props)

    this.state = {
      showOptions: false,
    }
  }

  showOptions = () => this.setState({ showOptions: true })
  hideOptions = () => this.setState({ showOptions: false })

  render() {
    return (
      <RightControlContainer>
        <OptionsWrapper onClick={this.showOptions} role="button">
          <OptionsDots fill={colors.darkGray} height="18px" />
          <DropdownMenuOptions
            show={this.state.showOptions}
            onClose={this.hideOptions}
            options={this.props.options}
            minWidth={this.props.minWidth || '300px'}>
            <div />
          </DropdownMenuOptions>
        </OptionsWrapper>
      </RightControlContainer>
    )
  }
}

interface DropdownMenuOptionsProps {
  show: boolean
  onClose: () => void
  options: DropdownMenuOption[]
  minWidth: string
  children: React.ReactNode
}

const DropdownMenuOptions = (props: DropdownMenuOptionsProps) => (
  <DropdownMenu
    show={props.show}
    onClose={props.onClose}
    options={props.options}
    minWidth={props.minWidth}
    placement="bottom-end">
    {props.children}
  </DropdownMenu>
)

export class RightHeadingControl extends React.Component {
  render() {
    return <RightControlContainer>{this.props.children}</RightControlContainer>
  }
}

export default ContentHeading
