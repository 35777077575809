import styled from 'styled-components/macro'
import { FlexRow, FlexColumn, colors } from 'js-components'

export const UsersContainer = styled(FlexRow)`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`

export const UserListContainer = styled(FlexColumn)`
  max-height: 100%;
  height: 100%;
  min-width: 336px;
  width: 336px;
  border-right: 1px solid ${colors.lightGray};
`

export const UserListHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 58px;
  max-height: 58px;
  border-bottom: 1px solid ${colors.lightGray};
`

export const UserListWrapper = styled(FlexColumn)`
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
`

export const UserDetailContainer = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: 12px;
`

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const UserDetailWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 8px;
`

export const FilterWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 60%;
`

export const TeamSelectorWrapper = styled.div`
  padding-right: 10px;
  width: 40%;
`

export const UserRowContainer = styled(FlexRow)<{
  last: boolean
  isSelected: boolean
}>`
  align-items: center;
  padding: 12px 32px 12px 32px;
  word-wrap: none;
  width: 336px;
  height: 75px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  ${props =>
    !props.last && `border-bottom: 1px solid ${colors.ultraLightGray};`}
  ${props => props.isSelected && `background-color: ${colors.ultraLightGray};`}
  &:hover {
    background-color: ${colors.ultraLightGray};
  }
  &,
  * {
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`

export const UserRowTextContainer = styled.div`
  margin: 0;
  padding: 0;
`

export const NotFoundTextContainer = styled.div`
  padding: 20px;
`
