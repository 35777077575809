import { FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAIL } from './actionTypes'
import { LG_COMPANY_CHANGED } from '../lgSettings/actionTypes'

import { IUserAccount } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { fetchUserSuccess, fetchUserFail } from './actionCreators'
import { lgCompanyChanged } from '../lgSettings/actionCreators'

export interface UserState {
  account: IUserAccount
  company: string
  error: string | null
  loading: boolean
}

const INITIAL_STATE: UserState = {
  account: {},
  company: '',
  error: null,
  loading: false,
}

const user = createReducer<UserState>(INITIAL_STATE, {
  [FETCH_USER]: state => ({
    ...state,
    error: null,
    loading: true,
  }),
  [FETCH_USER_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchUserSuccess>,
  ) => ({
    ...state,
    account: action.payload.account,
    company: action.payload.licenseGroup.company,
    error: null,
    loading: false,
  }),
  [FETCH_USER_FAIL]: (state, action: ReturnType<typeof fetchUserFail>) => ({
    ...state,
    error: action.payload.error.message,
    loading: false,
  }),
  [LG_COMPANY_CHANGED]: (
    state,
    action: ReturnType<typeof lgCompanyChanged>,
  ) => ({
    ...state,
    company: action.payload,
  }),
})

export default user
