import React from 'react'
import { DeleteDialog } from 'js-components'
import { IConferenceRoom } from 'truly-ts'

interface ConferenceRoomDeleteDialogProps {
  show: boolean
  onClose: (deleted: boolean) => void
  selectedRoom?: IConferenceRoom
  onDelete: () => void
}

const ConferenceRoomDeleteDialog: React.FC<ConferenceRoomDeleteDialogProps> = (
  props: ConferenceRoomDeleteDialogProps,
) => {
  const { show, selectedRoom } = props

  const onDelete = () => {
    props.onDelete()
    props.onClose(true)
  }

  const onClose = () => props.onClose(false)

  return (
    <DeleteDialog
      show={show}
      title={
        selectedRoom
          ? `Delete "${selectedRoom.name}"?`
          : `No Conference Room Selected`
      }
      deleteActionText="Delete"
      description="The conference room will be deleted. Phone numbers assigned to this conference room will be unassigned. This action cannot be undone."
      onCancel={onClose}
      onDelete={onDelete}
      confirmText="DELETE"
    />
  )
}

export default ConferenceRoomDeleteDialog
