import React, { useState } from 'react'
import { LoginModal, colors } from 'js-components'
import { ContainerBG, Container, ButtonHeader } from './Styles'
import {
  useLoginFlow,
  useNumberChooser,
  objectToQueryParamString,
  useActionCreator,
} from 'truly-utils'
import trulyApiFactory from 'truly-api'
import { currentTrulyApi, authTrulyApi } from '../../utils/HTTP'
import { loggedIn } from '../../reducers/auth/actionCreators'
import { ReactComponent as AdminSVG } from '../../common/icons/admin.svg'
import { ReactComponent as LogoSVG } from '../../common/icons/logo.svg'
import { toastr } from 'react-redux-toastr'
import Loader from '../Loader/Loader'

const apiClient = trulyApiFactory({
  axios: currentTrulyApi,
})

const authClient = trulyApiFactory({
  axios: authTrulyApi,
})

const Login: React.FC = () => {
  const onLoggedIn = useActionCreator(loggedIn)
  const [waitingForAuth, setWaitingForAuth] = useState(true)
  const {
    loading,
    loginFlowCallbacks,
    step,
    userEmail,
    defaultParams,
  } = useLoginFlow(
    {
      me: apiClient.accounts.me,
      gatekeeper: apiClient.me.getGatekeeperSettings,
      forgotPassword: authClient.login.forgotPasssword,
      updateAccountWithDetails: apiClient.login.updateAccountWithDetails,
      updatePassword: apiClient.login.updatePassword,
      purchasePhoneNumber: apiClient.lg.purchasePhoneNumber,
      assignPhoneNumber: apiClient.lg.assignPhoneNumber,
      signupViaEmail: authClient.login.signupEmail,
      updateCompanyName: apiClient.lg.updateCompanyName,
      validatePassword: authClient.accounts.validatePassword,
    },
    {
      loggedIn: acct => {
        onLoggedIn(acct)
      },
      canChoosePhoneNumber: false,
      redirectForgotPasswordBack: true,
      allowSignup: true,
      signUpUrl: window.trulyEnv.signup_url,
      trulyAuthUrl: window.trulyEnv.truly_auth_url,
      startOAuth: (url, queryParams) => {
        window.location.href = `${url}?${objectToQueryParamString(queryParams)}`
        return false
      },
      onError: msg => {
        setWaitingForAuth(false)
        toastr.error('An Error Occurred', msg)
      },
      onReady: () => {
        setWaitingForAuth(false)
      },
    },
  )
  const { numberChooser } = useNumberChooser({
    searchPhoneNumbers: apiClient.lg.searchPhoneNumber,
  })

  return (
    <ContainerBG>
      <Container>
        {waitingForAuth ? (
          <Loader color={colors.white} />
        ) : (
          <LoginModal
            loading={loading}
            defaultParams={defaultParams}
            step={step}
            loginFlowCallbacks={loginFlowCallbacks}
            numberChooser={numberChooser}
            userEmail={userEmail}
            header={
              <ButtonHeader>
                <LogoSVG
                  height="40px"
                  width="100px"
                  fill={colors.accentPurple}
                  className="logo"
                />
                <AdminSVG
                  height="30px"
                  width="80px"
                  fill={colors.accentPurple}
                  className="admin"
                />
              </ButtonHeader>
            }
          />
        )}
      </Container>
    </ContainerBG>
  )
}

export default Login
