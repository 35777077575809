import styled from 'styled-components/macro'
import {
  FlexRow,
  Card,
  FlexColumn,
  colors,
  Button,
  Regular,
  Small,
  Avatar,
  spaceSizes,
} from 'js-components'

export const ModalCard = styled(Card)`
  padding: 0;
  width: 332px;
`

export const InputWrapper = styled.div`
  width: 100%;
  padding: 14px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGray};
`
export const ResultsContainer = styled(FlexColumn)`
  width: 100%;
  /* padding: 0 15px 10px 15px; */
  /* text-align: center; */
  max-height: 300px;
  overflow-y: scroll;
`

export const AccountRow = styled(FlexRow)`
  align-items: center;
  padding: 12px 22px 12px 14px;
  border-bottom: 1px solid ${colors.ultraLightGray};
`

export const ContentSection = styled(FlexRow)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
`

export const UserButton = styled(Button)`
  font-weight: bolder;
`

export const NameColumn = styled(FlexColumn)`
  overflow-x: hidden;
`

export const AccountName = styled(Regular)`
  font-size: 14px;
  color: ${colors.trulyDark};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TeamSubtitle = styled(Small)`
  color: ${colors.trulyDark};
  font-size: 10px;
  margin-top: 2px;
`

export const AccountAvatar = styled(Avatar)`
  padding-right: ${spaceSizes.sm}px;
`
