import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import {
  all,
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  FETCH_MESSAGES,
  DELETE_MESSAGE,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
} from './actionTypes'
import {
  fetchMessages,
  fetchMessagesSuccess,
  fetchMessagesFail,
  deleteMessage,
  messageRemoved,
  messageAdded,
  createMessageFail,
  createMessage,
  messageUpdated,
  updateMessage,
  updateMessageFail,
} from './actionCreators'
import { IMessage } from 'truly-ts'
import { toastr } from 'react-redux-toastr'
import { IState } from '../../store'
import { updatePhoneMenu } from '../phoneMenus/actionCreators'
import { PHONE_MENU_UPDATED } from '../phoneMenus/actionTypes'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* messagesSaga() {
  yield all([
    takeLatest(FETCH_MESSAGES, fetchMessagesSaga),
    takeEvery(CREATE_MESSAGE, createMessageSaga),
    takeEvery(DELETE_MESSAGE, deleteMessageSaga),
    takeEvery(UPDATE_MESSAGE, updateMessageSaga),
  ])
}

function* fetchMessagesSaga() {
  try {
    const req = yield call(client.messages.fetchMessages)
    yield put(fetchMessagesSuccess(req.data.messages as IMessage[]))
  } catch (e) {
    console.error('fetching messages', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get voice messages. Please refresh the page.',
    )
    yield put(fetchMessagesFail())
  }
}

function* createMessageSaga(action: ReturnType<typeof createMessage>) {
  const { title, file } = action.payload

  try {
    const newMessage = yield call(client.messages.createMessage, title, file)
    yield put(messageAdded(newMessage))
  } catch (e) {
    console.error('creating phone menu', e)
    toastr.error(
      'An Error Occurred',
      'Unable to create phone menu, Please try again.',
    )
    yield put(createMessageFail())
  }
}

function* updateMessageSaga(action: ReturnType<typeof updateMessage>) {
  const { message, file, id } = action.payload

  yield put(messageUpdated(message)) // optimistic
  try {
    yield call(client.messages.updateMessage, id, message, file)
    yield put(fetchMessages())
  } catch (e) {
    console.error('creating phone menu', e)
    toastr.error(
      'An Error Occurred',
      'Unable to create phone menu, Please try again.',
    )
    yield put(updateMessageFail())
  }
}

function* deleteMessageSaga(action: ReturnType<typeof deleteMessage>) {
  const message = action.payload.message
  const phoneMenus = action.payload.phoneMenus
  if (!message.id) {
    throw new Error("Can't delete phone menu message message with no ID")
  }

  yield put(messageRemoved(message)) // optimistic

  if (phoneMenus) {
    for (let x = 0; x < phoneMenus.length; x++) {
      yield put(updatePhoneMenu(phoneMenus[x]))
      yield take(PHONE_MENU_UPDATED)
    }
  }

  try {
    yield call(client.messages.deleteMessage, message.id)
    const path = yield select((state: IState) => state.router.location.pathname)
    if (path !== '/phone-menus') {
      yield put(push('/phone-menus'))
    }
  } catch (e) {
    console.error(`deleting phone menu ${action.payload.message.id}`, e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete phone menu. Please try again.',
    )
    yield put(messageAdded(message)) // revert
  }
}
