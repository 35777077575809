// Note: Similar hook exists in web-onboarding. Will need DRY'd after switching redux hooks
import { push, replace } from 'connected-react-router'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IState } from '../../store'
import { useActionCreator } from 'truly-utils'

export default function useRouter(preserveSearch?: boolean) {
  const navPush = useActionCreator(push)
  const navReplace = useActionCreator(replace)
  const search = useSelector((state: IState) => state.router.location.search)
  const pushCallback = useCallback(
    (pathname: string) =>
      preserveSearch
        ? navPush({
            pathname,
            search,
          })
        : navPush(pathname),
    [navPush, preserveSearch, search],
  )
  const replaceCallback = useCallback(
    (pathname: string) =>
      preserveSearch
        ? navReplace({
            pathname,
            search,
          })
        : navReplace(pathname),
    [navReplace, preserveSearch, search],
  )

  return {
    push: pushCallback,
    replace: replaceCallback,
  }
}
