import React, { useState } from 'react'
import { useActionCreator } from 'truly-utils'
import {
  ModalLayout,
  colors,
  TextInput,
  InlineBold,
  EmptyResults,
  InlineDialog,
} from 'js-components'
import { useSelector } from 'react-redux'

import {
  InputWrapper,
  ModalCard,
  ResultsContainer,
  AccountRow,
  UserButton,
  AccountName,
  TeamSubtitle,
  NameColumn,
  ContentSection,
  AccountAvatar,
} from './AddUserModalStyles'
import { ReactComponent as EmailSVG } from 'js-components/src/components/Icons/Email.svg'
import { patchAccountRole } from '../../reducers/accounts/actionCreators'
import * as accountsSelectors from '../../reducers/accounts/accountsSelectors'
import * as rolesSelectors from '../../reducers/roles/rolesSelectors'
import * as authSelectors from '../../reducers/auth/authSelectors'
import { ILicenseGroupAccount, IRole } from 'truly-ts'
import TeamUtils from '../../utils/model-utils/team-utils'
import Permissions from '../../constants/Permissions'

interface AddUserModalProps {
  show: boolean
  onClose: () => void
  team: IRole
}

const AddUserModal: React.FC<AddUserModalProps> = props => {
  const accounts = useSelector(accountsSelectors.accounts)
  const teams = useSelector(rolesSelectors.roles)
  const onPatchAccount = useActionCreator(patchAccountRole)
  const [searchValue, setSearchValue] = useState('')
  const [isEmail, setIsEmail] = useState(false)
  const loggedInAccount = useSelector(authSelectors.account)

  const handleInputChange = (value: string) => {
    // TODO: reimplement email invite functionality
    // if (value === '') {
    // 	setIsEmail(false);
    // } else if (isValidEmail(value)) {
    // 	setIsEmail(true);
    // }
    setIsEmail(false)
    setSearchValue(value)
  }

  const handleTeamAssign = (account: ILicenseGroupAccount) => () => {
    onPatchAccount(account, props.team.id)
  }

  const getTeamName = (roleId: number) => {
    const selectedTeam = teams?.find(team => team.id === roleId)
    return selectedTeam?.display_name
  }

  const onEmailInviteClick = () => {
    alert('not implemented')
  }

  const renderEmail = () => {
    const accountByEmail = accounts?.find(
      account => account.email === searchValue,
    )

    if (accountByEmail) {
      const fullname = `${accountByEmail.first_name} ${accountByEmail.last_name}`
      return (
        <AccountRow key={accountByEmail.id}>
          <ContentSection>
            <AccountAvatar
              colorSeed={fullname}
              size="medium"
              text={fullname}
              noPattern
            />
            <NameColumn>
              <AccountName>
                {accountByEmail.first_name} {accountByEmail.last_name}
              </AccountName>
            </NameColumn>
          </ContentSection>
        </AccountRow>
      )
    }
    return (
      <AccountRow>
        <ContentSection>
          <AccountAvatar size="medium" icon={<EmailSVG height="100%" />} />
          <NameColumn>
            <AccountName>{searchValue}</AccountName>
            <TeamSubtitle>
              <InlineBold>send invite</InlineBold>
            </TeamSubtitle>
          </NameColumn>
        </ContentSection>
        <UserButton
          value="Invite"
          color={colors.accentLavender}
          round
          onClick={onEmailInviteClick}
        />
      </AccountRow>
    )
  }

  const renderAccounts = () => {
    let filteredAccounts: ILicenseGroupAccount[] | null
    const valueLowerCase = searchValue.toLowerCase().trim()
    if (searchValue === '' || !accounts) {
      filteredAccounts = null
    } else {
      filteredAccounts = accounts.filter(account => {
        const fullName = `${account.first_name
          .toLowerCase()
          .trim()} ${account.last_name.toLowerCase().trim()}`

        // Filters out if user is part of current team. Filters on their first, last and email.
        return (
          account.role_id !== props.team.id &&
          (fullName.includes(valueLowerCase) ||
            account.email.toLowerCase().trim().includes(valueLowerCase))
        )
      })
    }

    return filteredAccounts && filteredAccounts.length ? (
      filteredAccounts.map(account => {
        const fullname = `${account.first_name} ${account.last_name}`.trim()
        const blockReassign =
          loggedInAccount?.id === account.id &&
          !TeamUtils.isPermissionEnabled(props.team, Permissions.AccessAdmin)
        return (
          <AccountRow key={account.id}>
            <ContentSection>
              <AccountAvatar
                colorSeed={fullname}
                size="medium"
                text={fullname}
                noPattern
              />
              <NameColumn>
                <AccountName>{fullname || account.email}</AccountName>
                {blockReassign && (
                  <TeamSubtitle>
                    cannot be assigned without the team having admin access
                  </TeamSubtitle>
                )}
                {!blockReassign && !!account.role_id && (
                  <TeamSubtitle>
                    will be reassigned from{' '}
                    <InlineBold>{getTeamName(account.role_id)}</InlineBold> Team
                  </TeamSubtitle>
                )}
              </NameColumn>
            </ContentSection>
            {account.role_id ? (
              <UserButton
                disabled={blockReassign}
                value="Reassign"
                color={colors.darkGray}
                onClick={handleTeamAssign(account)}
                round
              />
            ) : (
              <UserButton
                disabled={blockReassign}
                value="Assign"
                color={colors.accentLavender}
                onClick={handleTeamAssign(account)}
                round
              />
            )}
          </AccountRow>
        )
      })
    ) : (
      <EmptyResults title="No Users found" />
    )
  }

  const { show, onClose, children } = props
  return (
    <InlineDialog
      visible={show}
      onRequestClose={onClose}
      placement="bottom-end"
      horizontalOffset={12}
      content={
        <ModalCard>
          <ModalLayout.Container>
            <ModalLayout.Content>
              <InputWrapper>
                <TextInput
                  placeholder="Add Users by Name or E-Mail"
                  value={searchValue}
                  onChange={handleInputChange}
                  hideClearButton
                  data-cy="add-user-select"
                />
              </InputWrapper>
              <ResultsContainer>
                {isEmail ? renderEmail() : renderAccounts()}
              </ResultsContainer>
            </ModalLayout.Content>
          </ModalLayout.Container>
        </ModalCard>
      }>
      {children}
    </InlineDialog>
  )
}

export default AddUserModal
