import * as React from 'react'
import { PopupModal, ModalLayout, TextInput } from 'js-components'
import { IBlockedNumber } from 'truly-ts'
import styled from 'styled-components'
import { e164Number } from 'truly-utils'

interface BlockedNumberCreateModalProps {
  show: boolean
  onClose: () => void
  addBlockedNumber: (blockedNumber: IBlockedNumber) => void
}

export default function BlockedNumberCreateModal(
  props: BlockedNumberCreateModalProps,
) {
  const [blockedNumber, setBlockedNumber] = React.useState('')

  const handleChange = (s: string) => {
    setBlockedNumber(s)
  }

  const handleBlockNumber = () => {
    props.addBlockedNumber({
      number: e164Number(blockedNumber) ?? blockedNumber,
      block_action: 'busy',
      block_level: 'licensegroup',
    })
    setBlockedNumber('')
    props.onClose()
  }

  return (
    <PopupModal
      show={props.show}
      width="575px"
      onRequestClose={props.onClose}
      hideOnBgClick
      showExitButton>
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header title={'New Blocked Number'} />
          <ModalLayout.HorizontalLine />
        </ModalLayout.Content>
        <PaddedContainer>
          <TextInput
            value={blockedNumber}
            autoFocus
            placeholder="e.g. (213) 704-5555"
            onChange={handleChange}
            data-cy="blocked-number-name-input"
          />
        </PaddedContainer>
        <ModalLayout.LightHorizontalLine />
        <ModalLayout.FooterBigButton
          actionText={'Block Number'}
          onAction={handleBlockNumber}
        />
      </ModalLayout.Container>
    </PopupModal>
  )
}

const PaddedContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`
