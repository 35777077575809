export const FETCH_API_CREDENTIALS = 'FETCH_API_CREDENTIAL'
export const FETCH_API_CREDENTIALS_SUCCESS = 'FETCH_API_CREDENTIAL_SUCCESS'
export const FETCH_API_CREDENTIALS_FAIL = 'FETCH_API_CREDENTIAL_FAIL'

export const GENERATE_API_CREDENTIAL = 'GENERATE_API_CREDENTIAL'
export const GENERATE_API_CREDENTIAL_SUCCESS = 'GENERATE_API_CREDENTIAL_SUCCESS'
export const GENERATE_API_CREDENTIAL_FAIL = 'GENERATE_API_CREDENTIAL_FAIL'

export const DELETE_API_CREDENTIAL = 'DELETE_API_CREDENTIAL'
export const DELETE_API_CREDENTIAL_SUCCESS = 'DELETE_API_CREDENTIAL_SUCCESS'
export const DELETE_API_CREDENTIAL_FAIL = 'DELETE_API_CREDENTIAL_FAIL'

export const UPDATE_API_CREDENTIAL = 'UPDATE_API_CREDENTIAL'
export const UPDATE_API_CREDENTIALL_SUCCESS = 'UPDATE_API_CREDENTIALL_SUCCESS'
export const UPDATE_API_CREDENTIAL_FAIL = 'UPDATE_API_CREDENTIAL_FAIL'
