import { IDataRetentionPolicy, IDataExpiration } from 'truly-ts'
import moment from 'moment'

// Important Note: These should map to moment duration object properties
export type RetentionUnit =
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years'

export interface RetentionPeriod {
  time: number
  unit: RetentionUnit
}

export function createNewDataRetentionPolicy(): IDataRetentionPolicy {
  return {
    phone_calls: null,
    recordings: null,
    sms: null,
  }
}

export function createDefaultRetentionPeriod(): RetentionPeriod {
  return { time: 30, unit: 'days' }
}

export function createNewDataExpiration(): IDataExpiration {
  return {
    retention_period: stringifyRetentionPeriod(createDefaultRetentionPeriod()),
  }
}

export function parseRetentionPeriod(
  retentionPeriod?: string | null,
): RetentionPeriod {
  if (!retentionPeriod) {
    return { time: 0, unit: 'hours' }
  }
  const duration = moment.duration(retentionPeriod)
  // If zero length, use default of hours
  if (duration.asMilliseconds() !== 0) {
    // Use highest unit that comes out to round number
    if (Number.isInteger(duration.asYears()))
      return { time: duration.asYears(), unit: 'years' }
    if (Number.isInteger(duration.asMonths()))
      return { time: duration.asMonths(), unit: 'months' }
    if (Number.isInteger(duration.asWeeks()))
      return { time: duration.asWeeks(), unit: 'weeks' }
    if (Number.isInteger(duration.asDays()))
      return { time: duration.asDays(), unit: 'days' }
    if (Number.isInteger(duration.asHours()))
      return { time: duration.asHours(), unit: 'hours' }
    if (Number.isInteger(duration.asMinutes()))
      return { time: duration.asMinutes(), unit: 'minutes' }
  }
  return { time: 0, unit: 'hours' }
}

export function stringifyRetentionPeriod(
  retentionPeriod: RetentionPeriod,
): string {
  return moment
    .duration({
      [retentionPeriod.unit]: retentionPeriod.time,
    })
    .toISOString()
}

export function isZeroDuration(retentionPeriod: string): boolean {
  const duration = moment.duration(retentionPeriod)
  return duration.asMilliseconds() === 0
}

export const UnitSelectOptions: Array<{
  value: RetentionUnit
  label: string
}> = [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
  {
    value: 'months',
    label: 'Months',
  },
  {
    value: 'years',
    label: 'Years',
  },
]
