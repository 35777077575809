import React from 'react'
import { INode, NodeType, MessageType, ValidationState } from 'truly-ts'
import noop from 'js-components/dist/src/utils/noop'
import { getMessageFromNode } from './node-utils'
import { ValidationHandler } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { ensureDefined } from '../utils'

// --- Base validation state for all
export interface NodeValidationState {
  validationType?: NodeType
  isAdvanced?: boolean
  showErrors: boolean
}

// --- Advanced validation state only
export interface AdvancedPhoneMenuNodeValidationState
  extends NodeValidationState {
  label: ValidationState
}

// -- Forwarding node state - Basic root node or advanced forwarding node
export interface ForwardingNodeValidationState
  extends NodeValidationState,
    NodeRulesValidationSubState,
    NodeCFOptionsValidationSubState {
  huntGroups: HuntGroupValidationSubState[]
  wrapup: ValidationState
}

// --- Send to voicemail state for advanced nodes
export interface SendToVoicemailValidationState extends NodeValidationState {
  customizeVoicemail: ValidationState
  userTeams: ValidationState
}

// --- Play recording state for advanced nodes
export interface PlayRecordingValidationState extends NodeValidationState {
  playRecording: ValidationState
}

// --- Sub-states to compose the others

export interface HuntGroupValidationSubState {
  userTeams: ValidationState
  queueMaxTime: ValidationState
  queueTimeout: ValidationState
  ringCycles: ValidationState
}

export interface NodeRulesValidationSubState {
  nodeMissedWithVM: {
    email: ValidationState
  }
  nodeMissedWithoutVM: {
    email: ValidationState
  }
}

export interface NodeCFOptionsValidationSubState {
  callerId: ValidationState
  playRecording: ValidationState
  customizeVoicemail: ValidationState
}

// --- End sub-states

export type NodeValidationContextValue<
  T extends NodeValidationState = NodeValidationState
> = {
  validationState: T
  validationChange: (update: Partial<T>) => void
}

// Context to handle communicating validation state
export const NodeValidationContext = React.createContext<NodeValidationContextValue>(
  {
    validationState: { showErrors: false },
    validationChange: noop,
  },
)

// Validates message exists
export const makeNodeMessageValidator = (type: MessageType, msg: string) =>
  new ValidationHandler<INode>(
    msg,
    node => !node || !!getMessageFromNode(node, type),
  )

// Check if a node is valid by its validation state
export function isNodeValid(validationState: NodeValidationState) {
  // check advanced first
  if (
    validationState.isAdvanced &&
    !isAdvancedValid(validationState as AdvancedPhoneMenuNodeValidationState)
  ) {
    return false
  }

  const type = getRequired(validationState.validationType)
  switch (type) {
    case NodeType.RootNode:
    case NodeType.ForwardCall:
      return isForwardCallNodeValid(
        validationState as ForwardingNodeValidationState,
      )
    case NodeType.PlayRecording:
      return isPlayRecordingNodeValid(
        validationState as PlayRecordingValidationState,
      )
    case NodeType.SendMessage:
      return isSendToVoicemailNodeValid(
        validationState as SendToVoicemailValidationState,
      )
    default:
      return true
  }
}

function isAdvancedValid(
  validationState: AdvancedPhoneMenuNodeValidationState,
) {
  return ensureDefined(validationState.label).valid
}

function isForwardCallNodeValid(
  validationState: ForwardingNodeValidationState,
) {
  ensureDefined(validationState.callerId)
  ensureDefined(validationState.customizeVoicemail)
  ensureDefined(validationState.huntGroups)
  ensureDefined(validationState.nodeMissedWithVM)
  ensureDefined(validationState.nodeMissedWithVM.email)
  ensureDefined(validationState.nodeMissedWithoutVM)
  ensureDefined(validationState.nodeMissedWithoutVM.email)
  ensureDefined(validationState.playRecording)
  ensureDefined(validationState.wrapup)

  return (
    validationState.callerId.valid &&
    validationState.customizeVoicemail.valid &&
    validationState.huntGroups.every(g => {
      return (
        ensureDefined(g.queueTimeout).valid &&
        ensureDefined(g.queueMaxTime).valid &&
        ensureDefined(g.ringCycles).valid &&
        ensureDefined(g.userTeams).valid
      )
    }) &&
    validationState.nodeMissedWithVM.email.valid &&
    validationState.nodeMissedWithoutVM.email.valid &&
    validationState.playRecording.valid &&
    validationState.wrapup.valid
  )
}

function isPlayRecordingNodeValid(
  validationState: PlayRecordingValidationState,
) {
  return ensureDefined(validationState.playRecording).valid
}

function isSendToVoicemailNodeValid(
  validationState: SendToVoicemailValidationState,
) {
  return (
    ensureDefined(validationState.userTeams).valid &&
    ensureDefined(validationState.customizeVoicemail).valid
  )
}
