import axios from 'axios'
import trulyApiFactory from 'truly-api'
import btoa from 'btoa'
import { get } from 'lodash'
import debounce from 'lodash/debounce'
import { apiUrl, authUrl } from '../constants/Config'
import { logout } from '../reducers/auth/actionCreators'
import { Store } from '../store'

const TIMESTAMP_HEADER = 'truly-timestamp'

let apiStore: Store | null = null

// any here is a hack for flow forgetting types with spread syntax https://github.com/facebook/flow/issues/3775
const addHeader = (config: any, name: string, value: string | number): any => ({
  ...config,
  headers: {
    ...config.headers,
    common: {
      ...config.headers.common,
      [name]: value,
    },
  },
})

const generateBasicAuthentication = (token: string) =>
  'Basic '.concat(btoa(' :'.concat(token)))
const addTimestamp = (config: any) => {
  const now = new Date()
  const tzOffset = now.getTimezoneOffset() * 60000
  const timestamp = now.getTime() - tzOffset
  return addHeader(config, TIMESTAMP_HEADER, timestamp)
}
const addAuthorization = (config: any, token: string | null) =>
  token
    ? addHeader(config, 'Authorization', generateBasicAuthentication(token))
    : config

// This is very arbitrary, could be increaased or decreased.
const minTimeForNewToast = 1000

// This is debounced because a session failing is likely to have a lot of other
// requests coming back around the same time.

export const dispatchWarningAndLogout = debounce(
  dispatch => {
    dispatch(logout())
  },
  minTimeForNewToast,
  { leading: true, trailing: false },
)

export const addAuthorizationHeaderInterceptor = (store: Store, config: any) =>
  addAuthorization(config, store.getState().auth.token)

export const addTimestampToRequest = (store: Store, config: any) =>
  addTimestamp(config)

const onError = (error: Error) => {
  const store = apiStore
  if (!store) {
    throw new Error('API Store has not been set in error')
  }

  const isUnauthorized = get(error, 'response.status') === 401

  if (isUnauthorized) {
    const wasLogged = store.getState().auth.isAuthenticated

    if (wasLogged) {
      dispatchWarningAndLogout(store.dispatch)
    }
  }

  return Promise.reject(error)
}

const mapStoreInterceptor = (
  interceptor: (store: Store, config: any) => any,
) => (config: any) => {
  if (!apiStore) {
    throw new Error('API Store not set yet')
  }
  return interceptor(apiStore, config)
}

export const currentTrulyApi = axios.create({
  baseURL: apiUrl,
  responseType: 'json',
  withCredentials: true,
})

export const authTrulyApi = axios.create({
  baseURL: authUrl,
  responseType: 'json',
  withCredentials: true,
})

// Interceptors have to be setup right away or they won't work. Appears to be an axios bug.
currentTrulyApi.interceptors.request.use(
  mapStoreInterceptor(addAuthorizationHeaderInterceptor),
  onError,
)
currentTrulyApi.interceptors.request.use(
  mapStoreInterceptor(addTimestampToRequest),
  onError,
)
currentTrulyApi.interceptors.response.use(response => response, onError)

export const setApiStore = (store: Store) => (apiStore = store)

const defaultTrulyClient = trulyApiFactory({
  axios: currentTrulyApi,
})

export default defaultTrulyClient
