import {
  IInvite,
  IPhoneMenu,
  INode,
  EntityType,
  ILicenseGroupAccount,
  IStatus,
  IConferenceRoom,
  IExtension,
  IExternalForward,
} from 'truly-ts'
import each from 'lodash/each'
import values from 'lodash/values'
import { devAssert } from 'truly-utils/macro'

export type EntityMap = {
  inviteMap?: { [k: number]: IInvite }
  phoneMenuMap?: { [k: number]: IPhoneMenu }
  nodeMap?: { [k: number]: INode }
  conferenceRoomMap?: { [k: number]: IConferenceRoom }
  accountMap?: { [k: number]: ILicenseGroupAccount }
  extensionMap?: { [k: number]: IExtension }
  externalUserMap?: { [k: number]: IExternalForward }
}

export type EntityOption = {
  name: string
  description: string
  entity_type: EntityType
  entity_id: number
  isInvite?: boolean
}

export const entityMapper: { [k in EntityType]: keyof EntityMap } = {
  conference_phone: 'conferenceRoomMap',
  account: 'accountMap',
  invite: 'inviteMap',
  node: 'nodeMap',
  phonemenu: 'phoneMenuMap',
  extension: 'extensionMap',
  external_forward: 'externalUserMap',
}

const entityNameMapFn: { [k in EntityType]: (entity: any) => string } = {
  conference_phone: (e: IConferenceRoom) => e.name,
  account: (e: ILicenseGroupAccount) =>
    e.first_name ? `${e.first_name} ${e.last_name}` : e.email,
  invite: (e: IInvite) => e.email,
  node: (e: INode) =>
    e.pre_select_text_to_speech
      ? `Phone Menu Action: ${e.pre_select_text_to_speech}`
      : 'Node',
  phonemenu: (e: IPhoneMenu) => e.name,
  extension: (e: IExtension) => e.name,
  external_forward: (e: IExternalForward) => e.name,
}

export const getEntityName = (
  entityMap: EntityMap,
  entityType: EntityType,
  entityId: number,
) => {
  const entityKey = entityMapper[entityType]
  const entities = entityMap[entityKey]
  if (!entities) return ''
  const entity = entities[entityId]
  if (!entity) return ''
  return entityNameMapFn[entityType](entity)
}

export const hasEntity = (
  entityMap: EntityMap,
  entityType: EntityType,
  entityId: number,
) => {
  const entities = entityMap[entityMapper[entityType]]
  if (!entities) return false
  const entity = entities[entityId]
  return !!entity
}

export const entityMapChanged = (
  prevEntities: EntityMap,
  nextEntities: EntityMap,
) => {
  return (
    prevEntities.conferenceRoomMap !== nextEntities.conferenceRoomMap ||
    prevEntities.inviteMap !== nextEntities.inviteMap ||
    prevEntities.nodeMap !== nextEntities.nodeMap ||
    prevEntities.phoneMenuMap !== nextEntities.phoneMenuMap ||
    prevEntities.accountMap !== nextEntities.accountMap ||
    prevEntities.extensionMap !== nextEntities.extensionMap ||
    prevEntities.externalUserMap !== nextEntities.externalUserMap
  )
}

export const entitiesToOptions = (entities: EntityMap): EntityOption[] => {
  const result: EntityOption[] = []

  if (entities.accountMap) {
    each(
      values(entities.accountMap),
      acct =>
        acct.id &&
        result.push({
          name: entityNameMapFn.account(acct),
          description: 'Account',
          entity_type: 'account',
          entity_id: acct.id,
          isInvite: acct.status === IStatus.Invited,
        }),
    )
  }

  if (entities.conferenceRoomMap) {
    each(
      values(entities.conferenceRoomMap),
      conf =>
        conf.id &&
        result.push({
          name: entityNameMapFn.conference_phone(conf),
          description: 'Conference Room',
          entity_type: 'conference_phone',
          entity_id: conf.id,
        }),
    )
  }

  if (entities.externalUserMap) {
    each(
      values(entities.externalUserMap),
      ext =>
        ext.id &&
        result.push({
          name: entityNameMapFn.external_forward(ext),
          description: 'External User',
          entity_type: 'external_forward',
          entity_id: parseInt(ext.id, 10),
        }),
    )
  }

  if (entities.inviteMap) {
    each(
      values(entities.inviteMap),
      i =>
        i.id &&
        result.push({
          name: entityNameMapFn.invite(i),
          description: 'Invited',
          entity_type: 'invite',
          entity_id: i.id,
          isInvite: true,
        }),
    )
  }

  if (entities.phoneMenuMap) {
    each(
      values(entities.phoneMenuMap),
      p =>
        p.id &&
        result.push({
          name: entityNameMapFn.phonemenu(p),
          description: 'Phone Menu',
          entity_type: 'phonemenu',
          entity_id: p.id,
        }),
    )
  }

  if (entities.nodeMap) {
    each(
      values(entities.nodeMap),
      n =>
        n.id &&
        result.push({
          name: entityNameMapFn.node(n),
          description: 'Phone Menu Action',
          entity_type: 'node',
          entity_id: n.id,
        }),
    )
  }

  devAssert(assert =>
    assert(!entities.extensionMap, 'Extensions not supported as options'),
  )

  return result
}
