import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import {
  all,
  takeLatest,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import {
  FETCH_PHONE_MENUS,
  CREATE_PHONE_MENU,
  DELETE_PHONE_MENU,
  UPDATE_PHONE_MENU,
  UPDATE_NODE,
  UPDATE_NODE_ASSIGNMENTS_FOR_TEAM,
  UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION,
} from './actionTypes'
import {
  fetchPhoneMenusSuccess,
  fetchPhoneMenusFail,
  createPhoneMenu,
  phoneMenuAdded,
  deletePhoneMenu,
  phoneMenuRemoved,
  updatePhoneMenu,
  phoneMenuUpdated,
  updateNode,
  updateNodeAssignmentsForTeam,
  updateNodeAssignmentsForExtension,
  createPhoneMenuFail,
} from './actionCreators'
import { toastr } from 'react-redux-toastr'
import differenceBy from 'lodash/differenceBy'
import groupBy from 'lodash/groupBy'
import {
  IPhoneNumber,
  IPhoneMenu,
  IForwardingNode,
  INonForwardingNode,
  INode,
} from 'truly-ts'
import * as selectors from './phoneMenusSelectors'
import * as roleSelectors from '../roles/rolesSelectors'
import * as extensionSelectors from '../extensions/extensionsSelectors'
import {
  takeLatestKeyed,
  mapIRoleToINodeRole,
  mapIExtensionToINodeExtension,
} from 'truly-utils'
import { devAssert } from 'truly-utils/macro'
import { IState } from '../../store'
import {
  replaceNode,
  replacedNodes,
  zipNodes,
} from '../../utils/model-utils/node-utils'
import { push, createMatchSelector, replace } from 'connected-react-router'
import { fetchExtensionsSaga } from '../extensions/extensionsSaga'
import {
  phoneNumberAdded,
  assignPhoneNumber,
  fetchPhoneNumbers,
} from '../phoneNumbers/actionCreators'

const phoneMenuPathMatcher = createMatchSelector(
  '/phone-menus/:id/submenu/:nodeId',
)

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* phoneMenusSaga() {
  yield all([
    takeLatest(FETCH_PHONE_MENUS, fetchPhoneMenusSaga),
    takeEvery(CREATE_PHONE_MENU, createPhoneMenuSaga),
    takeEvery(DELETE_PHONE_MENU, deletePhoneMenuSaga),
    takeLatestKeyed(
      UPDATE_PHONE_MENU,
      updatePhoneMenuSaga,
      (action: ReturnType<typeof updatePhoneMenu>) =>
        `${action.payload.phoneMenu.id}`,
    ),
    takeLatest(
      UPDATE_NODE_ASSIGNMENTS_FOR_TEAM,
      updateNodeAssignmentsForTeamSaga,
    ),
    takeLatest(
      UPDATE_NODE_ASSIGNMENTS_FOR_EXTENSION,
      updateNodeAssignmentsForExtensionSaga,
    ),
    takeLatestKeyed(
      UPDATE_NODE,
      updateNodeSaga,
      (action: ReturnType<typeof updateNode>) => `${action.payload.node.id}`,
    ),
  ])
}

export function* fetchPhoneMenusSaga() {
  try {
    const req = yield call(client.lg.fetchPhoneMenus, true)
    yield put(fetchPhoneMenusSuccess(req.data.phonemenus))
  } catch (e) {
    console.error('fetching phone menus', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get phone menus. Please refresh the page.',
    )
    yield put(fetchPhoneMenusFail())
  }
}

function* updatePhoneMenuSaga(action: ReturnType<typeof updatePhoneMenu>) {
  if (!action.payload.phoneMenu.id) {
    throw new Error("Can't update updatePhoneMenuSaga with no ID")
  }

  const existingPhoneMenu = (yield select(
    selectors.phoneMenuById(action.payload.phoneMenu.id),
  )) as IPhoneMenu
  devAssert(assert => assert(existingPhoneMenu, `Phone menu doesn't exist`))
  devAssert(assert =>
    assert(
      existingPhoneMenu !== action.payload.phoneMenu,
      'Phone menu was mutated',
    ),
  )

  const phoneMenu: IPhoneMenu = {
    ...action.payload.phoneMenu,
    phonenumbers: (action.payload.phoneMenu.phonenumbers || []).map(
      pn =>
        ({
          ...pn,
          entity: {
            type: 'phonemenu',
            id: action.payload.phoneMenu.id,
          },
          // TODO hack because of atomic update, instead of calling API
          entity_type: 'phonemenu',
          entity_id: action.payload.phoneMenu.id,
        } as IPhoneNumber),
    ),
  }

  try {
    const result = yield call(client.lg.updatePhoneMenu, phoneMenu, true)

    // Get the current submenu page
    const match = yield select(phoneMenuPathMatcher)
    const currentPathNodeId = match?.params?.nodeId
      ? parseInt(match.params.nodeId, 10)
      : null

    yield put(phoneMenuUpdated(result.data))

    // If the node in view was a fake negative id, we need to replace the path to use the new node id
    if (currentPathNodeId && currentPathNodeId < 0) {
      // zip old and new tree to match old nodes with new nodes
      const zippedNodes = zipNodes(phoneMenu.tree, result.data.tree)
      const activeNode = zippedNodes.find(
        nodes => nodes.nodeA && nodes.nodeA.id === currentPathNodeId,
      )
      if (activeNode && activeNode.nodeB) {
        yield put(
          replace(
            `/phone-menus/${phoneMenu.id}/submenu/${activeNode.nodeB.id}`,
          ),
        )
      } else {
        console.warn(
          'Could not accurately navigate to new node id, navigating back to root of phone menu.',
        )
        yield put(replace(`/phone-menus/${phoneMenu.id}`))
      }
    }

    yield put(fetchPhoneNumbers()) // update phone numbers, instead of trying to find the diff and update state
  } catch (e) {
    console.error(`updating phone menu ${phoneMenu.id}`, e)
    toastr.error(
      'An Error Occurred',
      'Unable to update phone menu. Please try again.',
    )
    yield put(phoneMenuUpdated(existingPhoneMenu)) // revert
    yield put(push(`/phone-menus/${phoneMenu.id}`))
  }
}

function* deletePhoneMenuSaga(action: ReturnType<typeof deletePhoneMenu>) {
  const phoneMenu = action.payload.phoneMenu as IPhoneMenu
  try {
    yield call(client.lg.deletePhoneMenu, phoneMenu.id)
    yield put(phoneMenuRemoved(phoneMenu))
    const path = yield select((state: IState) => state.router.location.pathname)
    if (path !== '/phone-menus') {
      yield put(push('/phone-menus'))
    }
  } catch (e) {
    console.error(`deleting phone menu ${action.payload.phoneMenu.id}`, e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete phone menu. Please try again.',
    )
  }
}

function* createPhoneMenuSaga(action: ReturnType<typeof createPhoneMenu>) {
  try {
    let phoneNumber: IPhoneNumber
    if (action.payload.options.isPhoneNumberEntityId) {
      // find the phone number they selected
      const getNumberReq = yield call(client.lg.fetchPhoneNumbers)
      const phoneNumbers = getNumberReq.data.phone_numbers as IPhoneNumber[]
      const foundNumber: IPhoneNumber | undefined = phoneNumbers.find(
        pn => pn.full_number.toString() === action.payload.options.phoneNumber,
      )
      if (!foundNumber)
        throw new Error(
          `Could not find phone number with id ${action.payload.options.phoneNumber}`,
        )

      phoneNumber = foundNumber
    } else {
      // purchase the phone number they selected
      const purchaseReq = yield call(
        client.lg.purchasePhoneNumber,
        action.payload.options.phoneNumber,
      )
      phoneNumber = purchaseReq.data.phone_number
      yield put(phoneNumberAdded(phoneNumber))
    }
    const createReq = yield call(
      client.lg.createPhoneMenu,
      action.payload.phoneMenu,
      true,
    )
    const phoneMenu = createReq.data as IPhoneMenu
    yield put(phoneMenuAdded(phoneMenu))
    yield put(
      assignPhoneNumber(
        'phonemenu',
        phoneMenu.id as number,
        phoneNumber.full_number,
      ),
    )
    yield put(push(`/phone-menus/${phoneMenu.id}`))
  } catch (e) {
    console.error('creating phone menu', e)
    toastr.error(
      'An Error Occurred',
      'Unable to create phone menu, Please try again.',
    )
    yield put(createPhoneMenuFail())
  }
}

function* updateNodeSaga(action: ReturnType<typeof updateNode>) {
  const phoneMenu = (yield select(
    selectors.phoneMenuById(action.payload.phoneMenuId),
  )) as IPhoneMenu
  devAssert(assert => assert(phoneMenu, `Phone menu doesn't exist`))

  const newPhoneMenu: IPhoneMenu = {
    ...phoneMenu,
    tree: replaceNode(phoneMenu.tree, action.payload.node),
  }

  yield put(phoneMenuUpdated(newPhoneMenu)) // optimistic
  try {
    yield call(client.lg.updateNode, action.payload.node)
    yield put(fetchPhoneNumbers()) // update phone numbers, instead of trying to find the diff and update state
  } catch (e) {
    console.error('saving node', e)
    toastr.error(
      'An Error Occurred',
      'Unable to save phone menu changes. Please try again.',
    )
    yield put(phoneMenuUpdated(phoneMenu)) // revert
  }
}

function* updateNodeAssignmentsForTeamSaga(
  action: ReturnType<typeof updateNodeAssignmentsForTeam>,
) {
  const { teamId, nodeIds } = action.payload
  const team = yield select(roleSelectors.roleById(teamId))
  devAssert(assert => assert(team, 'Team not found'))

  const allNodes = (yield select(selectors.allNodes)) as Array<
    IForwardingNode | INonForwardingNode
  >
  const existingAssignments = allNodes.filter(
    node => node.roles && node.roles.some(role => role.id === teamId),
  )
  const newAssignments = allNodes.filter(n => nodeIds.includes(n.id))

  // find all assignments that were added to the team
  const addedAssignments = differenceBy(
    newAssignments,
    existingAssignments,
    'id',
  )
  // find all assignments that were removed from the team
  const removedAssignments = differenceBy(
    existingAssignments,
    newAssignments,
    'id',
  )

  // changed assignments (nodes)
  const updatedAssignments = [
    ...addedAssignments.map(node => ({
      ...node,
      roles: [...(node.roles ?? []), mapIRoleToINodeRole(team, 0)], // assume 0th hunt group
    })),
    ...removedAssignments.map(node => ({
      ...node,
      roles: node.roles?.filter(role => role.id !== team.id),
    })),
  ]

  yield call(updateManyNodes, updatedAssignments)
}

function* updateNodeAssignmentsForExtensionSaga(
  action: ReturnType<typeof updateNodeAssignmentsForExtension>,
) {
  const { extensionId, nodeIds } = action.payload
  let extension = yield select(extensionSelectors.extensionById(extensionId))
  if (!extension) {
    // Extension wasn't found so reload extensions, then try again (may not have been loaded in the first place)
    yield call(fetchExtensionsSaga)
    extension = yield select(extensionSelectors.extensionById(extensionId))
  }
  devAssert(assert => assert(extension, 'Extension not found'))

  const allNodes = (yield select(selectors.allNodes)) as Array<
    IForwardingNode | INonForwardingNode
  >
  const existingAssignments = allNodes.filter(
    node =>
      node.extensions && node.extensions.some(ext => ext.id === extensionId),
  )
  const newAssignments = allNodes.filter(n => nodeIds.includes(n.id))

  // find all assignments that were added to the extension
  const addedAssignments = differenceBy(
    newAssignments,
    existingAssignments,
    'id',
  )
  // find all assignments that were removed from the extension
  const removedAssignments = differenceBy(
    existingAssignments,
    newAssignments,
    'id',
  )

  // changed assignments (nodes)
  const updatedAssignments = [
    ...addedAssignments.map(node => ({
      ...node,
      extensions: [
        ...(node.extensions ?? []),
        mapIExtensionToINodeExtension(extension, 0),
      ], // assume 0th hunt group
    })),
    ...removedAssignments.map(node => ({
      ...node,
      extensions: node.extensions?.filter(ext => ext.id !== extension.id),
    })),
  ]

  yield call(updateManyNodes, updatedAssignments)
}

function* updateManyNodes(manyNodes: INode[]) {
  // group the nodes into phone menus in order to update a phone menu atomically
  const phoneMenuGroupedNodes = Object.values(
    groupBy(manyNodes, assignment => assignment.phonemenu_id),
  )
  yield all(
    phoneMenuGroupedNodes.map(nodes =>
      call(function* () {
        const phoneMenu = (yield select(
          selectors.phoneMenuById(nodes[0].phonemenu_id),
        )) as IPhoneMenu
        devAssert(assert => assert(phoneMenu, `Phone menu doesn't exist`))

        const newPhoneMenu: IPhoneMenu = {
          ...phoneMenu,
          tree: replacedNodes(phoneMenu.tree, nodes),
        }

        yield put(phoneMenuUpdated(newPhoneMenu)) // optimistic

        try {
          // Save all the nodes at the same time (Possibly use newer atomic API soon?)
          yield all(nodes.map(node => call(client.lg.updateNode, node)))
        } catch (e) {
          console.error('saving node updates', phoneMenu, e)
          toastr.error(
            'An Error Occurred',
            `Unable to save changes to ${phoneMenu.name}. Please try again.`,
          )
          yield put(phoneMenuUpdated(phoneMenu)) // revert
        }
      }),
    ),
  )
  yield put(fetchPhoneNumbers()) // update phone numbers, instead of trying to find the diff and update state
}
