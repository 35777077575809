import * as React from 'react'
import {
  Container,
  LoaderWrapper,
  ContentContainer,
  TableFilterWrapper,
  TableWrapper,
  TextDataContainer,
  ActionsDataContainer,
  ActionContainer,
  ActionIconWrapper,
} from './Styles'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { RouteComponentProps } from 'react-router'
import {
  FlexColumn,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  TableRow,
  TableData,
  colors,
  Regular,
  Small,
  ToolTip,
  Trash,
  DeleteDialog,
  Edit,
} from 'js-components'
import Loader from '../Loader/Loader'
import { IExternalForward } from 'truly-ts'
import { formatPhoneNumber, makeSearchFn, SearchFunctions } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import ExternalUserEditModalContainer from '../../containers/ExternalUserEditModalContainer'
import PageTitle from '../PageTitle/PageTitle'
import { ExternalUsersTitle } from '../../constants/PageTitles'

const RowHeight = 75
const CellWidth = '240px'

interface ExternalUsersProps extends RouteComponentProps {
  loadExternalUsers: () => void
  onDelete: (extUser: IExternalForward) => void
  externalUsers?: IExternalForward[]
}

interface ExternalUsersState {
  searchValue: string
  deleting: boolean
  editing: boolean
  selectedExternalUser?: IExternalForward
}

export default class ExternalUsers extends React.Component<
  ExternalUsersProps,
  ExternalUsersState
> {
  constructor(props: ExternalUsersProps) {
    super(props)

    this.state = {
      searchValue: '',
      deleting: false,
      editing: false,
    }
  }

  componentDidMount() {
    this.props.loadExternalUsers()
  }

  onCreateExternalUser = () => {
    this.setState({
      editing: true,
      selectedExternalUser: undefined,
    })
  }

  onEditExternalUser = (extUser: IExternalForward) => {
    this.setState({
      editing: true,
      selectedExternalUser: extUser,
    })
  }

  onDeleteExternalUser = (extUser: IExternalForward) => {
    this.setState({
      deleting: true,
      selectedExternalUser: extUser,
    })
  }

  onSearchInputChanged = (searchValue: string) => {
    this.setState({
      searchValue,
    })
  }

  clearFilters = () => {
    this.setState({
      searchValue: '',
    })
  }

  onConfirmDelete = () => {
    const extUser = getRequired(this.state.selectedExternalUser)
    this.props.onDelete(extUser)
    this.setState({
      deleting: false,
    })
  }

  onCloseDelete = () => {
    this.setState({
      deleting: false,
    })
  }

  onCloseEditing = () => {
    this.setState({
      editing: false,
    })
  }

  getFilteredExternalUsers = () => {
    let externalUsers = getRequired(this.props.externalUsers)
    const { searchValue } = this.state

    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<IExternalForward>(
        [
          'name',
          { field: 'forwarding_number', fn: SearchFunctions.phoneNumberSearch },
        ],
        searchValue,
      )
      externalUsers = externalUsers.filter(searchFn)
    }

    return externalUsers
  }

  isFiltered = () => {
    const { searchValue } = this.state

    return (
      this.props.externalUsers &&
      this.props.externalUsers.length > 0 &&
      !!(searchValue && searchValue.trim())
    )
  }

  render() {
    const { searchValue, deleting, editing, selectedExternalUser } = this.state

    if (!this.props.externalUsers) {
      return (
        <FlexColumn>
          <PageTitle title={ExternalUsersTitle} />
          <ContentHeading title={ExternalUsersTitle} />
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </FlexColumn>
      )
    }

    const externalUsers = this.getFilteredExternalUsers()
    const isFiltered = this.isFiltered()

    return (
      <Container>
        <PageTitle title={ExternalUsersTitle} />
        <DeleteDialog
          show={deleting}
          title={
            selectedExternalUser
              ? `Delete "${selectedExternalUser.name}"`
              : 'No User Selected'
          }
          deleteActionText="Delete"
          description="This external number will be deleted."
          onCancel={this.onCloseDelete}
          onDelete={this.onConfirmDelete}
        />
        <ExternalUserEditModalContainer
          show={editing}
          externalUser={selectedExternalUser}
          onClose={this.onCloseEditing}
        />
        <ContentHeading
          title={ExternalUsersTitle}
          rightControl={
            <HeadingAddButton
              accessibilityLabel="Add External User"
              onClick={this.onCreateExternalUser}
            />
          }
        />
        <ContentContainer>
          <TableFilterWrapper>
            <TableFilterRow
              searchInput
              searchInputValue={searchValue}
              searchPlaceholder="Filter"
              onSearchInputChanged={this.onSearchInputChanged}
              selectedFilterOption="all"
              filterOptions={[
                {
                  value: 'all',
                  label: 'All External Users',
                },
              ]}
            />
          </TableFilterWrapper>
          <TableWrapper>
            <ScrollableTable
              header={<ExternalUsersHeaderRow />}
              rowHeight={RowHeight}
              emptyView={
                <EmptyResults
                  title={
                    isFiltered ? 'No Numbers Found' : 'No Phone Numbers... Yet.'
                  }
                  actionText={
                    isFiltered ? 'Clear All Filters' : 'Add New Phone Number'
                  }
                  onActionClick={
                    isFiltered ? this.clearFilters : this.onCreateExternalUser
                  }
                />
              }
              rowCount={externalUsers.length}
              renderRow={idx => (
                <ExternalUserTableRow
                  key={idx}
                  rowHeight={RowHeight}
                  onEdit={this.onEditExternalUser}
                  onDelete={this.onDeleteExternalUser}
                  externalUser={externalUsers[idx]}
                />
              )}
            />
          </TableWrapper>
        </ContentContainer>
      </Container>
    )
  }
}

class ExternalUsersHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData width={CellWidth} text="Name" />
        <TableHeaderData width={CellWidth} text="Number" />
        <TableHeaderData text="" />
      </TableHeaderRow>
    )
  }
}

class ExternalUserTableRow extends React.PureComponent<
  {
    externalUser: IExternalForward
    rowHeight: number
    onEdit: (extUser: IExternalForward) => void
    onDelete: (extUser: IExternalForward) => void
  },
  { hovering: boolean }
> {
  constructor(props: any) {
    super(props)

    this.state = {
      hovering: false,
    }
  }

  onMouseEnter = () => this.setState({ hovering: true })
  onMouseLeave = () => this.setState({ hovering: false })

  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.externalUser)
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.externalUser)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const {
      externalUser,
      onEdit,
      onDelete,
      rowHeight,
      ...restProps
    } = this.props

    return (
      <TableRow
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        height={rowHeight}
        verticalAlign="middle"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={CellWidth}>
          <TextDataContainer>
            <Regular width={CellWidth} truncate color={colors.trulyDark}>
              {externalUser.name}
            </Regular>
          </TextDataContainer>
        </TableData>
        <TableData width={CellWidth}>
          {(externalUser.forwarding_number || '').trim() ? (
            <Regular color={colors.trulyDark}>
              {formatPhoneNumber(externalUser.forwarding_number)}
            </Regular>
          ) : (
            <Regular color={colors.darkGray}>None</Regular>
          )}
        </TableData>
        <TableData>
          {this.state.hovering && (
            <ActionsDataContainer>
              <ActionContainer>
                <ToolTip
                  enabled
                  orderPreference="bottom"
                  toolTipContent={this.renderTooltipContent('Delete')}>
                  <ActionIconWrapper
                    data-cy="delete-data-button"
                    onClick={this.onDelete}>
                    <Trash width="24" height="24" />
                  </ActionIconWrapper>
                </ToolTip>
              </ActionContainer>
              <ActionContainer>
                <ToolTip
                  enabled
                  orderPreference="bottom"
                  toolTipContent={this.renderTooltipContent('Edit')}>
                  <ActionIconWrapper onClick={this.onEdit}>
                    <Edit width="24" height="24" />
                  </ActionIconWrapper>
                </ToolTip>
              </ActionContainer>
            </ActionsDataContainer>
          )}
        </TableData>
      </TableRow>
    )
  }
}
