import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { IAction } from '../../store'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { FETCH_USER } from './actionTypes'
import { fetchUserSuccess, fetchUserFail } from './actionCreators'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* userSaga() {
  yield all([takeLatest(FETCH_USER, fetchUserInfoSaga)])
}

function* fetchUserInfoSaga(_action: IAction) {
  try {
    const { userReq, lgReq } = yield all({
      userReq: call(client.me.fetchMe),
      lgReq: call(client.lg.fetchLicenseGroup),
    })

    yield put(fetchUserSuccess(userReq.data.account, lgReq.data.license_group))
  } catch (e) {
    yield put(fetchUserFail(e))
  }
}
