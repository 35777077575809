import find from 'lodash/find'
import defaultTrulyClient from '../../utils/HTTP'
import {
  all,
  takeLatest,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import * as selectors from './phoneNumbersSelectors'
import isEqual from 'lodash/isEqual'

import {
  FETCH_PHONE_NUMBERS,
  UPDATE_PHONE_NUMBER,
  SEARCH_PHONE_NUMBERS,
  RELEASE_PHONE_NUMBER,
  ASSIGN_PHONE_NUMBER,
  UNASSIGN_PHONE_NUMBER,
} from './actionTypes'
import {
  fetchPhoneNumbersSuccess,
  fetchPhoneNumbersFail,
  updatePhoneNumber,
  phoneNumberChanged,
  searchPhoneNumbers,
  searchPhoneNumbersSuccess,
  searchPhoneNumbersFail,
  releasePhoneNumber,
  phoneNumberRemoved,
  phoneNumberAdded,
  assignPhoneNumber,
  unassignPhoneNumber,
  phoneNumberAssigned,
  phoneNumberUnassigned,
} from './actionCreators'
import {
  takeLatestKeyed,
  entityTypeToAPIEntity,
  retry,
  RetryHelpers,
} from 'truly-utils'
import { toastr } from 'react-redux-toastr'
import { IPhoneNumber, EntityType } from 'truly-ts'
import { ensureDefined } from '../../utils/utils'
import PhoneNumberUtils from '../../utils/model-utils/phone-number-utils'

export function* phoneNumbersSaga() {
  yield all([
    takeLatest(FETCH_PHONE_NUMBERS, fetchPhoneNumbersSaga),
    takeLatestKeyed(
      UPDATE_PHONE_NUMBER,
      updatePhoneNumberSaga,
      (action: ReturnType<typeof updatePhoneNumber>) =>
        `${action.payload.e164Number}`,
    ),
    takeLatest(SEARCH_PHONE_NUMBERS, searchPhoneNumbersSaga),
    takeEvery(RELEASE_PHONE_NUMBER, releasePhoneNumberSaga),
    takeEvery(ASSIGN_PHONE_NUMBER, assignPhoneNumberSaga),
    takeEvery(UNASSIGN_PHONE_NUMBER, unassignPhoneNumberSaga),
  ])
}

function* fetchPhoneNumbersSaga() {
  try {
    const req = yield call(defaultTrulyClient.lg.fetchPhoneNumbers)

    // TODO: Replace owner_id items when BE API to link to the correct user id is done
    const clientDirectory = yield call(defaultTrulyClient.lg.directory)

    req.data.phone_numbers.forEach((phone_number: IPhoneNumber) => {
      const fullNumber = phone_number.full_number.toString()
      const ownerAccount = clientDirectory.data.directory.find(
        (account: any) => {
          const numbers = account.phone_numbers
          if (numbers.includes(fullNumber)) {
            return account
          }

          return null
        },
      )

      if (ownerAccount) {
        phone_number.owner_id = ownerAccount.account_id
      }
    })
    yield put(fetchPhoneNumbersSuccess(req.data.phone_numbers))
  } catch (e) {
    yield put(fetchPhoneNumbersFail(e))
  }
}

function* releasePhoneNumberSaga(
  action: ReturnType<typeof releasePhoneNumber>,
) {
  const number = action.payload.phoneNumber

  try {
    yield put(phoneNumberRemoved(number)) // optimistic
    yield call(defaultTrulyClient.lg.releasePhoneNumber, number)
  } catch (e) {
    console.error('releasing phone number', e)
    toastr.error(
      'An Error Occurred',
      'Unable to release phone number. Please try again.',
    )
    yield put(phoneNumberAdded(number)) // revert
  }
}

function* updatePhoneNumberSaga(action: ReturnType<typeof updatePhoneNumber>) {
  const { context, e164Number, entityId, entityType } = action.payload
  const existingNumber: IPhoneNumber = yield* getPhoneNumberByE164(e164Number)
  ensureDefined(existingNumber)

  if (entityType && !entityId) {
    throw new Error('Trying to assign entity without id')
  }

  const newPhoneNumber: IPhoneNumber = {
    ...(entityType && entityId
      ? PhoneNumberUtils.setAssignment(existingNumber, entityType, entityId)
      : PhoneNumberUtils.clearAssignment(existingNumber)),
    context,
  }

  yield put(phoneNumberChanged(newPhoneNumber)) // optimistic
  try {
    // Update the context value first with the update API, then if the assignment changed call the approriate API
    let changedNumber: IPhoneNumber
    const response = yield call(defaultTrulyClient.lg.updatePhoneNumber, {
      ...existingNumber,
      context,
    })
    changedNumber = response.data.phone_number
    if (!isEqual(existingNumber.entity, newPhoneNumber.entity)) {
      // if assignment changed
      if (entityType && entityId) {
        // assigned now
        changedNumber = yield* doAssignPhoneNumber(
          changedNumber,
          entityType,
          entityId,
        )
      } else {
        // unassigned now
        ensureDefined(existingNumber.entity) // need to know where to unassign from

        if (!existingNumber.entity) {
          throw new Error("Can't un-assign already unassigned number")
        }

        changedNumber = yield* doUnassignPhoneNumber(
          e164Number,
          existingNumber.entity.type,
          existingNumber.entity.id,
        )
      }
    }
    // for server-side updates (which are sure because of the assignment change)
    yield put(phoneNumberChanged(changedNumber))
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save phone number. Please try again.',
    )
    yield put(phoneNumberChanged(existingNumber)) // revert
  }
}

export function* doAssignPhoneNumber(
  phoneNumber: IPhoneNumber,
  entityType: EntityType,
  entityId: number,
) {
  yield call(
    defaultTrulyClient.lg.assignPhoneNumber,
    entityTypeToAPIEntity(entityType),
    entityId,
    phoneNumber.full_number,
  )
  // BE doesn't have a fetch for a single phone number, so fetch all
  // There is a replication issue on the backend where it's possible to fetch before the
  // number has been assigned - we retry as a hack to wait for that to finish - JR
  const newNumber = yield call(
    retry,
    async () => {
      const response = await defaultTrulyClient.lg.fetchPhoneNumbers()
      const num = (response.data.phone_numbers as IPhoneNumber[]).find(
        pn => pn.full_number === phoneNumber.full_number,
      ) as IPhoneNumber
      if (num.entity?.id !== entityId || num.entity?.type !== entityType) {
        throw new Error('Number not assigned yet')
      }
      return num
    },
    2,
    attempt => RetryHelpers.linearBackoff(attempt, 100),
  )

  ensureDefined(newNumber)

  if (phoneNumber.entity) {
    // existing assignment, so remove that assignment
    yield put(
      phoneNumberUnassigned(
        phoneNumber.entity.type,
        phoneNumber.entity.id,
        phoneNumber.full_number,
      ),
    )
  }

  yield put(phoneNumberAssigned(entityType, entityId, newNumber))
  return newNumber
}

function* assignPhoneNumberSaga(action: ReturnType<typeof assignPhoneNumber>) {
  const { entityType, entityId, e164Number } = action.payload
  const existingPhoneNumber = yield* getPhoneNumberByE164(e164Number)

  const newPhoneNumber = PhoneNumberUtils.setAssignment(
    existingPhoneNumber,
    entityType,
    entityId,
  )
  yield put(phoneNumberChanged(newPhoneNumber)) // optimistic

  try {
    const changedNumber = yield* doAssignPhoneNumber(
      existingPhoneNumber,
      entityType,
      entityId,
    )
    // update in the case of remote changes (which there will be because of the assignment change)
    yield put(phoneNumberChanged(changedNumber))
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to assign phone number. Please try again.',
    )
    yield put(phoneNumberChanged(existingPhoneNumber)) // revert
  }
}

function* doUnassignPhoneNumber(
  e164Number: string,
  entityType: EntityType,
  entityId: number,
) {
  const existingPhoneNumber = yield* getPhoneNumberByE164(e164Number)
  yield put(phoneNumberUnassigned(entityType, entityId, e164Number)) // optimistic
  try {
    yield call(
      defaultTrulyClient.lg.unassignPhoneNumber,
      entityTypeToAPIEntity(entityType),
      entityId,
      e164Number,
    )
  } catch (err) {
    console.error('unassign phone number failed, reverting unassignment', err)
    yield put(phoneNumberAssigned(entityType, entityId, existingPhoneNumber)) // revert
    throw err
  }
  // BE doesn't have a fetch for a single phone number, so fetch all
  const response = yield call(defaultTrulyClient.lg.fetchPhoneNumbers)
  const phoneNumber = (response.data.phone_numbers as IPhoneNumber[]).find(
    pn => pn.full_number === e164Number,
  )
  ensureDefined(phoneNumber)
  return phoneNumber as IPhoneNumber
}

function* unassignPhoneNumberSaga(
  action: ReturnType<typeof unassignPhoneNumber>,
) {
  const { entityType, entityId, e164Number } = action.payload
  const existingPhoneNumber = yield* getPhoneNumberByE164(e164Number)

  const newPhoneNumber = PhoneNumberUtils.clearAssignment(existingPhoneNumber)
  yield put(phoneNumberChanged(newPhoneNumber)) // optimistic

  try {
    const changedNumber = yield* doUnassignPhoneNumber(
      e164Number,
      entityType,
      entityId,
    )
    // update in the case of remote changes (which there will be because of the assignment change)
    yield put(phoneNumberChanged(changedNumber))
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to unassign phone number. Please try again.',
    )
    yield put(phoneNumberChanged(existingPhoneNumber)) // revert
  }
}

function* searchPhoneNumbersSaga(
  action: ReturnType<typeof searchPhoneNumbers>,
) {
  try {
    const req = yield call(
      defaultTrulyClient.lg.searchPhoneNumber,
      action.payload.type,
      action.payload.areaCode,
    )
    yield put(searchPhoneNumbersSuccess(req.data.numbers))
  } catch (e) {
    console.error(e)
    yield put(searchPhoneNumbersFail())
  }
}

function* getPhoneNumberByE164(full_number: string) {
  const phoneNumbers = yield select(selectors.phoneNumbers)
  let phoneNumber = find(phoneNumbers, { full_number })
  if (!phoneNumber) {
    // load if number not found
    yield* fetchPhoneNumbersSaga()
    phoneNumber = find(phoneNumbers, { full_number })
  }
  return phoneNumber
}
