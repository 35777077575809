import * as React from 'react'
import { IForwardingNode, MessageType } from 'truly-ts'
import {
  FlexColumn,
  Checkbox,
  TextInput,
  ValidationMessage,
} from 'js-components'
import { CheckItem, SubItem, ValidationRow } from '../Styles'
import MessageEditRow from '../MessageEditRow'
import {
  NodeValidationContext,
  NodeValidationContextValue,
  ForwardingNodeValidationState,
} from '../../../utils/model-utils/node-validation'
import { ValidationHandler } from 'truly-utils'

const callerIdValidationHandler = new ValidationHandler<
  string | undefined | null
>('Please enter a caller ID', id => !!id?.trim())

interface NodeCallForwardingOptionsProps {
  node: IForwardingNode
  onNodeChange: (node: IForwardingNode) => void
}

interface NodeCallForwardingOptionsState {
  customCallerIdEnabled: boolean
}

export default class NodeCallForwardingOptions extends React.Component<
  NodeCallForwardingOptionsProps,
  NodeCallForwardingOptionsState
> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<ForwardingNodeValidationState> // docs say to do this

  constructor(props: NodeCallForwardingOptionsProps) {
    super(props)

    this.state = this.initialState()
  }

  componentDidMount() {
    this.validateNode(this.props.node, this.state.customCallerIdEnabled)
  }

  componentDidUpdate(prevProps: NodeCallForwardingOptionsProps) {
    if (prevProps.node.id !== this.props.node.id) {
      this.setState(this.initialState())
      this.validateNode(this.props.node, this.state.customCallerIdEnabled)
    }
  }

  initialState() {
    const { node } = this.props
    return {
      customCallerIdEnabled: !!(node && node.sip_push_context),
    }
  }

  validateNode(node: IForwardingNode, callerIdEnabled: boolean) {
    this.context.validationChange({
      callerId: callerIdEnabled
        ? callerIdValidationHandler.validate(node.sip_push_context)
        : ValidationHandler.createValidState(), // valid if disabled
    })
  }

  onCustomCallerIdEnabledChange = (enabled: boolean) => {
    this.setState({
      customCallerIdEnabled: enabled,
    })
    let node = this.props.node
    if (!enabled) {
      node = {
        ...this.props.node,
        sip_push_context: '',
      }
      this.props.onNodeChange(node)
    }
    this.validateNode(node, enabled)
  }

  onCustomCalledIdChange = (value: string) => {
    const newNode = {
      ...this.props.node,
      sip_push_context: value,
    }
    this.props.onNodeChange(newNode)
    this.validateNode(newNode, this.state.customCallerIdEnabled)
  }

  onCallbackChange = (enabled: boolean) => {
    this.props.onNodeChange({
      ...this.props.node,
      is_callback_enabled: enabled,
    })
  }

  render() {
    const { node } = this.props
    const { customCallerIdEnabled } = this.state
    const { validationState } = this.context
    const defaultRecordingName =
      node && node.pre_select_text_to_speech
        ? `${node.pre_select_text_to_speech} `
        : ''

    return (
      <FlexColumn width="100%" alignItems="flex-start">
        <CheckItem>
          <MessageEditRow
            label="Play a voice recording before the call"
            node={node}
            type={MessageType.NodeMessage}
            validationKey="playRecording"
            onNodeChange={newNode =>
              this.props.onNodeChange(newNode as IForwardingNode)
            }
            validationMessage="Please Choose a Recording"
            newRecordingDefaultName={`${defaultRecordingName}Forward`}
          />
        </CheckItem>
        <CheckItem>
          <Checkbox
            label="Customize what users see in their Caller ID"
            checked={customCallerIdEnabled}
            checkChanged={this.onCustomCallerIdEnabledChange}
          />
        </CheckItem>
        {customCallerIdEnabled && (
          <SubItem>
            <TextInput
              value={node.sip_push_context}
              onChange={this.onCustomCalledIdChange}
              placeholder="Type a Caller Id"
              validationState={
                validationState.showErrors
                  ? validationState.callerId
                  : undefined
              }
            />
            {validationState.showErrors && (
              <ValidationRow>
                <ValidationMessage validation={validationState.callerId} />
              </ValidationRow>
            )}
          </SubItem>
        )}
        <CheckItem>
          <MessageEditRow
            label="Customize voicemail message"
            node={node}
            type={MessageType.NodeVoicemailGreeting}
            onNodeChange={newNode =>
              this.props.onNodeChange(newNode as IForwardingNode)
            }
            validationKey="customizeVoicemail"
            validationMessage="Please Choose a Voicemail"
            newRecordingDefaultName={`${defaultRecordingName}Voicemail`}
          />
        </CheckItem>
        <CheckItem>
          <Checkbox
            label="Enable callback option for queued callers"
            checked={node.is_callback_enabled || false}
            checkChanged={this.onCallbackChange}
          />
        </CheckItem>
      </FlexColumn>
    )
  }
}
