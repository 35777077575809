import { connect } from 'react-redux'
import Reports from '../components/Reports/Reports'
import { IState } from '../store'
import { fetchDashboards } from '../reducers/reports/actionCreators'
import { openChatSupport } from '../reducers/chatsupport/actionCreators'

const mapStateToProps = (state: IState) => ({
  dashboards: state.reports.dashboards,
})

const mapDispatchToProps = {
  loadDashboards: fetchDashboards,
  openChatSupport,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
