import { connect } from 'react-redux'
import TeamCreateModal from '../components/TeamCreateModal/TeamCreateModal'
import { createRole } from '../reducers/roles/actionCreators'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  onCreate: createRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamCreateModal)
