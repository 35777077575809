import { FETCH_USER, FETCH_USER_FAIL, FETCH_USER_SUCCESS } from './actionTypes'

import { IUserAccount, ILicenseGroup } from 'truly-ts'

export const fetchUser = () => ({
  type: FETCH_USER,
  payload: {},
})

export const fetchUserSuccess = (
  account: IUserAccount,
  licenseGroup: ILicenseGroup,
) => ({
  type: FETCH_USER_SUCCESS,
  payload: {
    account,
    licenseGroup,
  },
})

export const fetchUserFail = (error: Error) => ({
  type: FETCH_USER_FAIL,
  payload: {
    error,
  },
})
