import { connect } from 'react-redux'
import PhoneMenus from '../components/PhoneMenus/PhoneMenus'
import { IState, Dispatch } from '../store'
import * as selectors from '../reducers/phoneMenus/phoneMenusSelectors'
import { fetchPhoneMenus } from '../reducers/phoneMenus/actionCreators'
import { fetchExtensions } from '../reducers/extensions/actionCreators'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { IPhoneMenu } from 'truly-ts'

interface PhoneMenuContainerProps {
  onDeletePhoneMenu: (phoneMenu: IPhoneMenu) => void
  searchValue: string
  clearFilters: () => void
}

const mapStateToProps = (state: IState) => ({
  phoneMenus: selectors.phoneMenus(state),
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: PhoneMenuContainerProps,
) => ({
  fetchData: () => {
    dispatch(fetchExtensions())
    dispatch(fetchPhoneMenus())
  },
  deletePhoneMenu: ownProps.onDeletePhoneMenu,
  searchValue: ownProps.searchValue,
  clearFilters: ownProps.clearFilters,
  navigate: bindActionCreators(push, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhoneMenus)
