import * as React from 'react'
import { INode, IExtension, IRole, NodeType, PhoneMenuType } from 'truly-ts'
import sortBy from 'lodash/sortBy'
import { getRequired } from 'truly-utils/macro'
import { MediumSectionHeader, ActionButton } from '../LayoutHelpers/Styles'
import {
  SectionGroup,
  SectionItem,
  FlexRow,
  colors,
  CircleAdd,
  ToolTip,
  Trash,
  Small,
  Edit,
  spaceSizes,
  Regular,
} from 'js-components'
import { PurpleCircle, ButtonsRow, DialOptionSectionItem } from './Styles'
import { NodeTypeDisplay } from '../../constants/phone-menu'
import {
  createDefaultAdvancedNode,
  getRandomNegativeId,
  markNodeDirty,
  replaceNode,
  removeNode,
} from '../../utils/model-utils/node-utils'
import PhoneMenuNodeEditPanel from '../PhoneMenuNodeEditPanel/PhoneMenuNodeEditPanel'

const defaultAvailableOptionNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

interface NodeDialOptionsProps {
  parentNode: INode
  phoneMenuId: number
  onParentNodeChange: (node: INode) => void
  onOpenSubmenuNode: (node: INode) => void
  extensions?: IExtension[]
  teams?: IRole[]
}

interface NodeDialOptionsState {
  editingNode?: INode | null
  editingIsNew?: boolean
}

export default class NodeDialOptions extends React.Component<
  NodeDialOptionsProps,
  NodeDialOptionsState
> {
  constructor(props: NodeDialOptionsProps) {
    super(props)
    this.state = {}
  }

  onEditNode = (node: INode, openEditorPanel?: boolean) => () => {
    if (node.type === NodeType.Menu && !openEditorPanel) {
      this.props.onOpenSubmenuNode(node)
    } else {
      this.setState({
        editingNode: node,
        editingIsNew: false,
      })
    }
  }

  onCloseNodeEditor = (wasSaved?: boolean) => {
    const { editingIsNew, editingNode } = this.state
    if (editingNode && !wasSaved && editingIsNew) {
      // wasn't saved and was just created, so just remove (definitely not valid)
      const rmNode = removeNode(this.props.parentNode, editingNode)
      if (rmNode) {
        this.props.onParentNodeChange(rmNode)
      }
    }

    this.setState({
      editingNode: null,
      editingIsNew: false,
    })
  }

  getAvailableOptionNumbers = () => {
    return defaultAvailableOptionNumbers.filter(n => {
      return !(this.props.parentNode.children || []).some(
        p => p.option_number === n,
      )
    })
  }

  onNodeUpdated = (node: INode) => {
    const dirtyNode = markNodeDirty(node)
    this.props.onParentNodeChange(replaceNode(this.props.parentNode, dirtyNode))

    // if just saved a phone menu, then open it up
    if (this.state.editingIsNew && node.type === NodeType.Menu) {
      this.props.onOpenSubmenuNode(dirtyNode)
    }
  }

  onNodeRemoved = (node: INode) => {
    // the reason for the force update is to give the animation of closing the menu option a chance
    this.forceUpdate(() => {
      const rmNode = removeNode(this.props.parentNode, node)
      if (rmNode) {
        this.props.onParentNodeChange(rmNode)
      }
      this.setState({
        editingNode: null,
        editingIsNew: false,
      })
    })
  }

  onAddNode = () => {
    const availableOptionNumbers = this.getAvailableOptionNumbers()
    if (availableOptionNumbers.length === 0) {
      return alert('Please remove an option before adding another one.')
    }

    if (!this.props.parentNode.id) {
      throw new Error("Can't node add to parent with no ID")
    }

    const newNode = createDefaultAdvancedNode(
      availableOptionNumbers[0],
      this.props.parentNode.id,
      this.props.phoneMenuId,
    )
    // create a dummy id so save knows to POST instead of PUT
    // since it's unique, it can be used as a reference point for editing too
    newNode.id = getRandomNegativeId()
    this.props.onParentNodeChange({
      ...this.props.parentNode,
      children: [
        ...(this.props.parentNode.children ?? []),
        markNodeDirty(newNode),
      ],
    })
    this.setState({
      editingNode: newNode,
      editingIsNew: true,
    })
  }

  trashButtonClicked = (node: INode) => (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.onNodeRemoved(node)
  }

  editButtonClicked = (node: INode) => (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.onEditNode(node, true)()
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const { editingNode, editingIsNew } = this.state
    const nodes = sortBy(
      getRequired(this.props.parentNode.children),
      // make 0 last
      (n: INode) => (n.option_number === 0 ? 99 : n.option_number),
    )

    return (
      <>
        <PhoneMenuNodeEditPanel
          phoneMenuId={this.props.phoneMenuId}
          node={editingNode}
          extensions={this.props.extensions ?? []}
          teams={this.props.teams ?? []}
          onClose={this.onCloseNodeEditor}
          show={!!editingNode}
          availableOptionNumbers={this.getAvailableOptionNumbers()}
          type={PhoneMenuType.Advanced}
          updateNode={this.onNodeUpdated}
          removeNode={this.onNodeRemoved}
          isNewNode={!!editingIsNew}
        />
        <MediumSectionHeader>Menu Options</MediumSectionHeader>
        <SectionGroup>
          {nodes.map(n => (
            <DialOptionSectionItem
              key={n.id}
              role="button"
              onClick={this.onEditNode(n)}>
              <FlexRow alignItems="center">
                {n.option_number && (
                  <DialNumberCircle number={n.option_number} />
                )}
                <Regular color={colors.trulyDark} ml={`${spaceSizes.md}px`}>
                  <strong>{NodeTypeDisplay[n.type]} </strong>
                  {n.pre_select_text_to_speech}
                </Regular>
                <ButtonsRow>
                  <ToolTip
                    enabled
                    orderPreference="bottom"
                    toolTipContent={this.renderTooltipContent('Edit')}>
                    <ActionButton
                      cursor="pointer"
                      onClick={this.editButtonClicked(n)}
                      role="button">
                      <Edit width="24" height="24" />
                    </ActionButton>
                  </ToolTip>
                  <ToolTip
                    enabled
                    orderPreference="bottom"
                    toolTipContent={this.renderTooltipContent('Remove')}>
                    <ActionButton
                      cursor="pointer"
                      onClick={this.trashButtonClicked(n)}
                      role="button">
                      <Trash width="24" height="24" />
                    </ActionButton>
                  </ToolTip>
                </ButtonsRow>
              </FlexRow>
            </DialOptionSectionItem>
          ))}
          <SectionItem
            key="add-option"
            data-cy="add-option-button"
            role="button"
            onClick={this.onAddNode}>
            <FlexRow alignItems="center">
              <CircleAdd width="32px" height="32px" />
              <Regular color={colors.trulyDark} ml={`${spaceSizes.md}px`}>
                Add Option
              </Regular>
            </FlexRow>
          </SectionItem>
        </SectionGroup>
      </>
    )
  }
}

class DialNumberCircle extends React.PureComponent<{ number: number }> {
  render() {
    return (
      <PurpleCircle>
        <Regular color={colors.white} bold mb="2px">
          {this.props.number}
        </Regular>
      </PurpleCircle>
    )
  }
}
