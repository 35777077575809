export const FETCH_EXTERNAL_USERS = 'FETCH_EXTERNAL_USERS'
export const FETCH_EXTERNAL_USERS_SUCCESS = 'FETCH_EXTERNAL_USERS_SUCCESS'
export const FETCH_EXTERNAL_USERS_FAIL = 'FETCH_EXTERNAL_USERS_FAIL'

export const DELETE_EXTERNAL_USER = 'DELETE_EXTERNAL_USER'
export const UPDATE_EXTERNAL_USER = 'UPDATE_EXTERNAL_USER'
export const CREATE_EXTERNAL_USER = 'CREATE_EXTERNAL_USER'

export const EXTERNAL_USER_DELETED = 'EXTERNAL_USER_DELETED'
export const EXTERNAL_USER_UPDATED = 'EXTERNAL_USER_UPDATED'
export const EXTERNAL_USER_ADDED = 'EXTERNAL_USER_ADDED'
