import styled from 'styled-components'
import { FlexColumn, FlexRow } from 'js-components'

export const Container = styled(FlexColumn)`
  height: 100%;
  max-height: 100%;
`

export const ContentContainer = styled(FlexColumn)`
  padding: 0px 14px 14px 14px;
  max-height: 100%;
  height: 100%;
`

export const LoaderWrapper = styled.div`
  margin-top: 200px;
`

export const ActionContainer = styled.div`
  cursor: pointer;

  * {
    cursor: pointer;
  }
`

export const ActionIconWrapper = styled.div`
  margin-right: 4px;
  margin-left: 4px;
  padding: 6px;
`

export const PhoneNumberTextWrapper = styled.div`
  margin-bottom: 2px;
`

export const TableFilterWrapper = styled.div`
  flex: 0;
`

export const TableWrapper = styled(FlexColumn)`
  height: 100%;
`

export const NumberDataContainer = styled(FlexColumn)`
  align-items: flex-start;
`

export const ActionsDataContainer = styled(FlexRow)`
  justify-content: flex-end;
`
