import {
  FETCH_FORMS,
  FETCH_FORMS_FAIL,
  FETCH_FORMS_SUCCESS,
  DELETE_FORM,
  FORM_DELETED,
  FORM_ADDED,
  UPDATE_FORM,
  FORM_UPDATED,
  CREATE_FORM,
  SET_FORM_SAVING,
} from './actionTypes'
import { Form } from './types'
import { SaveStatus } from '../../utils/Saving'
import { Unsaved } from 'truly-ts'

export const fetchForms = () => ({
  type: FETCH_FORMS,
  payload: {},
})

export const fetchFormsFail = () => ({
  type: FETCH_FORMS_FAIL,
  payload: {},
})

export const fetchFormsSuccess = (forms: Form[]) => ({
  type: FETCH_FORMS_SUCCESS,
  payload: {
    forms,
  },
})

export const deleteForm = (form: Form) => ({
  type: DELETE_FORM,
  payload: {
    form,
  },
})

export const formDeleted = (id: string) => ({
  type: FORM_DELETED,
  payload: {
    id,
  },
})

export const formAdded = (form: Form) => ({
  type: FORM_ADDED,
  payload: {
    form,
  },
})

export const updateForm = (id: string, form: Partial<Form>) => ({
  type: UPDATE_FORM,
  payload: {
    form,
    id,
  },
})

export const formUpdated = (form: Form) => ({
  type: FORM_UPDATED,
  payload: {
    form,
  },
})

export const createForm = (form: Unsaved<Form>) => ({
  type: CREATE_FORM,
  payload: {
    form,
  },
})

export const setFormSaving = (
  saveStatus: SaveStatus,
  formId?: number | string,
) => ({
  type: SET_FORM_SAVING,
  payload: {
    saveStatus,
    formId,
  },
})
