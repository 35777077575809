import React from 'react'
import {
  FlexRow,
  Regular,
  colors,
  Avatar,
  Small,
  InlineDialog,
} from 'js-components'
import { ILicenseGroupAccount } from 'truly-ts'
import { ReactComponent as EmailSVG } from 'js-components/src/components/Icons/Email.svg'
import { StyledCard } from './Styles'
import { isValidEmail } from 'truly-utils'

interface EmailModalProps {
  show: boolean
  email: string
  users?: ILicenseGroupAccount[]
  company?: string
}

const EmailModal: React.FC<EmailModalProps> = React.memo(props => {
  const { email, users, company, children } = props
  const show = props.show && !!email.trim()

  const existingUser =
    users &&
    users.find(u => u.email.toLowerCase().trim() === email.toLowerCase().trim())
  const isInvalidEmail = !isValidEmail(email) || !!existingUser

  return (
    <InlineDialog
      visible={show}
      verticalOffset={12}
      fixed
      content={
        <StyledCard disabled={isInvalidEmail}>
          <FlexRow
            justifyContent="flex-start"
            alignItems="center"
            style={isInvalidEmail ? { opacity: 0.35 } : {}}>
            <Avatar
              size="big"
              style={{ marginRight: '16px' }}
              square={!existingUser}
              colorSeed={existingUser && existingUser.email}
              text={
                existingUser &&
                `${existingUser.first_name} ${existingUser.last_name}`
              }
              icon={!existingUser && <EmailSVG width="100%" />}
            />
            <div>
              <Regular color={colors.trulyDark}>
                {existingUser
                  ? `${existingUser.first_name} ${existingUser.last_name} (Already a User)`
                  : email}
              </Regular>
              <Small color={colors.accentPurple} bold mt="2px">
                {existingUser ? existingUser.email : `Invite to ${company}`}
              </Small>
            </div>
          </FlexRow>
        </StyledCard>
      }>
      {children}
    </InlineDialog>
  )
})

export default EmailModal
