import styled from 'styled-components'
import { colors, Card } from 'js-components'

export const StyledCard = styled(Card)<{ disabled?: boolean }>`
  width: 510px;
  ${props =>
    !props.disabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${colors.ultraLightGray};
    }
  `}
  ${props => props.disabled && `cursor: not-allowed;`}
`
