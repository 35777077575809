import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import LogRocket from 'logrocket'

function currentPage() {
  return window.location.pathname.substr(
    window.location.pathname.lastIndexOf('/') + 1,
  )
}

let bugsnagClient = null
let ErrorBoundary: any = null
const shortHash = `#${(window.trulyEnv.commit_hash || '').substring(0, 8)}`

if (!window.trulyEnv.disable_analytics) {
  bugsnagClient = Bugsnag.createClient({
    apiKey: window.trulyEnv.bugsnag_key,
    appVersion: shortHash,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage:
      process.env.NODE_ENV === 'development'
        ? 'local'
        : window.trulyEnv.release_stage || 'production',
    onError: event => {
      event.addMetadata('LogRocket', {
        sessionURL: LogRocket.sessionURL,
      })
    },
  })
  window.bugsnagClient = bugsnagClient
}

if (bugsnagClient) {
  bugsnagClient.setContext(currentPage())
  ErrorBoundary = bugsnagClient.getPlugin('react')
}

export const withErrorBoundary = (App: JSX.Element): JSX.Element => {
  if (ErrorBoundary) {
    return <ErrorBoundary>{App}</ErrorBoundary>
  }
  return App
}
