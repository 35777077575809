import React, { useState, useEffect } from 'react'
import {
  IFormElement,
  IFormElementListValue,
  IFormExternalField,
  InputType,
  ValidationState,
} from 'truly-ts'
import uniq from 'lodash/uniq'
import {
  ModalLayout,
  InlineSelectEdit,
  SidePanelModal,
  InlineTextEdit,
  colors,
  Regular,
  TableHeaderRow,
  TableHeaderData,
  TableRow,
  Table,
  CircleRemove,
  TableData,
  CircleAdd,
  ValidationMessage,
  TableDragHandle,
  Link,
} from 'js-components'
import { inputTypeToDisplay } from '../../utils/Forms'
import { IconWrapper, AddOptionButtonWrapper } from './Styles'
import { ReactComponent as Draggable } from '../../common/icons/draggable.svg'
import { titleValidationHandler } from '../../utils/Validation'
import { ActionButton } from '../LayoutHelpers/Styles'
import { moveIn, ValidationHandler, useValidatedState } from 'truly-utils'

const picklistValidationHandler = new ValidationHandler<
  IFormElementListValue[]
>(
  'Please add at least one valid option.',
  v => v && v.filter(lv => lv.value.trim() && lv.label.trim()).length > 0,
)

const LabelWidth = '180px'
const TypeOptions = (['select', 'textfield', 'checkbox'] as Array<
  IFormElement['input_type']
>).map(t => ({
  value: t,
  label: inputTypeToDisplay(t),
}))

interface FormFieldEditProps {
  show: boolean
  element?: IFormElement | null
  onElementChanged: (
    newElement: IFormElement,
    oldElement?: IFormElement,
  ) => void
  onClose: () => void
  validateSyncIdHandler: ValidationHandler<string[]>
}

const FormFieldEdit: React.FC<FormFieldEditProps> = props => {
  const { show, onClose, onElementChanged, element } = props
  const [isNew, setIsNew] = useState(true)
  const [required, setRequired] = useState(false)
  const [formFieldTitle, setFormFieldTitle] = useState('')
  const [type, setType] = useState('select')
  const [listValues, setListValues] = useState([{ value: '', label: '' }])
  const [
    syncId,
    syncIdValidationState,
    setSyncIdAndValidate,
  ] = useValidatedState([''], props.validateSyncIdHandler)
  const [canValidate, setCanValidate] = useState(false)
  const [
    titleValidationState,
    setTitleValidationState,
  ] = useState<ValidationState | null>(null)
  const [autoFocusLast, setAutoFocusLast] = useState(false)
  const [
    picklistValidationState,
    setPicklistValidationState,
  ] = useState<ValidationState | null>(null)

  useEffect(() => {
    if (props.element) {
      const external_fields = props.element.external_fields ?? []

      setIsNew(false)
      setRequired(props.element.required)
      setFormFieldTitle(props.element.label)
      setType(props.element.input_type)
      setListValues(props.element.list_values ?? [])
      setSyncIdAndValidate(
        false,
        external_fields?.length > 0
          ? uniq(external_fields.map(f => f.field_name))
          : [''],
      )
      setCanValidate(false)
      setTitleValidationState(null)
      setAutoFocusLast(false)
      setPicklistValidationState(null)
    } else if (props.show) {
      setIsNew(true)
      setRequired(false)
      setFormFieldTitle('')
      setType('select')
      setListValues([{ value: '', label: '' }])
      setSyncIdAndValidate(false, [''])
      setCanValidate(false)
      setTitleValidationState(null)
      setAutoFocusLast(false)
      setPicklistValidationState(null)
    }
  }, [props.element, props.show, setSyncIdAndValidate])

  const onToggleRequired = (isRequired: boolean) => setRequired(isRequired)

  const updateType = (val: string) => {
    const updatedType = val as IFormElement['input_type']
    setType(updatedType)
    setPicklistValidationState(
      canValidate && updatedType === 'select'
        ? picklistValidationHandler.validate(listValues)
        : null,
    )
  }

  const updateTitle = (updatedTitle: string) => {
    const oldValue = formFieldTitle
    setFormFieldTitle(updatedTitle)
    setTitleValidationState(
      canValidate ? titleValidationHandler.validate(updatedTitle) : null,
    )

    // Keep title in sync with sync id if it hasn't been changed
    if (syncId.length === 1 && convertToValueText(oldValue) === syncId[0]) {
      updateSyncId(convertToValueText(updatedTitle))
    }
  }

  const updateSyncId = (updatedSyncId: string) => {
    setSyncIdAndValidate(true, [updatedSyncId])
  }

  const removeListValue = (value: IFormElementListValue) => {
    const updatedListValues = [...listValues]
    updatedListValues.splice(updatedListValues.indexOf(value), 1)

    setListValues(updatedListValues)
    setPicklistValidationState(
      canValidate
        ? picklistValidationHandler.validate(updatedListValues)
        : null,
    )
  }

  const updateListValueLabel = (lv: IFormElementListValue, label: string) => {
    const updatedListValues = [...listValues]
    const listValuePosition = updatedListValues.indexOf(lv)
    updatedListValues.splice(listValuePosition, 1, {
      ...lv,
      label,
      value:
        !lv.value || lv.value === convertToValueText(lv.label)
          ? convertToValueText(label)
          : lv.value,
    })

    setListValues(updatedListValues)
    setPicklistValidationState(
      canValidate
        ? picklistValidationHandler.validate(updatedListValues)
        : null,
    )
  }

  const updateListValueValue = (lv: IFormElementListValue, value: string) => {
    const updatedListValues = [...listValues]
    const listValuePosition = updatedListValues.indexOf(lv)
    updatedListValues.splice(listValuePosition, 1, {
      ...lv,
      value,
    })

    setListValues(updatedListValues)
    setPicklistValidationState(
      canValidate
        ? picklistValidationHandler.validate(updatedListValues)
        : null,
    )
  }

  const addNewListValue = () => {
    const newListValues = [
      ...listValues,
      {
        label: '',
        value: '',
      },
    ]

    setListValues(newListValues)
    setPicklistValidationState(
      canValidate ? picklistValidationHandler.validate(newListValues) : null,
    )
    setAutoFocusLast(true)
  }

  const picklistOrderChanged = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return
    const updatedListValues = [...listValues]
    moveIn(updatedListValues, oldIndex, newIndex)

    setListValues(updatedListValues)
    setPicklistValidationState(
      canValidate
        ? picklistValidationHandler.validate(updatedListValues)
        : null,
    )
  }

  const syncIdToExternalFields = (): IFormExternalField[] => {
    let syncIds: string[] = []

    syncId.forEach((currentSyncId: string) => {
      if (currentSyncId.indexOf(',') >= 0) {
        syncIds = syncIds.concat(currentSyncId.split(','))
      } else {
        syncIds.push(currentSyncId)
      }
    })

    syncIds = syncIds.map(s => s.trim())

    if (syncIds.length === 0) {
      return []
    }
    return syncIds
      .map((id): IFormExternalField[] => [
        {
          field_name: id,
          provider: 'salesforce',
        },
        {
          field_name: id,
          provider: 'zendesk',
        },
      ])
      .reduce((result, value) => [...result, ...value])
  }

  const convertToValueText = (label: string): string => {
    return label.replace(/\s/g, '_').toLowerCase().replace(/\W+/g, '')
  }

  const onSave = () => {
    const updatedTitleValidationState = titleValidationHandler.validate(
      formFieldTitle,
    )
    const updatedPicklistValidationState =
      type === 'select'
        ? picklistValidationHandler.validate(listValues)
        : ValidationHandler.createValidState()

    setCanValidate(true)
    setTitleValidationState(updatedTitleValidationState)
    setPicklistValidationState(updatedPicklistValidationState)
    const syncIdValid = setSyncIdAndValidate(true, syncId)?.valid ?? false

    if (
      updatedTitleValidationState.valid &&
      updatedPicklistValidationState.valid &&
      syncIdValid
    ) {
      const newField: IFormElement = {
        ...(element as IFormElement),
        input_type: type as InputType,
        label: formFieldTitle,
        name: convertToValueText(formFieldTitle),
        required,
        list_values:
          type === 'select'
            ? listValues.filter((lv: any) => lv.value.trim() && lv.label.trim())
            : [],
        external_fields: syncIdToExternalFields(),
      }
      onElementChanged(newField, element ?? undefined)
      onClose()
    }
  }

  const inlineTextEditStyle = { marginLeft: '-9px', width: '280px' }

  const toggleHelpText = (
    <>
      <Regular as="p" mt="0px">
        Required fields must have a value in order for the user to make another
        outbound call.
      </Regular>
      <Regular as="p" mb="0px">
        For more information on Required Forms, read our{' '}
        <Link
          href="https://intercom.help/truly_co/en/articles/3188444-understanding-required-forms-admin-2-0"
          newWindow>
          Knowledge Base article
        </Link>
        .
      </Regular>
    </>
  )

  return (
    <SidePanelModal
      visible={show}
      width="750px"
      toggleSwitchText={type === 'checkbox' ? '' : 'required'}
      toggleValue={required}
      onToggleChanged={onToggleRequired}
      onRequestClose={onClose}
      data-cy="required-toggle"
      helpText={toggleHelpText}>
      <ModalLayout.Container>
        <ModalLayout.Header
          title={
            isNew ? 'New Form Field' : formFieldTitle.trim() || 'Form Field'
          }
          addBottomBorder
        />
        <ModalLayout.Content>
          <ModalLayout.SubHeader title="Details" />
          <ModalLayout.FieldRow
            label="Name"
            fieldWidth="100%"
            labelWidth={LabelWidth}
            validationState={titleValidationState}
            shouldPadRight
            helpText="This is the friendly identifying title of the field that users will see in their application.">
            <InlineTextEdit
              data-cy="form-field-title"
              originalValue={formFieldTitle}
              onSave={updateTitle}
              saveOnType
              validationState={titleValidationState}
              placeholder="New Form Field"
            />
          </ModalLayout.FieldRow>
          <ModalLayout.LightHorizontalLine />
          <ModalLayout.FieldRow
            label="API Key"
            fieldWidth="100%"
            labelWidth={LabelWidth}
            helpText="The API key is used by your CRM to map data to a specific field. For Salesforce, don't forget to add __c for custom fields"
            shouldPadRight
            validationState={syncIdValidationState}>
            {syncId.length > 1 ? (
              <Regular color={colors.trulyDark}>{syncId.join(', ')}</Regular>
            ) : (
              <InlineTextEdit
                data-cy="form-field-sync-id"
                originalValue={syncId[0] || ''}
                onSave={updateSyncId}
                saveOnType
                placeholder="new_form_field"
                validationState={syncIdValidationState}
              />
            )}
          </ModalLayout.FieldRow>
          <ModalLayout.LightHorizontalLine />
          <ModalLayout.FieldRow
            label="Type"
            fieldWidth="100%"
            labelWidth={LabelWidth}
            shouldPadRight
            data-cy="form-field-type">
            <InlineSelectEdit
              originalValue={type}
              options={TypeOptions}
              onSave={updateType}
            />
          </ModalLayout.FieldRow>
          <ModalLayout.HorizontalLine />
          {type === 'select' && (
            <ModalLayout.Row
              shouldPadTop
              shouldPadBottom
              validationMessage={
                <ValidationMessage validation={picklistValidationState} />
              }>
              <Table
                validationState={picklistValidationState}
                sortable
                orderChanged={picklistOrderChanged}
                header={
                  <TableHeaderRow>
                    <TableHeaderData text="" width="40px" />
                    <TableHeaderData text="Label" />
                    <TableHeaderData text="Value" />
                    <TableHeaderData text="" width="40px" />
                  </TableHeaderRow>
                }>
                {listValues.map((lv, idx) => (
                  <TableRow
                    key={idx}
                    verticalAlign="middle"
                    height={55}
                    sortIndex={idx}>
                    <TableData width="40px">
                      {/* eslint-disable-next-line react/jsx-no-bind */}
                      <IconWrapper
                        onClick={() => removeListValue(lv)}
                        role="button">
                        <CircleRemove width="22px" height="22px" />
                      </IconWrapper>
                    </TableData>
                    <TableData>
                      <InlineTextEdit
                        originalValue={lv.label}
                        data-cy={`form-field-option-label-${idx + 1}`}
                        style={inlineTextEditStyle}
                        placeholder={`Option ${idx + 1}`}
                        saveOnType
                        autoFocus={
                          autoFocusLast && idx === listValues.length - 1
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        onSave={v => updateListValueLabel(lv, v)}
                      />
                    </TableData>
                    <TableData>
                      <InlineTextEdit
                        originalValue={lv.value}
                        data-cy={`form-field-option-value-${idx + 1}`}
                        style={inlineTextEditStyle}
                        placeholder={`option_${idx + 1}`}
                        saveOnType
                        // eslint-disable-next-line react/jsx-no-bind
                        onSave={v => updateListValueValue(lv, v)}
                      />
                    </TableData>
                    <TableData width="40px">
                      <TableDragHandle>
                        <ActionButton cursor="move">
                          <Draggable
                            width="24"
                            height="24"
                            style={{ transform: 'translateY(2px)' }}
                          />
                        </ActionButton>
                      </TableDragHandle>
                    </TableData>
                  </TableRow>
                ))}
                <TableRow key="add-row" verticalAlign="middle" height={55}>
                  <TableData>
                    <AddOptionButtonWrapper
                      onClick={addNewListValue}
                      role="button">
                      <CircleAdd width="22px" height="22px" />
                      <Regular ml="18px" color={colors.trulyDark}>
                        Add Option
                      </Regular>
                    </AddOptionButtonWrapper>
                  </TableData>
                </TableRow>
              </Table>
            </ModalLayout.Row>
          )}
        </ModalLayout.Content>
        <ModalLayout.Footer actionText="Save" onAction={onSave} pinToBottom />
      </ModalLayout.Container>
    </SidePanelModal>
  )
}

export default FormFieldEdit
