import * as React from 'react'
import { DeleteDialog } from 'js-components'
import { IWebhook } from 'truly-ts'

interface WebhooksDeleteDialogProps {
  show: boolean
  onClose: (deleted: boolean) => void
  selectedWebhook?: IWebhook
  onDelete: () => void
}

const WebhooksDeleteDialog: React.FC<WebhooksDeleteDialogProps> = props => {
  const { show, selectedWebhook, onDelete, onClose } = props

  const onDeleteDialog = () => {
    onDelete()
    onClose(true)
  }

  const onCloseDialog = () => onClose(false)

  return (
    <DeleteDialog
      show={show}
      title={
        selectedWebhook
          ? `Delete "${selectedWebhook.name}"?`
          : `No Webhook Selected`
      }
      deleteActionText="Delete"
      description="This webhook will be deleted."
      onCancel={onCloseDialog}
      onDelete={onDeleteDialog}
      confirmText="DELETE"
    />
  )
}

export default React.memo(WebhooksDeleteDialog)
