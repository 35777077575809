import { devAssert } from 'truly-utils/macro'

export function ensureDefined<T>(val?: T): T {
  devAssert(assert =>
    assert(
      typeof val !== 'undefined' && val !== null,
      'Value is undefined or null',
    ),
  )
  if (val !== null && typeof val !== 'undefined') return val
  throw new Error('Value is undefined')
}
