import {
  FETCH_PHONE_NUMBERS,
  FETCH_PHONE_NUMBERS_SUCCESS,
  FETCH_PHONE_NUMBERS_FAIL,
  UPDATE_PHONE_NUMBER,
  PHONE_NUMBER_CHANGED,
  SEARCH_PHONE_NUMBERS,
  SEARCH_PHONE_NUMBERS_SUCCESS,
  SEARCH_PHONE_NUMBERS_FAIL,
  RELEASE_PHONE_NUMBER,
  PHONE_NUMBER_REMOVED,
  PHONE_NUMBER_ADDED,
  ASSIGN_PHONE_NUMBER,
  UNASSIGN_PHONE_NUMBER,
  PHONE_NUMBER_ASSIGNED,
  PHONE_NUMBER_UNASSIGNED,
} from './actionTypes'

import { IPhoneNumber, EntityType, PurchaseNumberType } from 'truly-ts'

export const fetchPhoneNumbers = () => ({
  type: FETCH_PHONE_NUMBERS,
  payload: {},
})

export const fetchPhoneNumbersSuccess = (phoneNumbers: IPhoneNumber[]) => ({
  type: FETCH_PHONE_NUMBERS_SUCCESS,
  payload: {
    phoneNumbers,
  },
})

export const fetchPhoneNumbersFail = (error: Error) => ({
  type: FETCH_PHONE_NUMBERS_FAIL,
  payload: {
    error,
  },
})

export const updatePhoneNumber = (
  e164Number: string,
  context: string | null,
  entityType: EntityType | null,
  entityId: number | null,
) => ({
  type: UPDATE_PHONE_NUMBER,
  payload: {
    e164Number,
    context,
    entityType,
    entityId,
  },
})

export const assignPhoneNumber = (
  entityType: EntityType,
  entityId: number,
  e164Number: string,
) => ({
  type: ASSIGN_PHONE_NUMBER,
  payload: {
    entityType,
    entityId,
    e164Number,
  },
})

export const phoneNumberAssigned = (
  entityType: EntityType,
  entityId: number,
  phoneNumber: IPhoneNumber,
) => ({
  type: PHONE_NUMBER_ASSIGNED,
  payload: {
    entityType,
    entityId,
    phoneNumber,
  },
})

export const unassignPhoneNumber = (
  entityType: EntityType,
  entityId: number,
  e164Number: string,
) => ({
  type: UNASSIGN_PHONE_NUMBER,
  payload: {
    entityType,
    entityId,
    e164Number,
  },
})

export const phoneNumberUnassigned = (
  entityType: EntityType,
  entityId: number,
  e164Number: string,
) => ({
  type: PHONE_NUMBER_UNASSIGNED,
  payload: {
    entityType,
    entityId,
    e164Number,
  },
})

export const releasePhoneNumber = (phoneNumber: IPhoneNumber) => ({
  type: RELEASE_PHONE_NUMBER,
  payload: {
    phoneNumber,
  },
})

export const phoneNumberChanged = (phoneNumber: IPhoneNumber) => ({
  type: PHONE_NUMBER_CHANGED,
  payload: {
    phoneNumber,
  },
})

export const phoneNumberRemoved = (phoneNumber: IPhoneNumber) => ({
  type: PHONE_NUMBER_REMOVED,
  payload: {
    phoneNumber,
  },
})

export const phoneNumberAdded = (phoneNumber: IPhoneNumber) => ({
  type: PHONE_NUMBER_ADDED,
  payload: {
    phoneNumber,
  },
})

export const searchPhoneNumbers = (
  type: PurchaseNumberType,
  areaCode?: string,
) => ({
  type: SEARCH_PHONE_NUMBERS,
  payload: {
    type,
    areaCode,
  },
})

export const searchPhoneNumbersSuccess = (numbers: string[]) => ({
  type: SEARCH_PHONE_NUMBERS_SUCCESS,
  payload: {
    numbers,
  },
})

export const searchPhoneNumbersFail = () => ({
  type: SEARCH_PHONE_NUMBERS_FAIL,
  payload: {},
})
