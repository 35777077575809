import * as React from 'react'
import {
  ContentContainer,
  LoaderWrapper,
  ActionIconWrapper,
  PhoneNumberTextWrapper,
  ActionContainer,
  TableFilterWrapper,
  Container,
  TableWrapper,
  ActionsDataContainer,
  NumberDataContainer,
} from './Styles'
import { RouteComponentProps } from 'react-router'
import {
  FlexColumn,
  TableFilterRow,
  ScrollableTable,
  TableHeaderRow,
  TableHeaderData,
  EmptyResults,
  TableRow,
  TableData,
  Edit,
  Trash,
  colors,
  Regular,
  Small,
  ToolTip,
  DeleteDialog,
  Link,
} from 'js-components'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import { IPhoneNumber } from 'truly-ts'
import {
  formatPhoneNumber,
  numberTypeDisplay,
  makeSearchFn,
  SearchFunctions,
} from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import Loader from '../Loader/Loader'
import PhoneNumberEditPanelContainer from '../../containers/PhoneNumberEditPanelContainer'
import { EntityMap, getEntityName } from '../../utils/Entity'
import { CustomSearchFunctions } from '../../utils/Search'
import PhoneNumberPurchasePanelContainer from '../../containers/PhoneNumberPurchasePanelContainer'
import PageTitle from '../PageTitle/PageTitle'
import { PhoneNumbersTitle } from '../../constants/PageTitles'
import memoizeOne from 'memoize-one'

const RowHeight = 75
const CellWidth = '160px'

interface PhoneNumbersProps extends RouteComponentProps {
  entitiesLoaded: boolean
  phoneNumbers?: IPhoneNumber[]
  loadPhoneNumbers: () => void
  entities: EntityMap
  releasePhoneNumber: (num: IPhoneNumber) => void
  navigate: (path: string) => void
}

interface PhoneNumbersState {
  selectedFilter: string
  searchValue: string

  editing?: boolean
  purchasing?: boolean
  selectedPhoneNumber?: IPhoneNumber | null
  deleting?: boolean
}

export default class PhoneNumbers extends React.Component<
  PhoneNumbersProps,
  PhoneNumbersState
> {
  static GetFilteredPhoneNumbers = memoizeOne(
    (
      allPhoneNumbers: IPhoneNumber[],
      entities: EntityMap,
      searchValue: string,
      selectedFilter: string,
    ) => {
      let phoneNumbers = allPhoneNumbers

      if (selectedFilter === 'unassigned') {
        phoneNumbers = phoneNumbers.filter(pn => !pn.entity)
      }

      if (searchValue && searchValue.trim()) {
        const searchFn = makeSearchFn<IPhoneNumber>(
          [
            'context',
            { field: 'full_number', fn: SearchFunctions.phoneNumberSearch },
            CustomSearchFunctions.assignmentSearch(entities),
          ],
          searchValue,
        )
        phoneNumbers = phoneNumbers.filter(searchFn)
      }

      return phoneNumbers
    },
  )

  constructor(props: PhoneNumbersProps) {
    super(props)
    this.state = {
      selectedFilter: 'all',
      searchValue: '',
    }
  }

  componentDidMount() {
    this.props.loadPhoneNumbers()
  }

  onSearchInputChanged = (searchValue: string) => {
    this.setState({
      searchValue,
    })
  }

  onFilterOptionChanged = (selectedFilter: string) => {
    this.setState({
      selectedFilter,
    })
  }

  clearFilters = () => {
    this.setState({
      selectedFilter: 'all',
      searchValue: '',
    })
  }

  onCloseEditor = () => {
    this.setState({
      editing: false,
    })
  }

  onEditPhoneNumber = (pn: IPhoneNumber) => {
    this.setState({
      editing: true,
      selectedPhoneNumber: pn,
    })
  }

  onAddPhoneNumber = () => this.setState({ purchasing: true })
  onCloseAddPhoneNumber = () => this.setState({ purchasing: false })

  onConfirmDelete = () => {
    if (!this.state.selectedPhoneNumber) {
      throw new Error("can't onConfirmDelete without selected number")
    }

    this.props.releasePhoneNumber(this.state.selectedPhoneNumber)
    this.setState({
      deleting: false,
      selectedPhoneNumber: null,
    })
  }

  onDeletePhoneNumber = (pn: IPhoneNumber) => {
    if (pn.entity) {
      return alert('You must unassign this number before deleting it.')
    }

    this.setState({
      deleting: true,
      selectedPhoneNumber: pn,
    })
  }

  onCloseDelete = () => {
    this.setState({
      deleting: false,
      selectedPhoneNumber: null,
    })
  }

  isFiltered = () => {
    const { searchValue, selectedFilter } = this.state

    return (
      !!this.props.phoneNumbers &&
      this.props.phoneNumbers.length > 0 &&
      (selectedFilter !== 'all' || !!(searchValue && searchValue.trim()))
    )
  }

  render() {
    const { entities, entitiesLoaded } = this.props
    const {
      selectedFilter,
      searchValue,
      editing = false,
      deleting = false,
      purchasing = false,
      selectedPhoneNumber,
    } = this.state

    if (!this.props.phoneNumbers || !entitiesLoaded) {
      return (
        <FlexColumn>
          <PageTitle title={PhoneNumbersTitle} />
          <ContentHeading title={PhoneNumbersTitle} />
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </FlexColumn>
      )
    }

    const phoneNumbers = PhoneNumbers.GetFilteredPhoneNumbers(
      getRequired(this.props.phoneNumbers),
      this.props.entities,
      this.state.searchValue,
      this.state.selectedFilter,
    )
    const isFiltered = this.isFiltered()

    return (
      <Container>
        <PageTitle title={PhoneNumbersTitle} />
        <PhoneNumberPurchasePanelContainer
          show={purchasing}
          onClose={this.onCloseAddPhoneNumber}
          entities={entities}
        />
        <PhoneNumberEditPanelContainer
          show={editing}
          phoneNumber={selectedPhoneNumber}
          onClose={this.onCloseEditor}
          entities={entities}
        />
        <DeleteDialog
          show={deleting}
          title={
            selectedPhoneNumber
              ? `Delete ${formatPhoneNumber(selectedPhoneNumber.full_number)}`
              : 'No Number Selected'
          }
          deleteActionText="Delete"
          description="This number will be deleted from your organization. This action cannot be undone."
          confirmText="DELETE"
          onCancel={this.onCloseDelete}
          onDelete={this.onConfirmDelete}
        />
        <ContentHeading
          title={PhoneNumbersTitle}
          rightControl={
            <HeadingAddButton
              accessibilityLabel="Add Phone Number"
              onClick={this.onAddPhoneNumber}
            />
          }
        />
        <ContentContainer>
          <TableFilterWrapper>
            <TableFilterRow
              searchInput
              searchInputValue={searchValue}
              selectedFilterOption={selectedFilter}
              onFilterOptionChanged={this.onFilterOptionChanged}
              onSearchInputChanged={this.onSearchInputChanged}
              searchPlaceholder="Filter"
              filterOptions={[
                {
                  value: 'all',
                  label: 'All Phone Numbers',
                },
                {
                  value: 'unassigned',
                  label: 'Unassigned',
                },
              ]}
            />
          </TableFilterWrapper>
          <PhoneNumberTable
            isFiltered={isFiltered}
            clearFilters={this.clearFilters}
            entities={this.props.entities}
            navigate={this.props.navigate}
            onAddPhoneNumber={this.onAddPhoneNumber}
            onDeletePhoneNumber={this.onDeletePhoneNumber}
            onEditPhoneNumber={this.onEditPhoneNumber}
            phoneNumbers={phoneNumbers}
          />
        </ContentContainer>
      </Container>
    )
  }
}

const PhoneNumberTable: React.FC<{
  isFiltered: boolean
  clearFilters: () => void
  onAddPhoneNumber: () => void
  phoneNumbers: IPhoneNumber[]
  onEditPhoneNumber: (pn: IPhoneNumber) => void
  onDeletePhoneNumber: (pn: IPhoneNumber) => void
  entities: EntityMap
  navigate: (path: string) => void
}> = React.memo(props => {
  const {
    clearFilters,
    entities,
    isFiltered,
    navigate,
    onAddPhoneNumber,
    onDeletePhoneNumber,
    onEditPhoneNumber,
    phoneNumbers,
  } = props

  return (
    <TableWrapper>
      <ScrollableTable
        header={<PhoneNumberTableHeaderRow />}
        rowHeight={RowHeight}
        emptyView={
          <EmptyResults
            title={isFiltered ? 'No Numbers Found' : 'No Phone Numbers... Yet.'}
            actionText={
              isFiltered ? 'Clear All Filters' : 'Add New Phone Number'
            }
            onActionClick={isFiltered ? clearFilters : onAddPhoneNumber}
          />
        }
        rowCount={phoneNumbers.length}
        renderRow={idx => (
          <PhoneNumberTableRow
            key={idx}
            rowHeight={RowHeight}
            onEdit={onEditPhoneNumber}
            onDelete={onDeletePhoneNumber}
            phoneNumber={phoneNumbers[idx]}
            entities={entities}
            navigate={navigate}
          />
        )}
      />
    </TableWrapper>
  )
})

PhoneNumberTable.displayName = 'PhoneNumberTable'

class PhoneNumberTableHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableHeaderRow {...this.props}>
        <TableHeaderData width={CellWidth} text="Number" />
        <TableHeaderData width={CellWidth} text="Context" />
        <TableHeaderData text="Assigned to" />
        <TableHeaderData width="100px" text="" />
      </TableHeaderRow>
    )
  }
}

class PhoneNumberTableRow extends React.PureComponent<{
  phoneNumber: IPhoneNumber
  entities: EntityMap
  rowHeight: number
  onEdit: (pn: IPhoneNumber) => void
  onDelete: (pn: IPhoneNumber) => void
  navigate: (path: string) => void
}> {
  getAssignment() {
    const phoneNumber = this.props.phoneNumber

    if (phoneNumber.entity) {
      const name = getEntityName(
        this.props.entities,
        phoneNumber.entity.type,
        phoneNumber.entity.id,
      )
      return (
        <Regular>
          <Link color={colors.trulyDark} onClick={this.onAssignmentClicked}>
            {name}
          </Link>
        </Regular>
      )
    }
    return <Regular color={colors.darkGray}>None</Regular>
  }

  onAssignmentClicked = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    const { entity } = this.props.phoneNumber

    if (!entity) {
      throw new Error("Can't navigate via onAssignmentClicked without entity")
    }

    switch (entity.type) {
      case 'phonemenu':
        return this.props.navigate(`/phone-menus/${entity.id}`)
      case 'account':
        return this.props.navigate(`/users/${entity.id}`)
      case 'conference_phone':
        return this.props.navigate(`/conference-rooms/${entity.id}`)
      case 'external_forward':
        return this.props.navigate(`/external-users/${entity.id}`)
      case 'node':
      case 'invite':
        return alert('Not impl - finish when other stuff is done')
    }
  }

  onEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onEdit(this.props.phoneNumber)
  }

  onDelete = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onDelete(this.props.phoneNumber)
  }

  renderTooltipContent = (text: string) => {
    return (
      <div>
        <Small>{text}</Small>
      </div>
    )
  }

  render() {
    const {
      phoneNumber,
      onEdit,
      onDelete,
      entities,
      rowHeight,
      navigate,
      ...restProps
    } = this.props

    return (
      <TableRow
        height={rowHeight}
        verticalAlign="top"
        onClick={this.onEdit}
        {...restProps}>
        <TableData width={CellWidth}>
          <NumberDataContainer>
            <PhoneNumberTextWrapper>
              <Regular color={colors.trulyDark}>
                {formatPhoneNumber(phoneNumber.full_number)}
              </Regular>
            </PhoneNumberTextWrapper>
            <Small color={colors.accentPurple} bold>
              {numberTypeDisplay(phoneNumber.full_number)}
            </Small>
          </NumberDataContainer>
        </TableData>
        <TableData width={CellWidth}>
          {phoneNumber.context ? (
            <Regular color={colors.trulyDark}>{phoneNumber.context}</Regular>
          ) : (
            <Regular color={colors.darkGray}>None</Regular>
          )}
        </TableData>
        <TableData>{this.getAssignment()}</TableData>
        <TableData showOnHover>
          <ActionsDataContainer>
            <ActionContainer>
              <ToolTip
                enabled
                orderPreference="bottom"
                toolTipContent={this.renderTooltipContent('Delete')}>
                <ActionIconWrapper
                  onClick={this.onDelete}
                  data-cy="delete-button">
                  <Trash width="24" height="24" />
                </ActionIconWrapper>
              </ToolTip>
            </ActionContainer>
            <ActionContainer>
              <ToolTip
                enabled
                orderPreference="bottom"
                toolTipContent={this.renderTooltipContent('Edit')}>
                <ActionIconWrapper onClick={this.onEdit} data-cy="edit-button">
                  <Edit width="24" height="24" />
                </ActionIconWrapper>
              </ToolTip>
            </ActionContainer>
          </ActionsDataContainer>
        </TableData>
      </TableRow>
    )
  }
}
