import React, { useState, useEffect } from 'react'
import { ModalLayout, PopupModal, TextInput } from 'js-components'
import { IAPICredential } from 'truly-ts'

const LabelWidth = '155px'

interface NewIntegrationProps {
  show: boolean
  onClose: () => any
  onAdd: (name: string | null) => void
  credential: IAPICredential | null
}

const IntegrationModal: React.FC<NewIntegrationProps> = (
  props: NewIntegrationProps,
) => {
  const { show, onClose, onAdd, credential } = props
  const [name, setName] = useState('')

  useEffect(() => {
    setName(credential?.name ?? '')
  }, [credential, show])

  const saveCreds = () => {
    if (name.trim().length > 0) {
      onAdd(name)
    } else {
      onAdd(null)
    }
  }

  return (
    <PopupModal
      show={show}
      width="550px"
      onRequestClose={onClose}
      hideOnBgClick>
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header
            title={credential ? 'Edit Credential' : 'New API Credentials'}
          />
          <ModalLayout.HorizontalLine />
          <ModalLayout.FieldRow
            label="Name"
            fieldWidth="100%"
            labelWidth={LabelWidth}
            shouldPadRight>
            <TextInput
              value={name}
              autoFocus
              placeholder="Type a Name"
              onChange={setName}
            />
          </ModalLayout.FieldRow>
          <ModalLayout.Footer
            actionText={credential ? 'Save' : 'Add'}
            onAction={saveCreds}
            onCancel={onClose}
          />
        </ModalLayout.Content>
      </ModalLayout.Container>
    </PopupModal>
  )
}

export default IntegrationModal
