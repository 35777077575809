import React, { useState, useEffect } from 'react'
import {
  InlineTextEdit,
  SectionGroup,
  SectionItem,
  FlexColumn,
  FlexRow,
  colors,
  MultiAvatar,
  Regular,
  InlineBold,
  spaceSizes,
  HelpIcon,
  Link,
  AvatarSizes,
} from 'js-components'
import {
  CallComplianceValue,
  ILicenseGroupSettings,
  IDataRetentionPolicy,
} from 'truly-ts'
import Loader from '../Loader/Loader'
import {
  CallComplianceDomesticOptions,
  CallComplianceInternationalOptions,
  usePreloader,
  useOpenCloseState,
} from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import EnabledSettingRow from '../EnabledSettingRow/EnabledSettingRow'
import {
  SmallSectionHeader,
  Label,
  FullWidthWrapper,
  ContentContainer,
  MainContainer,
  SmallSectionHeaderDescription,
  FieldLoader,
} from '../LayoutHelpers/Styles'
import ContentHeading from '../ContentHeading/ContentHeading'
import PageTitle from '../PageTitle/PageTitle'
import { OrganizationSettingsTitle } from '../../constants/PageTitles'
import SelectSettingRow from '../SelectSettingRow/SelectSettingRow'
import { getCallComplianceOptions } from '../../utils/call-compliance'
import * as lgSelectors from '../../reducers/lgSettings/lgSettingsSelectors'
import * as teamSelectors from '../../reducers/roles/rolesSelectors'
import {
  fetchLicenseGroup,
  updateLgCallCompliance,
  updateLgCompany,
  updateLgAutoInvite,
  updateLgRoutingProtection,
  updateLgAccessRecordings,
  updateLgDataRetention,
  updateLgAllowPasswordAuth,
} from '../../reducers/lgSettings/actionCreators'
import { useDispatch, useSelector } from 'react-redux'
import DataRetentionValueDisplay from '../DataRetention/DataRetentionValueDisplay'
import ChangeDataRetentionPolicyDialog from '../DataRetention/ChangeDataRetentionPolicyDialog'
import CustomDataRetention from '../DataRetention/CustomDataRetention'
import { fetchRoles } from '../../reducers/roles/actionCreators'

const routingHelpText = (
  <>
    <Regular as="p" mt="0px">
      Truly uses a basic call forwarding mechanism to route calls to mobile
      phones. By requiring a key press for inbound mobile calls, Truly verifies
      that someone is there and ready to answer the call, preventing a call from
      entering a user’s personal voicemail box during times of network latency
      or when a mobile phone is turned off.
    </Regular>
    <Regular as="p" mb="0px">
      For more information on Routing Protection, read our{' '}
      <Link
        href="https://intercom.help/truly_co/en/articles/1388794-understanding-routing-protection"
        newWindow>
        Knowledge Base article
      </Link>
      .
    </Regular>
  </>
)

export default function OrganizationSettings() {
  const dispatch = useDispatch()
  const lgInfo = usePreloader(lgSelectors.lgInfo, fetchLicenseGroup, true)
  const teams = usePreloader(teamSelectors.roles, fetchRoles)
  const dataRetentionPolicy = useSelector(lgSelectors.dataRetention)
  const teamsWithCustomDataRetention = teams?.filter(
    team => !!team.data_retention,
  )

  const [recordingToggleVisible, setRecordingToggleVisible] = useState(false)
  const [hasInitializedInfo, setHasInitializedInfo] = useState(false)

  useEffect(() => {
    if (lgInfo && !hasInitializedInfo) {
      // This is used to sunset the toggle as we coax everyone toward enabling it
      setHasInitializedInfo(true)
      setRecordingToggleVisible(!lgInfo.settings?.access_recording)
    }
  }, [lgInfo, hasInitializedInfo])

  const [editingPolicy, showEditPolicy, closeEditPolicy] = useOpenCloseState(
    false,
  )
  const [
    showTeamPolicies,
    openTeamPolicies,
    closeTeamPolicies,
  ] = useOpenCloseState(false)

  const saveDataRetentionPolicy = (policy: IDataRetentionPolicy | null) => {
    dispatch(updateLgDataRetention(policy))
  }

  const saveUsaCallCompliance = (value: string) => {
    const intlCallCompliance = lgInfo!.settings!.call_compliance!
      .international_call_compliance
    dispatch(
      updateLgCallCompliance(
        value as CallComplianceValue,
        intlCallCompliance as CallComplianceValue,
      ),
    )
  }

  const saveIntlCallCompliance = (value: string) => {
    const usaCallCompliance = lgInfo!.settings!.call_compliance!
      .usa_call_compliance
    dispatch(
      updateLgCallCompliance(
        usaCallCompliance as CallComplianceValue,
        value as CallComplianceValue,
      ),
    )
  }

  const saveCompanyName = (company: string) =>
    dispatch(updateLgCompany(company))
  const saveAutoInvite = (enabled: boolean) =>
    dispatch(updateLgAutoInvite(enabled))
  const saveRoutingProtection = (setting: string) =>
    dispatch(
      updateLgRoutingProtection(
        setting as ILicenseGroupSettings['routing_protection'],
      ),
    )
  const saveAccessRecordings = (enabled: boolean) =>
    dispatch(updateLgAccessRecordings(enabled))
  const saveAllowPasswordAuth = (enabled: boolean) =>
    dispatch(updateLgAllowPasswordAuth(enabled))

  if (!lgInfo) {
    return (
      <FlexColumn>
        <PageTitle title={OrganizationSettingsTitle} />
        <OrganizationHeading />
        <Loader mt="200px" />
      </FlexColumn>
    )
  }

  const settings = getRequired(lgInfo.settings)
  const callCompliance = getRequired(settings.call_compliance)

  const routingProtectionOptions = [
    {
      label: 'Disabled',
      description:
        'Do not require users to press 1 when answering a phone call on mobile.',
      value: 'off',
    },
    {
      label: 'Phone Menu Calls Only',
      description:
        'Require users to press 1 when answering a phone menu calls on mobile.',
      value: 'phone_menu',
    },
    {
      label: 'All',
      description:
        'Require users to press 1 when answering a phone call on mobile.',
      value: 'phone_menu_and_direct',
    },
  ]

  return (
    <MainContainer>
      <ChangeDataRetentionPolicyDialog
        show={editingPolicy}
        onClose={closeEditPolicy}
        onChange={saveDataRetentionPolicy}
        currentPolicy={dataRetentionPolicy}
        alwaysEnabled
      />
      <CustomDataRetention
        list={
          teamsWithCustomDataRetention?.map(team => ({
            name: team.display_name,
            policy: team.data_retention ?? null,
            link: `/teams/${team.id}/Settings`,
          })) ?? []
        }
        onClose={closeTeamPolicies}
        show={showTeamPolicies}
        type="Team"
      />
      <PageTitle title={OrganizationSettingsTitle} />
      <OrganizationHeading />
      <ContentContainer padTop data-cy="org-settings-container">
        <SmallSectionHeader first>General Information</SmallSectionHeader>
        <SectionGroup>
          <SectionItem>
            <FlexRow alignItems="center">
              <Label width="140px">Company Name</Label>
              <FullWidthWrapper>
                <InlineTextEdit
                  originalValue={lgInfo.company || ''}
                  onSave={saveCompanyName}
                  showButtons
                  required
                />
              </FullWidthWrapper>
            </FlexRow>
          </SectionItem>
        </SectionGroup>
        <SmallSectionHeader>Organization Info</SmallSectionHeader>
        <SectionGroup>
          <SectionItem>
            <EnabledSettingRow
              enabled={settings.auto_invite || false}
              title="Auto Invite Users in Domain"
              enabledDesc="Users with an email address at your domain will be able to create a Truly account."
              disabledDesc="Users must be specifically invited to create a Truly account."
              onChange={saveAutoInvite}
            />
          </SectionItem>
          {recordingToggleVisible && (
            <SectionItem>
              <EnabledSettingRow
                enabled={settings.access_recording || false}
                title="Call Recording Access"
                enabledDesc="Users can view their call recordings."
                disabledDesc="Users can not view their call recordings."
                onChange={saveAccessRecordings}
              />
            </SectionItem>
          )}
          <SectionItem>
            <SelectSettingRow
              title="Routing Protection"
              originalValue={settings.routing_protection}
              options={routingProtectionOptions}
              onSave={saveRoutingProtection}
              data-cy="routing-protection"
              helpText={routingHelpText}
            />
          </SectionItem>
        </SectionGroup>

        <FlexRow alignItems="center">
          <SmallSectionHeader>Call Compliance Settings</SmallSectionHeader>
          <HelpIcon style={{ margin: '20px 0 0 6px' }}>
            <Regular as="p" mt="0px">
              Users not part of a Team will follow company-wide recording
              compliance settings. Team settings will override company
              compliance settings.
            </Regular>

            <Regular as="p" mb="0px">
              For more information, read our Knowledge Base Article for{' '}
              <Link
                href="https://intercom.help/truly_co/en/articles/3021431-understanding-usa-call-recording-compliance"
                newWindow>
                US Recording Compliance
              </Link>{' '}
              and{' '}
              <Link
                href="https://intercom.help/truly_co/en/articles/3021535-understanding-international-call-recording-compliance"
                newWindow>
                International Recording Compliance
              </Link>
              .
            </Regular>
          </HelpIcon>
        </FlexRow>

        <SectionGroup>
          <SectionItem>
            <SelectSettingRow
              data-cy="us-compliance-row"
              title="US Compliance"
              originalValue={callCompliance.usa_call_compliance}
              onSave={saveUsaCallCompliance}
              options={getCallComplianceOptions(
                CallComplianceDomesticOptions,
                false,
              )}
              width="350px"
            />
          </SectionItem>
          <SectionItem>
            <SelectSettingRow
              data-cy="intl-compliance-row"
              title="International Compliance"
              originalValue={callCompliance.international_call_compliance}
              onSave={saveIntlCallCompliance}
              options={getCallComplianceOptions(
                CallComplianceInternationalOptions,
                false,
              )}
              width="350px"
            />
          </SectionItem>
        </SectionGroup>
        <SmallSectionHeader>Data Retention Settings</SmallSectionHeader>
        <SmallSectionHeaderDescription>
          Determines how long data around your calls and messages will be
          available to your organization. Data retention policies are applied
          retroactively.
        </SmallSectionHeaderDescription>
        <SectionGroup>
          <SectionItem data-cy="lg-data-retention-overview">
            <DataRetentionValueDisplay
              editable
              onEdit={showEditPolicy}
              policy={dataRetentionPolicy}
            />
          </SectionItem>
          {teams ? (
            <SectionItem
              {...((teamsWithCustomDataRetention?.length ?? 0) > 0
                ? { onClick: openTeamPolicies }
                : {})}
              data-cy="team-data-retention-overview">
              <FlexRow alignItems="center">
                <MultiAvatar
                  bgColor={colors.white}
                  size={AvatarSizes.Big}
                  avatars={
                    teamsWithCustomDataRetention?.map(team => ({
                      text: team.display_name,
                      colorSeed: team.display_name,
                    })) ?? []
                  }
                />
                <Regular
                  color={colors.trulyDark}
                  ml={`${
                    teamsWithCustomDataRetention?.length === 0
                      ? spaceSizes.sm
                      : spaceSizes.md
                  }px`}>
                  <InlineBold>
                    {teamsWithCustomDataRetention?.length}{' '}
                    {teamsWithCustomDataRetention?.length === 1
                      ? 'Team'
                      : 'Teams'}
                  </InlineBold>{' '}
                  with custom data retention policies
                </Regular>
              </FlexRow>
            </SectionItem>
          ) : (
            <SectionItem>
              <FieldLoader delay={100} />
            </SectionItem>
          )}
        </SectionGroup>

        <SmallSectionHeader>Authentication Settings</SmallSectionHeader>
        <SectionGroup>
          <SectionItem>
            <EnabledSettingRow
              enabled={settings.allow_password_auth ?? true}
              title="Password Authentication"
              enabledDesc="Users will be able to log in via Truly password and OAuth authentication."
              disabledDesc="Users will only be able to sign in via an OAuth Provider."
              onChange={saveAllowPasswordAuth}
            />
          </SectionItem>
        </SectionGroup>
      </ContentContainer>
    </MainContainer>
  )
}

const OrganizationHeading = React.memo(() => (
  <ContentHeading title="Organization Settings" />
))
