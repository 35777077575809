import {
  SET_UNSAVED_CHANGES,
  CLEAR_UNSAVED_CHANGES,
  SHOW_UNSAVED_CHANGES_DIALOG,
  HIDE_UNSAVED_CHANGES_DIALOG,
  CONTINUE_NAVIGATION,
} from './actionTypes'
import { LocationActionPayload } from 'connected-react-router'

export const setUnsavedChanges = (
  title: string,
  description: string,
  allowedNavRegex?: string,
) => ({
  type: SET_UNSAVED_CHANGES,
  payload: {
    title,
    description,
    allowedNavRegex,
  },
})

export const clearUnsavedChanges = () => ({
  type: CLEAR_UNSAVED_CHANGES,
  payload: {},
})

export const showUnsavedChangesDialog = (
  continueAction: LocationActionPayload,
) => ({
  type: SHOW_UNSAVED_CHANGES_DIALOG,
  payload: {
    continueAction,
  },
})

export const hideUnsavedChangesDialog = () => ({
  type: HIDE_UNSAVED_CHANGES_DIALOG,
  payload: {},
})

export const continueNavigation = () => ({
  type: CONTINUE_NAVIGATION,
  payload: {},
})
