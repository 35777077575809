import styled from 'styled-components'
import { FlexRow } from 'js-components'

export const Container = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

export const IconWrapper = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 36px;
  height: 36px;
`

export const DetailedLabelWrapper = styled.div`
  margin-left: 6px;
`
