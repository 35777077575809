import { connect } from 'react-redux'
import Header from '../components/Header/Header'
import { IState, Dispatch } from '../store'
import {
  accountInfo,
  company,
  userLoading,
} from '../reducers/user/userSelectors'

const mapStateToProps = (state: IState) => ({
  userAccount: accountInfo(state),
  company: company(state),
  userLoading: userLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
