import React from 'react'

interface TimeLoopComponentProps {
  loopTime: number
  stages: React.ReactNode[]
}

interface TimeLoopComponentState {
  index: number
}

export class TimeLoopComponent extends React.PureComponent<
  TimeLoopComponentProps,
  TimeLoopComponentState
> {
  private interval: any

  constructor(props: TimeLoopComponentProps) {
    super(props)
    this.state = {
      index: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.cycle, this.props.loopTime)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  cycle = () => {
    this.setState({
      index: (this.state.index + 1) % this.props.stages.length,
    })
  }

  render() {
    return this.props.stages[this.state.index % this.props.stages.length]
  }
}
