import { IHuntGroup, NodeType } from 'truly-ts'

type QueueStrategy = IHuntGroup['queue_strategy']

export const QueueStrategyDisplay: { [key in QueueStrategy]: string } = {
  round_robin: 'Round Robin',
  ring_all: 'Ring All',
  random: 'Random',
  linear: 'Linear',
  least_recent: 'Least Recent',
  fewest_calls: 'Fewest Calls',
}

export const NodeTypeDisplay: { [key in NodeType]: string } = {
  forward_call: 'Forward',
  menu: 'Submenu',
  play_recording: 'Play Recording',
  root_node: '',
  send_message: 'Send to Voicemail',
}

export enum NodeAssignmentType {
  Role = 'role',
  Extension = 'extension',
}
