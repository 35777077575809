import React from 'react'
import { ConfirmDialog, colors } from 'js-components'

interface UnsavedChangesDialogProps {
  show: boolean
  back: () => void
  goOn: () => void
  title: string
  description: string
}

export default function UnsavedChangesDialog(props: UnsavedChangesDialogProps) {
  const { show, back, goOn, title, description } = props

  return (
    <ConfirmDialog
      show={show}
      confirmText="Continue"
      cancelText="Back"
      title={title}
      description={description}
      color={colors.alertRed}
      onCancel={back}
      onConfirm={goOn}
    />
  )
}
