import React, { CSSProperties } from 'react'
import styled from 'styled-components/macro'
import {
  FlexColumn,
  FlexRow,
  Small,
  colors,
  Regular,
  SpinnerMaterial,
} from 'js-components'
import { useDelayedVisibility } from 'truly-utils'

export const MainContainer = styled(FlexColumn)`
  height: 100%;
  max-height: 100%;
`

export const ContentContainer = styled(FlexColumn)<{ padTop?: boolean }>`
  padding: ${props => (props.padTop ? 14 : 0)}px 14px 14px 14px;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const TableFilterWrapper = styled.div<{ inTabList?: boolean }>`
  flex: ${props => (props.inTabList ? 'auto' : 0)};
`

export const TableWrapper = styled(FlexColumn)`
  height: 100%;
`

export const LeftAlignedTableData = styled(FlexColumn)`
  align-items: flex-start;
`

export const CenteredTableData = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`

export const RightAlignedTableData = styled(FlexRow)`
  justify-content: flex-end;
`

export const ActionButton = styled.div<{ cursor: CSSProperties['cursor'] }>`
  margin-right: 4px;
  margin-left: 4px;
  padding: 6px;

  ${props =>
    props.cursor &&
    `cursor: ${props.cursor};
  * {
    cursor: ${props.cursor};
  }`}
`

export const SmallSectionHeader = styled(Small).attrs<typeof Small>({
  bold: true,
})<{ first?: boolean }>`
  color: ${colors.darkGray};
  margin-top: ${props => (props.first ? '6px' : '32px')};
  margin-left: 8px;
  margin-bottom: 14px;
`

export const SmallSectionHeaderDescription = styled(Regular)`
  color: ${colors.darkestGray};
  margin: -10px 8px 20px 8px;
`

export const MediumSectionHeader = styled(Regular).attrs<
  typeof Regular & { color?: string }
>({
  bold: true,
})`
  color: ${props => props.color || colors.accentPurple};
  margin: 16px 4px 16px 4px;
`

export const MediumSectionHeaderDescription = styled(Regular)`
  color: ${colors.darkestGray};
  margin: -10px 4px 20px 4px;
`

const LabelWrapper = styled.div<{ width?: string }>`
  margin-right: 14px;
  margin-left: 6px;
  margin-top: 9px;
  margin-bottom: 9px;
  text-align: right;
  ${props => (props.width ? `width: ${props.width};` : '')}
`

export const Label: React.SFC<{
  children: React.ReactNode
  width?: string
}> = ({ children, width }) => (
  <LabelWrapper width={width}>
    <Regular bold color={colors.trulyDark}>
      {children}
    </Regular>
  </LabelWrapper>
)

export const FullWidthWrapper = styled.div`
  width: 100%;
`

export const FieldLoader = React.memo((props: { delay?: number }) => {
  const { delay } = props
  const loaderProps = useDelayedVisibility(delay || 0, true, { margin: '10px' })
  return (
    <SpinnerMaterial
      strokeWidth={3}
      size="20px"
      color={colors.accentLavender}
      {...loaderProps}
    />
  )
})
