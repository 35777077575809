export enum SaveStatus {
  Unsaved,
  Saving,
  Success,
  Failed,
}

export interface SaveState {
  status: SaveStatus
  id?: number | string
}
