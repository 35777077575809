import React from 'react'
import { IRole } from 'truly-ts'
import { getRequired } from 'truly-utils/macro'
import { DeleteDialog } from 'js-components'

interface TeamDeleteDialogProps {
  show: boolean
  onClose: (deleted: boolean) => void
  onDelete: (id: number, navToTeams: boolean) => void
  selectedTeam?: IRole
  navToTeams?: boolean
}

export default function TeamDeleteDialog(props: TeamDeleteDialogProps) {
  const onDelete = () => {
    props.onDelete(
      getRequired(props.selectedTeam).id,
      props.navToTeams || false,
    )
    props.onClose(true)
  }

  const onClose = () => props.onClose(false)

  const { show, selectedTeam } = props

  return (
    <DeleteDialog
      show={show}
      title={
        selectedTeam
          ? `Delete "${selectedTeam.display_name}"`
          : 'No Team Selected'
      }
      deleteActionText="Delete"
      description={
        selectedTeam &&
        `The “${selectedTeam.display_name}” team will be deleted. Users still assigned to this team will be removed, which may result in a loss of permissions. This action cannot be undone.`
      }
      confirmText="DELETE"
      onCancel={onClose}
      onDelete={onDelete}
    />
  )
}
