import { CallComplianceValue } from 'truly-ts'

export const getCallComplianceOptions = (
  complianceOptions: Array<{
    value: CallComplianceValue | 'inherit'
    label: string
    description: string
  }>,
  canInherit: boolean,
  inheritValue?: CallComplianceValue,
): Array<{
  value: CallComplianceValue | 'inherit'
  label: string
  description: string
}> => {
  let lgDisplay = 'Off'

  if (!canInherit) {
    return complianceOptions
  }

  if (!inheritValue) {
    return [
      // Put in a placeholder while the organization default is loading
      {
        value: 'inherit',
        label: 'Organization Default (...)',
        description: '...',
      },
      ...complianceOptions,
    ]
  }

  switch (inheritValue) {
    case 'on':
      lgDisplay = 'On'
      break
    case 'record-truly':
      lgDisplay = 'Truly Side'
      break
    case 'record-truly-always':
      lgDisplay = 'Truly Side Always'
      break
  }

  const selectedComplianceOption = complianceOptions.find(
    option => option.value === inheritValue,
  )

  return [
    {
      value: 'inherit',
      label: `Organization Default (${lgDisplay})`,
      description: selectedComplianceOption
        ? selectedComplianceOption.description
        : '',
    },
    ...complianceOptions,
  ]
}
