import { connect } from 'react-redux'
import { IState } from '../store'
import * as menusSelectors from '../reducers/phoneMenus/phoneMenusSelectors'
import {
  deletePhoneMenu,
  updatePhoneMenu,
} from '../reducers/phoneMenus/actionCreators'
import {
  deleteMessage,
  fetchMessages,
} from '../reducers/messages/actionCreators'
import PhoneMenusAndRecordings from '../components/PhoneMenusAndRecordings/PhoneMenusAndRecordings'

const mapStateToProps = (state: IState) => ({
  entities: {
    phoneMenuMap: menusSelectors.phoneMenuMap(state),
    nodeMap: menusSelectors.allNodeMap(state),
  },
})

const mapDispatchToProps = {
  deletePhoneMenu,
  deleteMessage,
  fetchMessages,
  updatePhoneMenu,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneMenusAndRecordings)
