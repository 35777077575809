import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import {
  FETCH_ROLES,
  DELETE_ROLE,
  UPDATE_ROLE,
  CREATE_ROLE,
} from './actionTypes'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { IRole, IForm, ILicenseGroupAccount } from 'truly-ts'
import find from 'lodash/find'
import differenceBy from 'lodash/differenceBy'
import {
  rolesFetched,
  fetchRolesFailed,
  deleteRole,
  roleRemoved,
  roleAdded,
  updateRole,
  roleUpdated,
  createRole,
} from './actionCreators'
import { toastr } from 'react-redux-toastr'
import * as rolesSelectors from './rolesSelectors'
import * as formsSelectors from '../forms/formsSelectors'
import * as authSelectors from '../auth/authSelectors'
import * as accountsSelectors from '../accounts/accountsSelectors'
import { devAssert } from 'truly-utils/macro'
import { fetchFormsSuccess } from '../forms/actionCreators'
import { Form } from '../forms/types'
import { push } from 'connected-react-router'
import { logoutUserIfNotAdmin } from '../../utils/saga-utils/user-saga-utils'
import { accountUpdated } from '../accounts/actionCreators'
import isEqual from 'lodash/isEqual'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* rolesSaga() {
  yield all([
    takeLatest(FETCH_ROLES, fetchRolesSaga),
    takeLatest(DELETE_ROLE, deleteRoleSaga),
    takeLatest(UPDATE_ROLE, updateRoleSaga),
    takeLatest(CREATE_ROLE, createRoleSaga),
  ])
}

function* fetchRolesSaga() {
  try {
    const { rolesReq, formsReq } = yield all({
      rolesReq: call(client.lg.fetchRoles),
      formsReq: call(client.forms.fetchForms),
    })

    const roles = rolesReq.data.roles as IRole[]
    const rawForms = formsReq.data.forms as IForm[]
    yield* updateRolesAndForms(rawForms, roles)
  } catch (e) {
    console.error('fetching roles', e)
    toastr.error(
      'An Error Occurred',
      'Unable to fetch teams. Please refresh the page.',
    )
    yield put(fetchRolesFailed())
  }
}

function* createRoleSaga(action: ReturnType<typeof createRole>) {
  try {
    const existingRoles = yield select(rolesSelectors.roles)
    const req = yield call(client.lg.createRole, {
      display_name: action.payload.name,
      department: null,
      position: null,
      permissions: null,
      accounts: null,
    })
    const newRoles = req.data.roles as IRole[]
    const forms = yield select(formsSelectors.forms)
    yield* updateRolesAndForms(forms, newRoles)
    const newRole = findNewRole(action.payload.name, existingRoles, newRoles)
    devAssert(assert => assert(newRole, `Role doesn't exist`)) // assert the role exists
    yield put(push(`/teams/${newRole.id}`))
  } catch (e) {
    console.error('creating role', e)
    toastr.error(
      'An Error Occurred',
      `Unable to create team "${action.payload.name}"`,
    )
  }
}

export function* deleteRoleSaga(action: ReturnType<typeof deleteRole>) {
  const existingRole: IRole = yield* getRoleById(action.payload.id)
  const loggedInUser = yield select(authSelectors.account)
  yield put(roleRemoved(action.payload.id)) // optimisic
  try {
    yield call(client.lg.deleteRole, action.payload.id)
    if (
      (existingRole.accounts || []).some(
        account => account.id === loggedInUser.id,
      )
    ) {
      // if this team had the logged in user assigned, check permissions
      yield call(logoutUserIfNotAdmin)
    }
    if (action.payload.navToTeams) {
      yield put(push('/teams'))
    }
  } catch (e) {
    console.error('delete role', e)
    toastr.error(
      'An Error Occurred',
      `Unable to delete "${existingRole.display_name}". Please try again.`,
    )
    yield put(roleAdded(existingRole)) // revert
  }
}

export function* updateRoleSaga(action: ReturnType<typeof updateRole>) {
  const { role } = action.payload

  if (!role.id) {
    throw new Error(`Unable to update role with no ID: ${JSON.stringify(role)}`)
  }

  const existingRole: IRole = yield* getRoleById(role.id)
  devAssert(assert => assert(role !== existingRole, 'Role was mutated'))

  yield put(roleUpdated(role)) // optimistic
  try {
    if (!isEqual(existingRole.data_retention, role.data_retention)) {
      yield call(
        client.lg.updateRoleDataRetention,
        role.id,
        role.data_retention ?? null,
      )
    }
    yield call(client.lg.updateRole, role)
    yield call(updateAccountChanges, existingRole, role)
    yield call(logoutUserIfAdminRemoved, existingRole, role)
  } catch (e) {
    console.error('update role', e)
    toastr.error(
      'An Error Occurred',
      `Unable to update "${role.display_name}". Please try again.`,
    )
    yield put(roleUpdated(existingRole)) // revert
  }
}

function* getRoleById(id: number) {
  const roles = yield select(rolesSelectors.roles)
  const role = find(roles, { id })
  return role
}

// Workaround because role creation API returns all roles instead of the one created
// So we have to guess which one it is
function findNewRole(
  display: string,
  oldRoles: IRole[],
  newRoles: IRole[],
): IRole {
  const candidateRoles = differenceBy(newRoles, oldRoles, 'id')
  const role = candidateRoles.find(r => r.display_name === display)
  return role || candidateRoles[0]
}

function* updateRolesAndForms(rawForms: IForm[], roles: IRole[]) {
  const forms = rawForms.map(
    (f): Form => ({
      ...f,
      roles: roles.filter(r => r.form && r.form.id === f.id),
    }),
  )
  yield put(rolesFetched(roles))
  yield put(fetchFormsSuccess(forms))
}

function* logoutUserIfAdminRemoved(existingRole: IRole, role: IRole) {
  const loggedInUser = yield select(authSelectors.account)

  // Check if we removed ourself from the role
  if (
    (existingRole.accounts || []).some(
      account => account.id === loggedInUser.id,
    ) &&
    !(role.accounts || []).some(account => account.id === loggedInUser.id)
  ) {
    // Our permission status may have changed, better logout if admin lost
    yield call(logoutUserIfNotAdmin)
  }
}

function* updateAccountChanges(existingRole: IRole, newRole: IRole) {
  // Handle accounts unassigned
  const oldAccounts = existingRole.accounts || []
  const newAccounts = newRole.accounts || []
  const removedAccounts = oldAccounts.filter(
    oldAcct => !newAccounts.some(newAcct => newAcct.id === oldAcct.id),
  )
  const accountsToUnassign = (((yield select(accountsSelectors.accounts)) ||
    []) as ILicenseGroupAccount[]).filter(account =>
    // Get only the accounts that need role_id removed
    removedAccounts.some(removedAccount => removedAccount.id === account.id),
  )
  for (const account of accountsToUnassign) {
    yield put(
      accountUpdated({
        ...account,
        role_id: null,
      }),
    )
  }
  // Don't worry about newly assigned since they're handled elsewhere in the accounts saga
}
