import styled from 'styled-components'
import { colors, FlexRow, FlexColumn } from 'js-components'

export const TabListContainer = styled.div`
  width: 100%;
  padding: 12px;
  padding-left: 18px;
  border-bottom: 1px solid ${colors.lightGray};
`

export const PaddedColumn = styled(FlexColumn)`
  padding-left: 26px;
  padding-right: 26px;
`

export const TimezoneRow = styled(FlexRow)`
  align-items: center;
  height: 88px;
  width: 100%;
`

export const HoursContainer = styled(FlexColumn)`
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const LightHorizontalLine = styled.div`
  width: 100%;
  min-height: 1px;
  max-height: 1px;
  background-color: ${colors.ultraLightGray};
`

export const AvailabilityPickerWrapper = styled.div`
  padding-bottom: 26px;
`
