import { EntityMap, hasEntity, getEntityName } from './Entity'
import { EntityInfo } from 'truly-ts'

export const CustomSearchFunctions = {
  assignmentSearch: (entityMap: EntityMap) => (
    val: { entity: EntityInfo | null },
    search: string,
  ) => {
    if (!val.entity) return false
    if (!hasEntity(entityMap, val.entity.type, val.entity.id)) return false
    const entityName = getEntityName(entityMap, val.entity.type, val.entity.id)
    const asString = (entityName || '').toLowerCase()
    return asString.indexOf(search) >= 0
  },
}
