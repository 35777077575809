import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import {
  FETCH_LG_SETTINGS,
  UPDATE_LG_COMPANY,
  UPDATE_LG_AUTO_INVITE,
  UPDATE_LG_ACCESS_RECORDINGS,
  UPDATE_LG_CALL_COMPLIANCE,
  UPDATE_LG_ROUTING_PROTECTION,
  UPDATE_LG_DATA_RETENTION,
  UPDATE_LG_ALLOW_PASSWORD_AUTH,
} from './actionTypes'
import {
  fetchLicenseGroupSuccess,
  fetchLicenseGroupFail,
  updateLgCompany,
  lgCompanyChanged,
  updateLgAutoInvite,
  lgAutoInviteChanged,
  updateLgRoutingProtection,
  lgRoutingProtectionChanged,
  updateLgAccessRecordings,
  lgAccessRecordingsChanged,
  updateLgCallCompliance,
  lgCallComplianceChanged,
  updateLgDataRetention,
  lgDataRetentionChanged,
  updateLgAllowPasswordAuth,
  lgAllowPasswordAuthChanged,
} from './actionCreators'
import { toastr } from 'react-redux-toastr'
import * as selectors from './lgSettingsSelectors'
import { ICallComplianceSettings } from 'truly-ts'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* lgSettingsSaga() {
  yield all([
    takeLatest(FETCH_LG_SETTINGS, fetchLgSettingsSaga),
    takeLatest(UPDATE_LG_COMPANY, updateLgCompanySaga),
    takeLatest(UPDATE_LG_AUTO_INVITE, updateLgAutoInviteSaga),
    takeLatest(UPDATE_LG_ROUTING_PROTECTION, updateLgRoutingProtectionSaga),
    takeLatest(UPDATE_LG_ACCESS_RECORDINGS, updateLgAccessRecordingsSaga),
    takeLatest(UPDATE_LG_CALL_COMPLIANCE, updateLgCallComplianceSaga),
    takeLatest(UPDATE_LG_DATA_RETENTION, updateLgDataRetentionSaga),
    takeLatest(UPDATE_LG_ALLOW_PASSWORD_AUTH, updateLgAllowPasswordAuthSaga),
  ])
}

function* fetchLgSettingsSaga() {
  try {
    const req = yield call(client.lg.fetchLicenseGroup)

    yield put(fetchLicenseGroupSuccess(req.data.license_group))
  } catch (e) {
    yield put(fetchLicenseGroupFail(e))
  }
}

function* updateLgCompanySaga(action: ReturnType<typeof updateLgCompany>) {
  const existingName = yield select(selectors.companyName)
  try {
    yield put(lgCompanyChanged(action.payload)) // optimistic update
    yield call(client.lg.updateCompanyName, action.payload)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save company name. Please try again.',
    )
    yield put(lgCompanyChanged(existingName)) // revert
  }
}

function* updateLgCallComplianceSaga(
  action: ReturnType<typeof updateLgCallCompliance>,
) {
  const existingCallCompliance = yield select(selectors.callCompliance)
  try {
    const newCallCompliance: ICallComplianceSettings = {
      usa_call_compliance: action.payload.usaCallCompliance,
      international_call_compliance: action.payload.intlCallCompliance,
    }
    yield put(lgCallComplianceChanged(newCallCompliance)) // optimistic update
    yield call(client.lg.updateCallCompliance, newCallCompliance)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save call compliance settings. Please try again.',
    )
    yield put(lgCallComplianceChanged(existingCallCompliance)) // revert
  }
}

function* updateLgAutoInviteSaga(
  action: ReturnType<typeof updateLgAutoInvite>,
) {
  const existingAutoInvite = yield select(selectors.autoInvite)
  try {
    yield put(lgAutoInviteChanged(action.payload)) // optimistic update
    yield call(client.lg.updateAutoInvite, action.payload)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save auto invite settings. Please try again.',
    )
    yield put(lgAutoInviteChanged(existingAutoInvite)) // revert
  }
}

function* updateLgRoutingProtectionSaga(
  action: ReturnType<typeof updateLgRoutingProtection>,
) {
  const existingRoutingProtection = yield select(selectors.routingProtection)
  try {
    yield put(lgRoutingProtectionChanged(action.payload)) // optimistic update
    yield call(client.lg.updateRoutingProtection, action.payload)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save routing protection settings. Please try again.',
    )
    yield put(lgRoutingProtectionChanged(existingRoutingProtection)) // revert
  }
}

function* updateLgAccessRecordingsSaga(
  action: ReturnType<typeof updateLgAccessRecordings>,
) {
  const existingAccessRecordings = yield select(selectors.accessRecordings)
  try {
    yield put(lgAccessRecordingsChanged(action.payload)) // optimistic update
    yield call(client.lg.updateAccessRecordings, action.payload)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save access recording settings. Please try again.',
    )
    yield put(lgAccessRecordingsChanged(existingAccessRecordings)) // revert
  }
}

function* updateLgDataRetentionSaga(
  action: ReturnType<typeof updateLgDataRetention>,
) {
  const existingDataRetention = yield select(selectors.dataRetention)
  try {
    yield put(lgDataRetentionChanged(action.payload.policy))
    yield call(client.lg.updateDataRetention, action.payload.policy)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save data retention settings. Please try again.',
    )
    yield put(lgDataRetentionChanged(existingDataRetention))
  }
}

function* updateLgAllowPasswordAuthSaga(
  action: ReturnType<typeof updateLgAllowPasswordAuth>,
) {
  const existingPasswordAuth = yield select(selectors.allowPasswordAuth)
  try {
    yield put(lgAllowPasswordAuthChanged(action.payload)) // optimistic update
    yield call(client.lg.updateAllowPasswordAuth, action.payload)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to save password auth settings. Please try again.',
    )
    yield put(lgAllowPasswordAuthChanged(existingPasswordAuth)) // revert
  }
}
