import * as React from 'react'
import { IForwardingNode, IExtension, IRole, IHuntGroup } from 'truly-ts'
import {
  FlexRow,
  Small,
  colors,
  ModalLayout,
  Select,
  Regular,
  TextInput,
  CircleRemove,
  Link,
  spaceSizes,
} from 'js-components'
import memoize from 'lodash/memoize'
import { RemoveButton, NumericInputWrapper } from '../Styles'
import {
  createDefaultHuntGroup,
  DefaultQueueMaxTime,
  DefaultQueueRingCycles,
  DefaultQueueTimeout,
} from '../../../utils/model-utils/node-utils'
import UsersTeamsEditRowContainer from '../../../containers/UsersTeamsEditRowContainer'
import {
  NodeValidationContext,
  NodeValidationContextValue,
  ForwardingNodeValidationState,
  HuntGroupValidationSubState,
} from '../../../utils/model-utils/node-validation'
import { ValidationHandler } from 'truly-utils'

const usersTeamsValidator = new ValidationHandler<any[]>(
  'Please add a user or role',
  values => values.length > 0,
)
const positiveNumberValidator = new ValidationHandler<number>(
  'Please enter a positive number',
  n => !Number.isNaN(n) && n > 0,
)

const QueueStrategyOptions: Array<{
  label: string
  value: IHuntGroup['queue_strategy']
}> = [
  {
    label: 'Ring All',
    value: 'ring_all',
  },
  {
    label: 'Round Robin',
    value: 'round_robin',
  },
  // TODO enable once verified on the back-end
  // {
  //   label: 'Fewest Calls',
  //   value: 'fewest_calls'
  // },
  // {
  //   label: 'Least Recent',
  //   value: 'least_recent'
  // },
  // {
  //   label: 'Linear',
  //   value: 'linear'
  // },
  // {
  //   label: 'Random',
  //   value: 'random'
  // }
]

interface NodeForwardingEditRowProps {
  node: IForwardingNode
  extensions: IExtension[]
  teams: IRole[]
  onNodeChange: (node: IForwardingNode) => void
}

export default class NodeForwardingEditRow extends React.Component<NodeForwardingEditRowProps> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<ForwardingNodeValidationState> // docs say to do this

  componentDidMount() {
    this.validateNode(this.props.node)
  }

  validateNode(node: IForwardingNode) {
    this.context.validationChange({
      huntGroups: node.hunt_groups.map(
        (hg, idx): HuntGroupValidationSubState => {
          return {
            userTeams: usersTeamsValidator.validate([
              ...node.extensions.filter(e => e.hunt_group === idx),
              ...node.roles.filter(r => r.hunt_group === idx),
            ]),
            queueTimeout: positiveNumberValidator.validate(hg.queue_timeout),
            queueMaxTime:
              hg.queue_strategy === 'round_robin'
                ? positiveNumberValidator.validate(hg.queue_max_time ?? 0)
                : ValidationHandler.createValidState(),
            ringCycles:
              hg.queue_strategy === 'ring_all'
                ? positiveNumberValidator.validate(hg.queue_ring_cycles ?? 0)
                : ValidationHandler.createValidState(),
          }
        },
      ),
    })
  }

  onNodeChange = (node: IForwardingNode) => {
    this.props.onNodeChange(node)
    this.validateNode(node)
  }

  updateHuntGroupValue = <T extends keyof IHuntGroup>(
    huntGroups: IHuntGroup[],
    huntGroup: number,
    key: T,
    value: IHuntGroup[T],
  ): IHuntGroup[] => {
    return huntGroups.map((hg, idx) => {
      if (idx !== huntGroup) return hg
      return {
        ...hg,
        [key]: value,
      }
    })
  }

  onChangeQueueMaxTime = memoize((huntGroup: number) => (value: string) => {
    const strippedValue = value.replace(/\D/g, '') // remove non digits
    const queueMaxTime = parseInt(strippedValue, 10)
    this.onNodeChange({
      ...this.props.node,
      hunt_groups: this.updateHuntGroupValue(
        this.props.node.hunt_groups,
        huntGroup,
        'queue_max_time',
        queueMaxTime,
      ),
    })
  })

  onChangeQueueTimeout = memoize((huntGroup: number) => (value: string) => {
    const strippedValue = value.replace(/\D/g, '') // remove non digits
    const queueTimeout = parseInt(strippedValue, 10)
    this.onNodeChange({
      ...this.props.node,
      hunt_groups: this.updateHuntGroupValue(
        this.props.node.hunt_groups,
        huntGroup,
        'queue_timeout',
        queueTimeout,
      ),
    })
  })

  onChangeQueueRingCycles = memoize((huntGroup: number) => (value: string) => {
    const strippedValue = value.replace(/\D/g, '') // remove non digits
    const queueRingCycles = parseInt(strippedValue, 10)
    this.onNodeChange({
      ...this.props.node,
      hunt_groups: this.updateHuntGroupValue(
        this.props.node.hunt_groups,
        huntGroup,
        'queue_ring_cycles',
        queueRingCycles,
      ),
    })
  })

  onChangeQueueStrategy = memoize((huntGroup: number) => (value: string) => {
    const queueStrategy = value as IHuntGroup['queue_strategy']
    let newHuntGroups = this.updateHuntGroupValue(
      this.props.node.hunt_groups,
      huntGroup,
      'queue_strategy',
      queueStrategy,
    )

    // Set the default value in case it was deleted
    newHuntGroups = this.updateHuntGroupValue(
      newHuntGroups,
      huntGroup,
      'queue_timeout',
      DefaultQueueTimeout,
    )
    newHuntGroups = this.updateHuntGroupValue(
      newHuntGroups,
      huntGroup,
      'queue_ring_cycles',
      DefaultQueueRingCycles,
    )

    // Change the mapped values if N/A or not
    if (queueStrategy === 'ring_all') {
      newHuntGroups = this.updateHuntGroupValue(
        newHuntGroups,
        huntGroup,
        'queue_ring_cycles',
        DefaultQueueRingCycles,
      )
      newHuntGroups = this.updateHuntGroupValue(
        newHuntGroups,
        huntGroup,
        'queue_max_time',
        null,
      )
    } else if (queueStrategy === 'round_robin') {
      newHuntGroups = this.updateHuntGroupValue(
        newHuntGroups,
        huntGroup,
        'queue_ring_cycles',
        null,
      )
      newHuntGroups = this.updateHuntGroupValue(
        newHuntGroups,
        huntGroup,
        'queue_max_time',
        DefaultQueueMaxTime,
      )
    }

    this.onNodeChange({
      ...this.props.node,
      hunt_groups: newHuntGroups,
    })
  })

  addRingGroup = () => {
    const newHuntGroups = [...this.props.node.hunt_groups]
    newHuntGroups.push(createDefaultHuntGroup(newHuntGroups.length))
    this.onNodeChange({
      ...this.props.node,
      hunt_groups: newHuntGroups,
    })
  }

  onRemoveHuntGroup = (huntGroup: number) => () => {
    const newHuntGroups = [...this.props.node.hunt_groups]
    newHuntGroups.splice(huntGroup, 1)

    // remove extensions and roles for hunt group and shift down
    const newExtensions = this.props.node.extensions
      .filter(e => e.hunt_group !== huntGroup)
      .map(e => ({
        ...e,
        hunt_group: e.hunt_group > huntGroup ? e.hunt_group - 1 : e.hunt_group,
      }))
    const newRoles = this.props.node.roles
      .filter(r => r.hunt_group !== huntGroup)
      .map(r => ({
        ...r,
        hunt_group: r.hunt_group > huntGroup ? r.hunt_group - 1 : r.hunt_group,
      }))

    this.onNodeChange({
      ...this.props.node,
      extensions: newExtensions,
      roles: newRoles,
      hunt_groups: newHuntGroups,
    })
  }

  getAllowedExtensions(huntGroupNum: number): IExtension[] {
    // We need to disable extensions that are already in other hunt groups
    const usedExtensions = this.props.node.extensions.filter(
      e => e.hunt_group !== huntGroupNum,
    )
    return this.props.extensions.filter(
      e => !usedExtensions.some(u => u.id === e.id),
    )
  }

  getAllowedTeams(huntGroupNum: number): IRole[] {
    // We need to disable teams that are already in other hunt groups
    const usedTeams = this.props.node.roles.filter(
      e => e.hunt_group !== huntGroupNum,
    )
    return this.props.teams.filter(t => !usedTeams.some(u => u.id === t.id))
  }

  render() {
    const { node } = this.props
    const { validationState } = this.context

    return node.hunt_groups.map((hg, huntGroupNum) => (
      <React.Fragment key={huntGroupNum}>
        {node.hunt_groups.length > 1 && (
          <FlexRow
            alignItems="center"
            width="100%"
            style={{ flex: '0 0 auto' }}>
            <Small
              bold
              color={colors.darkestGray}
              ml="150px"
              mb={`${spaceSizes.sm}px`}>
              {huntGroupNum === 0
                ? 'first ring these users'
                : 'then these users'}
            </Small>
          </FlexRow>
        )}
        <UsersTeamsEditRowContainer
          node={node}
          onNodeChange={this.onNodeChange}
          extensions={this.getAllowedExtensions(huntGroupNum)}
          teams={this.getAllowedTeams(huntGroupNum)}
          huntGroup={huntGroupNum}
          validationState={
            validationState.showErrors
              ? validationState.huntGroups?.[huntGroupNum]?.userTeams
              : undefined
          }
        />
        <ModalLayout.FieldRow
          label="Ring Strategy:"
          labelWidth="150px"
          labelColor={colors.darkGray}
          shouldPadRight
          fieldWidth="100%"
          validationState={
            validationState.showErrors
              ? node.hunt_groups[huntGroupNum].queue_strategy === 'ring_all'
                ? validationState.huntGroups[huntGroupNum].queueTimeout
                : validationState.huntGroups[huntGroupNum].queueMaxTime
              : null
          }>
          <FlexRow alignItems="center">
            <div style={{ width: '120px' }}>
              <Select
                value={node.hunt_groups[huntGroupNum].queue_strategy}
                options={QueueStrategyOptions}
                onChange={this.onChangeQueueStrategy(huntGroupNum)}
                data-cy="ring-strategy-options"
              />
            </div>
            {node.hunt_groups[huntGroupNum].queue_strategy === 'ring_all' && (
              <>
                <Regular
                  mr={`${spaceSizes.sm}px`}
                  ml={`${spaceSizes.sm}px`}
                  color={colors.trulyDark}>
                  users simultaneously for
                </Regular>
                <NumericInputWrapper>
                  <TextInput
                    value={
                      Number.isNaN(node.hunt_groups[huntGroupNum].queue_timeout)
                        ? ''
                        : `${node.hunt_groups[huntGroupNum].queue_timeout}`
                    }
                    onChange={this.onChangeQueueTimeout(huntGroupNum)}
                    hideClearButton
                    validationState={
                      validationState.showErrors
                        ? validationState.huntGroups[huntGroupNum].queueTimeout
                        : undefined
                    }
                    data-cy="ring-all-timeout-input"
                  />
                </NumericInputWrapper>
                <Regular ml={`${spaceSizes.sm}px`} color={colors.trulyDark}>
                  seconds
                </Regular>
              </>
            )}
            {node.hunt_groups[huntGroupNum].queue_strategy ===
              'round_robin' && (
              <>
                <Regular
                  mr={`${spaceSizes.sm}px`}
                  ml={`${spaceSizes.sm}px`}
                  color={colors.trulyDark}>
                  while keeping callers in queue for up to
                </Regular>
                <NumericInputWrapper>
                  <TextInput
                    value={
                      Number.isNaN(
                        node.hunt_groups[huntGroupNum].queue_max_time,
                      )
                        ? ''
                        : `${node.hunt_groups[huntGroupNum].queue_max_time}`
                    }
                    onChange={this.onChangeQueueMaxTime(huntGroupNum)}
                    hideClearButton
                    validationState={
                      validationState.showErrors
                        ? validationState.huntGroups[huntGroupNum].queueMaxTime
                        : undefined
                    }
                    data-cy="round-robin-timeout-input"
                  />
                </NumericInputWrapper>
                <Regular ml={`${spaceSizes.sm}px`} color={colors.trulyDark}>
                  minutes
                </Regular>
              </>
            )}
          </FlexRow>
        </ModalLayout.FieldRow>
        {node.hunt_groups[huntGroupNum].queue_strategy === 'ring_all' && (
          <ModalLayout.FieldRow
            label=""
            labelWidth="150px"
            fieldWidth="100%"
            shouldPadRight
            validationState={
              validationState.showErrors
                ? validationState.huntGroups[huntGroupNum].ringCycles
                : null
            }>
            <FlexRow
              alignItems="center"
              width="100%"
              style={{ position: 'relative' }}>
              <Regular color={colors.trulyDark} mr={`${spaceSizes.sm}px`}>
                up to
              </Regular>
              <NumericInputWrapper>
                <TextInput
                  value={
                    Number.isNaN(
                      node.hunt_groups[huntGroupNum].queue_ring_cycles,
                    )
                      ? ''
                      : `${node.hunt_groups[huntGroupNum].queue_ring_cycles}`
                  }
                  onChange={this.onChangeQueueRingCycles(huntGroupNum)}
                  hideClearButton
                  validationState={
                    validationState.showErrors
                      ? validationState.huntGroups[huntGroupNum].ringCycles
                      : undefined
                  }
                  data-cy="ring-all-attempts-input"
                />
              </NumericInputWrapper>
              <Regular color={colors.trulyDark} ml={`${spaceSizes.sm}px`}>
                time
                {node.hunt_groups[huntGroupNum].queue_ring_cycles === 1
                  ? ''
                  : 's'}{' '}
                if nobody answers
              </Regular>
              {huntGroupNum > 0 &&
                huntGroupNum !== node.hunt_groups.length - 1 && ( // all the in-betweens
                  <RemoveButton
                    role="button"
                    onClick={this.onRemoveHuntGroup(huntGroupNum)}
                    style={{ marginTop: `${spaceSizes.sm}px` }}>
                    <CircleRemove width="22px" height="22px" />
                  </RemoveButton>
                )}
            </FlexRow>
          </ModalLayout.FieldRow>
        )}
        {node.hunt_groups[huntGroupNum].queue_strategy === 'round_robin' && (
          <ModalLayout.FieldRow
            label=""
            labelWidth="150px"
            fieldWidth="100%"
            shouldPadRight
            validationState={
              validationState.showErrors
                ? validationState.huntGroups[huntGroupNum].ringCycles
                : null
            }>
            <FlexRow alignItems="center" width="100%">
              <Regular
                mr={`${spaceSizes.sm}px`}
                ml={`${spaceSizes.sm}px`}
                color={colors.trulyDark}>
                and up to
              </Regular>
              <NumericInputWrapper>
                <TextInput
                  value={
                    Number.isNaN(node.hunt_groups[huntGroupNum].queue_timeout)
                      ? ''
                      : `${node.hunt_groups[huntGroupNum].queue_timeout}`
                  }
                  onChange={this.onChangeQueueTimeout(huntGroupNum)}
                  hideClearButton
                  validationState={
                    validationState.showErrors
                      ? validationState.huntGroups[huntGroupNum].queueTimeout
                      : undefined
                  }
                  data-cy="round-robin-timeout-input"
                />
              </NumericInputWrapper>
              <Regular ml={`${spaceSizes.sm}px`} color={colors.trulyDark}>
                seconds per user
              </Regular>
            </FlexRow>
          </ModalLayout.FieldRow>
        )}
        {huntGroupNum === node.hunt_groups.length - 1 && ( // last one
          <ModalLayout.Row>
            <FlexRow
              justifyContent="center"
              alignItems="center"
              width="100%"
              style={{ position: 'relative' }}>
              <Link onClick={this.addRingGroup}>
                <Small bold>add another ring group</Small>
              </Link>
              {huntGroupNum > 0 && (
                <RemoveButton
                  role="button"
                  onClick={this.onRemoveHuntGroup(huntGroupNum)}>
                  <CircleRemove width="22px" height="22px" />
                </RemoveButton>
              )}
            </FlexRow>
          </ModalLayout.Row>
        )}
        <ModalLayout.HorizontalLine />
      </React.Fragment>
    ))
  }
}
