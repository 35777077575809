import createFlags, { Computable } from 'flag'
import { createReduxBindings } from 'flag/redux'

// Usage: https://github.com/garbles/flag

export interface TrulyFlags {
  // Example flag:
  // myFeature: boolean;
  dataRetentionAllowMinutes: boolean
}

export const DefaultFlags: Computable<TrulyFlags> = {
  // Example default setting:
  // myFeature: false
  dataRetentionAllowMinutes: false,
}

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<TrulyFlags>()
const {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
} = createReduxBindings(FlagsProvider)

export {
  Flag,
  useFlag,
  useFlags,
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
}
