import { useState, useCallback, useDebugValue } from 'react'

export default function useClearableState<T>(
  defaultValue: T,
): [T, (v: T) => void, () => void] {
  const [value, setValue] = useState(defaultValue)
  useDebugValue(value ? 'Has Value' : 'Cleared')
  const clearValue = useCallback(() => setValue(defaultValue), [defaultValue])
  return [value, setValue, clearValue]
}
