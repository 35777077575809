import * as React from 'react'
import { IForwardingNode, IExtension, IRole } from 'truly-ts'
import {
  NodeRulesState,
  nodeRulesToState,
  createDefaultRuleState,
  mergeRules,
  nodeStateToRules,
} from '../../../utils/model-utils/node-rules-utils'
import NodeForwardingEditRow from './NodeForwardingEditRow'
import { ModalLayout, colors, FlexRow, TextInput, Regular } from 'js-components'
import NodeCallForwardingOptions from './NodeCallForwardingOptions'
import {
  NodeValidationContext,
  NodeValidationContextValue,
  ForwardingNodeValidationState,
} from '../../../utils/model-utils/node-validation'
import { ValidationHandler, isValidEmail } from 'truly-utils'
import { EntityOption } from '../../../utils/Entity'
import sortBy from 'lodash/sortBy'
import NodeRulesRowContainer from '../../../containers/NodeRulesRowContainer'
import EnabledSettingRow from '../../EnabledSettingRow/EnabledSettingRow'

const wrapupValidator = new ValidationHandler<number>(
  'Please enter a wrap up time',
  n => !Number.isNaN(n) && n >= 0,
)

const emailRuleValidator = new ValidationHandler<NodeRulesState>(
  'Please enter a valid email',
  r =>
    !r.sendEmail.enabled ||
    (r.sendEmail.enabled && isValidEmail(r.sendEmail.email)),
)

interface ForwardCallEditProps {
  node: IForwardingNode
  extensions: IExtension[]
  teams: IRole[]
  onChange: (node: IForwardingNode) => void
}

interface ForwardCallEditState {
  rules: {
    rulesNodeMissedWithVoicemail: NodeRulesState
    rulesNodeMissedWithoutVoicemail: NodeRulesState
    rulesNodeAnswered: NodeRulesState
  }
  userOptions: EntityOption[]
}

export default class ForwardCallEdit extends React.Component<
  ForwardCallEditProps,
  ForwardCallEditState
> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<ForwardingNodeValidationState> // docs say to do this

  constructor(props: ForwardCallEditProps) {
    super(props)

    this.state = this.initialState()
  }

  componentDidMount() {
    this.validateNode(this.props.node, this.state.rules)
  }

  componentDidUpdate(prevProps: ForwardCallEditProps) {
    if (prevProps.node.id !== this.props.node.id) {
      this.setState(this.initialState())
    }
  }

  initialState(): ForwardCallEditState {
    const { node, extensions } = this.props
    return {
      rules: this.ruleStatesFromNode(node),
      userOptions: sortBy(
        [
          ...extensions.map(
            extension =>
              ({
                entity_id: extension.id,
                name: extension.name,
                entity_type: 'extension',
                description: '',
              } as EntityOption),
          ),
        ],
        (o: EntityOption) => o.name,
      ),
    }
  }

  validateNode(node: IForwardingNode, rules: ForwardCallEditState['rules']) {
    const { validationChange } = this.context
    validationChange({
      wrapup: wrapupValidator.validate(node.wrapup_time),
      nodeMissedWithVM: {
        email: emailRuleValidator.validate(rules.rulesNodeMissedWithVoicemail),
      },
      nodeMissedWithoutVM: {
        email: emailRuleValidator.validate(
          rules.rulesNodeMissedWithoutVoicemail,
        ),
      },
    })
  }

  ruleStatesFromNode = (
    node?: IForwardingNode,
  ): ForwardCallEditState['rules'] => {
    return {
      rulesNodeMissedWithVoicemail: node
        ? nodeRulesToState(
            node.notification_rules ?? [],
            node.sync_rules ?? [],
            'node_missed',
            true,
          )
        : createDefaultRuleState(),
      rulesNodeMissedWithoutVoicemail: node
        ? nodeRulesToState(
            node.notification_rules ?? [],
            node.sync_rules ?? [],
            'node_missed',
            false,
          )
        : createDefaultRuleState(),
      rulesNodeAnswered: node
        ? nodeRulesToState(
            node.notification_rules ?? [],
            node.sync_rules ?? [],
            'node_answered',
          )
        : createDefaultRuleState(),
    }
  }

  onChangeWrapupTime = (value: string) => {
    const strippedValue = value.replace(/\D/g, '') // remove non digits
    const wrapupTime = parseInt(strippedValue, 10)
    const newNode = {
      ...this.props.node,
      wrapup_time: wrapupTime,
    }
    this.props.onChange(newNode)
    this.validateNode(newNode, this.state.rules)
  }

  updateNodeRulesInNode(
    existingNode: IForwardingNode,
    ruleState: ForwardCallEditState['rules'],
  ): IForwardingNode {
    const { notificationRules, syncRules } = mergeRules(
      nodeStateToRules(
        ruleState.rulesNodeMissedWithVoicemail,
        'node_missed',
        true,
      ),
      nodeStateToRules(
        ruleState.rulesNodeMissedWithoutVoicemail,
        'node_missed',
        false,
      ),
      nodeStateToRules(ruleState.rulesNodeAnswered, 'node_answered'),
    )
    const node = {
      ...existingNode,
      notification_rules: notificationRules,
      sync_rules: syncRules,
    }
    return node
  }

  onRecordCallsChange = (enabled: boolean) => {
    const newNode = {
      ...this.props.node,
      record_calls: enabled,
    }
    this.props.onChange(newNode)
    this.validateNode(newNode, this.state.rules)
  }

  onRulesChanged = (
    key: keyof ForwardCallEditState['rules'],
    rules: NodeRulesState,
  ) => {
    const newRuleState: ForwardCallEditState['rules'] = {
      ...this.state.rules,
      [key]: rules,
    }
    const node = this.updateNodeRulesInNode(this.props.node, newRuleState)
    this.setState({
      rules: newRuleState,
    })
    this.props.onChange(node)
    this.validateNode(node, newRuleState)
  }

  onRulesNodeMissedWithVoicemailChanged = (rules: NodeRulesState) =>
    this.onRulesChanged('rulesNodeMissedWithVoicemail', rules)
  onRulesNodeMissedWithoutVoicemailChanged = (rules: NodeRulesState) =>
    this.onRulesChanged('rulesNodeMissedWithoutVoicemail', rules)
  onRulesNodeAnsweredChanged = (rules: NodeRulesState) =>
    this.onRulesChanged('rulesNodeAnswered', rules)

  render() {
    const { node, extensions, teams } = this.props
    const { rules, userOptions } = this.state
    const { validationState } = this.context

    return (
      <>
        <NodeForwardingEditRow
          node={node as IForwardingNode}
          onNodeChange={this.props.onChange}
          extensions={extensions}
          teams={teams}
        />
        <ModalLayout.FieldRow
          label="Wrap Up:"
          labelWidth="150px"
          labelColor={colors.darkGray}
          shouldPadRight
          fieldWidth="100%"
          validationState={
            validationState.showErrors ? validationState.wrapup : null
          }>
          <FlexRow alignItems="center">
            <div style={{ width: '80px' }}>
              <TextInput
                value={
                  Number.isNaN(node.wrapup_time) ? '' : `${node.wrapup_time}`
                }
                onChange={this.onChangeWrapupTime}
                hideClearButton
                validationState={
                  validationState.showErrors
                    ? validationState.wrapup
                    : undefined
                }
                data-cy="wrap-up-input"
              />
            </div>
            <Regular ml="8px" color={colors.trulyDark}>
              seconds of wrap up allowed after call
            </Regular>
          </FlexRow>
        </ModalLayout.FieldRow>
        <ModalLayout.HorizontalLine />
        <ModalLayout.Row>
          <NodeRulesRowContainer
            label="Perform actions when a call with voicemail is missed:"
            rules={rules.rulesNodeMissedWithVoicemail}
            showNotificationOptions
            showSyncOptions
            assignableSalesforce
            onChange={this.onRulesNodeMissedWithVoicemailChanged}
            emailValidationState={
              validationState.showErrors
                ? validationState.nodeMissedWithVM?.email
                : undefined
            }
            salesforceOptions={userOptions}
            node={node as IForwardingNode}
          />
          <NodeRulesRowContainer
            label="Perform actions when a call with no voicemail is missed:"
            rules={rules.rulesNodeMissedWithoutVoicemail}
            showNotificationOptions
            showSyncOptions
            assignableSalesforce
            onChange={this.onRulesNodeMissedWithoutVoicemailChanged}
            emailValidationState={
              validationState.showErrors
                ? validationState.nodeMissedWithoutVM?.email
                : undefined
            }
            salesforceOptions={userOptions}
            node={node as IForwardingNode}
          />
          <NodeRulesRowContainer
            label="Perform actions when a call is answered:"
            rules={rules.rulesNodeAnswered}
            showSyncOptions
            onChange={this.onRulesNodeAnsweredChanged}
          />
        </ModalLayout.Row>
        <ModalLayout.HorizontalLine />
        <ModalLayout.Row>
          <NodeCallForwardingOptions
            node={node as IForwardingNode}
            onNodeChange={this.props.onChange}
          />
        </ModalLayout.Row>
        <ModalLayout.LightHorizontalLine />
        <ModalLayout.Row>
          <EnabledSettingRow
            enabled={node.record_calls || false}
            onChange={this.onRecordCallsChange}
            title="Inbound Call Recording"
            enabledDesc="This setting will take precedence over org or team compliance settings."
            disabledDesc="This setting will take precedence over org or team compliance settings."
          />
        </ModalLayout.Row>
        <ModalLayout.LightHorizontalLine />
      </>
    )
  }
}
