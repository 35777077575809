export const CALL_EVENTS = {
  CALL_ANSWERED: 'call_answered',
  CALL_DIAL_EXTENSION_ENDED: 'call_dial_extension_ended',
  CALL_DIAL_EXTENSION_START: 'call_dial_extension_start',
  CALL_ENDED: 'call_ended',
  CALL_FIELDS_UPDATED: 'call_fields_updated',
  CALL_NOTE: 'call_note',
  CALL_PARKED: 'call_parked',
  CALL_START: 'call_start',
  CALL_SYNC: 'call_sync',
  CONFERENCE_CALL_PARTICIPANT: 'conference_call_participant',
  RECORDING_READY: 'recording_ready',
  RECORDING_STARTED: 'recording_started',
  RECORDING_STOPPED: 'recording_stopped',
  VOICEMAIL: 'voicemail',
  VOICEMAIL_TRANSCRIPT: 'voicemail_transcript',
}

export const DISPLAY_CALL_EVENTS: {
  [key: string]: string
} = {
  call_answered: 'Call Answered',
  call_dial_extension_ended: 'Call Dial Extension Ended',
  call_dial_extension_start: 'Call Dial Extension Start',
  call_ended: 'Call Ended',
  call_fields_updated: 'Call Fields Updated',
  call_note: 'Call Note',
  call_parked: 'Call Parked',
  call_start: 'Call Start',
  call_sync: 'Call Sync',
  conference_call_participant: 'Conference Call Participant',
  recording_ready: 'Recording Ready',
  recording_started: 'Recording Started',
  recording_stopped: 'Recording Stopped',
  voicemail: 'Voicemail',
  voicemail_transcript: 'Voicemail Transcript',
}

export const SMS_EVENTS = {
  PENDING: 'sms_pending',
  DELIVERED: 'sms_delivered',
  FAILED: 'sms_failed',
  RECEIVED: 'sms_received',
}

export const DISPLAY_SMS_EVENTS: {
  [key: string]: string
} = {
  sms_pending: 'SMS Pending',
  sms_delivered: 'SMS Delivered',
  sms_failed: 'SMS Failed',
  sms_received: 'SMS Received',
}
