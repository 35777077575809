import {
  FINISH_OAUTH,
  LOGOUT,
  GET_SESSION_TOKEN,
  LOGGED_IN,
} from './actionTypes'

import { IAccount } from 'truly-ts'

export const logout = () => ({
  type: LOGOUT,
  payload: {},
})

export const getSessionToken = (
  token: string,
  provider: string,
  redirectUrl: string,
) => ({
  payload: {
    token,
    provider,
    redirectUrl,
  },
  type: GET_SESSION_TOKEN,
})

export const finishOauth = (token?: string) => ({
  type: FINISH_OAUTH,
  payload: {
    token,
  },
})

export const loggedIn = (account: IAccount) => ({
  type: LOGGED_IN,
  payload: {
    account,
  },
})
