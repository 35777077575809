import React, { useState, useEffect } from 'react'
import {
  PopupModal,
  ModalLayout,
  FlexRow,
  Switch,
  colors,
  InlineTextEdit,
  SpinnerMaterial,
} from 'js-components'
import { SpinnerContainer } from './Styles'
import { resetUserPassword } from '../../reducers/accounts/actionCreators'
import { DetailedLabel, ValueWrapper } from '../EnabledSettingRow/Styles'
import { confirmPasswordValidationHandler } from '../../utils/Validation'
import { validatePassword } from '../../utils/accounts'
import {
  useAsyncValidatedState,
  useValidatedState,
  useActionCreator,
} from 'truly-utils'

interface PasswordEditModalProps {
  show: boolean
  onClose: () => void
  email: string
  userId: number
}

interface ConfirmPasswordValidation {
  password: string | null
  confirmPassword: string | null
}

const labelWidth = '180px'

const PasswordEditModal: React.FC<PasswordEditModalProps> = props => {
  const { show, onClose, email, userId } = props
  const resetPassword = useActionCreator(resetUserPassword)

  const [
    newPassword,
    passwordValidationState,
    setPasswordAndValidate,
    newPasswordIsLoading,
  ] = useAsyncValidatedState('', validatePassword)

  const [
    confirmPassword,
    confirmPasswordValidationState,
    setConfirmPasswordAndValidate,
  ] = useValidatedState<string | null, ConfirmPasswordValidation>(
    '',
    confirmPasswordValidationHandler,
  )
  const [passwordReset, setPasswordReset] = useState(true)

  const onNewPasswordInputChange = (inputNewPassword: string) => {
    setPasswordAndValidate(true, inputNewPassword, {
      password: inputNewPassword,
      email,
    })
    if (confirmPassword?.trim()) {
      setConfirmPasswordAndValidate(true, confirmPassword, {
        password: inputNewPassword,
        confirmPassword,
      })
    }
  }
  const onConfirmPasswordInputChange = (inputConfirmPassword: string) =>
    setConfirmPasswordAndValidate(true, inputConfirmPassword, {
      password: newPassword,
      confirmPassword: inputConfirmPassword,
    })

  const handleChangePasswordSubmit = () => {
    resetPassword(userId.toString(), newPassword, passwordReset)
    onClose()
  }

  useEffect(() => {
    setPasswordAndValidate(false, '', { email: '', password: '' })
    setConfirmPasswordAndValidate(false, '', {
      password: '',
      confirmPassword: '',
    })
    setPasswordReset(true)
  }, [show, setPasswordAndValidate, setConfirmPasswordAndValidate])

  const isValid =
    passwordValidationState &&
    confirmPasswordValidationState &&
    passwordValidationState.valid &&
    confirmPasswordValidationState.valid &&
    !newPasswordIsLoading

  return (
    <PopupModal
      show={show}
      hideOnBgClick
      showExitButton
      onRequestClose={onClose}
      width="576px">
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header title="Change User's Password" />
          <ModalLayout.HorizontalLine />
          <ModalLayout.FieldRow
            label="Password*"
            labelWidth={labelWidth}
            shouldPadRight
            validationState={
              newPasswordIsLoading ? null : passwordValidationState
            }
            hasValidationIcons>
            <FlexRow>
              <InlineTextEdit
                originalValue={newPassword}
                autoFocus
                placeholder="Password"
                onSave={onNewPasswordInputChange}
                saveOnType
                type="password"
              />
              {newPasswordIsLoading && (
                <SpinnerContainer>
                  <SpinnerMaterial
                    color={colors.darkGray}
                    strokeWidth={6}
                    size="21px"
                  />
                </SpinnerContainer>
              )}
            </FlexRow>
          </ModalLayout.FieldRow>
          <ModalLayout.LightHorizontalLine />
          <ModalLayout.FieldRow
            label="Confirm Password*"
            labelWidth={labelWidth}
            shouldPadRight
            validationState={confirmPasswordValidationState}
            hasValidationIcons>
            <FlexRow>
              <InlineTextEdit
                originalValue={confirmPassword}
                placeholder="Confirm Password"
                onSave={onConfirmPasswordInputChange}
                saveOnType
                type="password"
              />
            </FlexRow>
          </ModalLayout.FieldRow>
          <ModalLayout.HorizontalLine />
          <ModalLayout.Row shouldPadBottom>
            <FlexRow justifyContent="space-between" alignItems="center">
              <FlexRow justifyContent="flex-start" alignItems="center">
                <DetailedLabel description="" enabled>
                  require password reset
                </DetailedLabel>
              </FlexRow>
              <ValueWrapper>
                <Switch
                  onChange={setPasswordReset}
                  checked={passwordReset}
                  activeColor={colors.accentLavender}
                />
              </ValueWrapper>
            </FlexRow>
          </ModalLayout.Row>
          <ModalLayout.FooterBigButton
            actionText="Change Password"
            onAction={handleChangePasswordSubmit}
            actionDisabled={!isValid}
          />
        </ModalLayout.Content>
      </ModalLayout.Container>
    </PopupModal>
  )
}

export default PasswordEditModal
