import * as React from 'react'
import { Container, OverlayContainer, OverlayWrapper } from './Styles'
import { SpinnerMaterial, colors } from 'js-components'
import { useDelayedVisibility } from 'truly-utils'

interface LoaderProps {
  overAll?: boolean
  mt?: string
  color?: string
}

const Loader: React.FC<LoaderProps> = props => {
  const { color = colors.lightGray, overAll, mt } = props
  const loaderProps = useDelayedVisibility(250, true)

  if (overAll) {
    return (
      <OverlayContainer {...loaderProps}>
        <OverlayWrapper>
          <SpinnerMaterial strokeWidth={5} color={color} />
        </OverlayWrapper>
      </OverlayContainer>
    )
  }

  return (
    <Container {...loaderProps} marginTop={mt} className="loader">
      <SpinnerMaterial strokeWidth={5} color={color} />
    </Container>
  )
}

export default Loader
