import React from 'react'
import { PhoneNumberRowContainer, IconWrapper } from './Styles'
import { CircleAdd, Regular, colors, ModalLayout } from 'js-components'

interface PhoneNumberAssignRowProps {
  onClick: () => void
  includeSeparator?: boolean
}

const PhoneNumberAssignRow = (props: PhoneNumberAssignRowProps) => (
  <ModalLayout.HoverableRow onClick={props.onClick}>
    {props.includeSeparator ? (
      <ModalLayout.LightHorizontalLine />
    ) : (
      <ModalLayout.Spacer />
    )}
    <ModalLayout.Row>
      <PhoneNumberRowContainer data-cy="add-phone-number">
        <Regular color={colors.trulyDark}>Add Phone Number</Regular>
        <IconWrapper>
          <CircleAdd width="22px" height="22px" />
        </IconWrapper>
      </PhoneNumberRowContainer>
    </ModalLayout.Row>
  </ModalLayout.HoverableRow>
)

export default React.memo(PhoneNumberAssignRow)
