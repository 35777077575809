import defaultTrulyClient from '../../utils/HTTP'
import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_BLOCKED_NUMBERS,
  BLOCKED_NUMBER_ADDED,
  BLOCKED_NUMBER_REMOVED,
} from './actionTypes'
import {
  fetchBlockedNumbersSuccess,
  fetchBlockedNumbersFail,
  addBlockedNumber,
  removeBlockedNumber,
  addBlockedNumberSuccess,
  removeBlockedNumberSuccess,
  removeBlockedNumberFail,
  addBlockedNumberFail,
} from './actionCreators'
import { toastr } from 'react-redux-toastr'

export function* blockedNumbersSaga() {
  yield all([
    takeLatest(FETCH_BLOCKED_NUMBERS, fetchBlockedNumbersSaga),
    takeEvery(BLOCKED_NUMBER_REMOVED, removeBlockedNumberSaga),
    takeEvery(BLOCKED_NUMBER_ADDED, addBlockedNumberSaga),
  ])
}

function* fetchBlockedNumbersSaga() {
  try {
    const req = yield call(defaultTrulyClient.lg.fetchBlockedNumbers)

    yield put(fetchBlockedNumbersSuccess(req.data.blocked_numbers))
  } catch (e) {
    toastr.error(
      'An Error Occurred',
      'Unable to fetch blocked numbers. Refresh to try again.',
    )
    yield put(fetchBlockedNumbersFail(e))
  }
}

function* removeBlockedNumberSaga(
  action: ReturnType<typeof removeBlockedNumber>,
) {
  const number = action.payload.blockedNumber

  try {
    yield call(defaultTrulyClient.lg.removeBlockedNumber, number)
    yield put(removeBlockedNumberSuccess(number))
  } catch (e) {
    yield put(removeBlockedNumberFail(e))
    toastr.error(
      'An Error Occurred',
      'Unable to remove blocked number. Please try again.',
    )
    yield put(addBlockedNumber(number)) // revert
  }
}

function* addBlockedNumberSaga(action: ReturnType<typeof addBlockedNumber>) {
  const number = action.payload.blockedNumber

  try {
    const req = yield call(defaultTrulyClient.lg.addBlockedNumber, number)
    yield put(addBlockedNumberSuccess(req.data.blocked_number))
  } catch (e) {
    toastr.error(
      'An Error Occured',
      'Unable to block invalid phone number. Please reformat and try again.',
    )
    yield put(addBlockedNumberFail(e))
  }
}
