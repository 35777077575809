import React, { useState } from 'react'
import { IPhoneNumber } from 'truly-ts'
import memoizeOne from 'memoize-one'
import {
  ContentContainer,
  TableFilterWrapper,
  LeftAlignedTableData,
  RightAlignedTableData,
  ActionButton,
  TableWrapper,
} from '../LayoutHelpers/Styles'
import {
  TableFilterRow,
  Small,
  TableRow,
  TableData,
  TableHeaderRow,
  TableHeaderData,
  Regular,
  colors,
  ToolTip,
  Trash,
  ScrollableTable,
  EmptyResults,
  DeleteDialog,
} from 'js-components'
import { formatPhoneNumber, numberTypeDisplay, makeSearchFn } from 'truly-utils'

const RowHeight = 75

interface PhoneMenuNumbersTableProps {
  phonenumbers?: IPhoneNumber[]
  name: string
  onAddNumberClicked: () => void
  onUnassignNumber: (phoneNumber: IPhoneNumber) => void
}

const filterPhoneNumbers = memoizeOne(
  (phoneNumbers: IPhoneNumber[], searchValue: string) => {
    let result = phoneNumbers
    if (searchValue && searchValue.trim()) {
      const searchFn = makeSearchFn<IPhoneNumber>(
        ['full_number', 'context'], // TODO correct phone number search
        searchValue,
      )
      result = result.filter(searchFn)
    }
    return result
  },
)

const PhoneMenuNumbersTable: React.FC<PhoneMenuNumbersTableProps> = (
  props: PhoneMenuNumbersTableProps,
) => {
  const [searchValue, setSearchValue] = useState('')
  const [unassigning, setUnassigning] = useState(false)
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<IPhoneNumber>()

  const onConfirmUnassign = () => {
    if (!selectedPhoneNumber) {
      throw new Error("Can't unassign null phone number")
    }
    props.onUnassignNumber(selectedPhoneNumber)
    setUnassigning(false)
    setSelectedPhoneNumber(undefined)
  }

  const onUnassignPhoneNumber = (phoneNumber: IPhoneNumber) => {
    setUnassigning(true)
    setSelectedPhoneNumber(phoneNumber)
  }

  const onCloseUnassign = () => {
    setUnassigning(false)
    setSelectedPhoneNumber(undefined)
  }

  const clearAllFilters = () => setSearchValue('')

  const isFiltered =
    props.phonenumbers &&
    props.phonenumbers.length > 0 &&
    !!(searchValue && searchValue.trim())

  const getFilteredNumbers = () => {
    return filterPhoneNumbers(props.phonenumbers || [], searchValue)
  }

  const filteredNumbers = getFilteredNumbers()

  return (
    <ContentContainer>
      <DeleteDialog
        show={unassigning}
        title={
          selectedPhoneNumber
            ? `Unassign ${formatPhoneNumber(selectedPhoneNumber.full_number)}`
            : ''
        }
        deleteActionText="Unassign"
        description={`This number will be unassigned from ${props.name}.`}
        onCancel={onCloseUnassign}
        onDelete={onConfirmUnassign}
      />
      <TableFilterWrapper>
        <TableFilterRow
          searchInput
          searchInputValue={searchValue}
          onSearchInputChanged={setSearchValue}
          selectedFilterOption="all"
          filterOptions={[
            {
              value: 'all',
              label: 'Phone Numbers',
            },
          ]}
          searchPlaceholder="Filter"
          addButtonText="Add Phone Number"
          onAddButtonClicked={props.onAddNumberClicked}
        />
      </TableFilterWrapper>
      <TableWrapper>
        <ScrollableTable
          header={<PhoneNumberHeader />}
          rowHeight={RowHeight}
          emptyView={
            <EmptyResults
              title={
                isFiltered
                  ? 'No Phone Numbers Found'
                  : 'No Phone Numbers Assigned... Yet.'
              }
              actionText={
                isFiltered ? 'Clear All Filters' : 'Assign Phone Number'
              }
              onActionClick={
                isFiltered ? clearAllFilters : props.onAddNumberClicked
              }
            />
          }
          rowCount={filteredNumbers.length}
          renderRow={idx => (
            <PhoneNumberRow
              key={filteredNumbers[idx].full_number}
              phoneNumber={filteredNumbers[idx]}
              rowHeight={RowHeight}
              onUnassign={onUnassignPhoneNumber}
            />
          )}
        />
      </TableWrapper>
    </ContentContainer>
  )
}

const PhoneNumberHeader = React.memo(props => (
  <TableHeaderRow {...props}>
    <TableHeaderData width="140px" text="Number" />
    <TableHeaderData text="Type" />
    <TableHeaderData text="" />
  </TableHeaderRow>
))

interface PhoneNumberRowProps {
  phoneNumber: IPhoneNumber
  rowHeight: number
  onUnassign: (phoneNumber: IPhoneNumber) => void
}

const PhoneNumberRow: React.FC<PhoneNumberRowProps> = React.memo(
  (props: PhoneNumberRowProps) => {
    const { rowHeight, onUnassign, phoneNumber, ...restProps } = props

    const renderTooltipContent = (text: string) => {
      return (
        <div>
          <Small>{text}</Small>
        </div>
      )
    }

    const handleUnassign = (ev: React.MouseEvent) => {
      ev.stopPropagation()
      onUnassign(phoneNumber)
    }

    return (
      <TableRow height={rowHeight} verticalAlign="middle" {...restProps}>
        <TableData width="140px">
          <LeftAlignedTableData>
            <Regular bold color={colors.trulyDark}>
              {formatPhoneNumber(phoneNumber.full_number)}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData>
          <LeftAlignedTableData>
            <Regular color={colors.trulyDark}>
              {numberTypeDisplay(phoneNumber.full_number)}
            </Regular>
          </LeftAlignedTableData>
        </TableData>
        <TableData showOnHover>
          <RightAlignedTableData>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={renderTooltipContent('Unassign')}>
              <ActionButton
                cursor="pointer"
                onClick={handleUnassign}
                role="button">
                <Trash width="24px" height="24px" />
              </ActionButton>
            </ToolTip>
          </RightAlignedTableData>
        </TableData>
      </TableRow>
    )
  },
)

export default PhoneMenuNumbersTable
