import React, { useState, useEffect, useCallback } from 'react'
import {
  PopupModal,
  ConfirmDialog,
  ModalLayout,
  SpinnerMaterial,
  colors,
} from 'js-components'
import { SaveStatus } from '../../utils/Saving'
import { phoneNumberSelectedValidationHandler } from '../../utils/Validation'
import defaultTrulyClient from '../../utils/HTTP'
import NumberPurchaserContainer from '../../containers/NumberPurchaserContainer'
import { IPhoneNumber } from 'truly-ts'
import {
  phoneNumberAdded,
  fetchPhoneNumbers,
} from '../../reducers/phoneNumbers/actionCreators'
import usePreloader from '../../utils/data-hooks/usePreloader'
import * as selectors from '../../reducers/phoneNumbers/phoneNumbersSelectors'
import { useValidatedState, useActionCreator } from 'truly-utils'

interface AddPhoneNumberModalProps {
  show: boolean
  onClose: () => void
  onAdd: (phoneNumber: IPhoneNumber) => void
  selectablePhoneNumbers?: IPhoneNumber[]
}

const AddPhoneNumberModal: React.FC<AddPhoneNumberModalProps> = props => {
  const { show, onClose, onAdd, selectablePhoneNumbers } = props
  const onPhoneNumberPurchased = useActionCreator(phoneNumberAdded)
  const phoneNumbers = usePreloader(selectors.phoneNumbers, fetchPhoneNumbers)
  const [
    selectedNumber,
    phoneNumberValidationState,
    setNumberAndValidate,
  ] = useValidatedState('', phoneNumberSelectedValidationHandler)
  const [isPhoneNumberSelected, setPhoneNumberSelected] = useState(false)
  const [canValidate, setCanValidate] = useState(false)
  const [purchaseStatus, setPurchaseStatus] = useState(SaveStatus.Unsaved)

  useEffect(() => {
    setNumberAndValidate(false, '')
    setCanValidate(false)
    setPurchaseStatus(SaveStatus.Unsaved)
  }, [show, setNumberAndValidate])

  const onSave = useCallback(() => {
    const pnValidationState = setNumberAndValidate(true, selectedNumber)
    setCanValidate(true)

    if (pnValidationState?.valid) {
      if (isPhoneNumberSelected) {
        // is phone number entity
        const pn =
          phoneNumbers &&
          phoneNumbers.find(p => p.full_number === selectedNumber)
        if (!pn) {
          return alert(
            'The selected phone number was deleted. Please pick another one.',
          )
        }
        onAdd(pn)
        onClose()
      } else if (selectedNumber) {
        // is a phone number we want to purchase
        setPurchaseStatus(SaveStatus.Saving)
        defaultTrulyClient.lg
          .purchasePhoneNumber(selectedNumber)
          .then(resp => resp.data.phone_number)
          .then(phoneNumber => {
            setPurchaseStatus(SaveStatus.Unsaved)
            onPhoneNumberPurchased(phoneNumber)
            onAdd(phoneNumber)
            onClose()
          })
          .catch(err => {
            setPurchaseStatus(SaveStatus.Failed)
            console.error('Failed to purchase number', err)
          })
      }
    }
  }, [
    selectedNumber,
    onAdd,
    onClose,
    isPhoneNumberSelected,
    onPhoneNumberPurchased,
    phoneNumbers,
    setNumberAndValidate,
  ])

  const resetPurchaseStatus = useCallback(
    () => setPurchaseStatus(SaveStatus.Unsaved),
    [],
  )
  const selectedNumberChanged = useCallback(
    (number: string, isPhoneNumberEntity: boolean) => {
      setNumberAndValidate(canValidate, number)
      setPhoneNumberSelected(isPhoneNumberEntity)
    },
    [canValidate, setNumberAndValidate],
  )

  return (
    <PopupModal
      show={show}
      width="550px"
      onRequestClose={onClose}
      hideOnBgClick>
      <ConfirmDialog
        show={purchaseStatus === SaveStatus.Failed}
        confirmText="Okay"
        title="Request Failed"
        description="Please try again or select a different number."
        color={colors.alertRed}
        onConfirm={resetPurchaseStatus}
        onCancel={resetPurchaseStatus}
      />
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header title="Add Phone Number" />
          <ModalLayout.HorizontalLine />
          <NumberPurchaserContainer
            labelWidth="165px"
            selectedNumber={selectedNumber ?? undefined}
            validation={phoneNumberValidationState}
            onSelectedNumberChanged={selectedNumberChanged}
            enableSelectUnassigned
            phoneNumbers={selectablePhoneNumbers}
          />
          <ModalLayout.Footer
            actionText={
              purchaseStatus === SaveStatus.Saving ? (
                <SpinnerMaterial
                  color={colors.accentPurple}
                  strokeWidth={9}
                  size="20px"
                />
              ) : (
                'Add'
              )
            }
            actionButtonWidth={100}
            actionDisabled={purchaseStatus !== SaveStatus.Unsaved}
            onAction={onSave}
            onCancel={onClose}
          />
        </ModalLayout.Content>
      </ModalLayout.Container>
    </PopupModal>
  )
}

export default AddPhoneNumberModal
