import { IPhoneNumber, EntityType } from 'truly-ts'

export default class PhoneNumberUtils {
  static setAssignment(
    phoneNumber: IPhoneNumber,
    entityType: EntityType,
    entityId: number,
  ): IPhoneNumber {
    return {
      ...phoneNumber,
      entity: {
        type: entityType,
        id: entityId,
      },
    }
  }

  static clearAssignment(phoneNumber: IPhoneNumber): IPhoneNumber {
    return {
      ...phoneNumber,
      entity: null,
    }
  }

  static isPhoneNumberAssignedTo(
    phoneNumber: IPhoneNumber,
    entityType: EntityType,
    entityId: number,
  ) {
    return (
      phoneNumber.entity &&
      phoneNumber.entity.type === entityType &&
      phoneNumber.entity.id === entityId
    )
  }
}
