import { createReducer } from 'truly-utils'
import { mapifyList } from '../../utils/Redux'
import { IMessage } from 'truly-ts'
import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAIL,
  MESSAGE_ADDED,
  MESSAGE_REMOVED,
  MESSAGE_UPDATED,
} from './actionTypes'
import {
  fetchMessagesSuccess,
  messageAdded,
  messageRemoved,
  messageUpdated,
} from './actionCreators'

export interface MessagesState {
  loading: boolean
  messages?: { [k: number]: IMessage }
  modifiedMessage: IMessage | null
}

const INITIAL_STATE: MessagesState = {
  loading: false,
  modifiedMessage: null,
}

const messages = createReducer<MessagesState>(INITIAL_STATE, {
  [FETCH_MESSAGES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_MESSAGES_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchMessagesSuccess>,
  ) => ({
    ...state,
    messages: mapifyList(action.payload.messages),
    loading: false,
  }),
  [FETCH_MESSAGES_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [MESSAGE_ADDED]: (state, action: ReturnType<typeof messageAdded>) => {
    if (!action.payload.message.id) {
      console.error('unable to add message with no ID')
      return state
    }
    return {
      ...state,
      messages: {
        ...state.messages,
        [action.payload.message.id]: action.payload.message,
        modifiedMessage: action.payload.message,
      },
    }
  },
  [MESSAGE_REMOVED]: (state, action: ReturnType<typeof messageRemoved>) => {
    const idToRemove = action.payload.message.id
    const newMessages = {
      ...state.messages,
    }
    if (idToRemove) {
      delete newMessages[idToRemove]
    }

    return {
      ...state,
      messages: newMessages,
      modifiedMessage: null,
    }
  },
  [MESSAGE_UPDATED]: (state, action: ReturnType<typeof messageUpdated>) => {
    const newMessages = action.payload.message.id
      ? {
          ...state.messages,
          [action.payload.message.id]: action.payload.message,
        }
      : state.messages
    return {
      ...state,
      messages: newMessages,
    }
  },
})

export default messages
