import * as React from 'react'
import { Container, TextContainer } from './Styles'
import { Avatar, Regular, colors, Small, AvatarSizes } from 'js-components'

interface EntityRowProps {
  title: React.ReactNode | string
  subtitle: string
  avatarText: string
  width: string
  avatarIcon?: React.ReactNode
  avatarSeed?: string
  squareAvatar?: boolean
}

export default class EntityRow extends React.Component<EntityRowProps> {
  render() {
    const {
      title,
      subtitle,
      avatarIcon,
      avatarText,
      width,
      avatarSeed,
      squareAvatar,
    } = this.props

    return (
      <Container width={width}>
        <Avatar
          style={{ marginRight: '8px', marginTop: '2px' }}
          size={AvatarSizes.Small}
          colorSeed={avatarSeed}
          square={squareAvatar}
          text={avatarText}
          icon={avatarIcon}
        />
        <TextContainer>
          {typeof title === 'string' ? (
            <Regular color={colors.trulyDark}>{title}</Regular>
          ) : (
            title
          )}
          <Small color={colors.darkGray}>{subtitle}</Small>
        </TextContainer>
      </Container>
    )
  }
}
