import styled from 'styled-components'
import { FlexRow } from 'js-components'

export const RemoveButton = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`

export const CheckItem = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  width: 100%;
`

export const ValidationRow = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;
`

export const SubItem = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  padding-left: 26px;
`

export const NumericInputWrapper = styled.div`
  width: 46px;
`
