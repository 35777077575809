import { Next, IAction } from '../../store/index'
import { OPEN_CHAT_SUPPORT } from '../../reducers/chatsupport/actionTypes'

export const handleApp = () => (store: any) => (next: Next) => (
  action: IAction,
) => {
  switch (action.type) {
    case OPEN_CHAT_SUPPORT: {
      if ((global as any).Intercom) {
        ;(global as any).Intercom('show')
      }
      break
    }
    default:
      break
  }

  return next(action)
}
