import * as React from 'react'
import { Container } from './Styles'
import { FlexRow, Button, colors, SpinnerMaterial } from 'js-components'
import { SaveStatus } from '../../utils/Saving'

interface ContentFooterProps {
  actionText: string
  closeText: string
  onActionClicked: () => void
  onCloseClicked: () => void
  savedText?: string
  saveStatus?: SaveStatus
  actionDisabled?: boolean
}

export default class ContentFooter extends React.Component<ContentFooterProps> {
  render() {
    return (
      <Container>
        <FlexRow justifyContent="flex-end" alignItems="center">
          <Button
            value={this.props.closeText}
            color={colors.ultraLightGray}
            textColor={colors.trulyDark}
            size="large"
            horizontalPadding={24}
            onClick={this.props.onCloseClicked}
            data-cy="footer-close-button"
          />
          <Button
            value={
              this.props.saveStatus === SaveStatus.Saving ? (
                <SpinnerMaterial
                  color={colors.white}
                  strokeWidth={9}
                  size="20px"
                />
              ) : this.props.saveStatus === SaveStatus.Success ? (
                this.props.savedText
              ) : (
                this.props.actionText
              )
            }
            color={
              this.props.saveStatus === SaveStatus.Success
                ? colors.alertGreen
                : colors.accentLavender
            }
            disabled={this.props.actionDisabled}
            textColor={colors.white}
            width={150}
            size="large"
            style={{ marginLeft: '10px' }}
            onClick={this.props.onActionClicked}
            data-cy="footer-action-button"
          />
        </FlexRow>
      </Container>
    )
  }
}
