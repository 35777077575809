import {
  FETCH_BLOCKED_NUMBERS,
  FETCH_BLOCKED_NUMBERS_SUCCESS,
  FETCH_BLOCKED_NUMBERS_FAIL,
  BLOCKED_NUMBER_REMOVED,
  BLOCKED_NUMBER_ADDED,
  BLOCKED_NUMBER_ADDED_SUCCESS,
  BLOCKED_NUMBER_REMOVED_SUCCESS,
  BLOCKED_NUMBER_REMOVED_FAIL,
  BLOCKED_NUMBER_ADDED_FAIL,
} from './actionTypes'

import { IBlockedNumber } from 'truly-ts'

export const fetchBlockedNumbers = () => ({
  type: FETCH_BLOCKED_NUMBERS,
  payload: {},
})

export const fetchBlockedNumbersSuccess = (
  blockedNumbers: IBlockedNumber[],
) => ({
  type: FETCH_BLOCKED_NUMBERS_SUCCESS,
  payload: {
    blockedNumbers,
  },
})

export const fetchBlockedNumbersFail = (error: Error) => ({
  type: FETCH_BLOCKED_NUMBERS_FAIL,
  payload: {
    error,
  },
})

export const removeBlockedNumber = (blockedNumber: IBlockedNumber) => ({
  type: BLOCKED_NUMBER_REMOVED,
  payload: {
    blockedNumber,
  },
})

export const removeBlockedNumberSuccess = (blockedNumber: IBlockedNumber) => ({
  type: BLOCKED_NUMBER_REMOVED_SUCCESS,
  payload: {
    blockedNumber,
  },
})
export const removeBlockedNumberFail = (error: any) => ({
  type: BLOCKED_NUMBER_REMOVED_FAIL,
  payload: {
    error,
  },
})

export const addBlockedNumber = (blockedNumber: IBlockedNumber) => ({
  type: BLOCKED_NUMBER_ADDED,
  payload: {
    blockedNumber,
  },
})

export const addBlockedNumberSuccess = (blockedNumber: IBlockedNumber) => ({
  type: BLOCKED_NUMBER_ADDED_SUCCESS,
  payload: {
    blockedNumber,
  },
})

export const addBlockedNumberFail = (error: any) => ({
  type: BLOCKED_NUMBER_ADDED_FAIL,
  payload: {
    error,
  },
})
