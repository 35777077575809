import React, { useCallback } from 'react'
import styled from 'styled-components'
import { colors } from 'truly-constants'
import { toastr } from 'react-redux-toastr'
import {
  ModalLayout,
  FlexRow,
  Small,
  VisibilityIndicator,
  ToolTip,
  Duplicate,
  Regular,
} from 'js-components'

import {
  useToggleState,
  webUtils,
  obfuscateText,
  getDataProps,
} from 'truly-utils'
import { ActionButton } from '../LayoutHelpers/Styles'

interface Props {
  password?: string | null
}

const PasswordRow = (props: Props) => {
  const { password } = props
  const dataProps = getDataProps(props, 'data-cy')
  const [hidden, toggle] = useToggleState(true)
  const passwordText = hidden ? obfuscateText(password) : password

  const copy = useCallback(
    e => {
      e.stopPropagation()
      if (password) {
        webUtils.copyToClipboard(password)
        toastr.success('Success', 'Password copied to clipboard')
      }
    },
    [password],
  )

  return (
    <ModalLayout.HoverableRow onClick={copy}>
      <ModalLayout.LightHorizontalLine />
      <ModalLayout.FieldRow
        shouldPadRight
        label="Password"
        labelWidth="160px"
        {...dataProps}>
        <FlexRow justifyContent="space-between" alignItems="center">
          <ReadonlyText> {passwordText}</ReadonlyText>
          <FlexRow alignItems="center" data-hover-row-show>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={
                <div>
                  <Small>{hidden ? 'Show' : 'Hide'}</Small>
                </div>
              }>
              <ActionButton
                cursor="pointer"
                role="button"
                onClick={e => {
                  e.stopPropagation()
                  toggle()
                }}
                data-cy="toggle-visibility-button">
                <VisibilityIndicator
                  height="30px"
                  visible={!hidden}
                  color={hidden ? colors.darkGray : colors.trulyDark}
                />
              </ActionButton>
            </ToolTip>
            <ToolTip
              enabled
              orderPreference="bottom"
              toolTipContent={
                <div>
                  <Small>Copy</Small>
                </div>
              }>
              <ActionButton
                cursor="pointer"
                role="button"
                data-cy="copy-password-button">
                <Duplicate height="32px" />
              </ActionButton>
            </ToolTip>
          </FlexRow>
        </FlexRow>
      </ModalLayout.FieldRow>
    </ModalLayout.HoverableRow>
  )
}

const ReadonlyText = styled(Regular)`
  color: ${colors.trulyDark};
  margin: 9px;
`

export default PasswordRow
