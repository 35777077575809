import {
  FETCH_WEBHOOKS,
  FETCH_WEBHOOKS_FAIL,
  FETCH_WEBHOOKS_SUCCESS,
  CREATE_WEBHOOK,
  WEBHOOK_ADDED,
  UPDATE_WEBHOOK,
  WEBHOOK_UPDATED,
  DELETE_WEBHOOK,
} from './actionTypes'

import { IWebhook } from 'truly-ts'

export const fetchWebhooks = () => ({
  type: FETCH_WEBHOOKS,
  payload: {},
})

export const fetchWebhooksSuccess = (webhooks: IWebhook[]) => ({
  type: FETCH_WEBHOOKS_SUCCESS,
  payload: {
    webhooks,
  },
})

export const fetchWebhooksFail = () => ({
  type: FETCH_WEBHOOKS_FAIL,
  payload: {},
})

export const createWebhook = (webhook: IWebhook) => ({
  type: CREATE_WEBHOOK,
  payload: {
    webhook,
  },
})

export const webhookAdded = (webhook: IWebhook) => ({
  type: WEBHOOK_ADDED,
  payload: {
    webhook,
  },
})

export const updateWebhook = (id: string, webhook: IWebhook) => ({
  type: UPDATE_WEBHOOK,
  payload: {
    id,
    webhook,
  },
})

export const webhookUpdated = (webhook: IWebhook) => ({
  type: WEBHOOK_UPDATED,
  payload: {
    webhook,
  },
})

export const deleteWebhook = (webhook: IWebhook) => ({
  type: DELETE_WEBHOOK,
  payload: {
    webhook,
  },
})
