import React from 'react'
import { IMessage } from 'truly-ts'
import {
  SectionGroup,
  SectionItem,
  FlexRow,
  colors,
  AudioDisplayMode,
  AudioPlayer,
  DownloadAudioButton,
  Button,
  Regular,
  spaceSizes,
} from 'js-components'
import { ReactComponent as AudioSVG } from 'js-components/src/components/Icons/Audio.svg'
import { legacyUrl } from '../../constants/Config'
import EditRecordingModalContainer from '../../containers/EditRecordingModalContainer'
import styled from 'styled-components'
import { useOpenCloseState } from 'truly-utils'

const ContentWrapper = styled(FlexRow)`
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`

interface RecordingEditorProps {
  message?: IMessage
  onChange?: (message: IMessage) => void
  height?: number
  newRecordingDefaultName?: string
  largePlay?: boolean
  onAddFileCallback?: (message: IMessage, file: Blob) => void
  borderless?: boolean // TODO: After refactoring UI, remove this
  minimal?: boolean
  hideExistingRecordings?: boolean
  hideRecordingName?: boolean
}

const RecordingEditor: React.FC<RecordingEditorProps> = props => {
  const [
    showRecordingEditModal,
    openRecordingEditModal,
    closeRecordingEditModal,
  ] = useOpenCloseState(false)

  const height = props.height ?? 64

  return (
    <>
      <EditRecordingModalContainer
        show={showRecordingEditModal}
        onMessageSelected={props.onChange}
        onClose={closeRecordingEditModal}
        defaultRecordingName={props.newRecordingDefaultName}
        onAddFileCallback={props.onAddFileCallback}
        hideExistingRecordings={props.hideExistingRecordings}
        hideRecordingName={props.hideRecordingName}
      />
      {props.message ? (
        <RecordingMessage
          largePlay={props.largePlay}
          message={props.message}
          openEditRecording={openRecordingEditModal}
          height={height}
          borderless={props.borderless}
          minimal={props.minimal}
        />
      ) : (
        <AddRecording
          openEditRecording={openRecordingEditModal}
          height={height}
          borderless={props.borderless}
          bolded={props.minimal}
        />
      )}
    </>
  )
}

const RecordingMessage: React.FC<{
  message: IMessage
  openEditRecording: () => void
  height: number
  largePlay?: boolean
  borderless?: boolean
  minimal?: boolean
}> = React.memo(props => {
  const nodeMessage: IMessage & { message_id?: number } = props.message
  // on nodes, it uses message_id, otherwise with plain messages it's just id
  const messageId = nodeMessage.message_id || props.message.id
  const src = `${legacyUrl}/message_content?cachebuster=${Math.random()}&messageId=${messageId}`
  const sectionGroupStyleOverrides = props.borderless
    ? { overflow: 'hidden', border: 'none' }
    : { overflow: 'hidden' }
  return (
    <SectionGroup style={sectionGroupStyleOverrides}>
      <SectionItem
        style={{ backgroundColor: colors.ultraLightGray }}
        removePadding>
        <ContentWrapper
          justifyContent="space-between"
          height={`${props.height}px`}>
          <FlexRow width="100%" alignItems="center">
            <AudioPlayer
              large={props.largePlay}
              displayMode={AudioDisplayMode.Short}
              src={src}
            />
            {!props.minimal && (
              <Regular ml="12px" color={colors.trulyDark}>
                {props.message.title}
              </Regular>
            )}
          </FlexRow>
          <FlexRow alignItems="center">
            {!props.minimal && (
              <>
                <DownloadAudioButton src={src} />
                <Button
                  color={colors.darkestGray}
                  textColor={colors.white}
                  round
                  size="small"
                  value="replace"
                  style={{ marginLeft: '12px' }}
                  onClick={props.openEditRecording}
                  data-cy="replace-audio-button"
                />
              </>
            )}
            {props.minimal && (
              <Regular
                bold
                color={colors.accentLavender}
                onClick={props.openEditRecording}
                pl={`${spaceSizes.md}px`}
                pr={`${spaceSizes.md}px`}>
                Replace
              </Regular>
            )}
          </FlexRow>
        </ContentWrapper>
      </SectionItem>
    </SectionGroup>
  )
})

const AddRecording: React.FC<{
  openEditRecording: () => void
  height: number
  borderless?: boolean
  bolded?: boolean
}> = React.memo(props => {
  const sectionGroupStyleOverrides = props.borderless
    ? { overflow: 'hidden', border: 'none' }
    : { overflow: 'hidden' }
  return (
    <SectionGroup style={sectionGroupStyleOverrides}>
      <SectionItem
        data-cy="add-recording-button"
        role="button"
        onClick={props.openEditRecording}
        removePadding>
        <ContentWrapper
          justifyContent="flex-start"
          height={`${props.height}px`}>
          <AudioSVG width="42px" fill={colors.accentPurple} />
          <Regular
            bold={props.bolded}
            m={`${spaceSizes.md}px`}
            color={colors.trulyDark}>
            Add Recording
          </Regular>
        </ContentWrapper>
      </SectionItem>
    </SectionGroup>
  )
})

export default RecordingEditor
