import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { FETCH_INVITES } from './actionTypes'
import { fetchInvitesSuccess, fetchInvitesFail } from './actionCreators'
import { toastr } from 'react-redux-toastr'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* invitesSaga() {
  yield all([takeLatest(FETCH_INVITES, fetchInvitesSaga)])
}

export function* fetchInvitesSaga() {
  try {
    const req = yield call(client.lg.fetchInvites)
    yield put(fetchInvitesSuccess(req.data.invites))
  } catch (e) {
    console.error('fetching invites', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get invites. Please refresh the page.',
    )
    yield put(fetchInvitesFail())
  }
}
