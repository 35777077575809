import React, { useCallback, useMemo } from 'react'
import usePreloader from '../../utils/data-hooks/usePreloader'
import * as phoneMenuSelectors from '../../reducers/phoneMenus/phoneMenusSelectors'
import * as roleSelectors from '../../reducers/roles/rolesSelectors'
import {
  fetchPhoneMenus,
  updateNodeAssignmentsForTeam,
  updateNodeAssignmentsForExtension,
} from '../../reducers/phoneMenus/actionCreators'
import { FieldLoader } from '../LayoutHelpers/Styles'
import {
  InlineMultiSelect,
  Regular,
  colors,
  NumpadSVG,
  EmptyResults,
  SpinnerMaterial,
} from 'js-components'
import { ReactComponent as UsersSVG } from 'js-components/src/components/Icons/Users.svg'
import { push } from 'connected-react-router'
import EntityRow from '../EntityRow/EntityRow'
import {
  assignableNodeOptionsFromPhoneMenus,
  AssignableNodeOption,
} from '../../utils/ui-utils/phone-menu-select-utils'
import { NodeType, IRole, PhoneMenuType, ILicenseGroupAccount } from 'truly-ts'
import { fetchRoles } from '../../reducers/roles/actionCreators'
import { devAssert } from 'truly-utils/macro'
import { useActionCreator } from 'truly-utils'
import { NodeAssignmentType } from '../../constants/phone-menu'

interface PhoneMenuSelectProps {
  assignmentType: NodeAssignmentType
  entity: ILicenseGroupAccount | IRole
}

const PhoneMenuSelect: React.FC<PhoneMenuSelectProps> = props => {
  const { entity, assignmentType } = props
  const navigate = useActionCreator(push)
  const updateTeamNodeAssignments = useActionCreator(
    updateNodeAssignmentsForTeam,
  )
  const updateExtensionNodeAssignments = useActionCreator(
    updateNodeAssignmentsForExtension,
  )
  const phoneMenus = usePreloader(
    phoneMenuSelectors.phoneMenus,
    fetchPhoneMenus,
  )
  const teams = usePreloader(roleSelectors.roles, fetchRoles)
  const assignableNodeOptions = useMemo(
    () => assignableNodeOptionsFromPhoneMenus(phoneMenus || []),
    [phoneMenus],
  )
  const optionsDirectAssigned = useMemo(() => {
    if (assignmentType === NodeAssignmentType.Role) {
      return assignableNodeOptions.filter(
        option =>
          option.node.roles &&
          option.node.roles.some(role => role.id === entity.id),
      )
    }
    return assignableNodeOptions.filter(
      option =>
        option.node.extensions &&
        option.node.extensions.some(
          ext => ext.id === (entity as ILicenseGroupAccount).extension_id,
        ),
    )
  }, [assignableNodeOptions, entity, assignmentType])

  const readOnlyOptionsAssigned = useMemo(() => {
    if (assignmentType === NodeAssignmentType.Role) return []
    return assignableNodeOptions
      .filter(
        option =>
          option.node.roles &&
          option.node.roles.some(
            role => role.id === (entity as ILicenseGroupAccount).role_id,
          ),
      )
      .filter(option => !optionsDirectAssigned.includes(option)) // exclude anything already added
  }, [assignmentType, assignableNodeOptions, optionsDirectAssigned, entity])

  const optionsAssignedToEntity = useMemo(
    () => [...optionsDirectAssigned, ...readOnlyOptionsAssigned],
    [optionsDirectAssigned, readOnlyOptionsAssigned],
  )

  const optionIsReadonly = useCallback(
    (option: AssignableNodeOption) => readOnlyOptionsAssigned.includes(option),
    [readOnlyOptionsAssigned],
  )

  const gotoPhoneMenus = useCallback(() => navigate('/phone-menus'), [navigate])
  const phoneMenuNameMap = useCallback(
    (option: AssignableNodeOption) => option.title,
    [],
  )
  const searchValueMap = useCallback(
    (option: AssignableNodeOption) =>
      option.node.type === NodeType.RootNode
        ? option.title
        : `${option.subtitle} ${option.title}`,
    [],
  )
  const tokenClick = useCallback(
    (option: AssignableNodeOption) =>
      navigate(`/phone-menus/${option.phoneMenuId}`),
    [navigate],
  )

  const onSave = useCallback(
    (options: AssignableNodeOption[]) => {
      if (assignmentType === NodeAssignmentType.Role) {
        updateTeamNodeAssignments(
          entity.id,
          options.map(o => o.node.id),
        )
      } else {
        updateExtensionNodeAssignments(
          (entity as ILicenseGroupAccount).extension_id,
          options
            // exclude the readonly options from being saved
            .filter(option => !readOnlyOptionsAssigned.includes(option))
            .map(o => o.node.id),
        )
      }
    },
    [
      updateTeamNodeAssignments,
      updateExtensionNodeAssignments,
      entity,
      assignmentType,
      readOnlyOptionsAssigned,
    ],
  )

  const renderPhoneMenuOption = useCallback(
    (option: AssignableNodeOption) => (
      <EntityRow
        title={<Regular color={colors.trulyDark}>{option.title}</Regular>}
        subtitle={option.subtitle}
        avatarIcon={
          option.phoneMenuType === PhoneMenuType.Advanced ? (
            <NumpadSVG width="100%" />
          ) : (
            <UsersSVG width="100%" />
          )
        }
        avatarText={option.title}
        avatarSeed={`${option.phoneMenuId}`}
        squareAvatar
        width="500px"
      />
    ),
    [],
  )
  const renderReadOnlyMessage = useCallback(
    (option: AssignableNodeOption) => {
      if (!teams) {
        return (
          <SpinnerMaterial
            color={colors.accentLavender}
            size="22px"
            strokeWidth={3}
          />
        )
      }
      devAssert(assert =>
        assert(
          assignmentType === NodeAssignmentType.Extension,
          'Invalid state, should be extension',
        ),
      )
      const team = teams.find(
        tm => tm.id === (entity as ILicenseGroupAccount).role_id,
      )
      devAssert(assert => assert(team, 'Team not found'))
      if (!team) return <div>Team not found. Contact support.</div>

      return (
        <EmptyResults
          title={`${
            option.node.type === NodeType.RootNode ? `Phone Menu` : `Node`
          } is assigned at the team level.`}
          actionText={`Go to ${team.display_name} Team`}
          // eslint-disable-next-line react/jsx-no-bind
          onActionClick={() => navigate(`/teams/${team.id}`)}
          hideIcon
        />
      )
    },
    [teams, navigate, entity, assignmentType],
  )
  const optionAvatarIcon = useCallback(
    (option: AssignableNodeOption) =>
      option.phoneMenuType === PhoneMenuType.Advanced ? (
        <NumpadSVG width="100%" />
      ) : (
        <UsersSVG width="75%" />
      ),
    [],
  )

  const optionAvatarSeed = useCallback(
    (option: AssignableNodeOption) => `${option.phoneMenuId}`,
    [],
  )

  if (!phoneMenus) {
    return <FieldLoader delay={250} />
  }
  return (
    <InlineMultiSelect
      defaultSelectedOptions={optionsAssignedToEntity}
      options={assignableNodeOptions}
      renderOption={renderPhoneMenuOption}
      onTokenClick={tokenClick}
      valueDisplayMap={phoneMenuNameMap}
      searchMap={searchValueMap}
      placeholder="type a phone menu name"
      showButtons
      onSave={onSave}
      saveOnTokenClick
      emptyView={{ entityName: 'Phone Menus', onClick: gotoPhoneMenus }}
      optionIsReadOnly={optionIsReadonly}
      renderReadOnlyMessage={renderReadOnlyMessage}
      optionAvatarIcon={optionAvatarIcon}
      optionAvatarColorSeed={optionAvatarSeed}
      data-cy="phone-menu-select-input"
      data-options-cy="phone-menu-select-options"
    />
  )
}

export default PhoneMenuSelect
