import {
  FETCH_LG_SETTINGS,
  FETCH_LG_SETTINGS_SUCCESS,
  FETCH_LG_SETTINGS_FAIL,
  LG_COMPANY_CHANGED,
  LG_AUTO_INVITE_CHANGED,
  LG_ROUTING_PROTECTION_CHANGED,
  LG_ACCESS_RECORDINGS_CHANGED,
  LG_CALL_COMPLIANCE_CHANGED,
  LG_DATA_RETENTION_CHANGED,
  LG_ALLOW_PASSWORD_AUTH_CHANGED,
} from './actionTypes'

import { ILicenseGroup } from 'truly-ts'
import { createReducer } from 'truly-utils'
import {
  fetchLicenseGroupSuccess,
  fetchLicenseGroupFail,
  lgCompanyChanged,
  lgAutoInviteChanged,
  lgRoutingProtectionChanged,
  lgAccessRecordingsChanged,
  lgCallComplianceChanged,
  lgDataRetentionChanged,
  lgAllowPasswordAuthChanged,
} from './actionCreators'
import { FETCH_USER_SUCCESS } from '../user/actionTypes'
import { fetchUserSuccess } from '../user/actionCreators'

export interface LGSettingsState {
  lg?: ILicenseGroup
  error: string | null
  loading: boolean
}

const INITIAL_STATE: LGSettingsState = {
  error: null,
  loading: false,
}

const user = createReducer<LGSettingsState>(INITIAL_STATE, {
  [FETCH_LG_SETTINGS]: state => ({
    ...state,
    error: null,
    loading: true,
  }),
  [FETCH_USER_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchUserSuccess>,
  ) => ({
    ...state,
    lg: action.payload.licenseGroup,
    error: null,
    loading: false,
  }),
  [FETCH_LG_SETTINGS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchLicenseGroupSuccess>,
  ) => ({
    ...state,
    lg: action.payload.lg,
    error: null,
    loading: false,
  }),
  [FETCH_LG_SETTINGS_FAIL]: (
    state,
    action: ReturnType<typeof fetchLicenseGroupFail>,
  ) => ({
    ...state,
    error: action.payload.error.message,
    loading: false,
  }),
  [LG_COMPANY_CHANGED]: (
    state,
    action: ReturnType<typeof lgCompanyChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      company: action.payload,
    },
  }),
  [LG_AUTO_INVITE_CHANGED]: (
    state,
    action: ReturnType<typeof lgAutoInviteChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: state.lg && {
        ...state.lg.settings,
        auto_invite: action.payload,
      },
    },
  }),
  [LG_ROUTING_PROTECTION_CHANGED]: (
    state,
    action: ReturnType<typeof lgRoutingProtectionChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: {
        ...state.lg.settings,
        routing_protection: action.payload,
      },
    },
  }),
  [LG_ACCESS_RECORDINGS_CHANGED]: (
    state,
    action: ReturnType<typeof lgAccessRecordingsChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: {
        ...state.lg.settings,
        access_recording: action.payload,
      },
    },
  }),
  [LG_CALL_COMPLIANCE_CHANGED]: (
    state,
    action: ReturnType<typeof lgCallComplianceChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: {
        ...state.lg.settings,
        call_compliance: action.payload,
      },
    },
  }),
  [LG_DATA_RETENTION_CHANGED]: (
    state,
    action: ReturnType<typeof lgDataRetentionChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: {
        ...state.lg.settings,
        data_retention: action.payload.policy,
      },
    },
  }),
  [LG_ALLOW_PASSWORD_AUTH_CHANGED]: (
    state,
    action: ReturnType<typeof lgAllowPasswordAuthChanged>,
  ) => ({
    ...state,
    lg: state.lg && {
      ...state.lg,
      settings: state.lg && {
        ...state.lg.settings,
        allow_password_auth: action.payload,
      },
    },
  }),
})

export default user
