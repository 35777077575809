import React, { useState, useEffect } from 'react'
import {
  ModalLayout,
  PopupModal,
  TextInput,
  SpinnerMaterial,
  colors,
} from 'js-components'
import NumberPurchaserContainer from '../../containers/NumberPurchaserContainer'
import { phoneNumberSelectedValidationHandler } from '../../utils/Validation'
import { useValidatedState } from 'truly-utils'
import { useDispatch, useSelector } from 'react-redux'
import { createConferenceRoom } from '../../reducers/conferenceRooms/actionCreators'
import * as conferenceRoomSelectors from '../../reducers/conferenceRooms/conferenceRoomsSelectors'

interface ConferenceRoomNewModalProps {
  show: boolean
  conferenceRoomCount: number
  onClose: () => any
}

export default function ConferenceRoomNewModal(
  props: ConferenceRoomNewModalProps,
) {
  const { show, onClose, conferenceRoomCount } = props
  const dispatch = useDispatch()
  const conferenceRoomLoading = useSelector(
    conferenceRoomSelectors.loadingConferenceRoom,
  )
  const [name, setName] = useState('')
  const [canValidate, setCanValidate] = useState(false)
  const [
    selectedNumber,
    selectedNumberValidationState,
    setAndValidateSelectedNumber,
  ] = useValidatedState('', phoneNumberSelectedValidationHandler)
  const [isTrulyNumber, setIsTrulyNumber] = useState<boolean>(false)

  useEffect(() => {
    if (props.show) {
      setName('')
      setCanValidate(false)
      setAndValidateSelectedNumber(false, '')
    }
  }, [props.show, setAndValidateSelectedNumber])

  const onNameChanged = (newName: string) => setName(newName)

  const onNumberChanged = (
    newSelectedNumber: string,
    newIsTrulyNumber: boolean,
  ) => {
    setIsTrulyNumber(newIsTrulyNumber)
    setAndValidateSelectedNumber(canValidate, newSelectedNumber)
  }

  const onSave = () => {
    setCanValidate(true)
    const phoneNumberValidationState = setAndValidateSelectedNumber(
      true,
      selectedNumber,
    )

    if (phoneNumberValidationState?.valid) {
      dispatch(
        createConferenceRoom(
          (name || `Conference Room ${props.conferenceRoomCount}`).trim(),
          selectedNumber || '',
          isTrulyNumber,
        ),
      )
    }
  }

  return (
    <PopupModal
      show={show}
      width="550px"
      onRequestClose={onClose}
      hideOnBgClick>
      <ModalLayout.Container>
        <ModalLayout.Content>
          <ModalLayout.Header title="Add New Conference Room" />
          <ModalLayout.HorizontalLine />
          <ModalLayout.VerticalInputRow label="Conference Room Name">
            <TextInput
              data-cy="conference-room-name"
              value={name}
              autoFocus
              placeholder={`Conference Room ${conferenceRoomCount}`}
              onChange={onNameChanged}
            />
          </ModalLayout.VerticalInputRow>
          <ModalLayout.HorizontalLine />
          <NumberPurchaserContainer
            labelWidth="165px"
            selectedNumber={selectedNumber ?? undefined}
            validation={selectedNumberValidationState}
            onSelectedNumberChanged={onNumberChanged}
            enableSelectUnassigned
          />
          <ModalLayout.Footer
            actionText={
              conferenceRoomLoading ? (
                <SpinnerMaterial
                  color={colors.trulyDark}
                  strokeWidth={5}
                  size="25px"
                />
              ) : (
                'Add'
              )
            }
            onAction={onSave}
            onCancel={onClose}
            actionDisabled={conferenceRoomLoading}
          />
        </ModalLayout.Content>
      </ModalLayout.Container>
    </PopupModal>
  )
}
