import {
  FETCH_CONFERENCE_ROOMS,
  FETCH_CONFERENCE_ROOMS_FAIL,
  FETCH_CONFERENCE_ROOMS_SUCCESS,
  CONFERENCE_ROOM_ADDED,
  CONFERENCE_ROOM_UPDATED,
  DELETE_CONFERENCE_ROOM,
  CREATE_CONFERENCE_ROOM,
  CREATE_CONFERENCE_ROOM_FAIL,
} from './actionTypes'

import {
  fetchConferenceRoomsSuccess,
  conferenceRoomAdded,
  conferenceRoomUpdated,
  deleteConferenceRoom,
} from './actionCreators'

import keyBy from 'lodash/keyBy'
import { IConferenceRoom, IPhoneNumber } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  PHONE_NUMBER_ADDED,
  PHONE_NUMBER_REMOVED,
  PHONE_NUMBER_UNASSIGNED,
  PHONE_NUMBER_ASSIGNED,
} from '../phoneNumbers/actionTypes'
import {
  phoneNumberAdded,
  phoneNumberRemoved,
  phoneNumberUnassigned,
  phoneNumberAssigned,
} from '../phoneNumbers/actionCreators'
import values from 'lodash/values'

export interface ConferenceRoomsState {
  loading: boolean
  loadingConferenceRoom: boolean
  conferenceRooms?: { [key: string]: IConferenceRoom } | null
}

const INITIAL_STATE: ConferenceRoomsState = {
  loading: false,
  loadingConferenceRoom: false,
}

const conferenceRooms = createReducer<ConferenceRoomsState>(INITIAL_STATE, {
  [FETCH_CONFERENCE_ROOMS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_CONFERENCE_ROOMS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchConferenceRoomsSuccess>,
  ) => ({
    ...state,
    loading: false,
    conferenceRooms: keyBy(action.payload.rooms, 'id'),
  }),
  [FETCH_CONFERENCE_ROOMS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [CREATE_CONFERENCE_ROOM]: state => ({
    ...state,
    loadingConferenceRoom: true,
  }),
  [CREATE_CONFERENCE_ROOM_FAIL]: state => ({
    ...state,
    loadingConferenceRoom: false,
  }),
  [CONFERENCE_ROOM_ADDED]: (
    state,
    action: ReturnType<typeof conferenceRoomAdded>,
  ) => ({
    ...state,
    conferenceRooms: {
      ...state.conferenceRooms,
      [getRequired(action.payload.room.id)]: action.payload.room,
    },
    loadingConferenceRoom: false,
  }),
  [CONFERENCE_ROOM_UPDATED]: (
    state,
    action: ReturnType<typeof conferenceRoomUpdated>,
  ) => ({
    ...state,
    conferenceRooms: {
      ...state.conferenceRooms,
      [getRequired(action.payload.room.id)]: action.payload.room,
    },
  }),
  [DELETE_CONFERENCE_ROOM]: (
    state,
    action: ReturnType<typeof deleteConferenceRoom>,
  ) => {
    const rooms: { [key: string]: IConferenceRoom } = {
      ...state.conferenceRooms,
    }
    delete rooms[action.payload.room.id]
    return {
      ...state,
      conferenceRooms: rooms,
    }
  },
  [PHONE_NUMBER_UNASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberUnassigned>,
  ) => {
    const { entityType, entityId, e164Number } = action.payload
    if (entityType !== 'conference_phone' || !state.conferenceRooms)
      return state
    const room = state.conferenceRooms[entityId]
    return {
      ...state,
      conferenceRooms: {
        ...state.conferenceRooms,
        [entityId]: {
          ...room,
          numbers: (room.numbers || []).filter(number => number !== e164Number),
        },
      },
    }
  },
  [PHONE_NUMBER_ASSIGNED]: (
    state,
    action: ReturnType<typeof phoneNumberAssigned>,
  ) => {
    const { entityType, entityId, phoneNumber } = action.payload
    if (entityType !== 'conference_phone' || !state.conferenceRooms)
      return state
    const room = state.conferenceRooms[entityId]
    return {
      ...state,
      conferenceRooms: {
        ...state.conferenceRooms,
        [entityId]: {
          ...room,
          numbers: [...(room.numbers || []), phoneNumber.full_number],
        },
      },
    }
  },
  [PHONE_NUMBER_ADDED]: (
    state,
    action: ReturnType<typeof phoneNumberAdded>,
  ) => {
    if (!state.conferenceRooms) return state
    return {
      ...state,
      conferenceRooms: keyBy(
        values(state.conferenceRooms).map(
          addNumberIfAssigned(action.payload.phoneNumber),
        ),
        'id',
      ),
    }
  },
  [PHONE_NUMBER_REMOVED]: (
    state,
    action: ReturnType<typeof phoneNumberRemoved>,
  ) => {
    if (!state.conferenceRooms) return state
    return {
      ...state,
      conferenceRooms: keyBy(
        values(state.conferenceRooms).map(
          removeNumberIfAssigned({
            ...action.payload.phoneNumber,
            entity: null,
          }),
        ),
        'id',
      ),
    }
  },
})

const removeNumberIfAssigned = (phone: IPhoneNumber) => (
  room: IConferenceRoom,
): IConferenceRoom => {
  if (
    room.numbers.some(n => n === phone.full_number) &&
    !isNumberAssignedToRoom(phone, room)
  ) {
    // shouldn't be assigned
    return {
      ...room,
      numbers: room.numbers.filter(n => n !== phone.full_number),
    }
  }
  return room
}

const addNumberIfAssigned = (phone: IPhoneNumber) => (
  room: IConferenceRoom,
): IConferenceRoom => {
  if (
    isNumberAssignedToRoom(phone, room) &&
    !room.numbers.some(n => n === phone.full_number)
  ) {
    // should be assigned
    return {
      ...room,
      numbers: [...room.numbers, phone.full_number],
    }
  }
  return room
}

function isNumberAssignedToRoom(
  phone: IPhoneNumber,
  room: IConferenceRoom,
): boolean {
  return (
    !!phone.entity &&
    phone.entity.id === room.id &&
    phone.entity.type === 'conference_phone'
  )
}

export default conferenceRooms
