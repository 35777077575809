import {
  FETCH_LG_SETTINGS,
  FETCH_LG_SETTINGS_SUCCESS,
  FETCH_LG_SETTINGS_FAIL,
  UPDATE_LG_COMPANY,
  LG_COMPANY_CHANGED,
  UPDATE_LG_AUTO_INVITE,
  UPDATE_LG_ROUTING_PROTECTION,
  UPDATE_LG_ACCESS_RECORDINGS,
  LG_AUTO_INVITE_CHANGED,
  LG_ROUTING_PROTECTION_CHANGED,
  LG_ACCESS_RECORDINGS_CHANGED,
  UPDATE_LG_CALL_COMPLIANCE,
  LG_CALL_COMPLIANCE_CHANGED,
  UPDATE_LG_DATA_RETENTION,
  LG_DATA_RETENTION_CHANGED,
  UPDATE_LG_ALLOW_PASSWORD_AUTH,
  LG_ALLOW_PASSWORD_AUTH_CHANGED,
} from './actionTypes'

import {
  CallComplianceValue,
  ILicenseGroup,
  ICallComplianceSettings,
  ILicenseGroupSettings,
  IDataRetentionPolicy,
} from 'truly-ts'

export const fetchLicenseGroup = () => ({
  type: FETCH_LG_SETTINGS,
  payload: {},
})

export const fetchLicenseGroupSuccess = (lg: ILicenseGroup) => ({
  type: FETCH_LG_SETTINGS_SUCCESS,
  payload: {
    lg,
  },
})

export const fetchLicenseGroupFail = (error: Error) => ({
  type: FETCH_LG_SETTINGS_FAIL,
  payload: {
    error,
  },
})

export const updateLgCompany = (company: string) => ({
  type: UPDATE_LG_COMPANY,
  payload: company,
})

export const lgCompanyChanged = (company: string) => ({
  type: LG_COMPANY_CHANGED,
  payload: company,
})

export const updateLgAutoInvite = (enabled: boolean) => ({
  type: UPDATE_LG_AUTO_INVITE,
  payload: enabled,
})

export const lgAutoInviteChanged = (enabled: boolean) => ({
  type: LG_AUTO_INVITE_CHANGED,
  payload: enabled,
})

export const updateLgRoutingProtection = (
  setting: ILicenseGroupSettings['routing_protection'],
) => ({
  type: UPDATE_LG_ROUTING_PROTECTION,
  payload: setting,
})

export const lgRoutingProtectionChanged = (
  setting: ILicenseGroupSettings['routing_protection'],
) => ({
  type: LG_ROUTING_PROTECTION_CHANGED,
  payload: setting,
})

export const updateLgAccessRecordings = (enabled: boolean) => ({
  type: UPDATE_LG_ACCESS_RECORDINGS,
  payload: enabled,
})

export const lgAccessRecordingsChanged = (enabled: boolean) => ({
  type: LG_ACCESS_RECORDINGS_CHANGED,
  payload: enabled,
})

export const updateLgCallCompliance = (
  usaCallCompliance: CallComplianceValue,
  intlCallCompliance: CallComplianceValue,
) => ({
  type: UPDATE_LG_CALL_COMPLIANCE,
  payload: {
    usaCallCompliance,
    intlCallCompliance,
  },
})

export const lgCallComplianceChanged = (
  callCompliance: ICallComplianceSettings,
) => ({
  type: LG_CALL_COMPLIANCE_CHANGED,
  payload: callCompliance,
})

export const updateLgDataRetention = (policy: IDataRetentionPolicy | null) => ({
  type: UPDATE_LG_DATA_RETENTION,
  payload: {
    policy,
  },
})

export const lgDataRetentionChanged = (
  policy: IDataRetentionPolicy | null,
) => ({
  type: LG_DATA_RETENTION_CHANGED,
  payload: {
    policy,
  },
})

export const updateLgAllowPasswordAuth = (enabled: boolean) => ({
  type: UPDATE_LG_ALLOW_PASSWORD_AUTH,
  payload: enabled,
})

export const lgAllowPasswordAuthChanged = (enabled: boolean) => ({
  type: LG_ALLOW_PASSWORD_AUTH_CHANGED,
  payload: enabled,
})
