import { Next, IAction, Store } from '../../store/index'
import {
  CONTINUE_NAVIGATION,
  SET_UNSAVED_CHANGES,
  CLEAR_UNSAVED_CHANGES,
} from '../../reducers/navigation/actionTypes'
import * as navigationSelectors from '../../reducers/navigation/navigationSelectors'
import {
  CALL_HISTORY_METHOD,
  CallHistoryMethodAction,
} from 'connected-react-router'
import {
  showUnsavedChangesDialog,
  clearUnsavedChanges,
  hideUnsavedChangesDialog,
  setUnsavedChanges,
} from '../../reducers/navigation/actionCreators'

export const handleNavigationHooks = () => (store: Store) => (next: Next) => (
  action: IAction,
) => {
  switch (action.type) {
    case CALL_HISTORY_METHOD: {
      // react router navigation
      if (!navigationSelectors.hasUnsavedChanges(store.getState())) {
        // if there aren't any unsaved changes
        break
      }

      const routerAction = action as CallHistoryMethodAction

      const allowedNavRegex = navigationSelectors.allowedNavRegex(
        store.getState(),
      )

      if (
        allowedNavRegex &&
        ['push', 'replace'].includes(routerAction.payload.method)
      ) {
        const args = routerAction.payload.args ?? []
        const path = args?.length > 0 ? args[0] : ''
        const regex = new RegExp(allowedNavRegex)
        if (regex.test(path)) {
          break // if allowed path, continue on
        }
      }
      // show custom dialog blocking navigation
      store.dispatch(showUnsavedChangesDialog(routerAction.payload))
      return
    }
    case CONTINUE_NAVIGATION: {
      const payload = navigationSelectors.continueAction(store.getState())
      store.dispatch(hideUnsavedChangesDialog())
      store.dispatch(clearUnsavedChanges())
      store.dispatch({
        type: CALL_HISTORY_METHOD,
        payload,
      })
      break
    }
    case SET_UNSAVED_CHANGES: {
      // show native dialog when navigating entirely away from app
      const desc = (action as ReturnType<typeof setUnsavedChanges>).payload
        .description
      window.onbeforeunload = () => {
        return desc
      }
      break
    }
    case CLEAR_UNSAVED_CHANGES: {
      window.onbeforeunload = null
      break
    }
    default:
      break
  }

  return next(action)
}
