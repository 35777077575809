import styled from 'styled-components'
import { FlexColumn, FlexRow } from 'js-components'

export const IconWrapper = styled(FlexColumn)`
  margin-right: 22px;
  justify-content: center;
  cursor: pointer;
`

export const AddOptionButtonWrapper = styled(FlexRow)`
  width: 150px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
`
