import React from 'react'
import { IDataRetentionPolicy } from 'truly-ts'
import {
  SidePanelModal,
  ModalLayout,
  FlexRow,
  Small,
  colors,
  Regular,
  spaceSizes,
} from 'js-components'
import { dataExpirationToDisplayShortFormat } from './utils'
import useRouter from '../../utils/custom-hooks/useRouter'

export const HeaderItem = (props: {
  width: number
  children: React.ReactNode
}) => (
  <div style={{ width: `${props.width}px` }}>
    <Small color={colors.darkGray} bold>
      {props.children}
    </Small>
  </div>
)

const RowItem = (props: { width: number; children: React.ReactNode }) => (
  <div style={{ width: `${props.width}px` }}>{props.children}</div>
)

interface Props {
  show: boolean
  onClose: () => void
  type: 'Team'
  list: Array<{
    name: string
    policy: IDataRetentionPolicy | null
    link: string
  }>
}

export default function CustomDataRetention({
  show,
  onClose,
  type,
  list,
}: Props) {
  const router = useRouter()
  return (
    <SidePanelModal onRequestClose={onClose} visible={show} width="746px">
      <ModalLayout.Container>
        <ModalLayout.Header
          title={`Custom Data Retention (${type})`}
          addBottomBorder
        />
        <ModalLayout.Content fullHeight>
          <ModalLayout.SubHeader mb="0px">
            <FlexRow pl="10px">
              <HeaderItem width={170}>User</HeaderItem>
              <HeaderItem width={140}>Recordings</HeaderItem>
              <HeaderItem width={140}>Call Data</HeaderItem>
              <HeaderItem width={140}>Messages</HeaderItem>
            </FlexRow>
          </ModalLayout.SubHeader>
          {list.map((item, idx) => (
            <React.Fragment key={idx}>
              <ModalLayout.HoverableRow onClick={() => router.push(item.link)}>
                {idx === 0 ? (
                  <ModalLayout.Spacer />
                ) : (
                  <ModalLayout.LightHorizontalLine />
                )}
                <ModalLayout.Row>
                  <FlexRow alignItems="center">
                    <RowItem width={170}>
                      <Regular
                        color={colors.trulyDark}
                        truncate
                        mr={`${spaceSizes.sm}px`}>
                        {item.name}
                      </Regular>
                    </RowItem>
                    <RowItem width={140}>
                      <Regular color={colors.darkGray}>
                        {dataExpirationToDisplayShortFormat(
                          item.policy?.recordings ?? null,
                        )}
                      </Regular>
                    </RowItem>
                    <RowItem width={140}>
                      <Regular color={colors.darkGray}>
                        {dataExpirationToDisplayShortFormat(
                          item.policy?.phone_calls ?? null,
                        )}
                      </Regular>
                    </RowItem>
                    <RowItem width={140}>
                      <Regular color={colors.darkGray}>
                        {dataExpirationToDisplayShortFormat(
                          item.policy?.sms ?? null,
                        )}
                      </Regular>
                    </RowItem>
                  </FlexRow>
                </ModalLayout.Row>
              </ModalLayout.HoverableRow>
            </React.Fragment>
          ))}
          {list.length > 0 && <ModalLayout.LightHorizontalLine />}
        </ModalLayout.Content>
      </ModalLayout.Container>
    </SidePanelModal>
  )
}
