import { connect } from 'react-redux'
import LeftNav from '../components/LeftNav/LeftNav'
import { IState, Dispatch } from '../store'
import { push } from 'connected-react-router'

const mapStateToProps = (state: IState) => ({
  currentPath: state.router.location ? state.router.location.pathname : '',
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  navigate: (path: string) => {
    dispatch(push(path))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav)
