import { IState } from '../../store'
import { createSelector } from 'reselect'
import values from 'lodash/values'

export const base = (state: IState) => state.webhooks
export const webhooks = createSelector(
  base,
  w => w.webhooks && values(w.webhooks),
)
export const webhooksLoading = (state: IState) => base(state).loading
export const webhookById = (id: number) => (state: IState) => {
  const map = base(state).webhooks
  return map && map[id]
}
