import React, { useState } from 'react'
import memoizeOne from 'memoize-one'
import {
  ILicenseGroupAccount,
  IRole,
  IAcceptInboundCalls,
  IPhoneNumber,
  IStatus,
  IAccountAvailability,
} from 'truly-ts'
import { ReactComponent as MonitorSVG } from 'js-components/src/components/Icons/Monitor.svg'
import { ReactComponent as AdminSVG } from 'js-components/src/components/Icons/Admin.svg'
import {
  DropdownContainer,
  Separator,
  TabListContainer,
  ReadonlyText,
  AvailabilityPickerWrapper,
  TabContainer,
} from './Styles'
import {
  colors,
  DropdownMenu,
  OptionsDots,
  DropdownMenuOption,
  TabList,
  Tab,
  ModalLayout,
  InlineTextEdit,
  Record,
  AvailabilityPicker,
  AvailabilityConstants,
  InlineSelectEdit,
  DeleteDialog,
  TimezoneSelect,
  AvailabilityUtils,
  FlexRow,
  Edit,
  EmptyResults,
  ConfirmDialog,
  Small,
  HelpIcon,
  Link,
  Regular,
  spaceSizes,
} from 'js-components'
import EnabledSettingRow from '../EnabledSettingRow/EnabledSettingRow'
import PasswordEditModal from '../PasswordEditModal/PasswordEditModal'
import PhoneNumberAssignRow from '../PhoneNumberAssignmentRow/PhoneNumberAssignRow'
import AddPhoneNumberModal from '../AddPhoneNumberModal/AddPhoneNumberModal'
import { ReactComponent as EmailSVG } from 'js-components/src/components/Icons/Email.svg'
// import PhoneMenuSelect from '../PhoneMenuSelect/PhoneMenuSelect';
// import * as extensionSelectors from '../../reducers/extensions/extensionsSelectors';
import * as accountsSelectors from '../../reducers/accounts/accountsSelectors'
import * as authSelectors from '../../reducers/auth/authSelectors'
import * as rolesSelectors from '../../reducers/roles/rolesSelectors'
// import usePreloader from '../../utils/data-hooks/usePreloader';
// import { fetchExtensions } from '../../reducers/extensions/actionCreators';
// import { NodeAssignmentType } from '../../constants/phone-menu';
import PhoneNumberUnassignRow from '../PhoneNumberAssignmentRow/PhoneNumberUnassignRow'
import {
  patchAccount,
  deleteAccount,
  updateAccountPermission,
  resendInvite,
} from '../../reducers/accounts/actionCreators'
import UserDetailInput from '../UserDetailInput/UserDetailInput'
import TeamPermissionModal from '../TeamPermissionModal/TeamPermissionModal'
import { TAB_LOCATIONS } from '../../constants/TabLocations'
import { assignPhoneNumber } from '../../reducers/phoneNumbers/actionCreators'
import { useOpenCloseState, useActionCreator } from 'truly-utils'
import PhoneMenuSelect from '../PhoneMenuSelect/PhoneMenuSelect'
import { NodeAssignmentType } from '../../constants/phone-menu'
import TeamUtils from '../../utils/model-utils/team-utils'
import Permissions from '../../constants/Permissions'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import PasswordRow from '../PasswordRow'

const GlobalDispositions = AvailabilityConstants.GLOBAL_DISPOSITIONS.map(d => ({
  value: `${d.value}`,
  label: d.label,
}))

const PhoneMenuDispositions = AvailabilityConstants.PHONEMENU_DISPOSITIONS.map(
  d => ({
    value: `${d.value}`,
    label: d.label,
  }),
)

const TabListWrapper: React.SFC = ({ children }) => (
  <TabListContainer>{children}</TabListContainer>
)
const TabWrapper: React.SFC = ({ children }) => (
  <TabContainer>{children}</TabContainer>
)

interface UserDetailProps {
  userId: number
}

const UserDetail: React.FC<UserDetailProps> = props => {
  const onPatchAccount = useActionCreator(patchAccount)
  const onUpdatePermission = useActionCreator(updateAccountPermission)
  const onDeleteAccount = useActionCreator(deleteAccount)
  const onAssignNumber = useActionCreator(assignPhoneNumber)
  const onResendInvite = useActionCreator(resendInvite)

  const user = useSelector(accountsSelectors.accountById(props.userId), isEqual)

  if (!user) {
    throw new Error("Can't load UserDetail without user")
  }

  const loggedInAccount = useSelector(authSelectors.account)
  const isLoggedInUser = props.userId === loggedInAccount?.id
  const [
    showingAdminLossAlert,
    openAdminLossAlert,
    closeAdminLossAlert,
  ] = useOpenCloseState(false)
  const [
    showingTeamUnassignWarning,
    openTeamUnassignWarning,
    closeTeamUnassignWarning,
  ] = useOpenCloseState(false)

  const team = useSelector(rolesSelectors.roleById(user.role_id), isEqual)
  const roles = useSelector(rolesSelectors.roles, isEqual) ?? []

  const [
    showEditPasswordModal,
    openPasswordEditModal,
    closePasswordEditModal,
  ] = useOpenCloseState(false)

  const [showDropdown, setShowDropdown] = useState(false)
  const [addingNumber, setAddingNumber] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onShowDropdown = () => setShowDropdown(true)
  const onHideDropdown = () => setShowDropdown(false)

  const resendInviteClicked = () => {
    onResendInvite(props.userId)
  }

  const onOpenAddNumber = () => setAddingNumber(true)
  const onCloseAddNumber = () => setAddingNumber(false)
  const onAddNumber = (number: IPhoneNumber) => {
    onAssignNumber('account', user.id, number.full_number)
  }

  const onDelete = () => setDeleting(true)
  const onCancelDelete = () => setDeleting(false)
  const onConfirmDelete = () => onDeleteAccount(user)

  const getDropdownMenuOptions = memoizeOne(
    (
      dropdownUser: ILicenseGroupAccount,
      dropdownIsInvite: boolean,
    ): DropdownMenuOption[] => {
      if (dropdownIsInvite) {
        return [
          {
            label: `Resend Invite (${dropdownUser.email})`,
            color: colors.trulyDark,
            onClick: resendInviteClicked,
          },
          {
            label: 'Revoke Invite',
            color: colors.alertRed,
            bold: true,
            onClick: onDelete,
          },
        ]
      }
      return [
        {
          label: 'Delete User',
          color: colors.alertRed,
          bold: true,
          onClick: onDelete,
        },
      ]
    },
  )

  const mapRolesOptions = (selectRoles: IRole[]) => {
    const baseOption = [{ value: '', label: 'None' }]
    if (!selectRoles) return baseOption
    const options = selectRoles.map(role => ({
      value: role.id.toString(),
      label: role.display_name,
    }))
    return [...baseOption, ...options]
  }

  const isInvite = user.status === IStatus.Invited

  const fullname = `${user.first_name} ${user.last_name}`

  const onSave = (key: keyof ILicenseGroupAccount) => (input: any) =>
    onPatchAccount(props.userId, { [key]: input })

  const onSelectSave = (key: keyof ILicenseGroupAccount) => (value: string) => {
    if (value === '') return onPatchAccount(props.userId, { [key]: null })

    const parsedValue = parseInt(value, 10)
    if (!parsedValue) return
    onPatchAccount(props.userId, { [key]: parsedValue })
  }

  const onRoleChange = (value: string) => {
    const newTeam = roles.find(role => `${role.id}` === value)
    if (!value && isLoggedInUser) {
      // None case, warn about possibly losing admin
      openTeamUnassignWarning()
      // prevent change
      return false
    } else if (
      newTeam &&
      isLoggedInUser &&
      !TeamUtils.isPermissionEnabled(newTeam, Permissions.AccessAdmin)
    ) {
      // This would take away the logged in admin priviledges, so we need to prevent the change
      openAdminLossAlert()
      // prevent change
      return false
    } else {
      onSelectSave('role_id')(value)
    }
  }

  const onConfirmUnassignFromTeam = () => {
    closeTeamUnassignWarning()
    onSelectSave('role_id')('')
  }

  const onPermissionSave = (permission: string) => () => {
    onUpdatePermission(
      props.userId,
      permission,
      !user.permissions.includes(permission),
    )
  }

  const onToggleAutomaticCallRecording = (automaticCallRecording: boolean) => {
    onPatchAccount(props.userId, {
      automatic_call_recording: !!automaticCallRecording,
    })
  }

  const onAvailabilitySave = (key: keyof IAcceptInboundCalls) => (
    availability: string,
  ) => {
    const modUserAvailability = user.availability
    if (availability === 'true') {
      modUserAvailability.accept_inbound_calls[key] = true
    } else if (availability === 'false') {
      modUserAvailability.accept_inbound_calls[key] = false
    }
    onPatchAccount(props.userId, { availability: modUserAvailability })
  }

  const onAvailabilityPickerSave = (calendar: any) => {
    const modUserAvailability = user.availability
    modUserAvailability.calendar = calendar
    onPatchAccount(props.userId, { availability: modUserAvailability })
  }

  const userIsInTeam: boolean = !!user.role_id
  const accountPhoneNumbers = user.phone_numbers.filter(
    ({ entity }) => entity.type === 'account',
  )

  return (
    <ModalLayout.Container>
      <DeleteDialog
        show={deleting}
        title={`Delete ${isInvite ? user.email : fullname}`}
        deleteActionText="Delete"
        description={`${
          isInvite ? user.email : fullname
        } will be deleted from your organization. Any numbers still assigned to this user will be unassigned.`}
        confirmText="DELETE"
        onCancel={onCancelDelete}
        onDelete={onConfirmDelete}
      />
      <ConfirmDialog
        show={showingAdminLossAlert}
        confirmText="Okay"
        title="Unable to Assign to Team"
        description="This team does not have admin access and would cause you to lose admin access if you assigned yourself. This action has been prevented."
        color={colors.alertOrange}
        onConfirm={closeAdminLossAlert}
        onCancel={closeAdminLossAlert}
      />
      <DeleteDialog
        show={showingTeamUnassignWarning}
        title="You Could Lose Admin Access"
        description="Removing yourself from this team could cause you to lose admin access and you could be logged out."
        confirmText="CONTINUE"
        deleteActionText="Continue"
        onCancel={closeTeamUnassignWarning}
        onDelete={onConfirmUnassignFromTeam}
      />
      <AddPhoneNumberModal
        show={addingNumber}
        onClose={onCloseAddNumber}
        onAdd={onAddNumber}
      />
      <ModalLayout.Content fullHeight>
        <DropdownContainer
          onClick={onShowDropdown}
          role="button"
          data-cy="drop-down">
          <OptionsDots fill={colors.darkGray} height="20px" />
          <DropdownMenu
            show={showDropdown}
            onClose={onHideDropdown}
            placement="bottom-end"
            horizontalOffset={8}
            options={getDropdownMenuOptions(user, isInvite)}
            minWidth="300px">
            <div />
          </DropdownMenu>
        </DropdownContainer>
        <PasswordEditModal
          show={showEditPasswordModal}
          onClose={closePasswordEditModal}
          email={user.email}
          userId={props.userId}
        />
        <ModalLayout.Header
          title={!isInvite ? fullname : undefined}
          subtitle={isInvite ? user.email : undefined}
          boldTitle={false}
          avatarOptions={{
            colorSeed: !isInvite ? fullname : undefined,
            text: !isInvite ? fullname : undefined,
            icon: isInvite ? <EmailSVG width="100%" /> : undefined,
          }}
        />
        <Separator />
        <TabList
          tabListWrapper={TabListWrapper}
          tabWrapper={TabWrapper}
          style={{ height: '100%', overflowY: 'auto' }}>
          <Tab name="Details">
            <ModalLayout.SubHeader title="Basic Information" />
            <ModalLayout.FieldRow
              shouldPadRight
              label="First Name"
              labelWidth="160px">
              <InlineTextEdit
                originalValue={user.first_name}
                placeholder="Enter First Name"
                onSave={onSave('first_name')}
                showButtons
                required
                data-cy="user-first-name"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Last Name"
              labelWidth="160px">
              <InlineTextEdit
                originalValue={user.last_name}
                placeholder="Enter Last Name"
                onSave={onSave('last_name')}
                showButtons
                required
                data-cy="user-last-name"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Email"
              labelWidth="160px">
              <InlineTextEdit
                originalValue={user.email}
                onSave={onSave('email')}
                showButtons
                required
                data-cy="user-email"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Team"
              labelWidth="160px">
              <InlineSelectEdit
                width="100%"
                onSave={onRoleChange}
                originalValue={team ? team.id.toString() : ''}
                options={mapRolesOptions(roles)}
                data-cy="user-team-select"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Department"
              labelWidth="160px"
              data-cy="user-department-row">
              <UserDetailInput
                defaultValue={user.department ?? undefined}
                onSave={onSave('department')}
                inputType="Department"
                disabled={!!userIsInTeam}
                placeholder="Enter Department"
                teamId={user.role_id}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Position"
              labelWidth="160px"
              data-cy="user-position-row">
              <UserDetailInput
                defaultValue={user.position ?? undefined}
                onSave={onSave('position')}
                inputType="Position"
                disabled={!!userIsInTeam}
                placeholder="Enter Position"
                teamId={user.role_id}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.HoverableRow onClick={openPasswordEditModal}>
              <ModalLayout.LightHorizontalLine />
              <ModalLayout.FieldRow
                shouldPadRight
                label="Password"
                labelWidth="160px"
                data-cy="user-password-row">
                <FlexRow justifyContent="space-between">
                  <ReadonlyText>••••••••</ReadonlyText>
                  <Edit data-hover-row-show />
                </FlexRow>
              </ModalLayout.FieldRow>
            </ModalLayout.HoverableRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Phone Menu"
              labelWidth="160px">
              <PhoneMenuSelect
                assignmentType={NodeAssignmentType.Extension}
                entity={user}
              />
            </ModalLayout.FieldRow>
            <ModalLayout.SubHeader>
              <FlexRow alignItems="center">
                <Small bold color={colors.darkGray} mr={`${spaceSizes.sm}px`}>
                  SIP Credentials
                </Small>
                <HelpIcon>
                  <Regular as="p" mt="0px">
                    SIP Credentials are used to configure a desk phone to work
                    with Truly.
                  </Regular>
                  <Regular as="p" mb="0px">
                    For more information on Desk Phones, read our{' '}
                    <Link
                      href="https://intercom.help/truly_co/en/collections/1433448-desk-phones"
                      newWindow>
                      Knowledge Base Article
                    </Link>
                    .
                  </Regular>
                </HelpIcon>
              </FlexRow>
            </ModalLayout.SubHeader>
            <ModalLayout.FieldRow
              shouldPadRight
              label="Username"
              labelWidth="160px"
              data-cy="sip-username">
              <ReadonlyText>{user.sip_credentials.username}</ReadonlyText>
            </ModalLayout.FieldRow>
            <PasswordRow
              password={user.sip_credentials.password}
              data-cy="sip-password"
            />
            <ModalLayout.SubHeader title="Settings" />
            <ModalLayout.Row shouldPadTop shouldPadBottom>
              <EnabledSettingRow
                data-cy="call-recording-row"
                enabled={user.automatic_call_recording}
                title="Automatic Call Recording"
                enabledDesc="Calls will start recording automatically"
                disabledDesc="Calls will not record automatically, users can manually start recording by pressing the record button"
                onChange={onToggleAutomaticCallRecording}
                icon={<Record width="22px" height="22px" />}
                readonly={!!userIsInTeam}
                readOnlyComponent={
                  <TeamPermissionModal
                    inputType="Automatic Call Recording"
                    tabLocation={TAB_LOCATIONS.DETAILS}
                    teamId={user.role_id}
                  />
                }
              />
            </ModalLayout.Row>
          </Tab>
          <Tab name="Phone Numbers">
            <ModalLayout.SubHeader title="Extension" />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Extension Number"
              labelWidth="180px"
              data-cy="user-extension-number-row"
              helpText="When calling a phone menu with dial by extension enabled, the caller may dial an extension number to reach a specific user.">
              <ReadonlyText>{user.extension_number}</ReadonlyText>
            </ModalLayout.FieldRow>
            <ModalLayout.SubHeader title={TAB_LOCATIONS.PHONE_NUMBERS} mb="0" />
            {accountPhoneNumbers.map(({ number, entity }, idx) => (
              <PhoneNumberUnassignRow
                key={`line-${number}`}
                number={number}
                assignedEntityType="account"
                assignedEntityId={user.id}
                assignedEntityDisplay={isInvite ? user.email : fullname}
                includeSeparator={idx !== 0}
              />
            ))}
            <PhoneNumberAssignRow
              onClick={onOpenAddNumber}
              includeSeparator={accountPhoneNumbers.length !== 0}
            />
            <ModalLayout.LightHorizontalLine />
          </Tab>
          <Tab name="Permissions">
            <ModalLayout.Spacer />
            <ModalLayout.Row>
              <EnabledSettingRow
                data-cy="access-admin-row"
                enabled={user.permissions.includes('access_admin')}
                title="Admin"
                enabledDesc="User will be able to access the admin panel"
                disabledDesc="User will be unable to access the admin panel"
                onChange={onPermissionSave('access_admin')}
                icon={<AdminSVG width="26px" height="26px" />}
                readonly={!!userIsInTeam || isLoggedInUser}
                readOnlyComponent={
                  userIsInTeam ? (
                    <TeamPermissionModal
                      inputType="Admin"
                      tabLocation={TAB_LOCATIONS.PERMISSIONS}
                      teamId={user.role_id}
                    />
                  ) : (
                    <EmptyResults
                      title="You cannot remove your own admin access."
                      hideIcon
                    />
                  )
                }
              />
            </ModalLayout.Row>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.Row>
              <EnabledSettingRow
                data-cy="access-call-monitor-row"
                enabled={user.permissions.includes('call_monitor')}
                title="Call Monitoring"
                enabledDesc="User will be able to access the call monitoring panel"
                disabledDesc="User will be unable to access the call monitoring panel"
                onChange={onPermissionSave('call_monitor')}
                icon={<MonitorSVG width="26px" height="26px" />}
                readonly={!!userIsInTeam}
                readOnlyComponent={
                  <TeamPermissionModal
                    inputType="Call Monitoring"
                    tabLocation={TAB_LOCATIONS.PERMISSIONS}
                    teamId={user.role_id}
                  />
                }
              />
            </ModalLayout.Row>
            <ModalLayout.LightHorizontalLine />
          </Tab>
          <Tab name="Availability">
            <ModalLayout.Spacer />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Timezone"
              labelWidth="160px"
              data-cy="user-timezone-row">
              <TimezoneSelect
                timezone={user.timezone}
                saveTimezone={onSave('timezone')}
                width="100%"
                inline
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Availability"
              labelWidth="160px"
              data-cy="user-availability-row">
              <InlineSelectEdit
                originalValue={`${user.availability.accept_inbound_calls.global}`}
                options={GlobalDispositions}
                onSave={onAvailabilitySave('global')}
                width="100%"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <ModalLayout.FieldRow
              shouldPadRight
              label="Phone Menu"
              labelWidth="160px"
              data-cy="user-phonemenu-availability-row">
              <InlineSelectEdit
                originalValue={`${user.availability.accept_inbound_calls.phone_menu}`}
                options={PhoneMenuDispositions}
                onSave={onAvailabilitySave('phone_menu')}
                width="100%"
              />
            </ModalLayout.FieldRow>
            <ModalLayout.LightHorizontalLine />
            <WrappedAvailabilityPicker
              availability={user.availability}
              saveAvailability={onAvailabilityPickerSave}
            />
          </Tab>
        </TabList>
      </ModalLayout.Content>
    </ModalLayout.Container>
  )
}

// formatCalendar is CPU heavy, so only render when needed
const WrappedAvailabilityPicker: React.FC<{
  availability: IAccountAvailability
  saveAvailability: (cal: any) => void
}> = React.memo(({ availability, saveAvailability }) => {
  const getCalendarAvailability = () => {
    const { calendar } = availability
    return AvailabilityUtils.formatCalendar(calendar) || []
  }
  return (
    <AvailabilityPickerWrapper data-cy="availability-picker">
      <AvailabilityPicker
        availability={!!availability && getCalendarAvailability()}
        rawAvailability={!!availability && availability.calendar}
        saveAvailability={saveAvailability}
      />
    </AvailabilityPickerWrapper>
  )
})

export default React.memo(UserDetail)
