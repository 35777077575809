import styled from 'styled-components'

export const Container = styled.div<{ width: string }>`
  min-height: 40px;
  padding: 10px;
  word-wrap: none;
  width: ${props => props.width};
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  &,
  * {
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`

export const TextContainer = styled.div`
  margin: 0;
  padding: 0;
`
