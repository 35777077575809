import { IDataExpiration } from 'truly-ts'
import {
  isZeroDuration,
  parseRetentionPeriod,
  UnitSelectOptions,
} from '../../utils/model-utils/data-retention-utils'
import { singularize, pluralize } from 'truly-utils'

export function dataExpirationToDisplayShortFormat(
  data: IDataExpiration | null,
) {
  if (!data) {
    return 'Indefinite'
  } else if (isZeroDuration(data.retention_period)) {
    return 'Not Retained'
  }
  return getUnitsDisplay(data)
}

export function dataExpirationToDisplayLongFormat(
  data: IDataExpiration | null,
) {
  if (!data) {
    return 'Will Not Be Deleted'
  } else if (isZeroDuration(data.retention_period)) {
    return 'Deleted Immediately'
  }
  return `Deleted After ${getUnitsDisplay(data)}`
}

function getUnitsDisplay(data: IDataExpiration) {
  const retention = parseRetentionPeriod(data.retention_period)
  const val = UnitSelectOptions.find(unit => unit.value === retention.unit)
  const label = singularize(val?.label ?? '')
  return timeUnitDisplay(retention.time, label, false)
}

function timeUnitDisplay(num: number, unit: string, abbreviate: boolean) {
  if (abbreviate) {
    return `${num}${unit[0].toLowerCase()}`
  }
  return `${num} ${num > 1 ? pluralize(unit) : unit}`
}
