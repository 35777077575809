import {
  FETCH_DASHBOARDS,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_FAIL,
} from './actionTypes'
import { IDashboard } from 'truly-ts'

export const fetchDashboards = () => ({
  type: FETCH_DASHBOARDS,
  payload: {},
})

export const fetchDashboardsFail = () => ({
  type: FETCH_DASHBOARDS_FAIL,
  payload: {},
})

export const fetchDashboardsSuccess = (dashboards: IDashboard[]) => ({
  type: FETCH_DASHBOARDS_SUCCESS,
  payload: {
    dashboards,
  },
})
