import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import differenceBy from 'lodash/differenceBy'
import concat from 'lodash/concat'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import {
  FETCH_FORMS,
  DELETE_FORM,
  UPDATE_FORM,
  CREATE_FORM,
} from './actionTypes'
import { toastr } from 'react-redux-toastr'
import {
  fetchFormsFail,
  fetchFormsSuccess,
  deleteForm,
  formDeleted,
  formAdded,
  updateForm,
  formUpdated,
  createForm,
  setFormSaving,
} from './actionCreators'
import * as formsSelectors from './formsSelectors'
import * as rolesSelectors from '../roles/rolesSelectors'
import { IForm, IRole } from 'truly-ts'
import { Form } from './types'
import { rolesFetched } from '../roles/actionCreators'
import { push } from 'connected-react-router'
import { IState } from '../../store'
import { SaveStatus } from '../../utils/Saving'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* formsSaga() {
  yield all([
    takeLatest(FETCH_FORMS, fetchFormsSaga),
    takeLatest(DELETE_FORM, deleteFormSaga),
    takeLatest(UPDATE_FORM, updateFormSaga),
    takeLatest(CREATE_FORM, createFormSaga),
  ])
}

function* fetchFormsSaga() {
  try {
    const { formsReq, rolesReq } = yield all({
      formsReq: call(client.forms.fetchForms),
      rolesReq: call(client.lg.fetchRoles),
    })

    const rawForms = formsReq.data.forms as IForm[]
    const roles = rolesReq.data.roles as IRole[]
    const forms = rawForms.map(
      (f): Form => ({
        ...f,
        roles: roles.filter(r => r.form && r.form.id === f.id),
      }),
    )
    yield put(rolesFetched(roles))
    yield put(fetchFormsSuccess(forms))
  } catch (e) {
    console.error('fetching forms and roles', e)
    toastr.error(
      'An Error Occurred',
      'Unable to fetch forms. Please refresh the page.',
    )
    yield put(fetchFormsFail())
  }
}

function* deleteFormSaga(action: ReturnType<typeof deleteForm>) {
  const frm = action.payload.form

  if (!frm.id) {
    throw new Error('Unable to delete form with no ID')
  }

  yield put(formDeleted(frm.id)) // optimistic
  try {
    yield call(client.forms.deleteForm, frm.id)
    const path = yield select((state: IState) => state.router.location.pathname)
    if (path !== '/forms') {
      yield put(push('/forms'))
    }
  } catch (e) {
    console.error('deleting form saga', e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete form. Please try again.',
    )
    yield put(formAdded(frm)) // revert
  }
}

function* createFormSaga(action: ReturnType<typeof createForm>) {
  const form = action.payload.form
  const apiForm = { ...form, roles: undefined } as IForm
  yield put(setFormSaving(SaveStatus.Saving, form.id)) // update UI to show we are saving
  try {
    const req = yield call(client.forms.createForm, apiForm)
    const newForm = req.data.form as IForm
    yield call(addFormRoles, newForm, form.roles)
    const roles = (yield select(rolesSelectors.roles)) as IRole[]
    yield put(
      formAdded({
        ...newForm,
        roles: roles.filter(r => r.form && r.form.id === newForm.id),
      }),
    )
    yield put(push(`/forms/${newForm.id}`))
    yield put(setFormSaving(SaveStatus.Success, newForm.id)) // update UI to show success
  } catch (e) {
    console.error('creating form saga', e)
    yield put(setFormSaving(SaveStatus.Failed, form.id)) // update UI to show failure
  }
}

function* updateFormSaga(action: ReturnType<typeof updateForm>) {
  const frm = (yield select(formsSelectors.formById(action.payload.id))) as Form
  if (!frm) {
    return toastr.error(
      'An Error Occurred',
      'Form does not exist. Please refresh the page',
    )
  }

  yield put(setFormSaving(SaveStatus.Saving, frm.id)) // update the UI that we are saving

  const newForm = { ...frm, ...action.payload.form }
  const id = action.payload.id

  if (!newForm.id) {
    console.error('New form has no ID')
    return toastr.error(
      'An Error Occurred',
      'Form does not exist. Please refresh the page',
    )
  }

  let existingDefault: Form | undefined
  if (newForm.licensegroup_default) {
    // search and undefault if a different one is default
    const allForms = (yield select(formsSelectors.forms)) as Form[]
    existingDefault = allForms.find(
      f => f.licensegroup_default && f.id !== frm.id,
    )
    if (existingDefault) {
      yield put(
        formUpdated({ ...existingDefault, licensegroup_default: false }),
      )
    }
  }
  yield put(formUpdated(newForm)) // optimistic

  try {
    const apiForm = { ...newForm, roles: undefined, id: undefined } as any // roles and id not part of API
    yield all([
      call(updateFormRoles, frm, newForm),
      call(client.forms.updateForm, id, apiForm),
    ])
    const roles = (yield select(rolesSelectors.roles)) as IRole[]

    const currentForm = (yield select(
      formsSelectors.formById(newForm.id),
    )) as Form
    // update the form to have updated roles
    yield put(
      formUpdated({
        ...currentForm,
        roles: roles.filter(r => r.form && r.form.id === currentForm.id),
      }),
    )
    const isSameSaveState =
      (yield select(formsSelectors.formSaveState)).id === frm.id
    if (isSameSaveState) {
      yield put(setFormSaving(SaveStatus.Success, frm.id)) // update UI that save succeeded
    }
  } catch (e) {
    console.error('updating form saga', e)
    yield put(formUpdated(frm)) // revert
    if (existingDefault) {
      yield put(formUpdated(existingDefault)) // revert existing default
    }
    const isSameSaveState =
      (yield select(formsSelectors.formSaveState)).id === frm.id
    if (isSameSaveState) {
      yield put(setFormSaving(SaveStatus.Failed, frm.id)) // update UI that save failed
    }
  }
}

// Currently we have to post each role assignment update individually.
// Ideally we would have the form API be able to update these in a transaction.
function* updateFormRoles(existingForm: Form, newForm: Form) {
  const existingRoles = existingForm.roles
  const newRoles = newForm.roles
  const addedRoles = differenceBy(newRoles, existingRoles, 'id')
  const removedRoles = differenceBy(existingRoles, newRoles, 'id')

  if (addedRoles.length === 0 && removedRoles.length === 0) return

  const newId = newForm.id
  if (!newId) {
    throw new Error('Unable to updateFormRoles with no ID')
  }

  yield all(
    concat(
      addedRoles.map(role =>
        call(client.lg.updateRole, {
          ...role,
          form: {
            id: newId,
            display_name: newForm.description,
          },
        }),
      ),
      removedRoles.map(role =>
        call(client.lg.updateRole, {
          ...role,
          form: null,
          disposition_list: null, // needed to delete form
        }),
      ),
    ),
  )
  yield call(fetchRoles)
}

function* addFormRoles(form: IForm, roles: IRole[]) {
  const formID = form.id
  if (!formID) {
    throw new Error('Unable to updateFormRoles with no ID')
  }

  yield all(
    roles.map(role =>
      call(client.lg.updateRole, {
        ...role,
        form: {
          id: formID,
          display_name: form.description,
        },
      }),
    ),
  )
  yield call(fetchRoles)
}

function* fetchRoles() {
  const rolesReq = yield call(client.lg.fetchRoles)
  const roles = rolesReq.data.roles as IRole[]
  yield put(rolesFetched(roles))
}
