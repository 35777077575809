import {
  FETCH_EXTERNAL_USERS,
  FETCH_EXTERNAL_USERS_SUCCESS,
  FETCH_EXTERNAL_USERS_FAIL,
  EXTERNAL_USER_ADDED,
  EXTERNAL_USER_DELETED,
  EXTERNAL_USER_UPDATED,
} from './actionTypes'

import keyBy from 'lodash/keyBy'
import { IExternalForward } from 'truly-ts'
import { createReducer } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import { FETCH_ENTITIES } from '../entities/actionTypes'

import {
  fetchExternalUsersSuccess,
  externalUserAdded,
  externalUserDeleted,
  externalUserUpdated,
} from './actionCreators'

export interface ExternalUsersState {
  loading: boolean
  externalUsers?: { [key: string]: IExternalForward }
}

const INITIAL_STATE: ExternalUsersState = {
  loading: false,
}

const externalUsers = createReducer<ExternalUsersState>(INITIAL_STATE, {
  [FETCH_ENTITIES]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_EXTERNAL_USERS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_EXTERNAL_USERS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchExternalUsersSuccess>,
  ) => ({
    ...state,
    loading: false,
    externalUsers: keyBy(action.payload.externalUsers, 'id'),
  }),
  [FETCH_EXTERNAL_USERS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [EXTERNAL_USER_ADDED]: (
    state,
    action: ReturnType<typeof externalUserAdded>,
  ) => ({
    ...state,
    externalUsers: {
      ...state.externalUsers,
      [getRequired(action.payload.externalUser.id)]: action.payload
        .externalUser,
    },
  }),
  [EXTERNAL_USER_DELETED]: (
    state,
    action: ReturnType<typeof externalUserDeleted>,
  ) => {
    const extUsers: ExternalUsersState['externalUsers'] = {
      ...state.externalUsers,
    }
    delete extUsers[getRequired(action.payload.externalUser.id)]
    return {
      ...state,
      externalUsers: extUsers,
    }
  },
  [EXTERNAL_USER_UPDATED]: (
    state,
    action: ReturnType<typeof externalUserUpdated>,
  ) => ({
    ...state,
    externalUsers: {
      ...state.externalUsers,
      [getRequired(action.payload.externalUser.id)]: action.payload
        .externalUser,
    },
  }),
})

export default externalUsers
