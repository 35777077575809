import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import {
  FETCH_API_CREDENTIALS,
  DELETE_API_CREDENTIAL,
  GENERATE_API_CREDENTIAL,
  UPDATE_API_CREDENTIAL,
} from './actionTypes'
import {
  fetchApiCredentialsSuccess,
  fetchApiCredentialsFail,
  deleteAPICredential,
  generateAPICredential,
  updateAPICredential,
} from './actionCreators'

import { toastr } from 'react-redux-toastr'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* credentialsSaga() {
  yield all([
    takeLatest(FETCH_API_CREDENTIALS, fetchApiCredentialsSaga),
    takeLatest(DELETE_API_CREDENTIAL, deleteAPICredentialSaga),
    takeLatest(GENERATE_API_CREDENTIAL, generateAPICredentialSaga),
    takeLatest(UPDATE_API_CREDENTIAL, updateAPICredentialSaga),
  ])
}

function* deleteAPICredentialSaga(
  action: ReturnType<typeof deleteAPICredential>,
) {
  try {
    yield call(client.lg.deleteAPICredential, action.payload.credential)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to delete credential. Please refresh the page.',
    )
    yield call(fetchApiCredentialsSaga)
  }
}

function* generateAPICredentialSaga(
  action: ReturnType<typeof generateAPICredential>,
) {
  try {
    yield call(client.lg.generateAPICredential, action.payload.name)
    yield call(fetchApiCredentialsSaga)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to generate credential. Please refresh the page.',
    )
    yield call(fetchApiCredentialsSaga)
  }
}

function* updateAPICredentialSaga(
  action: ReturnType<typeof updateAPICredential>,
) {
  try {
    yield call(client.lg.updateAPICredential, action.payload.credential)
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to update credential. Please refresh the page.',
    )
    yield call(fetchApiCredentialsSaga)
  }
}

function* fetchApiCredentialsSaga() {
  try {
    const req = yield call(client.lg.fetchApiCredentials)
    yield put(fetchApiCredentialsSuccess(req.data.credentials))
  } catch (e) {
    console.error(e)
    toastr.error(
      'An Error Occurred',
      'Unable to get credentials. Please refresh the page.',
    )
    yield put(fetchApiCredentialsFail())
  }
}
