import { connect } from 'react-redux'
import TeamDeleteDialog from '../components/TeamDeleteDialog/TeamDeleteDialog'
import { deleteRole } from '../reducers/roles/actionCreators'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  onDelete: deleteRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamDeleteDialog)
