import * as React from 'react'
import { Helmet } from 'react-helmet'

const PageTitle: React.FC<{ title: string }> = props => (
  <Helmet>
    <title>{props.title} | Truly Admin</title>
  </Helmet>
)

export default PageTitle
