import { IRule, IRuleParam } from 'truly-ts'
import {
  SupportedNotificationActionType,
  SupportedCallNotifications,
} from '../../constants/node-rules'

export interface NodeRulesState {
  notify: {
    enabled: boolean
    value: SupportedNotificationActionType
  }
  sendEmail: {
    enabled: boolean
    email: string
  }
  createZendeskTicket: {
    enabled: boolean
  }
  createSalesforceTicket: {
    enabled: boolean
    account_id?: number | null
  }
}

export function createDefaultRuleState(): NodeRulesState {
  return {
    notify: {
      enabled: false,
      value: 'notify_users_all',
    },
    sendEmail: {
      enabled: false,
      email: '',
    },
    createZendeskTicket: {
      enabled: false,
    },
    createSalesforceTicket: {
      enabled: false,
    },
  }
}

type NodeRulesResult = { notificationRules: IRule[]; syncRules: IRule[] }

export function nodeStateToRules(
  state: NodeRulesState,
  condition: IRule['condition'],
  voicemailRequired?: boolean,
): NodeRulesResult {
  const notificationRules: IRule[] = []
  const syncRules: IRule[] = []

  if (state.notify.enabled) {
    notificationRules.push(
      createRule(state.notify.value, condition, voicemailRequired),
    )
  }

  if (state.sendEmail.enabled) {
    notificationRules.push(
      createRuleWithParam(
        'notify_custom_email',
        condition,
        voicemailRequired,
        'email',
        state.sendEmail.email,
      ),
    )
  }

  syncRules.push(
    createRuleWithParam(
      'zendesk_sync_enabled',
      condition,
      voicemailRequired,
      'sync_enabled',
      state.createZendeskTicket.enabled,
    ),
  )

  const sfParams: IRuleParam[] = [
    {
      name: 'sync_enabled',
      value: state.createSalesforceTicket.enabled,
    },
  ]

  if (voicemailRequired !== undefined) {
    sfParams.push({
      name: 'voicemail_required',
      value: voicemailRequired,
    })
  }

  sfParams.push({
    name: 'account_id',
    value: state.createSalesforceTicket.account_id
      ? state.createSalesforceTicket.account_id
      : null,
  })

  const action: IRule['action'] = 'salesforce_sync_enabled'

  syncRules.push({
    action,
    condition,
    params: sfParams,
  })

  return {
    notificationRules,
    syncRules,
  }
}

export function nodeRulesToState(
  notificationRules: IRule[],
  syncRules: IRule[],
  condition: IRule['condition'],
  voicemailRequired?: boolean,
): NodeRulesState {
  const notifyRule = getRuleInActions(
    notificationRules,
    SupportedCallNotifications,
    condition,
    voicemailRequired,
  )
  const emailRule = getRule(
    notificationRules,
    'notify_custom_email',
    condition,
    voicemailRequired,
  )
  const zendeskTicketRule = getRule(
    syncRules,
    'zendesk_sync_enabled',
    condition,
    voicemailRequired,
  )
  const salesforceTicket = getRule(
    syncRules,
    'salesforce_sync_enabled',
    condition,
    voicemailRequired,
  )

  return {
    notify: {
      enabled: notifyRule ? true : false,
      value: notifyRule
        ? (notifyRule.action as SupportedNotificationActionType)
        : 'notify_users_all',
    },
    sendEmail: {
      enabled: emailRule ? true : false,
      email: getParamValue<string>(emailRule, 'email', ''),
    },
    createZendeskTicket: {
      enabled: getParamValue<boolean>(zendeskTicketRule, 'sync_enabled', false),
    },
    createSalesforceTicket: {
      enabled: getParamValue<boolean>(salesforceTicket, 'sync_enabled', false),
      account_id: getParamValue<number>(salesforceTicket, 'account_id', null),
    },
  }
}

export function mergeRules(...rules: NodeRulesResult[]): NodeRulesResult {
  return rules.reduce((prev, current) => ({
    notificationRules: [
      ...prev.notificationRules,
      ...current.notificationRules,
    ],
    syncRules: [...prev.syncRules, ...current.syncRules],
  }))
}

export function ruleHasVoicemail(rule: IRule) {
  return rule.params.some(
    r => r.name === 'voicemail_required' && r.value === true,
  )
}

export function ruleMatchesVoicemailRequired(
  rule: IRule,
  voicemailRequired?: boolean,
) {
  if (typeof voicemailRequired === 'undefined') return true
  return voicemailRequired ? ruleHasVoicemail(rule) : !ruleHasVoicemail(rule)
}

export function getRule(
  rules: IRule[],
  action: IRule['action'],
  condition: IRule['condition'],
  voicemailRequired?: boolean,
) {
  return rules.find(
    r =>
      r.condition === condition &&
      r.action === action &&
      ruleMatchesVoicemailRequired(r, voicemailRequired),
  )
}

export function getRuleInActions(
  rules: IRule[],
  actions: Array<IRule['action']>,
  condition: IRule['condition'],
  voicemailRequired?: boolean,
) {
  return rules.find(
    r =>
      r.condition === condition &&
      actions.some(a => a === r.action) &&
      ruleMatchesVoicemailRequired(r, voicemailRequired),
  )
}

export function getParamValue<T>(
  rule: IRule | undefined,
  paramName: IRuleParam['name'],
  defaultValue?: T | null,
): T {
  const assumedDefault = typeof defaultValue === 'undefined' ? '' : defaultValue
  if (!rule) return assumedDefault as T
  const param = rule.params.find(p => p.name === paramName)
  return (param ? param.value : assumedDefault) as T
}

export function createRule(
  action: IRule['action'],
  condition: IRule['condition'],
  voicemailRequired?: boolean,
): IRule {
  return {
    action,
    condition,
    params: [
      {
        name: 'voicemail_required',
        value: voicemailRequired,
      },
    ],
  }
}

export function createRuleWithParam(
  action: IRule['action'],
  condition: IRule['condition'],
  voicemailRequired: boolean | undefined,
  paramName: IRuleParam['name'],
  paramValue: string | number | boolean,
): IRule {
  const result = {
    action,
    condition,
    params: [
      {
        name: paramName,
        value: paramValue,
      },
    ],
  }
  if (voicemailRequired !== undefined) {
    result.params.push({
      name: 'voicemail_required',
      value: !!voicemailRequired,
    })
  }
  return result
}
