export const FETCH_WEBHOOKS = 'FETCH_WEBHOOKS'
export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS'
export const FETCH_WEBHOOKS_FAIL = 'FETCH_WEBHOOKS_FAIL'

export const CREATE_WEBHOOK = 'CREATE_WEBHOOK'
export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK'

export const WEBHOOK_ADDED = 'WEBHOOK_ADDED'
export const WEBHOOK_UPDATED = 'WEBHOOK_UPDATED'

export const DELETE_WEBHOOK = 'DELETE_WEBHOOK'
export const WEBHOOK_DELETED = 'WEBHOOK_DELETED'
