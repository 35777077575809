export const FETCH_BLOCKED_NUMBERS = 'FETCH_BLOCKED_NUMBERS'
export const FETCH_BLOCKED_NUMBERS_SUCCESS = 'FETCH_BLOCKED_NUMBERS_SUCCESS'
export const FETCH_BLOCKED_NUMBERS_FAIL = 'FETCH_BLOCKED_NUMBERS_FAIL'

export const BLOCKED_NUMBER_REMOVED = 'BLOCKED_NUMBER_REMOVED'
export const BLOCKED_NUMBER_REMOVED_SUCCESS = 'BLOCKED_NUMBER_REMOVED_SUCCESS'
export const BLOCKED_NUMBER_REMOVED_FAIL = 'BLOCKED_NUMBER_REMOVED_FAIL'

export const BLOCKED_NUMBER_ADDED = 'BLOCKED_NUMBER_ADDED'
export const BLOCKED_NUMBER_ADDED_SUCCESS = 'BLOCKED_NUMBER_ADDED_SUCCESS'
export const BLOCKED_NUMBER_ADDED_FAIL = 'BLOCKED_NUMBER_ADDED_FAIL'
