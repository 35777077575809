import trulyApiFactory from 'truly-api'
import { currentTrulyApi } from '../../utils/HTTP'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { FETCH_DASHBOARDS } from './actionTypes'
import { fetchDashboardsSuccess, fetchDashboardsFail } from './actionCreators'
import { toastr } from 'react-redux-toastr'

const client = trulyApiFactory({
  axios: currentTrulyApi,
})

export function* reportsSaga() {
  yield all([takeLatest(FETCH_DASHBOARDS, fetchDashboardsSaga)])
}

function* fetchDashboardsSaga() {
  try {
    const req = yield call(client.dashboards.fetchDashboards)
    yield put(fetchDashboardsSuccess(req.data.dashboards))
  } catch (e) {
    console.error('fetch dashboards', e)
    toastr.error(
      'An Error Occurred',
      'Unable to get dashboards. Please refresh the page.',
    )
    yield put(fetchDashboardsFail())
  }
}
