import React, { useCallback, useState, useEffect } from 'react'
import {
  PopupModal,
  ModalLayout,
  Large,
  colors,
  FlexRow,
  Record,
  AudioPlayer,
  AudioDisplayMode,
  Button,
  TextInput,
  Recorder,
  FileDrop,
  AudioRecordedResult,
  Small,
  Link,
  SpinnerMaterial,
} from 'js-components'
import DescriptiveAction from '../DescriptiveAction/DescriptiveAction'
import { ReactComponent as UploadSVG } from 'js-components/src/components/Icons/Upload.svg'
import { IMessage } from 'truly-ts'
import { legacyUrl } from '../../constants/Config'
import {
  ExistingAudioList,
  ExistingAudioWrapper,
  AudioTitle,
  RecordingRow,
  AudioPlayerRow,
  UploadingContainer,
} from './Styles'
import * as messagesSelectors from '../../reducers/messages/messagesSelectors'
import usePreloader from '../../utils/data-hooks/usePreloader'
import { nameValidationHandler } from '../../utils/Validation'
import { readBlobToUri, useValidatedState, useActionCreator } from 'truly-utils'
import { fetchMessages } from '../../reducers/messages/actionCreators'
import { ActionButton } from '../LayoutHelpers/Styles'
import defaultTrulyClient from '../../utils/HTTP'
import { openChatSupport } from '../../reducers/chatsupport/actionCreators'
import { toastr } from 'react-redux-toastr'

const RecordingStates = {
  None: 'none',
  CreateRecording: 'create-recording',
  UploadRecording: 'upload-recording',
}

interface EditRecordingModalProps {
  show: boolean
  onMessageSelected?: (message: IMessage) => void
  onClose: () => void
  defaultRecordingName?: string
  hideExistingRecordings?: boolean
  hideRecordingName?: boolean
  currentMessage?: IMessage
  createMessage?: (title: string, file: Blob) => void
  onAddFileCallback?: (message: IMessage, file: Blob) => void
}

const EditRecordingModal: React.FC<EditRecordingModalProps> = props => {
  const { onMessageSelected, onClose, show } = props
  const onRecordingSelected = useCallback(
    (message: IMessage) => {
      if (onMessageSelected) {
        onMessageSelected(message)
      }
      onClose()
    },
    [onMessageSelected, onClose],
  )
  const [recordingState, createRecording, uploadRecording] = useRecordingState(
    show,
  )

  return (
    <PopupModal
      show={props.show}
      onRequestClose={props.onClose}
      showExitButton
      width="576px">
      {recordingState === RecordingStates.None && (
        <RecordingOptionSelection
          onRecordingSelected={onRecordingSelected}
          createRecording={createRecording}
          uploadRecording={uploadRecording}
          hideExistingRecordings={props.hideExistingRecordings}
        />
      )}
      {recordingState !== RecordingStates.None && (
        <NewRecording
          mode={recordingState}
          defaultRecordingName={props.defaultRecordingName}
          messageSelected={onRecordingSelected}
          hideRecordingName={props.hideRecordingName}
          currentMessage={props.currentMessage}
          createMessage={props.createMessage}
          onAddFileCallback={props.onAddFileCallback}
        />
      )}
    </PopupModal>
  )
}

const NewRecording: React.FC<{
  defaultRecordingName?: string
  mode: keyof typeof RecordingStates
  messageSelected: (message: IMessage) => void
  hideRecordingName?: boolean
  currentMessage?: IMessage
  createMessage?: (title: string, file: Blob) => void
  onAddFileCallback?: (message: IMessage, file: Blob) => void
}> = props => {
  const {
    defaultRecordingName,
    messageSelected,
    hideRecordingName,
    currentMessage,
    onAddFileCallback,
  } = props
  const [canValidate, setCanValidate] = useState(false)
  const [name, nameValidationState, setNameAndValidate] = useValidatedState(
    '',
    nameValidationHandler,
  )
  const [audioUri, setAudioUri] = useState('')
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [uploading, setUploading] = useState(false)
  const [didError, setDidError] = useState(false)
  const chatWithSupport = useActionCreator(openChatSupport)
  const setName = useCallback(
    (val: string) =>
      setNameAndValidate(!defaultRecordingName && canValidate, val),
    [canValidate, defaultRecordingName, setNameAndValidate],
  )

  const onStoppedRecording = useCallback(
    async (result: AudioRecordedResult) => {
      const uri = await readBlobToUri(result.audioBlob)
      setDidError(false)
      setAudioUri(uri)
      setAudioBlob(result.audioBlob)
    },
    [],
  )

  const onFileSelected = useCallback(async (file: File) => {
    if (file) {
      const uri = await readBlobToUri(file)
      setDidError(false)
      setAudioUri(uri)
      setAudioBlob(file)
    } else {
      toastr.error('Unsupported File', 'Please upload a .wav file')
    }
  }, [])
  const clearAudioUri = useCallback(() => {
    setAudioUri('')
    setAudioBlob(null)
  }, [])

  const onAdd = useCallback(async () => {
    const nameValid = setNameAndValidate(!defaultRecordingName, name)
    setCanValidate(true)

    if (defaultRecordingName || nameValid?.valid) {
      setUploading(true)
      if (!audioBlob) {
        console.error('No audio blob - aborting upload')
        return
      }
      try {
        const msgName = name.trim() ?? defaultRecordingName
        let resp: any

        if (currentMessage?.id) {
          resp = await defaultTrulyClient.messages.updateMessage(
            currentMessage.id,
            currentMessage,
            audioBlob,
          )
        } else {
          resp = await defaultTrulyClient.messages.createMessage(
            msgName,
            audioBlob,
          )
        }

        if (onAddFileCallback) {
          onAddFileCallback(resp.data, audioBlob)
        }

        messageSelected(resp.data)
      } catch (e) {
        setDidError(true)
        setUploading(false)
        clearAudioUri()
        console.error('Failed to upload', e)
      }
    }
  }, [
    name,
    defaultRecordingName,
    messageSelected,
    audioBlob,
    clearAudioUri,
    setNameAndValidate,
    currentMessage,
    onAddFileCallback,
  ])

  useEffect(() => () => setUploading(false), [])

  return (
    <ModalLayout.Container>
      <ModalLayout.Content>
        <ModalLayout.Row shouldPadTop>
          <FlexRow justifyContent="center">
            <Large mt="12px" bold color={colors.accentPurple}>
              New Recording
            </Large>
          </FlexRow>
        </ModalLayout.Row>
        <ModalLayout.HorizontalLine />
        {uploading ? (
          <>
            <ModalLayout.Row>
              <UploadingContainer>
                <SpinnerMaterial
                  size="26px"
                  strokeWidth={4}
                  color={colors.accentPurple}
                />
                <Small bold color={colors.accentLavender} mt="4px">
                  Uploading...
                </Small>
              </UploadingContainer>
            </ModalLayout.Row>
          </>
        ) : (
          <>
            {!hideRecordingName && (
              <>
                <ModalLayout.FieldRow
                  label="recording name"
                  labelWidth="170px"
                  shouldPadRight
                  validationState={nameValidationState}>
                  <TextInput
                    autoFocus
                    value={name}
                    onChange={setName}
                    validationState={nameValidationState}
                    placeholder={
                      props.defaultRecordingName || 'type a recording name'
                    }
                  />
                </ModalLayout.FieldRow>
                <ModalLayout.LightHorizontalLine />
              </>
            )}
            {audioUri && (
              <AudioPlayerRow data-cy="recording-options">
                <AudioPlayer large src={audioUri} />
                {props.mode === RecordingStates.CreateRecording && (
                  <ActionButton
                    cursor="pointer"
                    role="button"
                    onClick={clearAudioUri}>
                    <Record
                      width="28px"
                      height="28px"
                      color={colors.accentLavender}
                    />
                  </ActionButton>
                )}
                {props.mode === RecordingStates.UploadRecording && (
                  <ActionButton
                    cursor="pointer"
                    role="button"
                    onClick={clearAudioUri}>
                    <UploadSVG
                      width="28px"
                      height="28px"
                      fill={colors.accentLavender}
                    />
                  </ActionButton>
                )}
              </AudioPlayerRow>
            )}
            {!audioUri && props.mode === RecordingStates.CreateRecording && (
              <RecordingRow>
                <Recorder onStoppedRecording={onStoppedRecording} />
              </RecordingRow>
            )}
            {!audioUri && props.mode === RecordingStates.UploadRecording && (
              <ModalLayout.Row>
                <FileDrop
                  accept="audio/wav"
                  onFileSelected={onFileSelected}
                  subtitle=".WAV files are supported"
                />
              </ModalLayout.Row>
            )}
            {didError && (
              <ModalLayout.Row>
                <FlexRow justifyContent="center">
                  <Small color={colors.alertRed} bold>
                    An error occurred uploading your recording. If problems
                    persist,{' '}
                    <Link color={colors.alertRed} onClick={chatWithSupport}>
                      contact support
                    </Link>
                    .
                  </Small>
                </FlexRow>
              </ModalLayout.Row>
            )}
            <ModalLayout.FooterBigButton
              actionDisabled={!audioBlob}
              actionText="Add Recording"
              onAction={onAdd}
            />
          </>
        )}
      </ModalLayout.Content>
    </ModalLayout.Container>
  )
}

const RecordingOptionSelection: React.FC<{
  onRecordingSelected: (message: IMessage) => void
  createRecording: () => void
  uploadRecording: () => void
  hideExistingRecordings?: boolean
}> = props => {
  const messages = usePreloader(messagesSelectors.messages, fetchMessages)

  return (
    <ModalLayout.Container>
      <ModalLayout.Content>
        <ModalLayout.Row shouldPadTop>
          <FlexRow justifyContent="center">
            <Large bold color={colors.accentPurple}>
              Edit Recording
            </Large>
          </FlexRow>
        </ModalLayout.Row>
        <ModalLayout.HorizontalLine />
        <ModalLayout.Row>
          <DescriptiveAction
            title="New Recording"
            desc="Record a new audio file using your connected audio input."
            iconSize="75%"
            onClick={props.createRecording}
            icon={<Record width="" height="" />}
          />
        </ModalLayout.Row>
        <ModalLayout.LightHorizontalLine />
        <ModalLayout.Row>
          <DescriptiveAction
            title="Upload Recording"
            desc="Upload an audio file from your computer"
            iconSize="75%"
            onClick={props.uploadRecording}
            icon={<UploadSVG />}
          />
        </ModalLayout.Row>

        {!props.hideExistingRecordings && (
          <>
            <ModalLayout.HorizontalLine />
            <ModalLayout.Row>
              <FlexRow justifyContent="center">
                <Large bold color={colors.accentPurple}>
                  Use Existing Recording
                </Large>
              </FlexRow>
            </ModalLayout.Row>
            <ExistingAudioList data-cy="existing-audio-list">
              {messages &&
                messages.map((m, idx) => (
                  <ExistingAudioWrapper
                    key={m.id}
                    last={idx === messages.length - 1}
                    role="button"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => props.onRecordingSelected(m)}>
                    <AudioPlayer
                      large
                      lazyLoad
                      displayMode={AudioDisplayMode.SingleButton}
                      src={`${legacyUrl}/message_content?cachebuster=${Math.random()}&messageId=${
                        m.id
                      }`}
                    />
                    <AudioTitle>{m.title}</AudioTitle>
                    <FlexRow
                      data-hover-show
                      justifyContent="flex-end"
                      style={{ flex: '1 1 auto' }}>
                      <Button
                        width={106}
                        color={colors.accentLavender}
                        value="assign"
                        round
                        size="medium"
                      />
                    </FlexRow>
                  </ExistingAudioWrapper>
                ))}
            </ExistingAudioList>
          </>
        )}
      </ModalLayout.Content>
    </ModalLayout.Container>
  )
}

function useRecordingState(
  show: boolean,
): [keyof typeof RecordingStates, () => void, () => void] {
  const [recordingState, setRecordingState] = useState(RecordingStates.None)
  useEffect(() => {
    setRecordingState(RecordingStates.None)
  }, [show])
  const createRecording = useCallback(
    () => setRecordingState(RecordingStates.CreateRecording),
    [],
  )
  const uploadRecording = useCallback(
    () => setRecordingState(RecordingStates.UploadRecording),
    [],
  )
  return [
    recordingState as keyof typeof RecordingStates,
    createRecording,
    uploadRecording,
  ]
}

export default EditRecordingModal
