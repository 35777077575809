import { isValidNumber, ValidationHandler } from 'truly-utils'

export const nameValidationHandler = new ValidationHandler(
  'Please Enter a Name',
  v => !!(v || '').trim(),
)
export const phoneNumberValidationHandler = new ValidationHandler(
  'Please Enter a Valid Phone Number',
  pn => !!(pn || '').trim() && isValidNumber(pn),
)
export const phoneNumberSelectedValidationHandler = new ValidationHandler<
  string | null
>('Please Choose a Phone Number', pn => !!pn)

export const titleValidationHandler = new ValidationHandler<string | undefined>(
  'Please Enter a Title',
  v => !!(v || '').trim(),
)

export const confirmPasswordValidationHandler = new ValidationHandler(
  'Password are not the same.',
  (passwordObj: { password: string | null; confirmPassword: string | null }) =>
    passwordObj.password === passwordObj.confirmPassword,
)
