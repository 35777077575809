import { IRule } from 'truly-ts'

export type SubsetAction<T extends IRule['action']> = T
export type SupportedNotificationActionType = SubsetAction<
  'notify_users_all' | 'notify_user_random'
>
export type SupportedSalesforceActionType = SubsetAction<
  'salesforce_custom_user' | 'salesforce_user_random'
>

export const SupportedCallNotificationOptions: Array<{
  value: SupportedNotificationActionType
  label: string
}> = [
  {
    value: 'notify_users_all',
    label: 'all users assigned to this node',
  },
  {
    value: 'notify_user_random',
    label: 'a random user',
  },
]

export const SupportedCallNotifications = SupportedCallNotificationOptions.map(
  n => n.value,
)

export const SupportedSalesforceAssignmentsOptions: Array<{
  value: SupportedSalesforceActionType
  label: string
}> = [
  {
    value: 'salesforce_user_random',
    label: 'a random user on this node',
  },
  {
    value: 'salesforce_custom_user',
    label: 'a specific user',
  },
]

export const SupportedSalesforceAssignments = SupportedSalesforceAssignmentsOptions.map(
  n => n.value,
)
