import * as React from 'react'
import {
  TabList,
  Tab,
  colors,
  DeleteDialog,
  TableFilterRow,
  FlexRow,
} from 'js-components'
import { MainContainer, TableFilterWrapper } from '../LayoutHelpers/Styles'
import { IPhoneMenu, IMessage, INode, PhoneMenuType } from 'truly-ts'
import ContentHeading, {
  HeadingAddButton,
} from '../ContentHeading/ContentHeading'
import PhoneMenusContainer from '../../containers/PhoneMenusContainer'
import RecordingsContainer from '../../containers/RecordingsContainer'
import PhoneMenuCreateModalContainer from '../../containers/PhoneMenuCreateModalContainer'
import RecordingModal from '../../containers/RecordingModalContainer'
import { TabListContainer } from './Styles'
import { EntityMap } from '../../utils/Entity'
import { replaceNode } from '../../utils/model-utils/node-utils'
import PageTitle from '../PageTitle/PageTitle'
import { PhoneMenuTitle } from '../../constants/PageTitles'

interface PhoneMenuProps {
  deletePhoneMenu: (phoneMenu: IPhoneMenu) => void
  deleteMessage: (recording: IMessage, phoneMenus?: IPhoneMenu[]) => void
  fetchMessages: () => void
  entities?: EntityMap
  updatePhoneMenu: (phoneMenu: IPhoneMenu) => void
}

interface PhoneMenusAndRecordingState {
  searchValue: string
  deletingMenu: boolean
  creatingMenu: boolean
  deletingRecording: boolean
  creatingRecording: boolean
  editRecording: boolean
  selectedPhoneMenu?: IPhoneMenu
  selectedRecording?: IMessage
}

export default class PhoneMenusAndRecordings extends React.Component<
  PhoneMenuProps,
  PhoneMenusAndRecordingState
> {
  constructor(props: PhoneMenuProps) {
    super(props)
    this.state = {
      searchValue: '',
      deletingMenu: false,
      creatingMenu: false,
      deletingRecording: false,
      creatingRecording: false,
      editRecording: false,
    }
  }

  onSearchInputChanged = (searchValue: string) => {
    this.setState({
      searchValue,
    })
  }

  clearFilters = () => {
    this.setState({
      searchValue: '',
    })
  }

  onNewPhoneMenu = () => this.setState({ creatingMenu: true })
  onHideNewPhoneMenu = () => this.setState({ creatingMenu: false })

  onNewRecording = () => this.setState({ creatingRecording: true })
  onEditRecording = (recording: IMessage) =>
    this.setState({ editRecording: true, selectedRecording: recording })

  onHideRecordingEditor = () => {
    this.setState({
      creatingRecording: false,
      editRecording: false,
      selectedRecording: undefined,
    })
  }

  onCloseDelete = () => {
    this.setState({
      deletingMenu: false,
      deletingRecording: false,
      selectedPhoneMenu: undefined,
      selectedRecording: undefined,
    })
  }

  onConfirmDelete = () => {
    const { selectedPhoneMenu, selectedRecording } = this.state

    if (selectedPhoneMenu) {
      this.props.deletePhoneMenu(selectedPhoneMenu)
    } else {
      // find nodes that contain removed message
      const phoneMenus = Object.values(this.props.entities?.phoneMenuMap ?? {})
      let entitiesWithRecording: any = []

      entitiesWithRecording = entitiesWithRecording.concat(
        phoneMenus.filter(
          pm =>
            pm.tree.messages?.find(
              msg => msg.message_id === selectedRecording?.id,
            ) || pm.tree.selected_recording_id === selectedRecording?.id,
        ),
      )

      entitiesWithRecording = entitiesWithRecording.concat(
        Object.values(this.props.entities?.nodeMap ?? []).filter(pmn => {
          if (pmn.selected_recording_id === selectedRecording?.id) return pmn
          return (
            pmn.messages &&
            pmn.messages.find(n => n.message_id === selectedRecording?.id)
          )
        }),
      )

      const updatedPhoneMenus: IPhoneMenu[] = []

      // remove message and update nodes
      entitiesWithRecording.forEach(
        (entityWithRecording: IPhoneMenu | INode) => {
          let updatedPhoneMenu: IPhoneMenu | null = null

          if (entityWithRecording) {
            const menuType = entityWithRecording.type
            let updatedNode: INode
            let topLevelMenu: IPhoneMenu

            if (
              menuType === PhoneMenuType.Basic ||
              menuType === PhoneMenuType.Advanced
            ) {
              const entityAsPhoneMenu = entityWithRecording as IPhoneMenu
              const phoneMessages = [
                ...(entityAsPhoneMenu.tree?.messages ?? []),
              ]
              const removedMessageIndex = phoneMessages.findIndex(
                pm => pm.message_id === selectedRecording?.id,
              )
              phoneMessages.splice(removedMessageIndex, 1)
              updatedNode = {
                ...entityAsPhoneMenu.tree,
                messages: phoneMessages,
              }
              topLevelMenu = entityAsPhoneMenu
            } else {
              const entityAsNode = entityWithRecording as INode
              const phoneMessages = [...(entityAsNode.messages ?? [])]
              const removedMessageIndex = phoneMessages.findIndex(
                pm => pm.message_id === selectedRecording?.id,
              )
              phoneMessages.splice(removedMessageIndex, 1)
              updatedNode = {
                ...entityAsNode,
                messages: phoneMessages,
              }
              topLevelMenu = phoneMenus.find(
                pm => pm.id === entityAsNode.phonemenu_id,
              ) as IPhoneMenu
            }

            updatedPhoneMenu = {
              ...topLevelMenu,
              tree: replaceNode(topLevelMenu.tree, updatedNode),
            }
          }

          if (!updatedPhoneMenu) {
            throw new Error('Unable to update phone menu')
          }

          updatedPhoneMenus.push(updatedPhoneMenu)
        },
      )

      if (selectedRecording) {
        this.props.deleteMessage(selectedRecording, updatedPhoneMenus)
      }
    }

    this.onCloseDelete()
  }

  onDeletePhoneMenu = (phoneMenu: IPhoneMenu) => {
    this.setState({
      deletingMenu: true,
      selectedPhoneMenu: phoneMenu,
    })
  }

  onDeleteRecording = (recording: IMessage) => {
    this.setState({
      deletingRecording: true,
      selectedRecording: recording,
    })
  }

  onConfirmEdit = () => {
    this.setState({ selectedRecording: undefined })
    this.props.fetchMessages()
  }

  renderTabListWrapper = (children: any) => {
    return (
      <FlexRow
        width="100%"
        pr="14px"
        alignItems="center"
        justifyContent="space-between">
        <TabListContainer>{Object.values(children)}</TabListContainer>
        <TableFilterWrapper inTabList>
          <TableFilterRow
            searchInput
            searchInputValue={this.state.searchValue}
            searchPlaceholder="Filter"
            onSearchInputChanged={this.onSearchInputChanged}
            selectedFilterOption="all"
          />
        </TableFilterWrapper>
      </FlexRow>
    )
  }

  render() {
    const {
      deletingMenu,
      creatingMenu,
      selectedPhoneMenu,
      searchValue,
      deletingRecording,
      creatingRecording,
      editRecording,
      selectedRecording,
    } = this.state

    let deletionText: string | null = null
    let deletionTitle: string

    if (deletingMenu) {
      deletionText =
        'This phone menu will be deleted. Phone numbers assigned to this phone menu will be unassigned. This action cannot be undone.'
    } else if (deletingRecording) {
      deletionText = `The "${selectedRecording?.title}" recording will be deleted. Phone menus with this greeting assigned will have this recording unassigned.`
    }

    if (selectedPhoneMenu) {
      deletionTitle = `Delete ${selectedPhoneMenu.name}`
    } else if (selectedRecording) {
      deletionTitle = `Delete "${selectedRecording.title}"?`
    } else {
      deletionTitle = 'None Selected'
    }

    return (
      <MainContainer>
        <PageTitle title={PhoneMenuTitle} />
        <DeleteDialog
          show={deletingMenu || deletingRecording}
          title={deletionTitle}
          deleteActionText="Delete"
          description={deletionText}
          confirmText="DELETE"
          onCancel={this.onCloseDelete}
          onDelete={this.onConfirmDelete}
        />
        <RecordingModal
          show={creatingRecording || editRecording}
          onClose={this.onHideRecordingEditor}
          currentMessage={selectedRecording}
          isEditing={editRecording}
        />
        <PhoneMenuCreateModalContainer
          show={creatingMenu}
          onClose={this.onHideNewPhoneMenu}
        />
        <ContentHeading
          title={PhoneMenuTitle}
          rightControl={
            <HeadingAddButton
              minWidth="260px"
              options={[
                {
                  color: colors.trulyDark,
                  label: 'New Phone Menu',
                  onClick: this.onNewPhoneMenu,
                },
                {
                  color: colors.trulyDark,
                  label: 'New Recording',
                  onClick: this.onNewRecording,
                },
              ]}
            />
          }
        />
        <TabList
          tabListWrapper={this.renderTabListWrapper}
          style={{ width: '100%', height: '100%' }}>
          <Tab name={PhoneMenuTitle}>
            <PhoneMenusContainer
              onDeletePhoneMenu={this.onDeletePhoneMenu}
              onNewPhoneMenu={this.onNewPhoneMenu}
              searchValue={searchValue}
              clearFilters={this.clearFilters}
            />
          </Tab>
          <Tab name="Recordings">
            <RecordingsContainer
              onDeleteRecording={this.onDeleteRecording}
              onAddRecording={this.onNewRecording}
              searchValue={searchValue}
              clearFilters={this.clearFilters}
              onEditRecording={this.onEditRecording}
            />
          </Tab>
        </TabList>
      </MainContainer>
    )
  }
}
