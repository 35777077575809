import { IState } from '../../store'
import { createSelector } from 'reselect'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
export const base = (state: IState) => state.phoneNumbers
export const phoneNumbersSearch = (state: IState) => base(state).search
export const phoneNumbers = createSelector(
  (state: IState) => base(state) && base(state).phoneNumbers,
  numbers => numbers && sortBy(values(numbers), p => p.full_number),
)
export const searchingPhoneNumbers = (state: IState) =>
  phoneNumbersSearch(state).searching
export const failedSearchingPhoneNumbers = (state: IState) =>
  phoneNumbersSearch(state).failed
export const foundPhoneNumbers = (state: IState) =>
  phoneNumbersSearch(state).numbers
