import * as React from 'react'
import { INode, IMessage, MessageType } from 'truly-ts'
import { ModalLayout, ValidationMessage } from 'js-components'
import {
  getMessageFromNode,
  messageToNodeMessage,
} from '../../../utils/model-utils/node-utils'
import {
  NodeValidationContext,
  NodeValidationContextValue,
  makeNodeMessageValidator,
  PlayRecordingValidationState,
} from '../../../utils/model-utils/node-validation'
import RecordingEditor from '../../RecordingEditor/RecordingEditor'

const nodeMessageValidationHandler = makeNodeMessageValidator(
  MessageType.NodeMessage,
  'Please choose a recording',
)

interface PlayRecordingEditProps {
  node: INode
  onChange: (node: INode) => void
}

export default class PlayRecordingEdit extends React.Component<PlayRecordingEditProps> {
  static contextType = NodeValidationContext
  context!: NodeValidationContextValue<PlayRecordingValidationState> // docs say to do this

  componentDidMount() {
    this.validateNode(this.props.node)
  }

  validateNode(node: INode) {
    const validationState = nodeMessageValidationHandler.validate(node)
    this.context.validationChange({
      playRecording: validationState,
    })
  }

  onChange = (message?: IMessage | null) => {
    let messages: IMessage[] = [...(this.props.node.messages || [])]
    messages = messages.filter(m => m.type !== MessageType.NodeMessage) // remove message
    if (message) {
      // add new message
      messages.push({
        ...messageToNodeMessage(message),
        type: MessageType.NodeMessage,
      })
    }
    const newNode = {
      ...this.props.node,
      messages,
    }
    this.props.onChange(newNode)
    this.validateNode(newNode)
  }

  render() {
    const {
      node: { pre_select_text_to_speech },
    } = this.props
    const { validationState } = this.context
    const message = getMessageFromNode(this.props.node, MessageType.NodeMessage)

    return (
      <ModalLayout.Row
        validationMessage={
          validationState.showErrors && (
            <ValidationMessage validation={validationState.playRecording} />
          )
        }>
        <RecordingEditor
          newRecordingDefaultName={
            message ? message.title : `${pre_select_text_to_speech}'s Recording`
          }
          message={message ?? undefined}
          onChange={this.onChange}
        />
      </ModalLayout.Row>
    )
  }
}
