import styled from 'styled-components'
import { colors, Small } from 'js-components'

export const LeftNavWidth = '240px'

export const Container = styled.div`
  width: ${LeftNavWidth};
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-right: 1px solid ${colors.lightGray};
  overflow-y: auto;
  flex: 0 0 auto;
`

export const SectionHeader = styled(Small).attrs<typeof Small>({
  bold: true,
})`
  color: ${colors.darkGray};
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 8px;
`

export const NavOption = styled.div`
  padding: 8px 14px 8px 10px;
  margin: 2px 6px 2px 6px;
  border-radius: 4px;
  background-color: transparent;
  color: ${colors.trulyDark};
  cursor: pointer;
  transition: 200ms background-color ease;

  &:hover {
    background-color: ${colors.ultraLightGray};
  }

  &[data-active='true'] {
    background-color: ${colors.accentLavender};
    color: ${colors.white};
  }
`
