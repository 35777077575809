export const FETCH_PHONE_NUMBERS = 'FETCH_PHONE_NUMBERS'
export const FETCH_PHONE_NUMBERS_SUCCESS = 'FETCH_PHONE_NUMBERS_SUCCESS'
export const FETCH_PHONE_NUMBERS_FAIL = 'FETCH_PHONE_NUMBERS_FAIL'

export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER'
export const RELEASE_PHONE_NUMBER = 'RELEASE_PHONE_NUMBER'
export const PHONE_NUMBER_CHANGED = 'PHONE_NUMBER_CHANGED'
export const PHONE_NUMBER_REMOVED = 'PHONE_NUMBER_REMOVED'
export const PHONE_NUMBER_ADDED = 'PHONE_NUMBER_ADDED'

export const SEARCH_PHONE_NUMBERS = 'SEARCH_PHONE_NUMBERS'
export const SEARCH_PHONE_NUMBERS_SUCCESS = 'SEARCH_PHONE_NUMBERS_SUCCESS'
export const SEARCH_PHONE_NUMBERS_FAIL = 'SEARCH_PHONE_NUMBERS_FAIL'

export const ASSIGN_PHONE_NUMBER = 'ASSIGN_PHONE_NUMBER'
export const PHONE_NUMBER_ASSIGNED = 'PHONE_NUMBER_ASSIGNED'
export const UNASSIGN_PHONE_NUMBER = 'UNASSIGN_PHONE_NUMBER'
export const PHONE_NUMBER_UNASSIGNED = 'PHONE_NUMBER_UNASSIGNED'
