import { connect } from 'react-redux'
import TeamEdit from '../components/TeamEdit/TeamEdit'
import { IState } from '../store'
import { fetchRoles, updateRole } from '../reducers/roles/actionCreators'
import { RouteComponentProps } from 'react-router'
import * as roleSelectors from '../reducers/roles/rolesSelectors'
import * as authSelectors from '../reducers/auth/authSelectors'
import * as lgSelectors from '../reducers/lgSettings/lgSettingsSelectors'
import * as formsSelectors from '../reducers/forms/formsSelectors'
import { fetchLicenseGroup } from '../reducers/lgSettings/actionCreators'
import { IAccount } from 'truly-ts'

const mapStateToProps = (
  state: IState,
  ownProps: RouteComponentProps<{ id: string; startingTab?: string }>,
) => ({
  team:
    roleSelectors.roleById(parseInt(ownProps.match.params.id, 10))(state) ??
    undefined,
  teamsLoaded: !!roleSelectors.roles(state),
  lgCallCompliance: lgSelectors.callCompliance(state),
  forms: formsSelectors.forms(state),
  startingTab: ownProps.match.params.startingTab,
  loggedInAccount: authSelectors.account(state) as IAccount,
  lgDataRetention: lgSelectors.dataRetention(state),
})

const mapDispatchToProps = {
  loadTeams: fetchRoles,
  loadLgInfo: fetchLicenseGroup,
  updateTeam: updateRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamEdit)
