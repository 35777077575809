import React, { useMemo, useCallback } from 'react'
import {
  Container,
  LogoWrapper,
  LogoContainer,
  UserInfoContainer,
  CaretWrapper,
} from './Styles'
import { ReactComponent as LogoSVG } from '../../common/icons/logo.svg'
import { ReactComponent as AdminSVG } from '../../common/icons/admin.svg'
import {
  colors,
  Small,
  Caret,
  FlexRow,
  DropdownMenu,
  DropdownMenuOption,
  Button,
  Avatar,
  FlexColumn,
  Regular,
} from 'js-components'
import { IUserAccount } from 'truly-ts'
import { TimeLoopComponent } from '../../utils/timed-component'
import { logout } from '../../reducers/auth/actionCreators'
import { openChatSupport } from '../../reducers/chatsupport/actionCreators'
import { useOpenCloseState, useActionCreator } from 'truly-utils'
import useRouter from '../../utils/custom-hooks/useRouter'

interface HeaderProps {
  userAccount: IUserAccount
  company: string
  userLoading: boolean
}

const isIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const Header: React.FC<HeaderProps> = props => {
  const { userAccount, company, userLoading } = props
  const [showingMenu, openMenu, closeMenu] = useOpenCloseState(false)
  const router = useRouter()
  const onLogout = useActionCreator(logout)
  const onChatWithSupport = useActionCreator(openChatSupport)
  const onHelpCenter = useCallback(
    () => window.open('https://intercom.help/truly_co'),
    [],
  )
  const adminOptOut = useCallback(
    () => window.parent.postMessage('admin_opt_out', '*'),
    [],
  )
  const legacyLogOut = useCallback(
    () => window.parent.postMessage('logout', '*'),
    [],
  )
  const v2OptOut = isIframe() && localStorage.getItem('v2OptOut') === 'true'
  const logoutFn = isIframe() ? legacyLogOut : onLogout

  const dropdownOptions = useMemo(
    (): DropdownMenuOption[] => [
      {
        label: 'Chat with Support',
        color: colors.trulyDark,
        onClick: onChatWithSupport,
      },
      {
        label: 'Help Center',
        color: colors.trulyDark,
        onClick: onHelpCenter,
      },
      {
        label: 'Log Out',
        color: colors.trulyDark,
        bold: true,
        onClick: logoutFn,
      },
    ],
    [onChatWithSupport, onHelpCenter, logoutFn],
  )

  const navigateHome = () => {
    router.push('/users')
  }

  const fullName =
    userAccount && `${userAccount.first_name} ${userAccount.last_name}`

  return (
    <Container>
      <LogoContainer onClick={navigateHome}>
        <LogoWrapper>
          <LogoSVG fill={colors.white} />
        </LogoWrapper>
        <AdminSVG fill={colors.white} style={{ marginBottom: '4px' }} />
      </LogoContainer>
      <FlexRow alignItems="center">
        {v2OptOut && (
          <Button
            onClick={adminOptOut}
            size="medium"
            color={colors.accentLavender}
            value="Back To Classic Admin"
          />
        )}
        <DropdownMenu
          minWidth="240px"
          placement="bottom-end"
          horizontalOffset={-12}
          show={showingMenu}
          options={dropdownOptions}
          header={
            <FlexColumn data-cy="user-name-company">
              <Regular color={colors.darkestGray}>{fullName}</Regular>
              <Small
                color={colors.darkestGray}
                bold
                data-cy="header-company-name">
                {company}
              </Small>
            </FlexColumn>
          }
          onClose={closeMenu}>
          <UserInfoContainer
            ml="12px"
            role="button"
            onClick={openMenu}
            data-cy="user-info-button">
            {userLoading ? (
              <Small color={colors.white} mr="12px">
                <TimeLoopComponent
                  loopTime={500}
                  stages={['Loading.', 'Loading..', 'Loading...']}
                />
              </Small>
            ) : (
              <>
                <FlexRow alignItems="center">
                  <Avatar size="small" colorSeed={fullName} text={fullName} />
                  <CaretWrapper>
                    <Caret
                      color={colors.white}
                      size="16px"
                      orientation="bottom"
                    />
                  </CaretWrapper>
                </FlexRow>
              </>
            )}
          </UserInfoContainer>
        </DropdownMenu>
      </FlexRow>
    </Container>
  )
}

export default Header
