import { all, takeLatest, call } from 'redux-saga/effects'

import { FETCH_ENTITIES } from './actionTypes'
import { fetchPhoneMenusSaga } from '../phoneMenus/phoneMenusSaga'
import { fetchInvitesSaga } from '../invites/invitesSaga'
import { fetchAccountsSaga } from '../accounts/accountsSaga'
import { fetchConferenceRoomsSaga } from '../conferenceRooms/conferenceRoomsSaga'
import { fetchExternalUsersSaga } from '../externalUsers/externalUsersSaga'

export function* entitiesSaga() {
  yield all([takeLatest(FETCH_ENTITIES, fetchEntitiesSaga)])
}

function* fetchEntitiesSaga() {
  yield all([
    call(fetchPhoneMenusSaga),
    call(fetchInvitesSaga),
    call(fetchConferenceRoomsSaga),
    call(fetchAccountsSaga),
    call(fetchExternalUsersSaga),
  ])
}
