export const FETCH_CONFERENCE_ROOMS = 'FETCH_CONFERENCE_ROOMS'
export const FETCH_CONFERENCE_ROOMS_SUCCESS = 'FETCH_CONFERENCE_ROOMS_SUCCESS'
export const FETCH_CONFERENCE_ROOMS_FAIL = 'FETCH_CONFERENCE_ROOMS_FAIL'

export const CREATE_CONFERENCE_ROOM = 'CREATE_CONFERENCE_ROOM'
export const CREATE_CONFERENCE_ROOM_FAIL = 'CREATE_CONFERENCE_ROOM_FAIL'
export const UPDATE_CONFERENCE_ROOM_SETTINGS = 'UPDATE_CONFERENCE_ROOM_SETTINGS'

export const CONFERENCE_ROOM_ADDED = 'CONFERENCE_ROOM_ADDED'
export const CONFERENCE_ROOM_UPDATED = 'CONFERENCE_ROOM_UPDATED'

export const DELETE_CONFERENCE_ROOM = 'DELETE_CONFERENCE_ROOM'
export const CONFERENCE_ROOM_DELETED = 'CONFERENCE_ROOM_DELETED'
