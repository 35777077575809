import { connect } from 'react-redux'
import PhoneNumberPurchasePanel from '../components/PhoneNumberPurchasePanel/PhoneNumberPurchasePanel'
import {
  updatePhoneNumber,
  phoneNumberAdded,
} from '../reducers/phoneNumbers/actionCreators'

const mapDispatchToProps = {
  onUpdatePhoneNumber: updatePhoneNumber,
  onPhoneNumberAdded: phoneNumberAdded,
}

export default connect(null, mapDispatchToProps)(PhoneNumberPurchasePanel)
