import styled from 'styled-components/macro'
import { FlexColumn, colors, FlexRow, Regular, spaceSizes } from 'js-components'

export const Container = styled(FlexColumn)`
  position: relative;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const DropdownContainer = styled.div`
  position: absolute;
  padding: 20px;
  top: 0;
  right: 0;
  cursor: pointer;
`

export const Separator = styled.div`
  width: 100%;
  background-color: ${colors.lightGray};
  min-height: 1px;
  max-height: 1px;
`

export const TabListContainer = styled(FlexRow)`
  justify-content: center;
  width: 100%;
  padding: 18px;
  padding-left: 26px;
  flex-shrink: 0;
`

export const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${colors.lightGray};
`

export const ReadonlyText = styled(Regular)`
  color: ${colors.trulyDark};
  margin: 9px;
`

export const AvailabilityPickerWrapper = styled.div`
  width: 100%;
  padding: 0 ${spaceSizes.md}px ${spaceSizes.md}px ${spaceSizes.md}px;
`
