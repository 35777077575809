import {
  ROLES_FETCHED,
  FETCH_ROLES,
  FETCH_ROLES_FAILED,
  ROLE_REMOVED,
  ROLE_ADDED,
  ROLE_UPDATED,
} from './actionTypes'

import keyBy from 'lodash/keyBy'
import {
  rolesFetched,
  roleRemoved,
  roleAdded,
  roleUpdated,
} from './actionCreators'
import { IRole } from 'truly-ts'
import { createReducer } from 'truly-utils'

export interface RolesState {
  loading: boolean
  roles?: { [key: number]: IRole }
}

const INITIAL_STATE: RolesState = {
  loading: false,
}

const roles = createReducer<RolesState>(INITIAL_STATE, {
  [FETCH_ROLES]: state => ({
    ...state,
    loading: true,
  }),
  [ROLES_FETCHED]: (state, action: ReturnType<typeof rolesFetched>) => ({
    ...state,
    roles: keyBy(action.payload.roles, 'id'),
    loading: false,
  }),
  [FETCH_ROLES_FAILED]: state => ({
    ...state,
    loading: false,
  }),
  [ROLE_REMOVED]: (state, action: ReturnType<typeof roleRemoved>) => {
    const rls = { ...state.roles }
    delete rls[action.payload.id]
    return {
      ...state,
      roles: rls,
    }
  },
  [ROLE_ADDED]: (state, action: ReturnType<typeof roleAdded>) => ({
    ...state,
    roles: {
      ...state.roles,
      [action.payload.role.id]: action.payload.role,
    },
  }),
  [ROLE_UPDATED]: (state, action: ReturnType<typeof roleUpdated>) => ({
    ...state,
    roles: {
      ...state.roles,
      [action.payload.role.id]: action.payload.role,
    },
  }),
})

export default roles
