import styled from 'styled-components/macro'
import { colors } from 'js-components'

export const Container = styled.div`
  height: 66px;
  min-height: 66px;
  padding: 12px;
  display: flex;
  border-bottom: 1px solid ${colors.lightGray};
`

export const RightControlContainer = styled.div`
  padding-right: 8px;
`

export const OptionsWrapper = styled.div`
  cursor: pointer;
  padding: 6px 6px 6px 6px;
`
