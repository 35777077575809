import {
  FETCH_CONFERENCE_ROOMS,
  FETCH_CONFERENCE_ROOMS_FAIL,
  FETCH_CONFERENCE_ROOMS_SUCCESS,
  CREATE_CONFERENCE_ROOM,
  CONFERENCE_ROOM_ADDED,
  UPDATE_CONFERENCE_ROOM_SETTINGS,
  CONFERENCE_ROOM_UPDATED,
  DELETE_CONFERENCE_ROOM,
  CREATE_CONFERENCE_ROOM_FAIL,
} from './actionTypes'

import { IConferenceRoom } from 'truly-ts'

export const fetchConferenceRooms = () => ({
  type: FETCH_CONFERENCE_ROOMS,
  payload: {},
})

export const fetchConferenceRoomsSuccess = (rooms: IConferenceRoom[]) => ({
  type: FETCH_CONFERENCE_ROOMS_SUCCESS,
  payload: {
    rooms,
  },
})

export const fetchConferenceRoomsFail = () => ({
  type: FETCH_CONFERENCE_ROOMS_FAIL,
  payload: {},
})

export const createConferenceRoom = (
  name: string,
  number: string,
  isTrulyNumber: boolean,
) => ({
  type: CREATE_CONFERENCE_ROOM,
  payload: {
    name,
    number,
    isTrulyNumber,
  },
})

export const conferenceRoomAdded = (room: IConferenceRoom) => ({
  type: CONFERENCE_ROOM_ADDED,
  payload: {
    room,
  },
})

export const updateConferenceRoomSettings = (
  id: number,
  settings: IConferenceRoom['settings'],
) => ({
  type: UPDATE_CONFERENCE_ROOM_SETTINGS,
  payload: {
    id,
    settings,
  },
})

export const createConferenceRoomFail = () => ({
  type: CREATE_CONFERENCE_ROOM_FAIL,
  payload: {},
})

export const conferenceRoomUpdated = (room: IConferenceRoom) => ({
  type: CONFERENCE_ROOM_UPDATED,
  payload: {
    room,
  },
})

export const deleteConferenceRoom = (room: IConferenceRoom) => ({
  type: DELETE_CONFERENCE_ROOM,
  payload: {
    room,
  },
})
