import * as React from 'react'
import {
  CallComplianceValue,
  IRole,
  ICallComplianceSettings,
  IDataRetentionPolicy,
  IAccount,
} from 'truly-ts'
import {
  MainContainer,
  ContentContainer,
  SmallSectionHeader,
  Label,
  FullWidthWrapper,
  SmallSectionHeaderDescription,
} from '../LayoutHelpers/Styles'
import {
  FlexColumn,
  colors,
  SectionGroup,
  SectionItem,
  FlexRow,
  InlineTextEdit,
  InlineSelectEdit,
  TabList,
  Tab,
  EmptyResults,
  DeleteDialog,
  Link,
  HelpIcon,
  Regular,
} from 'js-components'
import { ReactComponent as MonitorSVG } from 'js-components/src/components/Icons/Monitor.svg'
import { ReactComponent as AdminSVG } from 'js-components/src/components/Icons/Admin.svg'
import Loader from '../Loader/Loader'
import ContentHeading, {
  HeadingDropdownMenu,
} from '../ContentHeading/ContentHeading'
import { TabListContainer } from './Styles'
import {
  CallComplianceDomesticOptions,
  CallComplianceInternationalOptions,
} from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import EnabledSettingRow from '../EnabledSettingRow/EnabledSettingRow'
import SelectSettingRow from '../SelectSettingRow/SelectSettingRow'
import TeamDeleteDialogContainer from '../../containers/TeamDeleteDialogContainer'
import Permissions, { Permission } from '../../constants/Permissions'
import TeamUsersTableContainer from '../../containers/TeamUsersTableContainer'
import { Form } from '../../reducers/forms/types'
import TeamUtils from '../../utils/model-utils/team-utils'
import PhoneMenuSelect from '../PhoneMenuSelect/PhoneMenuSelect'
import { NodeAssignmentType } from '../../constants/phone-menu'
import PageTitle from '../PageTitle/PageTitle'
import { TeamsTitle } from '../../constants/PageTitles'
import { getCallComplianceOptions } from '../../utils/call-compliance'
import DataRetentionValueDisplay from '../DataRetention/DataRetentionValueDisplay'
import ChangeDataRetentionPolicyDialog from '../DataRetention/ChangeDataRetentionPolicyDialog'

const LabelWidth = '125px'

const TabListWrapper: React.SFC = ({ children }) => (
  <TabListContainer data-cy="tab-list">{children}</TabListContainer>
)

interface TeamEditProps {
  team?: IRole
  forms?: Form[]
  lgCallCompliance?: ICallComplianceSettings | null
  lgDataRetention?: IDataRetentionPolicy | null
  teamsLoaded: boolean
  loadTeams: () => void
  updateTeam: (team: IRole) => void
  startingTab?: string
  loadLgInfo: () => void
  loggedInAccount: IAccount
}

interface TeamEditDeleteState {
  deleting: boolean
  verifyingRemoveSelf: boolean
  verifyingDeleteSelfTeam: boolean
  editingDataRetention: boolean
}

export default class TeamEdit extends React.Component<
  TeamEditProps,
  TeamEditDeleteState
> {
  constructor(props: TeamEditProps) {
    super(props)
    this.state = {
      deleting: false,
      verifyingRemoveSelf: false,
      verifyingDeleteSelfTeam: false,
      editingDataRetention: false,
    }
  }

  componentDidMount() {
    if (!this.props.teamsLoaded) {
      this.props.loadTeams()
    }

    if (!Object.keys(this.props.lgCallCompliance ?? {}).length) {
      this.props.loadLgInfo()
    }
  }

  openVerifyRemoveSelf = () => {
    this.setState({
      verifyingRemoveSelf: true,
    })
  }

  closeVerifyRemoveSelf = () => {
    this.setState({
      verifyingRemoveSelf: false,
      verifyingDeleteSelfTeam: false,
    })
  }

  onOpenDataRetentionEdit = () => {
    this.setState({
      editingDataRetention: true,
    })
  }

  onCloseDataRetentionEdit = () => {
    this.setState({
      editingDataRetention: false,
    })
  }

  onDataRetentionChange = (policy: IDataRetentionPolicy | null) => {
    if (!this.props.team) {
      throw new Error('onDataRetentionChange without team')
    }

    this.props.updateTeam({
      ...this.props.team,
      data_retention: policy,
    })
  }

  onDelete = () => {
    if (this.isLoggedInUserAssigned()) {
      // Prevent delete if current user is assigned
      this.setState({
        verifyingDeleteSelfTeam: true,
      })
    } else {
      this.setState({
        deleting: true,
      })
    }
  }

  onConfirmDeleteSelfTeam = () => {
    this.setState({
      verifyingDeleteSelfTeam: false,
      deleting: true,
    })
  }

  onCloseDelete = (deleted: boolean) => {
    this.setState({
      deleting: false,
    })
  }

  onTeamNameChanged = (display_name: string) => {
    if (!this.props.team) {
      throw new Error('onTeamNameChanged without team')
    }

    this.props.updateTeam({
      ...this.props.team,
      display_name,
    })
  }

  onPositionChanged = (position: string) => {
    if (!this.props.team) {
      throw new Error('onPositionChanged without team')
    }

    this.props.updateTeam({
      ...this.props.team,
      position,
    })
  }

  onDepartmentChanged = (department: string) => {
    if (!this.props.team) {
      throw new Error('onDepartmentChanged without team')
    }

    this.props.updateTeam({
      ...this.props.team,
      department,
    })
  }

  onUSCallComplianceChanged = (value: string | boolean) => {
    if (!this.props.team) {
      throw new Error('onUSCallComplianceChanged without team')
    }

    const callComplianceValue =
      value === 'inherit' ? null : (value as CallComplianceValue)
    this.props.updateTeam({
      ...this.props.team,
      usa_call_compliance: callComplianceValue,
    })
  }

  onInternationalCallComplianceChanged = (value: string | boolean) => {
    if (!this.props.team) {
      throw new Error('onInternationalCallComplianceChanged without team')
    }

    const callComplianceValue =
      value === 'inherit' ? null : (value as CallComplianceValue)
    this.props.updateTeam({
      ...this.props.team,
      international_call_compliance: callComplianceValue,
    })
  }

  onFormChanged = (id: string) => {
    if (!this.props.team) {
      throw new Error('onFormChanged without team')
    }

    if (id === 'default') {
      this.props.updateTeam({
        ...this.props.team,
        disposition_list: null,
        form: null,
      })
      return
    }

    const frm = getRequired(
      getRequired(this.props.forms).find(f => f.id === id),
    )
    this.props.updateTeam({
      ...this.props.team,
      disposition_list: null,
      form: {
        id: frm.id,
        display_name: frm.description,
      },
    })
  }

  onCanRecordChanged = (can_record: string) => {
    if (!this.props.team) {
      throw new Error('onCanRecordChanged without team')
    }

    this.props.updateTeam({
      ...this.props.team,
      can_record: can_record === 'true' ? true : false,
    })
  }

  onUnassignAccount = (account: { id: number; display_name?: string }) => {
    if (account.id === this.props.loggedInAccount.id) {
      // If we remove ourselves, verify that we are doing it on purpose
      this.setState({
        verifyingRemoveSelf: true,
      })
    } else {
      this.props.updateTeam(
        TeamUtils.unassignAccount(getRequired(this.props.team), account),
      )
    }
  }

  onConfirmUnassignAccount = () => {
    this.setState({
      verifyingRemoveSelf: false,
    })
    this.props.updateTeam(
      TeamUtils.unassignAccount(getRequired(this.props.team), {
        id: this.props.loggedInAccount.id,
      }),
    )
  }

  onPermissionRemoved(permission: Permission) {
    this.props.updateTeam(
      TeamUtils.removePermission(getRequired(this.props.team), permission),
    )
  }

  onPermissionAdded(permission: Permission) {
    this.props.updateTeam(
      TeamUtils.addPermission(getRequired(this.props.team), permission),
    )
  }

  onPermissionChanged(enabled: boolean, permission: Permission) {
    if (enabled) this.onPermissionAdded(permission)
    else this.onPermissionRemoved(permission)
  }

  onVoiceMonitorChanged = (enabled: boolean) => {
    this.onPermissionChanged(enabled, Permissions.CallMonitor)
  }

  onAdminChanged = (enabled: boolean) => {
    this.onPermissionChanged(enabled, Permissions.AccessAdmin)
  }

  getPermissionEnabled(permission: Permission) {
    return TeamUtils.isPermissionEnabled(
      getRequired(this.props.team),
      permission,
    )
  }

  getFormOptions() {
    const forms = this.props.forms ?? []

    return [
      {
        value: 'default',
        label: 'Default Form',
      },
      ...forms.map(f => ({
        value: f.id,
        label: f.description,
      })),
    ]
  }

  isLoggedInUserAssigned = () => {
    const team = getRequired(this.props.team)
    return (team.accounts || []).some(
      account => account.id === this.props.loggedInAccount.id,
    )
  }

  render() {
    const {
      deleting,
      verifyingRemoveSelf,
      verifyingDeleteSelfTeam,
    } = this.state

    if (!this.props.teamsLoaded || !this.props.team || !this.props.forms) {
      return (
        <FlexColumn>
          <PageTitle title={TeamsTitle} />
          <ContentHeading title="Team" />
          <Loader mt="200px" />
        </FlexColumn>
      )
    }

    const recordCallsOptions = [
      {
        label: 'Automatically',
        description: 'Calls will start recording automatically',
        value: 'true',
      },
      {
        label: 'Manually',
        description:
          'Calls will not record automatically, users can manually start recording by pressing the record button.',
        value: 'false',
      },
    ]

    const team = getRequired(this.props.team)

    // settings should be loaded with user
    const lgCallCompliance = getRequired(this.props.lgCallCompliance)

    return (
      <MainContainer>
        <PageTitle title={TeamsTitle} />
        <ChangeDataRetentionPolicyDialog
          show={this.state.editingDataRetention}
          currentPolicy={team.data_retention}
          onChange={this.onDataRetentionChange}
          onClose={this.onCloseDataRetentionEdit}
          companyPolicy={this.props.lgDataRetention}
        />
        <TeamDeleteDialogContainer
          show={deleting}
          selectedTeam={team}
          onClose={this.onCloseDelete}
          navToTeams
        />
        <DeleteDialog
          show={verifyingRemoveSelf || verifyingDeleteSelfTeam}
          title="You Could Lose Admin Access"
          description="Since you are a member of this team, this action could cause your account to lose access to the admin panel and you could be logged out."
          confirmText="CONTINUE"
          deleteActionText="Continue"
          onCancel={this.closeVerifyRemoveSelf}
          onDelete={
            verifyingDeleteSelfTeam
              ? this.onConfirmDeleteSelfTeam
              : this.onConfirmUnassignAccount
          }
        />
        <ContentHeading
          title={team.display_name}
          backlinks={[{ label: 'Teams', url: '/teams' }]}
          rightControl={
            <HeadingDropdownMenu
              minWidth="200px"
              options={[
                {
                  color: colors.alertRed,
                  bold: true,
                  label: 'Delete Team',
                  onClick: this.onDelete,
                },
              ]}
            />
          }
        />
        <TabList
          tabListWrapper={TabListWrapper}
          startingTab={this.props.startingTab}
          style={{ height: '100%', overflowY: 'auto' }}>
          <Tab name="Details">
            <ContentContainer padTop>
              <SmallSectionHeader first>Basic Information</SmallSectionHeader>
              <SectionGroup>
                <SectionItem>
                  <FlexRow alignItems="center">
                    <Label width={LabelWidth}>Team Name</Label>
                    <FullWidthWrapper>
                      <InlineTextEdit
                        originalValue={team.display_name}
                        onSave={this.onTeamNameChanged}
                        showButtons
                        required
                        data-cy="team.name"
                      />
                    </FullWidthWrapper>
                  </FlexRow>
                </SectionItem>
                <SectionItem>
                  <FlexRow alignItems="center">
                    <Label width={LabelWidth}>Position</Label>
                    <FullWidthWrapper>
                      <InlineTextEdit
                        originalValue={team.position || ''}
                        onSave={this.onPositionChanged}
                        placeholder="ex. SDR"
                        showButtons
                        data-cy="team.position"
                      />
                    </FullWidthWrapper>
                  </FlexRow>
                </SectionItem>
                <SectionItem>
                  <FlexRow alignItems="center">
                    <Label width={LabelWidth}>Department</Label>
                    <FullWidthWrapper>
                      <InlineTextEdit
                        originalValue={team.department || ''}
                        onSave={this.onDepartmentChanged}
                        placeholder="ex. Sales"
                        showButtons
                        data-cy="team.department"
                      />
                    </FullWidthWrapper>
                  </FlexRow>
                </SectionItem>
                <SectionItem>
                  <FlexRow alignItems="center">
                    <HelpIcon>
                      <Regular as="p" mt="0px">
                        Enter the title of a phone menu to easily add an entire
                        Team to a ring group.
                      </Regular>

                      <Regular as="p" mb="0px">
                        To configure routing specifics for a menu, click the
                        phone menu name within this field, or visit the Phone
                        Menu tab in the left navigation column.
                      </Regular>
                    </HelpIcon>
                    <Label width="105px">Phone Menu</Label>
                    <FullWidthWrapper>
                      <PhoneMenuSelect
                        assignmentType={NodeAssignmentType.Role}
                        entity={team}
                      />
                    </FullWidthWrapper>
                  </FlexRow>
                </SectionItem>
              </SectionGroup>
              <SmallSectionHeader>Call Recording</SmallSectionHeader>
              <SectionGroup>
                <SectionItem>
                  <SelectSettingRow
                    title="Record Calls"
                    originalValue={team.can_record ? 'true' : 'false'}
                    options={recordCallsOptions}
                    onSave={this.onCanRecordChanged}
                    data-cy="record-calls"
                  />
                </SectionItem>
                {team.can_record && (
                  <SectionItem>
                    <SelectSettingRow
                      data-cy="us-compliance-row"
                      title="US Compliance"
                      originalValue={team.usa_call_compliance || 'inherit'}
                      onSave={this.onUSCallComplianceChanged}
                      options={getCallComplianceOptions(
                        CallComplianceDomesticOptions,
                        true,
                        lgCallCompliance?.usa_call_compliance,
                      )}
                      width="350px"
                    />
                  </SectionItem>
                )}
                {team.can_record && (
                  <SectionItem>
                    <SelectSettingRow
                      data-cy="intl-compliance-row"
                      title="International Compliance"
                      originalValue={
                        team.international_call_compliance || 'inherit'
                      }
                      onSave={this.onInternationalCallComplianceChanged}
                      options={getCallComplianceOptions(
                        CallComplianceInternationalOptions,
                        true,
                        lgCallCompliance?.international_call_compliance,
                      )}
                      width="350px"
                    />
                  </SectionItem>
                )}
              </SectionGroup>

              <FlexRow alignItems="center">
                <SmallSectionHeader>Forms</SmallSectionHeader>
                <HelpIcon style={{ margin: '20px 0 0 6px' }}>
                  <Regular as="p" mt="0px">
                    Assigning a Form to a Team ensures that all members of the
                    team enter consistent data for each call.
                  </Regular>
                  <Regular as="p" mb="0px">
                    For more information on Forms, read our{' '}
                    <Link
                      href="https://intercom.help/truly_co/en/articles/3188396-how-to-create-custom-disposition-forms-admin-2-0"
                      newWindow>
                      Knowledge Base Article
                    </Link>
                    .
                  </Regular>
                </HelpIcon>
              </FlexRow>

              <SectionGroup>
                <SectionItem>
                  <FlexRow alignItems="center" data-cy="form-row">
                    <Label width="100px">Form</Label>
                    <InlineSelectEdit
                      width="310px"
                      onSave={this.onFormChanged}
                      originalValue={(team.form && team.form.id) || 'default'}
                      options={this.getFormOptions()}
                      data-cy="form-select"
                    />
                  </FlexRow>
                </SectionItem>
              </SectionGroup>
            </ContentContainer>
          </Tab>
          <Tab name="Users">
            <TeamUsersTableContainer
              team={team}
              onUnassignAccount={this.onUnassignAccount}
            />
          </Tab>
          <Tab name="Permissions">
            <ContentContainer padTop>
              <SectionGroup>
                <SectionItem>
                  <EnabledSettingRow
                    enabled={this.getPermissionEnabled(Permissions.AccessAdmin)}
                    title="Admin"
                    enabledDesc="User will be able to access the admin panel."
                    disabledDesc="User will be unable to access the admin panel."
                    onChange={this.onAdminChanged}
                    icon={<AdminSVG width="26px" height="26px" />}
                    data-cy="admin-setting-row"
                    readonly={this.isLoggedInUserAssigned()}
                    readOnlyComponent={
                      <EmptyResults
                        title="You cannot remove admin access because you are assigned to this team."
                        hideIcon
                      />
                    }
                  />
                </SectionItem>
                <SectionItem>
                  <EnabledSettingRow
                    enabled={this.getPermissionEnabled(Permissions.CallMonitor)}
                    title="Call Monitoring"
                    enabledDesc="User will be able to access the call monitoring panel."
                    disabledDesc="User will be unable to access the call monitoring panel."
                    onChange={this.onVoiceMonitorChanged}
                    icon={<MonitorSVG width="26px" height="26px" />}
                    data-cy="permission-setting-row"
                  />
                </SectionItem>
              </SectionGroup>
            </ContentContainer>
          </Tab>
          <Tab name="Settings">
            <ContentContainer padTop>
              <SmallSectionHeader first>Data Retention</SmallSectionHeader>
              <SmallSectionHeaderDescription>
                Determines how long data around your calls and messages will be
                available to your organization. Data retention policies are
                applied retroactively.
              </SmallSectionHeaderDescription>
              <SectionGroup>
                <SectionItem>
                  <DataRetentionValueDisplay
                    editable
                    onEdit={this.onOpenDataRetentionEdit}
                    policy={team.data_retention ?? undefined}
                    organizationPolicy={this.props.lgDataRetention ?? undefined}
                  />
                </SectionItem>
              </SectionGroup>
            </ContentContainer>
          </Tab>
        </TabList>
      </MainContainer>
    )
  }
}
