import * as React from 'react'
import './App.css'
import { connect } from 'react-redux'
import {
  Route,
  withRouter,
  RouteComponentProps,
  Switch,
  Redirect,
} from 'react-router'
import Login from './components/Login/Login'
import { IState } from './store'
import MainLayout from './components/MainLayout/MainLayout'
import PhoneNumbersContainer from './containers/PhoneNumbersContainer'
import ExternalUsersContainer from './containers/ExternalUsersContainer'
import ReportsContainer from './containers/ReportsContainer'
import ConferenceRooms from './components/ConferenceRooms/ConferenceRooms'
import FormsContainer from './containers/FormsContainer'
import FormEditContainer from './containers/FormEditContainer'
import TeamEditContainer from './containers/TeamEditContainer'
import PhoneMenusAndRecordingsContainer from './containers/PhoneMenusAndRecordingsContainer'
import Webhooks from './components/Webhooks/Webhooks'
import PhoneMenuEdit from './components/PhoneMenuEdit/PhoneMenuEdit'
import Integrations from './components/Integrations/Integrations'
import OrganizationSettings from './components/OrganizationSettings/OrganizationSettings'
import Users from './components/Users/Users'
import Teams from './components/Teams/Teams'
import BlockedNumbers from './components/BlockedNumbers/BlockedNumbers'

interface AppProps extends RouteComponentProps {
  isAuth: boolean
}

class App extends React.Component<AppProps> {
  public render() {
    const { isAuth } = this.props

    return (
      <div>
        {!isAuth ? (
          <Login />
        ) : (
          <MainLayout>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/users" />} />
              <Route path="/integrations" component={Integrations} />
              <Route path="/org-settings" component={OrganizationSettings} />
              <Route path="/reports" component={ReportsContainer} />
              <Route path="/phone-numbers" component={PhoneNumbersContainer} />
              <Route
                path="/external-users"
                component={ExternalUsersContainer}
              />
              <Route
                path="/conference-rooms/:id?"
                component={ConferenceRooms}
              />
              <Route path="/forms/:id" component={FormEditContainer} />
              <Route path="/forms" component={FormsContainer} />
              <Route
                path="/teams/:id/:startingTab?"
                component={TeamEditContainer}
              />
              <Route path="/teams" component={Teams} />
              <Route
                path={['/phone-menus/:id/submenu/:nodeId', '/phone-menus/:id']}
                component={PhoneMenuEdit}
              />
              <Route
                path="/phone-menus"
                component={PhoneMenusAndRecordingsContainer}
              />
              <Route path="/blocked-numbers" component={BlockedNumbers} />
              <Route path="/users/:id?" component={Users} />
              <Route path="/webhooks/:id?" component={Webhooks} />
            </Switch>
          </MainLayout>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  isAuth: !!state.auth.loggedInAccount,
})

export default withRouter(connect(mapStateToProps, {})(App))
