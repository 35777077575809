import {
  FETCH_FORMS,
  FETCH_FORMS_FAIL,
  FETCH_FORMS_SUCCESS,
  FORM_DELETED,
  FORM_ADDED,
  FORM_UPDATED,
  SET_FORM_SAVING,
} from './actionTypes'

import keyBy from 'lodash/keyBy'
import { createReducer } from 'truly-utils'
import { getRequired } from 'truly-utils/macro'
import {
  fetchFormsSuccess,
  formDeleted,
  formAdded,
  formUpdated,
  setFormSaving,
} from './actionCreators'
import { Form } from './types'
import { SaveState, SaveStatus } from '../../utils/Saving'

export interface FormsState {
  loading: boolean
  forms?: { [key: string]: Form }
  saveState: SaveState
}

const INITIAL_STATE: FormsState = {
  loading: false,
  saveState: {
    status: SaveStatus.Unsaved,
  },
}

const forms = createReducer<FormsState>(INITIAL_STATE, {
  [FETCH_FORMS]: state => ({
    ...state,
    loading: true,
  }),
  [FETCH_FORMS_SUCCESS]: (
    state,
    action: ReturnType<typeof fetchFormsSuccess>,
  ) => ({
    ...state,
    loading: false,
    forms: keyBy(action.payload.forms, 'id'),
  }),
  [FETCH_FORMS_FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [FORM_DELETED]: (state, action: ReturnType<typeof formDeleted>) => {
    const frms: FormsState['forms'] = { ...state.forms }
    delete frms[action.payload.id]
    return {
      ...state,
      forms: frms,
    }
  },
  [FORM_ADDED]: (state, action: ReturnType<typeof formAdded>) => ({
    ...state,
    forms: {
      ...state.forms,
      [getRequired(action.payload.form.id)]: action.payload.form,
    },
  }),
  [FORM_UPDATED]: (state, action: ReturnType<typeof formUpdated>) => ({
    ...state,
    forms: {
      ...state.forms,
      [getRequired(action.payload.form.id)]: action.payload.form,
    },
  }),
  [SET_FORM_SAVING]: (state, action: ReturnType<typeof setFormSaving>) => ({
    ...state,
    saveState: {
      status: action.payload.saveStatus,
      id: action.payload.formId,
    },
  }),
})

export default forms
