import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { IPhoneMenu, INode, IPhoneNumber } from 'truly-ts'
import { FlexColumn, colors, TabList, Tab, Regular, Link } from 'js-components'
import Loader from '../Loader/Loader'
import { MainContainer } from '../LayoutHelpers/Styles'
import ContentHeading, {
  HeadingDropdownMenu,
} from '../ContentHeading/ContentHeading'
import { TabListContainer } from './Styles'
import { devAssert } from 'truly-utils/macro'
import ContentFooter from '../ContentFooter/ContentFooter'
import PhoneMenuNumbersTable from '../PhoneMenuNumbersTable/PhoneMenuNumbersTable'
import {
  markNodeDirty,
  replaceNode,
  traceNodeExclusive,
  removeNode,
  findNodeById,
} from '../../utils/model-utils/node-utils'
import AddPhoneNumberModal from '../AddPhoneNumberModal/AddPhoneNumberModal'
import {
  getAddedPhoneNumbers,
  getRemovedPhoneNumbers,
  isEditingTreeRoot,
} from '../../utils/model-utils/phone-menus-utils'
import PhoneMenuEditDetailsTab from './components/PhoneMenuEditDetailsTab'
import PhoneMenuEditHoursTab from './components/PhoneMenuEditHoursTab'
import PageTitle from '../PageTitle/PageTitle'
import { PhoneMenuTitle } from '../../constants/PageTitles'
import { usePreloader, useOpenCloseState, useStableCallback } from 'truly-utils'
import * as phoneMenuSelectors from '../../reducers/phoneMenus/phoneMenusSelectors'
import {
  fetchPhoneMenus,
  deletePhoneMenu,
  updatePhoneMenu,
} from '../../reducers/phoneMenus/actionCreators'
import { useSelector, useDispatch } from 'react-redux'
import * as extensionsSelectors from '../../reducers/extensions/extensionsSelectors'
import * as rolesSelectors from '../../reducers/roles/rolesSelectors'
import * as phoneNumbersSelectors from '../../reducers/phoneNumbers/phoneNumbersSelectors'
import { fetchExtensions } from '../../reducers/extensions/actionCreators'
import { fetchRoles } from '../../reducers/roles/actionCreators'
import { fetchPhoneNumbers } from '../../reducers/phoneNumbers/actionCreators'
import { useParams } from 'react-router'
import useDirty from '../../utils/custom-hooks/useDirty'
import useRouter from '../../utils/custom-hooks/useRouter'
import { getNode, getBacklinks } from './utils'
import {
  PhoneMenuDeleteDialog,
  SubmenuDeleteDialog,
} from './components/DeleteDialogs'

const TabListWrapper: React.SFC = ({ children }) => (
  <TabListContainer data-cy="tab-list">{children}</TabListContainer>
)

interface RouteParams {
  id: string
  nodeId: string
}

export default function PhoneMenuEdit() {
  const { id, nodeId } = useParams<RouteParams>()
  const dispatch = useDispatch()
  const { push } = useRouter()

  const phoneMenus = usePreloader(
    phoneMenuSelectors.phoneMenus,
    fetchPhoneMenus,
  )
  const storedPhoneMenu =
    phoneMenus && phoneMenus.find(menu => menu.id === parseInt(id, 10))
  const phoneMenusLoaded = !!useSelector(phoneMenuSelectors.phoneMenuMap)
  const extensions = usePreloader(
    extensionsSelectors.extensions,
    fetchExtensions,
  )
  const teams = usePreloader(rolesSelectors.roles, fetchRoles)
  const phoneNumbers = usePreloader(
    phoneNumbersSelectors.phoneNumbers,
    fetchPhoneNumbers,
  )

  const [phoneMenu, setPhoneMenu] = useState(
    storedPhoneMenu && ({ ...storedPhoneMenu } as IPhoneMenu),
  )
  const [dirty, setDirty, resetDirty] = useDirty(
    'Unpublished Changes',
    'You have made changes to this phone menu that have not been published. Leaving this page without saving will cause changes to be lost.',
    `\\/phone-menus\\/${id}`,
  )
  const [
    showPhoneNumberPurchase,
    openShowPhoneNumberPurchase,
    closeShowPhoneNumberPurchase,
  ] = useOpenCloseState(false)
  const [deleting, setDeleting, clearDeleting] = useOpenCloseState(false)

  const editingNodeId =
    (nodeId && parseInt(nodeId, 10)) ||
    (storedPhoneMenu && storedPhoneMenu.tree.id)

  const [editingNode, setEditingNode] = useState(
    getNode(storedPhoneMenu, editingNodeId),
  )

  // when the phone menu or editing node changes
  useEffect(() => {
    setEditingNode(getNode(phoneMenu, editingNodeId))
  }, [phoneMenu, editingNodeId])

  const resetState = useStableCallback(() => {
    const newPhoneMenu =
      storedPhoneMenu && ({ ...storedPhoneMenu } as IPhoneMenu)
    setPhoneMenu(newPhoneMenu)
    setEditingNode(getNode(newPhoneMenu, editingNodeId))
    resetDirty()
    closeShowPhoneNumberPurchase()
    clearDeleting()
  })

  // Reset state when the original phone menu changes
  useEffect(() => {
    resetState()
  }, [storedPhoneMenu, resetState])

  const openSubmenu = useCallback(
    (node: INode) => {
      setEditingNode(node) // update editing node before navigating occurs so next render will be ready
      if (!storedPhoneMenu) {
        throw new Error("can't openSubmenu without storedPhoneMenu")
      }
      if (node.id === storedPhoneMenu.tree.id) {
        push(`/phone-menus/${storedPhoneMenu.id}`)
      } else {
        push(`/phone-menus/${storedPhoneMenu.id}/submenu/${node.id}`)
      }
    },
    [storedPhoneMenu, push],
  )

  const onPhoneMenuChanged = (pm: IPhoneMenu) => {
    setPhoneMenu(pm)
    setDirty()
  }

  const onConfirmDelete = () => {
    if (!editingNode) {
      throw new Error('onConfirmDelete without editing node')
    }

    if (!phoneMenu) {
      throw new Error('onConfirmDelete without phoneMenu')
    }

    if (isEditingTreeRoot(phoneMenu, editingNode)) {
      if (!storedPhoneMenu) {
        throw new Error('onConfirmDelete without storedPhoneMenu')
      }

      resetDirty()
      dispatch(deletePhoneMenu(storedPhoneMenu))
    } else {
      // find the node to navigate up to
      const nodesBetween = traceNodeExclusive(phoneMenu.tree, editingNode)
      const newTree = removeNode(phoneMenu.tree, editingNode)
      const newEditingNode = nodesBetween.pop() || newTree // go up one level
      if (!newTree || !newEditingNode) {
        throw new Error("can't navigate to null phone menu or tree")
      }
      setPhoneMenu({
        ...phoneMenu,
        tree: newTree,
      })
      setDirty()
      openSubmenu(newEditingNode)
    }
    clearDeleting()
  }

  const getAssignedPhoneNumbers = () => {
    if (!editingNode) {
      throw new Error('getAssignedPhoneNumbers without editing node')
    }

    if (!phoneMenu) {
      throw new Error('getAssignedPhoneNumbers without phoneMenu')
    }

    if (isEditingTreeRoot(phoneMenu, editingNode)) {
      return phoneMenu.phonenumbers
    }
    return editingNode.phonenumbers
  }

  const onAssignNumber = (pn: IPhoneNumber) => {
    if (!editingNode) {
      throw new Error('onAssignNumber without editing node')
    }

    if (!phoneMenu) {
      throw new Error('onAssignNumber without phoneMenu')
    }

    const isEditingRoot = isEditingTreeRoot(phoneMenu, editingNode)

    if (isEditingRoot) {
      setPhoneMenu({
        ...phoneMenu,
        phonenumbers: [...(phoneMenu.phonenumbers || []), pn],
      })
      setDirty()
    } else {
      const updatedNumberNode = {
        ...editingNode,
        phonenumbers: [...(editingNode.phonenumbers || []), pn],
      }

      updateEditingNode(markNodeDirty(updatedNumberNode))
    }
  }

  const onUnassignNumber = (phoneNumber: IPhoneNumber) => {
    if (!editingNode) {
      throw new Error('onUnassignNumber without editing node')
    }

    if (!phoneMenu) {
      throw new Error('onUnassignNumber without phoneMenu')
    }

    const isEditingRoot = isEditingTreeRoot(phoneMenu, editingNode)
    const { full_number } = phoneNumber

    if (isEditingRoot) {
      setPhoneMenu({
        ...phoneMenu,
        phonenumbers: (phoneMenu.phonenumbers || []).filter(
          pn => pn.full_number !== full_number,
        ),
      })
      setDirty()
    } else {
      const updatedNumberNode = {
        ...editingNode,
        phonenumbers: (editingNode.phonenumbers || []).filter(
          pn => pn.full_number !== full_number,
        ),
      }

      updateEditingNode(markNodeDirty(updatedNumberNode))
    }
  }

  const onNameChanged = (name: string) => {
    if (!editingNode) {
      throw new Error('onNameChanged without editing node')
    }

    if (isEditingTreeRoot(phoneMenu, editingNode) && phoneMenu) {
      setPhoneMenu({
        ...phoneMenu,
        name,
      })
      setDirty()
    } else {
      updateEditingNode(
        markNodeDirty({
          ...editingNode,
          pre_select_text_to_speech: name,
        }),
      )
    }
  }

  const isNotFound = () => {
    return (
      (phoneMenus && !storedPhoneMenu) ||
      (phoneMenu &&
        editingNodeId &&
        !findNodeById(phoneMenu.tree, editingNodeId))
    )
  }

  const updateEditingNode = (node: INode) => {
    if (!phoneMenu) {
      throw new Error('updateEditingNode without phoneMenu')
    }

    if (!editingNode) {
      throw new Error('updateEditingNode without editing node')
    }

    const newTree = replaceNode(phoneMenu.tree, node)
    setPhoneMenu({
      ...phoneMenu,
      tree: newTree,
    })
    setEditingNode(findNodeById(newTree, editingNode.id)) // use same changed instance
    setDirty()
  }

  const onSaveChanges = () => {
    if (!phoneMenu) {
      throw new Error('onSaveChanges without phoneMenu')
    }

    devAssert(assert =>
      assert(phoneMenu.name.trim(), 'Name should never be blank'),
    )

    dispatch(updatePhoneMenu(phoneMenu))
    resetDirty()
  }

  const goToPhoneMenus = () => {
    push('/phone-menus')
  }

  const goToUsers = () => {
    push('/users')
  }

  const getSelectablePhoneNumbers = (): IPhoneNumber[] => {
    if (!storedPhoneMenu) {
      throw new Error(
        'getSelectablePhoneNumbers called without storedPhoneMenu',
      )
    }

    if (!phoneMenu) {
      throw new Error('getSelectablePhoneNumbers called without phoneMenu')
    }

    const numbersToRemove = getAddedPhoneNumbers(storedPhoneMenu, phoneMenu)
    const numbersToAdd = getRemovedPhoneNumbers(storedPhoneMenu, phoneMenu)

    if (!phoneNumbers) {
      console.warn('getSelectablePhoneNumbers called without phoneNumbers')
      return []
    }

    return [
      ...phoneNumbers.filter(
        pn => !numbersToRemove.some(n => pn.full_number === n.full_number),
      ),
      ...numbersToAdd.map(n => ({
        // make them appear unassigned
        ...n,
        entity: null,
      })),
    ]
  }

  const menuBacklinks = useMemo(
    () => getBacklinks(openSubmenu, phoneMenu, editingNode),
    [openSubmenu, phoneMenu, editingNode],
  )

  if (isNotFound()) {
    return (
      <FlexColumn>
        <PageTitle title={PhoneMenuTitle} />
        <ContentHeading title={PhoneMenuTitle} />
        <FlexColumn justifyContent="center" alignItems="center">
          <Regular color={colors.trulyDark} mt="32px">
            Oops. We couldn't find this phone menu.
          </Regular>
          <Regular color={colors.trulyDark}>
            <Link onClick={goToPhoneMenus}>Go Back to the Phone Menu List</Link>
          </Regular>
        </FlexColumn>
      </FlexColumn>
    )
  }

  if (
    !phoneMenusLoaded ||
    !storedPhoneMenu ||
    !phoneMenu ||
    !extensions ||
    !teams ||
    !phoneNumbers ||
    !editingNode
  ) {
    return (
      <FlexColumn>
        <PageTitle title={PhoneMenuTitle} />
        <ContentHeading title={PhoneMenuTitle} />
        <Loader mt="200px" />
      </FlexColumn>
    )
  }

  const isEditingRoot = isEditingTreeRoot(phoneMenu, editingNode)

  return (
    <MainContainer>
      <PageTitle title={PhoneMenuTitle} />
      <AddPhoneNumberModal
        onClose={closeShowPhoneNumberPurchase}
        show={showPhoneNumberPurchase}
        onAdd={onAssignNumber}
        selectablePhoneNumbers={getSelectablePhoneNumbers()}
      />
      {isEditingRoot ? (
        <PhoneMenuDeleteDialog
          show={deleting}
          name={phoneMenu.name}
          onCancel={clearDeleting}
          onDelete={onConfirmDelete}
        />
      ) : (
        <SubmenuDeleteDialog
          show={deleting}
          onCancel={clearDeleting}
          onDelete={onConfirmDelete}
        />
      )}
      <ContentHeading
        title={
          isEditingRoot
            ? phoneMenu.name
            : editingNode.pre_select_text_to_speech ?? ''
        }
        textSize="small"
        editableTitle
        collapse
        onTitleChanged={onNameChanged}
        backlinks={menuBacklinks}
        rightControl={
          <HeadingDropdownMenu
            minWidth="260px"
            options={[
              {
                color: colors.alertRed,
                bold: true,
                label: isEditingRoot ? 'Delete Phone Menu' : 'Delete Sub-Menu',
                onClick: setDeleting,
              },
            ]}
          />
        }
      />
      <TabList
        tabListWrapper={TabListWrapper}
        style={{ height: '100%', overflowY: 'auto' }}>
        <Tab name="Phone Menu">
          <PhoneMenuEditDetailsTab
            editingNode={editingNode}
            extensions={extensions}
            teams={teams}
            openSubMenu={openSubmenu}
            phoneMenu={phoneMenu}
            phoneMenuChanged={onPhoneMenuChanged}
            updateEditingNode={updateEditingNode}
          />
        </Tab>
        <Tab name="Phone Numbers">
          <PhoneMenuNumbersTable
            phonenumbers={getAssignedPhoneNumbers() ?? []}
            name={
              isEditingRoot
                ? phoneMenu.name
                : editingNode.pre_select_text_to_speech ?? ''
            }
            onAddNumberClicked={openShowPhoneNumberPurchase}
            onUnassignNumber={onUnassignNumber}
          />
        </Tab>
        {isEditingRoot && (
          <Tab name="Hours">
            <PhoneMenuEditHoursTab
              editingNode={editingNode}
              extensions={extensions}
              phoneMenu={phoneMenu}
              phoneMenuChanged={onPhoneMenuChanged}
              updateEditingNode={updateEditingNode}
              goToUsers={goToUsers}
            />
          </Tab>
        )}
      </TabList>
      <ContentFooter
        actionText="Save"
        closeText="Close"
        actionDisabled={!dirty}
        onActionClicked={onSaveChanges}
        onCloseClicked={goToPhoneMenus}
      />
    </MainContainer>
  )
}
