import { connect } from 'react-redux'
import Forms from '../components/Forms/Forms'
import * as selectors from '../reducers/forms/formsSelectors'
import { IState, Dispatch } from '../store'
import {
  fetchForms,
  deleteForm,
  updateForm,
} from '../reducers/forms/actionCreators'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'

const mapStateToProps = (state: IState) => ({
  forms: selectors.forms(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadForms: bindActionCreators(fetchForms, dispatch),
  navigate: (path: string) => {
    dispatch(push(path))
  },
  deleteForm: bindActionCreators(deleteForm, dispatch),
  updateForm: bindActionCreators(updateForm, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Forms)
