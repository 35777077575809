import { all } from 'redux-saga/effects'
import { authSaga } from '../reducers/auth/authSaga'
import { userSaga } from '../reducers/user/userSaga'
import { lgSettingsSaga } from '../reducers/lgSettings/lgSettingsSaga'
import { phoneNumbersSaga } from '../reducers/phoneNumbers/phoneNumbersSaga'
import { phoneMenusSaga } from '../reducers/phoneMenus/phoneMenusSaga'
import { entitiesSaga } from '../reducers/entities/entitiesSaga'
import { invitesSaga } from '../reducers/invites/invitesSaga'
import { extensionsSaga } from '../reducers/extensions/extensionsSaga'
import { externalUsersSaga } from '../reducers/externalUsers/externalUsersSaga'
import { reportsSaga } from '../reducers/reports/reportsSaga'
import { credentialsSaga } from '../reducers/credentials/credentialsSaga'
import { conferenceRoomsSaga } from '../reducers/conferenceRooms/conferenceRoomsSaga'
import { formsSaga } from '../reducers/forms/formsSaga'
import { rolesSaga } from '../reducers/roles/rolesSaga'
import { messagesSaga } from '../reducers/messages/messagesSaga'
import { accountsSaga } from '../reducers/accounts/accountsSaga'
import { webhooksSaga } from '../reducers/webhooks/webhooksSaga'
import { blockedNumbersSaga } from '../reducers/blockedNumbers/blockedNumbersSaga'

export default function* rootSaga(): any {
  yield all([
    authSaga(),
    userSaga(),
    lgSettingsSaga(),
    phoneNumbersSaga(),
    phoneMenusSaga(),
    invitesSaga(),
    extensionsSaga(),
    entitiesSaga(),
    externalUsersSaga(),
    messagesSaga(),
    reportsSaga(),
    formsSaga(),
    rolesSaga(),
    conferenceRoomsSaga(),
    credentialsSaga(),
    accountsSaga(),
    webhooksSaga(),
    blockedNumbersSaga(),
  ])
}
